import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import IndexHome from './home/Index';
import IndexCampaigns from './campaigns/Index';
import NewCampaign from './campaigns/New';
import DealsList from './deals/DealsList';
import DealFormPage from './deals/DealFormPage';
// import Profile from './profile/Index';
import ProfileStatsPage from './profile/ProfileStatsPage';
import ProfileAboutPage from './profile/ProfileAboutPage';
import Scorecard from './scorecard/Scorecard';
import NotFound from './NotFound';
import KPILeaderboard from './kpi-leaderboard';
import Dashboards from './dashboards/Index';
import SelectChart from './charts/SelectChart';
import NewReward from './rewards/NewReward';
import UsersScreen from './users/UsersScreen';
import OrgsList from './config/OrgsList';
import OrgConfigPage from './config/OrgConfigPage';
import Games from './games';
import DevComp from './config/DevComp';
import TrainingHomePage from './training/TrainingHomePage';
import TrainingCategoryPage from './training/TrainingCategoryPage';
import TrainingVideoPage from './training/TrainingVideoPage';
import Feed from './feed';
import Comments from './feed/Comments';
import NewGame from './games/NewGame';
import NewIncentive from './games/NewIncentive';
import RewardsList from './rewards/RewardsList';
import TeamsScreen from './teams/TeamsScreen';
import TeamPage from './teams/TeamPage';
import TeamFormPage from './teams/TeamFormPage';
import RewardDetails from './rewards/RewardDetails';
import ReportsScreen from './reports/ReportsScreen';
import DataImports from './data-imports/DataImports';
import UserFormPage from './users/UserFormPage';

export default function RouteConfiguration() {
  return (
    <BrowserRouter>
      <RouteWithBG />
    </BrowserRouter>
  );
}

function RouteWithBG() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/feed" />} />
          {/* <Route path="profile/:userId" element={<Profile />} /> */}
          {/* <Route path="profile/:userId" element={<ProfileStatsPage />} /> */}
          <Route path="scorecard/:userId" element={<Scorecard />} />
          <Route path="campaigns" element={<IndexCampaigns />} />
          <Route
            path="charts"
            // @ts-ignore
            element={<SelectChart userID={global?.me?.id} />}
          />
          <Route path="leaderboard/:missionId" element={<IndexHome />} />
          <Route path="leaderboard" element={<IndexHome />} />
          <Route path="kpileaderboard/:kpiId" element={<KPILeaderboard />} />
          <Route path="kpileaderboard" element={<KPILeaderboard />} />
          <Route path="games" element={<Games />} />
          <Route path="training" element={<TrainingHomePage />} />
          <Route path="training/:category" element={<TrainingCategoryPage />} />
          <Route
            path="training/:category/:lessonId"
            element={<TrainingVideoPage />}
          />
          <Route path="games/new-game" element={<NewGame />} />
          <Route path="games/new-incentive" element={<NewIncentive />} />
          <Route path="dashboards/:dashboardId" element={<Dashboards />} />
          <Route path="dashboards" element={<Dashboards />} />
          <Route path="devtool" element={<DevComp />} />
          {/* <Route
            path="pipeline/:userId/:categoryId/:stageId"
            element={<DealsList />}
          /> */}
          <Route path="pipeline" element={<DealsList />} />
          <Route path="pipeline/:dealId" element={<DealsList />} />
          <Route path="pipeline/new" element={<DealFormPage />} />
          <Route path="pipeline/edit/:dealId" element={<DealFormPage />} />
          <Route path="feed" element={<Feed />} />
          <Route path="feed/comments" element={<Comments />} />
          <Route path="campaigns/new" element={<NewCampaign />} />
          <Route path="rewards" element={<RewardsList />} />
          <Route path="rewards/new" element={<NewReward />} />
          <Route path="rewards/details" element={<RewardDetails />} />
          <Route path="users" element={<UsersScreen />} />
          <Route path="users/new" element={<UserFormPage />} />
          <Route path="users/edit/:userId" element={<UserFormPage />} />
          <Route path="users/stats/:userId" element={<ProfileStatsPage />} />
          <Route path="users/about/:userId" element={<ProfileAboutPage />} />
          <Route path="teams" element={<TeamsScreen />} />
          <Route path="teams/:teamId" element={<TeamPage />} />
          <Route path="teams/new" element={<TeamFormPage />} />
          <Route path="teams/edit/:teamId" element={<TeamFormPage />} />
          <Route path="admin-config" element={<OrgsList />} />
          <Route path="admin-config/:orgID" element={<OrgConfigPage />} />
          <Route path="reports" element={<ReportsScreen />} />
          <Route path="imports" element={<DataImports />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
