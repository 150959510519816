import React, { useEffect, useState } from 'react';
import {
  TopThreeCard,
  TopThreeCardImage,
  TopThreeCardImageDefault,
  TopThreeCardPosition,
  TopThreeCardUserName,
  TopThreeContainer,
} from '../NormalDetails.styles';
import getUserAvatar from '../../../../../common/utils/getUserAvatar';
import { LoadingContainer } from '../../../Games.styles';
import CenteredProgress from '../../../../../components/CenteredProgress';
import Game from '../../../_types/Game';
import isTeamGame from '../../../games-helpers/isTeamGame';
import getOrdinalSuffixOf from '../../../games-helpers/getOrdinalSuffixOf';
import { useNavigate } from 'react-router';

interface Props {
  game: Game;
}

const TopThree = ({ game }: Props) => {
  if (isTeamGame(game)) {
    return null;
  }

  const [loading, setLoading] = useState(false);
  const [topThreeUserAvatars, setTopThreeUserAvatars] = useState<string[]>([]);
  const sortedUsers = game.users
    ? game.users.items.sort((a, b) => a.position - b.position)
    : [];
  const topThreeUsers = sortedUsers.slice(0, 3);
  const navigate = useNavigate();

  const getTopThreeUserAvatars = async () => {
    try {
      const avatarPromises = topThreeUsers.map((user) => {
        return getUserAvatar(user.user.imageName, user.user.imageType, true);
      });

      const avatars = await Promise.all(avatarPromises);
      setTopThreeUserAvatars(avatars);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTopThreeUserAvatars();
  }, [game]);

  if (topThreeUsers.length === 0) {
    return null;
  }

  if (loading) {
    <LoadingContainer>
      <CenteredProgress />
    </LoadingContainer>;
  }

  return (
    <TopThreeContainer>
      {/* 2nd */}
      {topThreeUsers.length >= 2 ? (
        <TopThreeCard
          onClick={() => {
            navigate(`/profile/${topThreeUsers[1].userID}`);
          }}
        >
          {topThreeUserAvatars[1] ? (
            <TopThreeCardImage
              position={2}
              src={topThreeUserAvatars[1]}
              alt="avatar"
            />
          ) : (
            <TopThreeCardImageDefault
              position={2}
              src={'/images/default-2nd-profile.svg'}
              alt="avatar"
            />
          )}
          <TopThreeCardPosition>{getOrdinalSuffixOf(2)}</TopThreeCardPosition>
          <TopThreeCardUserName>
            {topThreeUsers[1].user.name.split(' ')[0]}
          </TopThreeCardUserName>
        </TopThreeCard>
      ) : null}

      {/* 1st */}
      <TopThreeCard
        onClick={() => {
          navigate(`/profile/${topThreeUsers[0].userID}`);
        }}
      >
        {topThreeUserAvatars[0] ? (
          <TopThreeCardImage
            position={1}
            src={topThreeUserAvatars[0]}
            alt="avatar"
          />
        ) : (
          <TopThreeCardImageDefault
            position={1}
            src={'/images/default-1st-profile.svg'}
            alt="avatar"
          />
        )}
        <TopThreeCardPosition>{getOrdinalSuffixOf(1)}</TopThreeCardPosition>
        <TopThreeCardUserName>
          {topThreeUsers[0].user.name.split(' ')[0]}
        </TopThreeCardUserName>
      </TopThreeCard>
      {/* 3rd */}
      {topThreeUsers.length >= 3 ? (
        <TopThreeCard
          onClick={() => {
            navigate(`/profile/${topThreeUsers[2].userID}`);
          }}
        >
          {topThreeUserAvatars[2] ? (
            <TopThreeCardImage
              position={3}
              src={topThreeUserAvatars[2]}
              alt="avatar"
            />
          ) : (
            <TopThreeCardImageDefault
              position={3}
              src={'/images/default-3rd-profile.svg'}
              alt="avatar"
            />
          )}
          <TopThreeCardPosition>{getOrdinalSuffixOf(3)}</TopThreeCardPosition>
          <TopThreeCardUserName>
            {topThreeUsers[2].user.name.split(' ')[0]}
          </TopThreeCardUserName>
        </TopThreeCard>
      ) : null}
    </TopThreeContainer>
  );
};

export default TopThree;
