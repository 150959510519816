import React from 'react';
import { KPILeaderboardState } from '../../_types';
import {
  CustomSelect,
  CustomFilterContainer,
  CustomFilterTitle,
} from '../KPIFilter.styles';

interface FilterTeamsProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const FilterTeams = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: FilterTeamsProps) => {
  return (
    <CustomFilterContainer>
      <CustomFilterTitle>Team</CustomFilterTitle>
      <CustomSelect
        onChange={(e) => {
          onChangeKpiLeaderboardState({
            ...kpiLeaderboardState,
            circleLevelID: e.target.value,
            currentPage: 0,
          });
          resetTokens();
        }}
      >
        <option value="" selected>
          Select
        </option>
        {kpiLeaderboardState.teams.length > 0 &&
          kpiLeaderboardState.teams.map((team) => {
            return (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            );
          })}
      </CustomSelect>
    </CustomFilterContainer>
  );
};

export default FilterTeams;
