import React from 'react';
import {
  BackButton,
  SubmissionButton,
  Title,
  ViewContainer,
} from './NewGame.styles';
import Stepper from '../Stepper';
import Step1 from './steps/Step1';
import Step3 from './steps/Step3';
import Step2 from './steps/Step2';
import NewGameState from './_types/NewGameState';
import { useNavigate } from 'react-router';
import { useAlert } from 'react-alert-with-buttons';
import ErrorMessagesState from './_types/ErrorMessagesState';

interface Props {
  steps: string[];
  activeStep: number;
  onChangeActiveStep: (newActiveStep: number) => void;
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  onSaveGame: () => Promise<void>;
  loading: boolean;
  error: any;
  shouldDisableNextOrFinishButton: boolean;
  setShouldDisableNextOrFinishButton: any;
  errorMessages: ErrorMessagesState;
}

const NewGameView: React.FC<Props> = ({
  activeStep,
  steps,
  onChangeActiveStep,
  newGameState,
  onChangeNewGameState,
  onSaveGame,
  shouldDisableNextOrFinishButton,
  setShouldDisableNextOrFinishButton,
  errorMessages,
}) => {
  const navigate = useNavigate();
  const alert = useAlert();

  return (
    <React.Fragment>
      <ViewContainer>
        <BackButton
          onClick={() => {
            alert.open({
              message:
                'Are you sure you wish to cancel the game creation process?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: async () => {
                    navigate('/games');
                    alert.close();
                  },
                  style: {
                    borderRadius: 16,
                    backgroundColor: '#5F27CD',
                    marginRight: 20,
                    color: '#fff',
                    fontWeight: 700,
                  },
                },
                {
                  label: 'No',
                  onClick: () => alert.close(),
                  style: {
                    borderRadius: 16,
                    backgroundColor: '#FF5C77',
                    color: '#fff',
                    fontWeight: 700,
                  },
                },
              ],
            });
          }}
        >
          <img src="/images/back.svg" alt="" />
          <div>Back</div>
        </BackButton>
        <Title>Create New Game</Title>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onChangeActiveStep={onChangeActiveStep}
        />
        {activeStep === 0 && (
          <Step1
            newGameState={newGameState}
            onChangeNewGameState={onChangeNewGameState}
            errorMessages={errorMessages}
          />
        )}
        {activeStep === 1 && (
          <Step2
            newGameState={newGameState}
            onChangeNewGameState={onChangeNewGameState}
            setShouldDisableNextOrFinishButton={
              setShouldDisableNextOrFinishButton
            }
          />
        )}
        {activeStep === 2 && (
          <Step3
            newGameState={newGameState}
            onChangeNewGameState={onChangeNewGameState}
          />
        )}
        <SubmissionButton
          disabled={shouldDisableNextOrFinishButton}
          onClick={async () => {
            if (shouldDisableNextOrFinishButton) return;

            // If last step, we save it
            if (activeStep === steps.length - 1) {
              await onSaveGame();
              navigate('/games');
              return;
            }

            onChangeActiveStep(activeStep + 1);
          }}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </SubmissionButton>
      </ViewContainer>
    </React.Fragment>
  );
};

export default NewGameView;
