import React from 'react';
import AdvanceOptions from '../new-game-inputs/AdvanceOptions';
import ImageThumbnail from '../new-game-inputs/ImageThumbnail';
import AddContestants from '../new-game-inputs/AddContestants';
import NewGameState from '../_types/NewGameState';
import GameImage from '../new-game-inputs/GameImage';
import BracketContestants from '../new-game-inputs/BracketContestants';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const Step3: React.FC<Props> = ({ newGameState, onChangeNewGameState }) => {
  if (newGameState.contestType === 'bracket_manual') {
    return (
      <>
        <BracketContestants
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
        <GameImage
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
      </>
    );
  }

  return (
    <>
      <AddContestants
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
      <ImageThumbnail
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
      <AdvanceOptions
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
    </>
  );
};

export default Step3;
