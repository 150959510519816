import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function DealCardSkeleton() {
  return (
    <Box className={'deal-card-container'}>
      <Box>
        <Box className={'deal-card-name-stage-section'}>
          <Skeleton
            sx={{ bgcolor: 'black', marginBottom: '8px' }}
            variant={'rounded'}
            width={250}
            height={27}
          ></Skeleton>
          <Box className={'deal-card-name-stage-section-right'}>
            <Box className={'deal-card-stage-skeleton-wrapper'}>
              <Skeleton
                variant={'rectangular'}
                sx={{ bgcolor: '#e2d8ff' }}
                width={40}
                height={24}
              ></Skeleton>
            </Box>
            {/* <Button disableRipple={true} className={'ellipsis-button'}>
              <EllipsisIcon></EllipsisIcon>
            </Button> */}
            <Skeleton
              sx={{ bgcolor: '#d0d2d8', marginLeft: '10px' }}
              variant={'circular'}
              width={24}
              height={24}
            ></Skeleton>
          </Box>
        </Box>
        {['', '', ''].map((attr, i) => (
          <Box key={i}>
            <Skeleton
              sx={{ marginBottom: '8px', marginRight: '8px' }}
              variant={'rounded'}
              width={150}
              height={18}
            ></Skeleton>
          </Box>
        ))}
      </Box>
      <Box className={'deal-card-owner-section'}>
        <Box className={'deal-card-owner-section-left'}>
          <Box style={{ display: 'flex', marginBottom: 8 }}>
            <Skeleton
              sx={{ bgcolor: 'black', marginRight: '8px' }}
              variant={'rounded'}
              width={48}
              height={21}
            ></Skeleton>
            <Box className={'deal-card-owner-profile-picture'}>
              <Skeleton variant={'circular'} height={24} width={24}></Skeleton>
            </Box>
            <Skeleton
              sx={{ bgcolor: '#5f27cd' }}
              variant={'rounded'}
              width={100}
              height={21}
            ></Skeleton>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Skeleton
              sx={{ bgcolor: 'black', marginRight: '8px' }}
              variant={'rounded'}
              width={48}
              height={21}
            ></Skeleton>
            <Box className={'deal-card-owner-profile-picture'}>
              <Skeleton variant={'circular'} height={24} width={24}></Skeleton>
            </Box>
            <Skeleton
              sx={{ bgcolor: '#5f27cd' }}
              variant={'rounded'}
              width={100}
              height={21}
            ></Skeleton>
          </Box>
        </Box>
        <Box className={'deal-card-owner-section-right'}>
          <Skeleton
            sx={{ bgcolor: '#868686' }}
            variant={'rounded'}
            width={100}
            height={18}
          ></Skeleton>
        </Box>
      </Box>
    </Box>
  );
}
