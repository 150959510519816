import ImageMetadata from './ImageMetadata';

export default class User {
  constructor(data) {
    this.data = data;
  }

  createImageMetadata() {
    return new ImageMetadata(this.getImageType(), this.getImageId());
  }

  getId() {
    return this.data.user ? this.data.user.id : this.data.subject.id;
  }

  getName() {
    return this.data.user ? this.data.user.name : this.data.subject.name;
  }

  getImageId() {
    return this.data.user
      ? this.data.user.imageName
      : this.data.subject.imageName;
  }

  getFirstName() {
    const firstNameIndex = 1;
    const name = this.getName();
    return name === null ? '' : name.split(' ', firstNameIndex);
  }

  getImageType() {
    console.log('***getImageType: ', this.data);
    return this.data.user
      ? this.data.user.imageType
      : this.data.subject.imageType;
  }

  getPosition() {
    return this.data.user ? this.data.position : this.data.currentPosition;
  }

  getCurrentAchievement() {
    return this.data.user ? this.data.doneActionCount : this.data.val;
  }

  getGoalToAchieve() {
    return this.data.user ? this.data.goalActionCount : this.data.goal;
  }

  calculatePercentComplete() {
    if (this.getGoalToAchieve() === 0) {
      return 100;
    }
    return (this.getCurrentAchievement() / this.getGoalToAchieve()) * 100;
  }
}
