import { Round, SeedType, Team } from './types';

// The totalSeeds is calculated to be the next power of two equal to or greater than totalCompetitors.
// The getInitialTeams function creates teams for the first round, using placeholders as necessary.
// The getTeam function returns a competitor if one exists at the given index, or a placeholder otherwise.

const generateGameBracket = (totalCompetitors: number): Round[] => {
  const rounds: Round[] = [];
  let totalSeeds = Math.pow(2, Math.ceil(Math.log2(totalCompetitors)));
  let isFirstRound = true;
  let currentRound = 1;

  while (totalSeeds >= 2) {
    const round: Round = {
      title: `Round ${currentRound}`,
      seeds: [],
    };

    for (let i = 0; i < totalSeeds / 2; i++) {
      const teams: Team[] = isFirstRound
        ? getInitialTeams(i, totalCompetitors)
        : [
            {
              name: `Round ${rounds.length} Winner`,
              avatar: '/images/add-contestant-empty.svg',
            },
            {
              name: `Round ${rounds.length} Winner`,
              avatar: '/images/add-contestant-empty.svg',
            },
          ];

      const seed: SeedType = {
        id: `seed-${i + 1}`,
        teams,
      };
      round.seeds.push(seed);
    }

    rounds.push(round);
    totalSeeds /= 2;
    isFirstRound = false;
    currentRound += 1;
  }

  return rounds;
};

const getInitialTeams = (
  seedIndex: number,
  totalCompetitors: number
): Team[] => {
  const firstTeamIndex = seedIndex * 2;
  const secondTeamIndex = seedIndex * 2 + 1;

  return [
    getTeam(firstTeamIndex, totalCompetitors),
    getTeam(secondTeamIndex, totalCompetitors),
  ];
};

const getTeam = (teamIndex: number, totalCompetitors: number): Team => {
  return teamIndex < totalCompetitors
    ? { name: 'Add', avatar: '/images/add-contestant.svg' }
    : { name: '', avatar: '/images/add-contestant-empty.svg' };
};

export default generateGameBracket;
