import {
  Box,
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import React, { useEffect, useState /*, useCallback */ } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router';
// import Moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import gql from 'graphql-tag';
import {
  getLocalDateTime,
  newLocalToISODateFormat,
  // getCurrentUTCDate,
} from '../../common/Utilities';
import FlexFields from './FlexFields';
import { BackIcon, GamifyIcon } from '../../components/GamifyIcon';
import { Formik } from 'formik';
import { GamifyToast } from '../../common/CustomToasts';
import DealFormPageSkeleton from './DealFormPageSkeleton';
// import CancelDealFormModal from './CancelDealFormModal';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import './DealFormPage.css';

export default function DealFormPage(props) {
  // NOTE: Only needed when DealFormPage is used at /edit/<dealID>, but it is now used in a modal and can use props
  const location = useLocation();

  // NOTE: DealFormModal is used now, so dealId is passed in as a prob and is not in useParams now
  const { dealId } = useParams();
  const isModal = false;
  let onTriggerDealsRefetch, onTriggerSelectedDealRefetch, setShowDealFormModal;
  // const {
  //   dealId,
  //   dealType,
  //   dealTypesCategoryIdMap,
  //   dealTypesConfigMap,
  //   setShowDealFormModal,
  //   onTriggerDealsRefetch,
  //   onTriggerSelectedDealRefetch,
  //   isModal,
  // } = props;
  // console.log('DealFormPage dealId: ', dealId);

  // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
  const navigate = useNavigate();

  let dealID = '';

  // TO DO: add current owner id as current user id if lambda doesn't set it.
  const newDeal = {
    id: '',
    name: '',
    description: '',
    value: '',
    imageName: '',
    imageType: '',
    status: 'active',
    orgID: global.me.orgID,
    role: '',
    contactID: '',
    contactType: '',
    categoryID: '',
    notes: '',
    followupDate: '',
    contacts: {
      items: [],
    },
  };

  // NOTE: These state variables are replaced by the passed in props because a modal is used now instead of a separate page at /edit/<dealID> and location is no longer needed to pass these state variables to the Form
  const [dealType, setDealType] = useState('');
  const [dealTypesCategoryIdMap, setDealTypesCategoryIdMap] = useState({});
  const [dealTypesConfigMap, setDealTypesConfigMap] = useState({});

  const [checkedLocation, setCheckedLocation] = useState(false);
  const [defaultDispositionID, setDefaultDispositionID] = useState(null);
  const [validateFields, setValidateFields] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState(newDeal);
  const [savedDealID, setSavedDealID] = useState('');
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const handleCancel = () => {
    setCancelOpen(true);
  };

  // check location state for dealTypes maps, get existing deal info if editing
  useEffect(() => {
    setCheckedLocation(true);

    // TO DO: move commented out props below into the location.state of DealsList handleNewDeal/handleEditDeal
    // const { dealID, config, isPinDrop, address, categoryID } = props;

    // // TO GET DEALID DEPENDING ON WHAT DATA IS PROVIDED
    // const dealIdToFetch =
    // dealID || (isPinDrop && !address.isInitialDrop ? address.id : "");

    // NOTE: The following code using location is only needed if DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now so it is replaced by props
    if (!location.state) return;

    let tempDealType, tempDealTypesCategoryIdMap;

    if (location.state.dealType && dealType === '') {
      tempDealType = location.state.dealType;
      setDealType(location.state.dealType);
    }
    if (location.state.dealTypesCategoryIdMap) {
      tempDealTypesCategoryIdMap = location.state.dealTypesCategoryIdMap;
      setDealTypesCategoryIdMap(location.state.dealTypesCategoryIdMap);
    }

    if (location.state.dealTypesConfigMap) {
      setDealTypesConfigMap(location.state.dealTypesConfigMap);
    }

    // NOTE: If DealFormPage is used at a separate /edit/<dealID> route, dealType and dealTypesCategoryIdMap need to be args of this function and the invocation of it below needs tempDealType and tempDealTypesCategoryIdMap from above passed into it
    async function prepareFormData(dealType, dealTypesCategoryIdMap) {
      console.log('dealType: ', dealType);
      console.log('dealTypesCategoryIdMap: ', dealTypesCategoryIdMap);
      setIsFormDataLoading(true);
      // NOTE: dealTypesCategoryIdMap[dealType] is the categoryID
      if (dealId) {
        await fetchDeal(
          dealId,
          dealTypesCategoryIdMap[dealType],
          props.dispositionID
        );
      }

      setIsFormDataLoading(false);
    }

    // prepareFormData();
    prepareFormData(tempDealType, tempDealTypesCategoryIdMap);
  }, [dealId]);

  // console.log(
  //   'DealFormPage location, parmasDealId, dealType, and maps: ',
  //   location,
  //   checkedLocation,
  //   paramsDealId,
  //   dealType,
  //   dealTypesCategoryIdMap,
  //   dealTypesConfigMap
  // );

  // // TO DO: figure out what parameters/ setters are needed in here onCloseDeal and onClose
  // // in MaintainDeal (in DealList in lyferize)
  // const onCloseDeal = useCallback(() => {
  //   setAddDeal(false);
  //   setShowDeal(false);
  //   setIsLoaded(false);
  //   setEditDeal(false);
  //   fetchDeals(
  //     searchAssigneeID,
  //     searchStage,
  //     categoryID,
  //     sortOrder,
  //     searchString,
  //     nextToken,
  //     true,
  //     2000
  //   );
  // }, [
  //   setAddDeal,
  //   setShowDeal,
  //   setIsLoaded,
  //   fetchDeals,
  //   searchAssigneeID,
  //   searchStage,
  //   categoryID,
  //   sortOrder,
  //   searchString,
  //   nextToken,
  // ]);

  // // in CreateDealScreen
  // const onClose = (val) => {
  // console.log("@@@val: ", val);

  //   if (val === undefined) {
  //     if (currentOperation === props.operation) {
  //       onCloseDeal();
  //     } else {
  //       setCurrentOperation(props.operation);
  //     }
  //   } else {
  // console.log ("^^^dealID: ", formikProps.values.dealID);
  //     // if ( currentOperation == "add" ) {
  //     //   if ( props.defaultstageID != "" && val != "" && val !== undefined ) {
  //     //     createFeedItem ( val, "" );
  //     //   }
  //     //   //TODO: setAddDeal(false);
  //     // } else {
  //     //   createFeedItem ( val, "" );
  //     // }
  //     // TODO:  create the feed item, but do NOT change the followup date
  //     // if (currentOperation == "edit") {
  //   //console.log ("stageID: ", stageID);
  //     //   if ( stageID != "") {
  //     //     createFeedItem ( props.dealID, stageID );
  //     //   }
  //     // }
  // console.log("***timed onclose****");
  //     if (currentOperation === props.operation) {
  //       setTimeout(() => onCloseDeal(), 1000);
  //     } else {
  //       setCurrentOperation(props.operation);
  //     }
  //   }
  // }

  // ///////////
  //
  //  get deal
  //
  // ///////////

  async function fetchDeal(
    dealID,
    categoryID,
    // flexFields,
    // address,
    // notes,
    dispositionID
  ) {
    const ourCategoryID = categoryID;

    // NOTE: listKPICategorys query is commented out because it gets the following validation error for web: "Validation error of type FieldUndefined: Field 'listKPICategorys' in type 'Query' is undefined @ 'listKPICategorys'",
    // The web app is using the category-deal type map value, so it should always have category ID from that

    // if (categoryID === undefined || categoryID === null || categoryID === '') {
    //   //
    //   //  TODO:  where does listKPICategorys come from?
    //   //
    //   const defaultCategoryQuery = gql`
    //     query MyQuery($orgID: ID!) {
    //       listKPICategorys(
    //         filter: { orgID: { eq: $orgID }, isDefault: { eq: true } }
    //         limit: 10000
    //       ) {
    //         items {
    //           id
    //         }
    //       }
    //     }
    //   `;
    //   const getDefaultCategoryRet = await API.graphql({
    //     query: defaultCategoryQuery,
    //     variables: { orgID: global.me.orgID },
    //   });
    //   console.log('getDefaultCategoryRet: ', getDefaultCategoryRet);
    //   if (getDefaultCategoryRet.data.listKPICategorys.items.length === 1) {
    //     ourCategoryID = getDefaultCategoryRet.data.listKPICategorys.items[0].id;
    //   } else {
    //     console.error(
    //       'fetchDeal: There must be exactly one default category for org: ' +
    //         global.me.orgID
    //     );
    //     return;
    //   }
    // }

    if (
      dispositionID === '' ||
      dispositionID === undefined ||
      dispositionID === null
    ) {
      setDefaultDispositionID(await getDefaultDispositionID(ourCategoryID));
    } else {
      setDefaultDispositionID(props.dispositionID);
    }

    // TO DO: add back in address if map gets implemented on web
    // const ourFlexAttributes = {};
    // if (dealID === '') {
    //   //
    //   //  Set the address in the flexFields
    //   //
    //   console.log('----address: ', address);
    //   // if ( address !== undefined && address != "" && address != null) {
    //   //   ourFlexAttributes['Zip'] = address['postalCode']
    //   //   ourFlexAttributes['City'] = address['city']
    //   //   ourFlexAttributes['State'] = address['region']
    //   //   ourFlexAttributes['Address'] = address['address']
    //   // }

    //   if (address !== undefined && address !== '' && address !== null) {
    //     for (const ff of flexFields) {
    //       console.log('!!!ff: ', ff);
    //       if ('pinMappingField' in ff) {
    //         if (ff.pinMappingField in address) {
    //           ourFlexAttributes[ff.name] = address[ff.pinMappingField];
    //         }
    //       }
    //       let ourAddress = '';
    //       if ('addressMapping' in ff) {
    //         for (const af of ff.addressMapping) {
    //           console.log('af: ', af);
    //           if (ourAddress === '') {
    //             ourAddress = address[af];
    //           } else {
    //             ourAddress = ourAddress + ', ' + address[af];
    //           }
    //         }
    //         console.log('ourAddress: ', ourAddress);
    //         ourFlexAttributes[ff.name] = ourAddress;
    //       }
    //     }
    //   }
    //   console.log('ourFlexAttributes: ', ourFlexAttributes);
    //   const ourDeal = {
    //     id: '',
    //     name: '',
    //     description: '',
    //     value: '',
    //     imageName: '',
    //     imageType: '',
    //     status: 'active',
    //     orgID: global.me.orgID,
    //     role: '',
    //     contactID: '',
    //     categoryID: '',
    //     contactType: '',
    //     notes: notes,
    //     followupDate: '',
    //     currentOwnerID: global.me.id,
    //     contacts: {
    //       items: [],
    //     },
    //   };

    //   ourDeal.categoryID = ourCategoryID;
    //   ourDeal.flexAttributes = JSON.stringify(ourFlexAttributes);
    //   console.log('ourDeal: ', ourDeal);
    //   setInitialValues(ourDeal);
    //   setIsLoaded(true);
    //   return ourDeal;
    // }

    const myQuery = gql`
      query MyQuery($dealID: ID!) {
        getDeal(id: $dealID) {
          id
          name
          notes
          status
          imageName
          description
          flexAttributes
          categoryID
          value
          currentDispositionID
          currentOwnerID
          pinAddress {
            name
            address
            city
            region
            postalCode
          }
        }
      }
    `;

    // console.log('!!getDeal: ', dealID);
    const getDealRet = await API.graphql({
      query: myQuery,
      variables: { dealID },
    });
    // console.log('!!!getDealRet: ', getDealRet);
    if (!getDealRet.data.getDeal) {
      setInitialValues(newDeal);
      return newDeal;
    }
    const deal = getDealRet.data.getDeal;
    const flexFieldDataJSON = JSON.parse(deal.flexAttributes);
    // console.log('!!!deal: ', deal);

    // let imageList = []
    // const dealImageLists = deal.imageName === ''?[]:JSON.parse(deal.imageName);
    // if (dealImageLists.length > 0) {
    //   for (const image of dealImageLists) {
    //     imageList = [...imageList, image]
    //   }
    // }

    const ourDeal = {
      id: deal.id,
      // name: deal.name,
      // description: deal.description,
      notes: deal.notes,
      // value: deal.value.toString(),
      imageName: deal.imageName,
      imageType: deal.imageType,
      status: deal.status,
      orgID: deal.orgID,
      role: '',
      contactID: '',
      contacts: deal.contacts,
      flexFieldData: deal.flexAttributes !== '' ? flexFieldDataJSON : '',
      pinAddress: deal.pinAddress,
      categoryID: deal.categoryID,
      // currentDispositionID: props.dispositionID,
      currentDispositionID: deal.currentDispositionID
        ? deal.currentDispositionID
        : defaultDispositionID,
      followupDate: '',
      currentOwnerID: deal.currentOwnerID,
    };

    // console.log('^^^^ourDeal: ', ourDeal);
    setInitialValues(ourDeal);
    // setIsLoaded(true);
  }

  // NOTE: createFeedItem is no longer needed as completeMission and updateAddress are done in maintainDealV3!
  // async function createFeedItem(dealID, stageID, values) {
  //   // console.log('createFeedItem: ', dealID, values);
  //   const myQuery = gql`
  //     query MyQuery($dealID: ID!) {
  //       getDeal(id: $dealID) {
  //         flexAttributes
  //         currentStageID
  //         currentDispositionID
  //         name
  //         stage {
  //           flexAttributes
  //         }
  //       }
  //     }
  //   `;

  //   const getDealRet = await API.graphql({
  //     query: myQuery,
  //     variables: { dealID },
  //   });
  //   // console.log('getDealRet: ', getDealRet);
  //   const deal = getDealRet.data.getDeal;
  //   let flexAttributes = {};
  //   if (deal.flexAttributes != null) {
  //     flexAttributes = JSON.parse(deal.flexAttributes);
  //   }

  //   let dealValue = 0;
  //   const KPIConfig = JSON.parse(getDealRet.data.getDeal.stage.flexAttributes);
  //   // console.log('KPIConfig: ', KPIConfig);
  //   if (KPIConfig != null) {
  //     if (KPIConfig.config.deal.dealValueField in flexAttributes) {
  //       dealValue = Number(
  //         flexAttributes[KPIConfig.config.deal.dealValueField]
  //       );
  //     }
  //   }

  //   // KPIConfig.config.deal.dealValueField
  //   let myDoneActionCount;

  //   if (dealValue === 0) {
  //     myDoneActionCount = 1;
  //   } else {
  //     myDoneActionCount = dealValue;
  //   }

  //   let ourStageID = stageID;
  //   if (ourStageID === '') {
  //     ourStageID = deal.currentStageID;
  //   }

  //   try {
  //     const completeMissionInput = {
  //       userMissionID: '',
  //       completedFlag: false,
  //       myDoneActionCount,
  //       comments: values.notes,
  //       // followupDate: shortLocalToISODateFormat(values.followupDate),
  //       doneImage: '',
  //       doneImageType: '',
  //       status: 'completed',
  //       extID: '',
  //       dealID,
  //       stageID: ourStageID,
  //       tmst: newLocalToISODateFormat(getLocalDateTime()),
  //       dispositionID: props.dispositionID
  //         ? props.dispositionID
  //         : defaultDispositionID,
  //     };

  //     if (props.contactType !== undefined && savedDealID === '') {
  //       //
  //       //  Include the contact type if it's defined and we haven't already saved this dealß
  //       //
  //       completeMissionInput.contactType = props.contactType;
  //     }
  //     // console.log('!!completeMissionInput: ', completeMissionInput);
  //     const completeMissionRes = await API.graphql({
  //       query: mutations.completeMission,
  //       variables: {
  //         missionInfo: JSON.stringify(completeMissionInput),
  //       },
  //     });
  //     const addressRcd = {
  //       id: dealID,
  //       name: deal.name,
  //       lastVisited: getCurrentUTCDate(),
  //     };

  //     if (deal.currentDispositionID !== '') {
  //       addressRcd.currentDispositionID = deal.currentDispositionID;
  //     }

  //     if (deal.currentDispositionID === '') {
  //       console.error('Blank deal.currentDispositionID: ', addressRcd);
  //     }
  //     try {
  //       API.graphql(
  //         graphqlOperation(mutations.updateAddress, { input: addressRcd })
  //       );
  //     } catch (err) {
  //       console.error('Error in updateAddress: ' + err);
  //     }
  //     const completeMissionStatus = JSON.parse(
  //       completeMissionRes.data.completeMission
  //     );
  //     // console.log('completeMissionStatus: ', completeMissionStatus);
  //     if (completeMissionStatus.status === 'success') {
  //       return true;
  //     }
  //     GamifyToast.error(
  //       global.appSettings.labels.mission.proper + ' save failed'
  //     );
  //     console.error('save failed: ' + completeMissionStatus.body);
  //     return false;
  //     // } else if (completeMissionStatus.status == "available") {
  //     //   alert (global.appSettings.labels.mission.proper + " Abandoned");
  //     //   //props.fetch(true);
  //     //   return (true);
  //     // } else if (completeMissionStatus.status == "success") {
  //     //   return (true);
  //     // } else if (completeMissionStatus.status.startsWith("WARNING:")) {
  //     //   console.error ( completeMissionRes.data.completeMission ) ;
  //     //   alert (completeMissionRes.data.completeMission );
  //     //   return (false);
  //     // } else {
  //     //   console.error ("error in completeMission(): ", completeMissionRes.data.completeMission);
  //     //   alert (completeMissionRes.data.completeMission + ".   Gamify support has been notified.");
  //     //   return (false);
  //   } catch (err) {
  //     console.error('error in completeMission(): ' + err.message);
  //     GamifyToast.error(err.message);
  //   }
  // }

  // ///////////
  //
  //  update deal
  //
  // ///////////

  async function updateMyDeal(ourDeal) {
    // console.log('updateMyDeal ourDeal: ', ourDeal);

    try {
      const newDeal = await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );
      // console.log('----newDeal: ', newDeal);
      // alert("Deal saved");
      setInitialValues(ourDeal);

      // props.onClose()

      const dealID = JSON.parse(newDeal.data.maintainDealV3);
      return dealID;
    } catch (err) {
      console.error('updateMyDeal(): error saving ' + ':' + err.message);
      // alert("There was an error saving your deal.");
      GamifyToast.error('There was an error saving your deal.');
      return '';
    }
  }

  // ///////////
  //
  //  Create deal
  //
  // ///////////

  async function createMyDeal(values, actions, config, categoryID, onComplete) {
    setIsLoading(true);
    // console.log('createMyDeal args: ', values, config, categoryID, onComplete);
    const flexFields = config.flexFields;

    let ourStage = values.currentStageID; // Use the currentStageID from the input
    if (values.id === '') {
      //
      //  If we're creating a new deal, set the currentStageID = the default stage
      //
      const myQuery = gql`
        query MyQuery($categoryID: ID!) {
          listStageByCategorySequence(
            categoryID: $categoryID
            filter: { isMapDefault: { eq: true } }
            limit: 10000
          ) {
            items {
              id
              sequence
            }
          }
        }
      `;
      const myQueryRes = await API.graphql({
        query: myQuery,
        variables: { categoryID },
      });
      // console.log('createMyDeal myQueryRes: ', myQueryRes);
      const stages = myQueryRes.data.listStageByCategorySequence.items;

      const sortedStages = stages.sort((a, b) => {
        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;
        return 0;
      });

      ourStage = sortedStages[0].id;
    }
    let flexFieldData;
    // console.log('createMyDeal !!!values: ', values);
    if ('flexFieldData' in values) {
      // console.log ("***found flexFieldData", values.flexFieldData);
      flexFieldData = values.flexFieldData;
    } else {
      // console.log ("***found flexAttributes", values.flexAttributes);
      flexFieldData = values.flexAttributes;
    }
    if (typeof flexFieldData === 'string') {
      flexFieldData = JSON.parse(flexFieldData);
    }

    // console.log('createMyDeal BEFORE flexFieldData: ', flexFieldData);
    // console.log('createMyDeal ourStage: ', ourStage);

    const ourDeal = {
      name: '',
      // description: values.description,
      // notes: values.notes,
      status: values.status,
      imageName: values.imageName,
      imageType: values.imageType,
      currentStageID: ourStage,
      currentOwnerID: values.currentOwnerID,
      currentOwnerType: 'user',
      orgID: values.orgID,
      // value: values.value,
      isDeleted: false,
      flexAttributes: JSON.stringify(flexFieldData),
      // categoryID: values.categoryID,
      categoryID: values.categoryID || categoryID,
      currentDispositionID: props.dispositionID
        ? props.dispositionID
        : defaultDispositionID,
      tmst: newLocalToISODateFormat(getLocalDateTime()),
    };
    // console.log('createMyDeal @@@ourDeal: ', ourDeal);
    for (const ff of flexFields) {
      // console.log('ff: ', ff);
      if ('createMyDeal dealField' in ff) {
        // console.log ("found dealField: '" + ff.dealField + "'");
        if (ff.dealField in ourDeal) {
          // console.log ("found dealField, name: '" +  ff.name + "'");
          //
          // Append to the value that's already there
          //
          if (ourDeal[ff.dealField].length > 0) {
            ourDeal[ff.dealField] =
              ourDeal[ff.dealField] + ' ' + flexFieldData[ff.name];
          } else {
            ourDeal[ff.dealField] = flexFieldData[ff.name];
          }
        } else {
          //
          //  New value, just create it
          //
          ourDeal[ff.dealField] = flexFieldData[ff.name];
        }
      }
    }

    // console.log(
    //   'createMyDeal config.dealMappingsName: ',
    //   config.dealMappingsName
    // );
    // console.log('createMyDeal AFTER flexFieldData: ', flexFieldData);

    if (config.dealMappingsName !== '') {
      const fields = config.dealMappingsName.split(',');
      for (const field of fields) {
        // console.log("createMyDeal field: '" + field.trim() + "'");
        // console.log("createMyDeal ourDeal.name: '" + ourDeal.name + "'");
        if (field.trim() in flexFieldData) {
          if (ourDeal.name) {
            ourDeal.name += ' ';
          }
          ourDeal.name += flexFieldData[field.trim()];
        }
      }
      // console.log('createMyDeal ourDeal.name: ', ourDeal.name);
    }
    // console.log(
    //   'createMyDeal config.dealMappingsDescription: ',
    //   config.dealMappingsDescription
    // );
    if (config.dealMappingsDescription !== '') {
      if (config.dealMappingsDescription in flexFieldData) {
        ourDeal.description = flexFieldData[config.dealMappingsDescription];
      }
    }
    // console.log(
    //   'createMyDeal config.dealMappingsValue: ',
    //   config.dealMappingsValue
    // );
    if (config.dealMappingsValue !== '') {
      if (config.dealMappingsValue in flexFieldData) {
        ourDeal.value = flexFieldData[config.dealMappingsValue];
      }
    }

    // console.log('createMyDeal !!!ourDeal: ', ourDeal);
    // console.log('createMyDeal !!!values: ', values);
    if (values.id !== '') {
      ourDeal.id = values.id;
      // console.log('updateMyDeal: ', ourDeal);
      const ret = await updateMyDeal(ourDeal);
      // console.log('---ret: ', ret);

      // NOTE: updateMyDeal's ret is "" if it fails, should stop function and loading if that happens
      if (ret === '') {
        console.error('updateMyDeal error');
        setIsLoading(false);
        return;
      }

      // NOTE: createFeedItem is no longer needed as completeMission and updateAddress are done in maintainDealV3!
      // const feedItemRet = await createFeedItem(ret, '', values);

      // // NOTE: createFeedItem's ret is false if it fails, should stop function and loading if that happens
      // if (!feedItemRet) {
      //   console.error('createFeedItem error');
      //   setIsLoading(false);
      //   return;
      // }

      if (onComplete) {
        onComplete(true);
      }

      // NOTE: Need to trigger refetch of deals if on a modal because there is not reroute to trigger the deals fetch now
      if (isModal) {
        onTriggerDealsRefetch();
        onTriggerSelectedDealRefetch();
      }

      setIsLoading(false);
      GamifyToast.success(`Deal updated successfully`);

      return ret;
    }
    // console.log ("props.addressID: ", props.addressID);

    // if (props.addressID !== "undefined") {
    //   ourDeal.id = props.addressID;
    // }

    try {
      const createDealRes = await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );
      // console.log('createMyDeal createDealRes: ', createDealRes);
      dealID = JSON.parse(createDealRes.data.maintainDealV3);
    } catch (err) {
      // TO DO:
      GamifyToast.error(`There was an error saving your deal`);
      setIsLoading(false);
      console.error('createMyDeal: error creating deal: ' + err.message);
      if (onComplete) {
        onComplete(false);
      }
      return '';
    }

    // NOTE: createFeedItem is no longer needed as completeMission and updateAddress are done in maintainDealV3!
    // const feedItemRet = await createFeedItem(dealID, '', values);

    // // NOTE: createFeedItem's ret is false if it fails, should stop function and loading if that happens
    // if (!feedItemRet) {
    //   console.error('createFeedItem error');
    //   setIsLoading(false);
    //   return;
    // }

    initialValues.id = dealID;
    setInitialValues(initialValues);
    setSavedDealID(dealID);
    if (onComplete) {
      onComplete(true);
    }

    // NOTE: Need to trigger refetch of deals if on a modal because there is not reroute to trigger the deals fetch now
    if (isModal) {
      onTriggerDealsRefetch();
    }

    console.log('actions: ', actions);
    actions.resetForm();

    setIsLoading(false);
    GamifyToast.success(`Deal created successfully`);
    return dealID;
  }

  // For Formik onSubmit:
  async function doSubmit(values, actions, config, categoryID) {
    // console.log(
    //   'doSubmit args (values, actions, config, categoryID): ',
    //   values,
    //   actions,
    //   config,
    //   categoryID
    // );
    if (values.flexFieldData === undefined) {
      //
      //  flexFieldData is undefined if we haven't changed anything in the form
      //

      // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
      // setShowDealFormModal(false);
      navigate(`/pipeline/${savedDealID}`);

      // onClose(savedDealID);
    }

    const ret = await createMyDeal(values, actions, config, categoryID, false);
    if (ret !== '') {
      // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
      // setShowDealFormModal(false);
      navigate(`/pipeline/${ret}`);

      // props.onClose(ret);
    }
    actions.setSubmitting(false);
  }

  async function getDefaultDispositionID(categoryID) {
    // console.log('getDefaultDispositionID categoryID: ', categoryID);
    const myQuery = gql`
      query MyQuery($categoryID: ID = "") {
        listStageDispositionByCategoryID(
          categoryID: $categoryID
          filter: { isDeleted: { eq: false } }
        ) {
          items {
            id
            isDefault
            title
          }
        }
      }
    `;
    const ret = await API.graphql({
      query: myQuery,
      variables: { categoryID },
    });
    // console.log('getDefaultDisposition: ', ret);
    let _defaultDispositionID = '';
    let defaultDefined = false;
    for (const disposition of ret.data.listStageDispositionByCategoryID.items) {
      // console.log('!!!disposition: ', disposition);
      if (disposition.isDefault) {
        _defaultDispositionID = disposition.id;
        defaultDefined = true;
      }
      if (
        !defaultDefined &&
        disposition.title.toString().toLowerCase().trim() === 'interested'
      ) {
        _defaultDispositionID = disposition.id;
      }
    }
    if (_defaultDispositionID === '') {
      console.error(
        `No default disposition or interested disposition found for category: ${categoryID}.`
      );
    }
    return _defaultDispositionID;
  }

  if (!checkedLocation || isFormDataLoading) {
    // if (true) {
    // console.log('DealFormPageSkeleton checkedLocation: ', checkedLocation);
    // console.log('DealFormPageSkeleton isFormDataLoading: ', isFormDataLoading);
    return (
      <DealFormPageSkeleton
        setShowModal={setShowDealFormModal}
        isModal={isModal}
      ></DealFormPageSkeleton>
    );
  }

  // TO DO: put paramsDealId check into ternaries in JSX below to render add or edit onClicks or add/edit text
  if (dealType !== '') {
    return (
      // New Deal: navigated from Deals List, deal type is set from there
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnMount={true} // Need this to validate before first submit attempt
        // validationSchema={createDealValidationSchema} // TO DO: Add yup validation schema here over doing manual validation functions
        onSubmit={(values, actions) =>
          doSubmit(
            values,
            actions,
            dealTypesConfigMap[dealType],
            dealTypesCategoryIdMap[dealType]
          )
        }
      >
        {(formikProps) => (
          <Box
            className={
              isModal ? 'deal-form-modal-container' : 'deal-form-page-container'
            }
          >
            {/* {console.log('formikProps: ', formikProps)} */}
            {/* <CancelDealFormModal
                props={{ cancelOpen, setCancelOpen }}
              ></CancelDealFormModal> */}
            <DynamicConfirmModal
              showDynamicConfirmModal={cancelOpen}
              setShowDynamicConfirmModal={setCancelOpen}
              zIndex={100002}
              leftAlignText={true}
              title={'Leave without saving changes?'}
              subtitle={`If you leave without saving, your progress will be lost.`}
              cancelButtonText={'Leave'}
              onCancel={() => {
                // NOTE: The following is for if DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
                // setShowDealFormModal(false);
                if (dealId) {
                  navigate(`/pipeline/${dealId}`);
                } else {
                  navigate('/pipeline');
                }
                setCancelOpen(false);
              }}
              confirmButtonText={'Keep editing'}
              onConfirm={() => {
                setCancelOpen(false);
              }}
            ></DynamicConfirmModal>
            <Box
              className={
                isModal
                  ? 'deal-form-modal-top-container'
                  : 'deal-form-page-top-container'
              }
            >
              <Box
                className={
                  isModal
                    ? 'deal-form-modal-cancel-container'
                    : 'deal-form-page-cancel-container'
                }
              >
                <Box className={'deal-form-cancel-button-container'}>
                  <Button
                    className={
                      isModal
                        ? 'deal-form-modal-cancel-button'
                        : 'deal-form-page-cancel-button'
                    }
                    onClick={() => {
                      if (formikProps.dirty) {
                        handleCancel();
                      } else {
                        // NOTE: The following is for if DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
                        // setShowDealFormModal(false);
                        if (dealId) {
                          navigate(`/pipeline/${dealId}`);
                        } else {
                          navigate('/pipeline');
                        }
                      }
                    }}
                    disableRipple={true}
                  >
                    <BackIcon></BackIcon>
                    <Typography
                      className={
                        isModal
                          ? 'deal-form-modal-cancel-button-text'
                          : 'deal-form-page-cancel-button-text'
                      }
                    >
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box
                  className={
                    isModal
                      ? 'deal-form-modal-title-container'
                      : 'deal-form-page-title-container'
                  }
                >
                  <Typography className={'deal-form-title-text'}>
                    {dealId
                      ? `Edit ${dealType.toLowerCase()} deal`
                      : `New ${dealType.toLowerCase()} deal`}
                  </Typography>
                </Box>
                <Box className={'deal-form-place-holder-container'}></Box>
              </Box>
            </Box>
            <Box
              className={
                isModal
                  ? 'modal-deal-form-container'
                  : 'page-deal-form-container'
              }
            >
              <FlexFields
                dealType={dealType}
                dealTypesConfigMap={dealTypesConfigMap}
                dealId={dealId}
                values={formikProps.values}
                validateFields={validateFields}
                // TO DO: canSave also used in onSubmit of create/edit button
                // canSave={(val) => {
                //   setCanSave(val);
                // }}
                canSave={canSave}
                setCanSave={setCanSave}
                fieldSetter={(val) => {
                  formikProps.setFieldValue('flexFieldData', val);
                }}
                hasSubmitted={hasSubmitted}
                setHasSubmitted={setHasSubmitted}
              ></FlexFields>
              <Box
                style={{
                  backgroundColor: '#F0F0F3',
                  borderRadius: 8,
                  marginTop: 15,
                  paddingBottom: 0,
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    backgroundColor: '#D0D2D8',
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
                  >
                    Notes
                  </Typography>
                  <GamifyIcon icon={'notes'} color="#323232" />
                </Box>
                <Box style={{ padding: '24px 16px 8px 16px' }}>
                  <TextareaAutosize
                    style={{
                      border: 'none',
                      borderRadius: 8,
                      width: '100%',
                      resize: 'none',
                      padding: '16px 12px 16px 12px',
                    }}
                    placeholder={'Add notes here'}
                    value={formikProps.values.notes}
                    onChange={(e) => {
                      formikProps.setFieldValue('notes', e.target.value);
                    }}
                  ></TextareaAutosize>
                </Box>
              </Box>
              <Box className={'save-deal-button-container'}>
                <Button
                  className={'save-deal-button'}
                  onClick={() => {
                    // console.log(
                    //   'formikProps.values.flexFieldData: ',
                    //   formikProps.values?.flexFieldData
                    // );
                    // if ( formikProps.values.flexFieldData === undefined ) {
                    //   alert ("Please enter something in this form before you press save.");
                    // } else {
                    //
                    //  Something changed, so save it
                    //
                    if (!canSave) {
                      GamifyToast.error('Please fix all errors before saving.');
                      // alert('Please fix all errors before saving.');
                    }
                    setHasSubmitted(true);
                    setValidateFields(true);
                    // const errors = readyToSave ( flexFields, formikProps.values );

                    // TO DO: comment everything below back in after putting all props into location.state
                    // if (
                    //   props.requireDate &&
                    //   !global.appSettings.features.deal
                    //     .hideFollowupDateField &&
                    //   formikProps.values.followupDate === ""
                    // ) {
                    //   alert(
                    //     "Please enter a new contact date before saving"
                    //   );
                    // } else
                    if (canSave) {
                      formikProps.handleSubmit();
                    }
                  }}
                  disableRipple={true}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{ color: 'white', height: 24, width: 24 }}
                    ></CircularProgress>
                  ) : (
                    <Typography className={'save-deal-button-text'}>
                      {/* TO DO: change text based on design */}
                      {dealId ? 'Update deal' : 'Save changes'}
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    );
  } else {
    // TO DO: Make display to give option to create deal based off all possible deal types?
    return (
      <></>
      // <Typography className={'test-deal-form-text'}>
      //   SELECT DEAL TYPE
      // </Typography>
    );
  }
}
