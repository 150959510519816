import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import {
  Box,
  Snackbar,
  Backdrop,
  Button,
  TextField,
  Switch,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import './CRMConfig.css';
import DealStageEditor from './DealStageEditor';
import { SelectDisposition, SelectStage } from '../../common/PickerSearch';
import {
  createStageCategory,
  updateStageCategory,
} from '../../../graphql/mutations';
import CreateFlexForm from './CreateFlexForm';

export default function CRMConfig(props) {
  const { orgID } = props;
  const flexAttributesTemp = {
    config: {
      saveButtonLabel: 'Save',
      deal: {
        label: 'Customer',
        flexFields: [],
        displayFields: [],
        pickerDisplayFields: [],
        dealMappingsName: '',
        dealMappingsDescription: '',
        availabiltyStage: '',
        availabiltyDisposition: '',
        dealMappingsValues: '',
      },
    },
  };
  const newCRMTemp = {
    orgID,
    title: '',
    iconName: '',
    iconType: '',
    iconColor: '',
    flexAttributes: JSON.stringify(flexAttributesTemp),
    isDefault: false,
    isDeleted: false,
    enablePindrop: true,
    enableDeal: true,
  };

  const [myCategories, setMyCategories] = useState([]);
  const [activeCRM, setActiveCRM] = useState(null);
  const [createBackdrop, setCreateBackdrop] = useState(false);
  const [newCRMObj, setNewCRMObj] = useState(newCRMTemp);
  const [dealSetupDispositions, setDealSetupDispositions] = useState(null);
  const [stageDispositionsMap, setStageDispositionsMap] = useState(null);

  async function fetchMyStageCategories(title = 'Contacts') {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listStageCategorys(filter: { orgID: { eq: $orgID } }, limit: 10000) {
          items {
            id
            title
            isDefault
            iconName
            iconType
            iconColor
            flexAttributes
            createdBy
            createdAt
            updatedAt
            enablePindrop
            enableDeal
            orgID
            isDeleted
            lastUpdatedBy
          }
        }
      }
    `;
    //      isSetupDeal: { eq: true }
    const dispositionQuery = gql`
      query MyQuery($orgID: ID!, $nextToken: String) {
        listStageDispositions(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          nextToken: $nextToken
        ) {
          items {
            id
            iconName
            isDeleted
            orgID
            title
            categoryID
            isSetupDeal
            requireDate
          }
          nextToken
        }
      }
    `;
    try {
      const listCategoryRes = await API.graphql({
        query: myQuery,
        variables: { orgID },
      });
      const categories = listCategoryRes.data.listStageCategorys.items;

      let nextToken = null;
      let allStageDispositions = [];
      do {
        const listDispositions = await API.graphql({
          query: dispositionQuery,
          variables: { orgID, nextToken },
        });
        const stageDispositions =
          listDispositions.data.listStageDispositions.items;
        allStageDispositions = allStageDispositions.concat(stageDispositions);
        nextToken = listDispositions.data.listStageDispositions.nextToken;
      } while (nextToken);

      const allDispositionObj = {};
      const setupDealObj = {};

      allStageDispositions.forEach((dis) => {
        if (!allDispositionObj[dis.categoryID]) {
          allDispositionObj[dis.categoryID] = [];
        }
        allDispositionObj[dis.categoryID].push(dis);
        if (dis.isSetupDeal) {
          if (!setupDealObj[dis.categoryID]) {
            setupDealObj[dis.categoryID] = [];
          }
          setupDealObj[dis.categoryID].push(dis);
        }
      });

      // const dispositionObj = allStageDispositions.reduce((acc, el) => {
      //   if (!acc[el.categoryID]) {
      //     acc[el.categoryID] = [];
      //   }
      //   acc[el.categoryID].push(el);
      //   return acc;
      // }, {});
      setMyCategories(categories);
      setDealSetupDispositions(setupDealObj);
      setStageDispositionsMap(allDispositionObj);
      let activeCRMTemp = categories.find((el) => el.title === title);
      if (!activeCRMTemp) activeCRMTemp = categories[0].title;
      setActiveCRM(activeCRMTemp);
    } catch (err) {
      alert('ERROR');
      console.error(
        'fetchMyStageCategories(): error fetching my categories',
        err
      );
    }
  }

  const handleCrmChange = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const cat = myCategories.find((el) => el.title === val);
    setActiveCRM(cat);
  };

  const crmCreateChange = (e) => {
    setNewCRMObj({
      ...newCRMObj,
      [e.target.name]: e.target.value,
    });
  };

  const createNewCRM = async () => {
    const submitCRM = { ...newCRMObj };
    submitCRM.flexAttributes = JSON.stringify(flexAttributesTemp);
    try {
      await API.graphql(
        graphqlOperation(createStageCategory, { input: submitCRM })
      );
    } catch (err) {
      console.error('Error creating new CRM ', err);
      return false;
    }
    fetchMyStageCategories(submitCRM.title);
    setCreateBackdrop(false);
  };

  useEffect(() => {
    fetchMyStageCategories();
  }, []);
  if (!myCategories || !activeCRM) return null;
  return (
    <View style={{ padding: '5px 10px' }}>
      <Box id="crm-titles-box">
        <Box style={{ display: 'flex' }}>
          {myCategories.map((cat, i) => (
            <Box
              key={i}
              name={cat.title}
              className={`crm-title-button ${
                activeCRM.title === cat.title ? 'active-crm' : 'non-active-crm'
              }`}
              onClick={handleCrmChange}
            >
              {cat.title}
            </Box>
          ))}
        </Box>
        <Box
          className="crm-title-button create-crm-button"
          onClick={() => setCreateBackdrop(true)}
        >
          CREATE CRM +
        </Box>
      </Box>
      {createBackdrop && (
        <Backdrop
          sx={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={createBackdrop}
        >
          <Box id="create-crm-backdrop">
            <Box
              className="backdrop-close-button"
              onClick={() => setCreateBackdrop(false)}
            >
              X
            </Box>
            <Box id="form-top-bar">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.4rem',
                  color: 'white',
                }}
              >
                CREATE NEW CRM
              </Text>
            </Box>
            <Box style={{ padding: '15px', width: '100%' }}>
              <Box style={{ width: '100%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Title
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="title"
                  value={newCRMObj.title}
                  onChange={crmCreateChange}
                />
              </Box>
              <Box>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Icon Name
                </Text>
                <TextField
                  style={{ width: '100%', fontWeight: 'bold' }}
                  name="iconName"
                  value={newCRMObj.iconName}
                  onChange={crmCreateChange}
                />
              </Box>
              <Box>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Icon Type
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="iconType"
                  value={newCRMObj.iconType}
                  onChange={crmCreateChange}
                />
              </Box>
            </Box>
            <Button
              onClick={createNewCRM}
              disabled={newCRMObj.title.length < 2}
              variant="contained"
              style={{ backgroundColor: '#6c7bc5', padding: '10px 20px' }}
            >
              CREATE
            </Button>
          </Box>
        </Backdrop>
      )}
      <EditCRM
        CRM={activeCRM}
        dealSetupStageDispositions={dealSetupDispositions[activeCRM.id]}
        stageDispositions={stageDispositionsMap[activeCRM.id]}
        refreshCRMs={(title) => fetchMyStageCategories(title)}
      />
    </View>
  );
}

export function EditCRM(props) {
  const { CRM, dealSetupStageDispositions } = props;
  const [activeTab, setActiveTab] = useState('general');
  const [myStages, setMyStages] = useState(null);
  const [singleStage, setSingleStage] = useState(null);
  const [stageBackdropOpen, setStageBackdropOpen] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState({ open: false, message: '' });
  const [CRMObj, setCRMObj] = useState(CRM);
  const [ogCRMObj, setOgCRMObj] = useState(CRM);
  const [flexAttributes, setFlexAttributes] = useState(null);
  const [ogFlexAttributes, setOgFlexAttributes] = useState(null);
  const [flexFields, setFlexFields] = useState([]);
  const [ogFlexFields, setOgFlexFields] = useState([]);
  const [openFormEditor, setOpenFormEditor] = useState(false);
  const [changed, setChanged] = useState(false);
  //   const [sanckbarMessage,setSnackbarMessage] = useState('')
  // console.log('STAGE DISPOS!!', dealSetupStageDispositions);
  useEffect(() => {
    if (!flexAttributes || !CRMObj || !flexFields) return;
    setChanged(
      () =>
        JSON.stringify(CRMObj) !== JSON.stringify(ogCRMObj) ||
        JSON.stringify({ ...flexAttributes.config.deal }) !==
          JSON.stringify({ ...ogFlexAttributes.config.deal }) ||
        JSON.stringify(flexFields) !== JSON.stringify(ogFlexFields)
    );
  }, [flexAttributes, flexFields, CRMObj]);

  async function fetchMyStages(categoryID) {
    const myQuery = gql`
      query MyQuery($categoryID: ID!, $orgID: ID!, $nextToken: String) {
        listStages(
          filter: {
            orgID: { eq: $orgID }
            categoryID: { eq: $categoryID }
            isDeleted: { eq: false }
          }
          limit: 10000
          nextToken: $nextToken
        ) {
          items {
            id
            action
            actionPastTense
            categoryID
            countUnit
            calendarAppointmentStatus
            countUnitPlural
            createdAt
            createdBy
            description
            flexAttributes
            icon
            iconColor
            iconName
            iconType
            userSelectable
            changeToDispositionID
            imageName
            imageType
            isDeleted
            lastUpdatedBy
            measure
            measurePlural
            orgID
            pinColor
            sequence
            sendToCRM
            requireCloser
            title
            updatedAt
            valueUnit
            valueUnitPlural
            winProbability
            isMapDefault
            isNoDealDefault
            isDisplay
            isActive
          }
          nextToken
        }
      }
    `;
    try {
      let _nextToken = null;
      let stages = [];
      do {
        const listStageRes = await API.graphql({
          query: myQuery,
          variables: { categoryID, orgID: CRM?.orgID, nextToken: _nextToken },
        });
        const _stages = listStageRes.data.listStages.items;
        _nextToken = listStageRes.data.listStages.nextToken;
        stages = [...stages, ..._stages];
      } while (_nextToken);

      const sortedStages = stages.sort((b, a) => {
        return b.sequence - a.sequence;
      });
      setMyStages(sortedStages);
    } catch (err) {
      console.error(
        'fetchMyStageCategories(): error fetching my categories',
        err
      );
    }
  }

  const handleChange = (e) => {
    setCRMObj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFlexChange = (e) => {
    setFlexAttributes((p) => ({
      config: {
        deal: {
          ...p.config.deal,
          [e.target.name]: e.target.value,
        },
      },
    }));
  };

  useEffect(() => {
    if (!CRM) return;
    fetchMyStages(CRM.id);
    setCRMObj(CRM);
    setOgCRMObj(CRM);
    try {
      setFlexAttributes(JSON.parse(CRM.flexAttributes));
      setOgFlexAttributes(JSON.parse(CRM.flexAttributes));
      setFlexFields(JSON.parse(CRM.flexAttributes).config.deal.flexFields);
      setOgFlexFields(JSON.parse(CRM.flexAttributes).config.deal.flexFields);
    } catch (err) {
      console.log('Invalid JSON object');
      console.log(err);
    }
  }, [props]);

  const handleCreateStage = () => {
    setSingleStage(null);
    setStageBackdropOpen(true);
  };

  const handleStageClick = (e) => {
    const val = e.currentTarget.getAttribute('name');
    setSingleStage(myStages.find((el) => el.id === val));
    setStageBackdropOpen(true);
  };

  const openSnackbar = (message) => {
    setSnackbarObj({ open: true, message });
    setTimeout(() => {
      setSnackbarObj({ open: false, message: '' });
    }, 5000);
  };

  const saveCRMChanges = async () => {
    const submitFlexAttributes = {
      ...flexAttributes,
      config: {
        deal: {
          ...flexAttributes.config.deal,
          displayFields: [],
          pickerDisplayFields: [],
          flexFields: [],
        },
      },
    };

    flexFields.forEach((ff) => {
      submitFlexAttributes.config.deal.flexFields.push(ff);
      if (ff.displayField)
        submitFlexAttributes.config.deal.displayFields.push(ff.name);
      if (ff.pickerDisplayField)
        submitFlexAttributes.config.deal.pickerDisplayFields.push(ff.name);
    });
    const submitCRM = {
      ...CRMObj,
      flexAttributes: JSON.stringify(submitFlexAttributes),
    };

    try {
      await API.graphql(
        graphqlOperation(updateStageCategory, { input: submitCRM })
      );
    } catch (err) {
      console.error('Error saving updates to CRM ', err);
    }
    props.refreshCRMs(submitCRM.title);
  };

  if (!myStages || !CRMObj) return null;
  return (
    <View style={{ marginTop: '10px' }}>
      <Box style={{ display: 'flex' }}>
        <Box
          onClick={() => setActiveTab('general')}
          className={`crm-tab ${
            activeTab === 'general' ? 'active-crm-tab' : 'non-active-crm-tab'
          }`}
        >
          GENERAL
        </Box>
        <Box
          onClick={() => setActiveTab('deal')}
          className={`crm-tab ${
            activeTab === 'deal' ? 'active-crm-tab' : 'non-active-crm-tab'
          }`}
        >
          DEAL STAGES
        </Box>
      </Box>
      <Box style={{ border: '1px solid gray' }}>
        {activeTab === 'deal' && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '15px',
              }}
            >
              <Box style={{ width: '100px' }} />
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  //   padding: '20px',
                }}
              >
                DEAL STAGES
              </Text>
              <Button variant="contained" onClick={handleCreateStage}>
                CREATE DEAL STAGE
              </Button>
            </Box>
            <Box style={{ width: '100%' }}>
              {myStages.map((stage, i) => (
                <Box
                  className="deal-stage-bar"
                  // style={{ backgroundColor: 'red' }}
                  key={i}
                  name={stage.id}
                  onClick={handleStageClick}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box className="sequence-circle">{stage.sequence}</Box>
                    <Box>
                      <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                        {stage.title}
                      </Text>
                      <Box style={{ display: 'flex' }}>
                        {[
                          [stage.isDisplay, 'Display'],
                          [stage.isActive, 'Active'],
                          [stage.sendToCRM, 'Send To CRM'],
                          [stage.isMapDefault, 'Map Default'],
                          [stage.isNoDealDefault, 'No Deal Default'],
                          [stage.requireCloser, 'Requier Closer'],
                          // [
                          //   stage.calendarAppointmentStatus &&
                          //     stage.calendarAppointmentStatus.length,
                          //   `Calendar Appointment Status: ${stage.calendarAppointmentStatus}`,
                          // ],
                        ]
                          .filter((el) => el[0])
                          .map((el, j, arr) => (
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              key={j}
                            >
                              <Text>{el[1]}</Text>
                              {j < arr.length - 1 ? (
                                <Box
                                  style={{
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: 'rgb(202, 202, 202)',
                                    margin: '0 10px',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          ))}
                        {stage.calendarAppointmentStatus &&
                        stage.calendarAppointmentStatus.length ? (
                          <>
                            <Box
                              style={{
                                width: '1px',
                                height: '1.5rem',
                                backgroundColor: 'rgb(202, 202, 202)',
                                margin: '0 10px',
                              }}
                            />
                            <Text
                              style={{ marginRight: '5px', fontWeight: 'bold' }}
                            >
                              {'Calendar Appointment Status: '}
                            </Text>
                            <Text>{stage.calendarAppointmentStatus}</Text>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            {stageBackdropOpen && (
              <DealStageEditor
                orgID={CRM.orgID}
                category={CRM.id}
                stageDispositions={props.stageDispositions}
                open={stageBackdropOpen}
                closer={setStageBackdropOpen}
                stageProp={singleStage}
                setter={setSingleStage}
                refreshList={() => fetchMyStages(CRM.id)}
                snackbar={(message) => openSnackbar(message)}
              />
            )}
          </Box>
        )}
        {activeTab === 'general' && (
          <Box style={{ backgroundColor: 'white' }}>
            <Box style={{ display: 'flex', padding: '15px' }}>
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Title
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="title"
                  value={CRMObj.title}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ width: '5%' }} />
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Icon Name
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="iconName"
                  value={CRMObj.iconName}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ width: '5%' }} />
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Icon Type
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="iconType"
                  value={CRMObj.iconType}
                  onChange={handleChange}
                />
              </Box>
            </Box>

            <Text
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.5rem',
              }}
            >
              Flex Field Mapping
            </Text>
            <Box style={{ display: 'flex', padding: '15px' }}>
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Name Attribute(s)
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="dealMappingsName"
                  value={flexAttributes.config.deal.dealMappingsName}
                  onChange={handleFlexChange}
                />
              </Box>
              <Box style={{ width: '5%' }} />
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Description Attribute(s)
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="dealMappingsDescription"
                  value={flexAttributes.config.deal.dealMappingsDescription}
                  onChange={handleFlexChange}
                />
              </Box>
              <Box style={{ width: '5%' }} />
              <Box style={{ width: '30%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Value Attribute
                </Text>
                <TextField
                  style={{ width: '100%' }}
                  name="dealMappingsValue"
                  value={flexAttributes.config.deal.dealMappingsValue || ''}
                  onChange={handleFlexChange}
                />
              </Box>
            </Box>

            {/* selectFields */}
            <Box style={{ display: 'flex', padding: '15px' }}>
              <Box style={{ width: '48%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Availability Stage
                </Text>
                <SelectStage
                  value={flexAttributes.config.deal.availabiltyStage || ''}
                  categoryID={CRMObj.id}
                  setter={(e) =>
                    setFlexAttributes((p) => ({
                      config: {
                        deal: {
                          ...p.config.deal,
                          availabiltyStage: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </Box>
              <Box style={{ width: '4%' }} />
              <Box style={{ width: '48%' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Availability Disposition
                </Text>
                <SelectDisposition
                  value={
                    flexAttributes.config.deal.availabiltyDisposition || ''
                  }
                  categoryID={CRM.id}
                  setter={(e) =>
                    setFlexAttributes((p) => ({
                      config: {
                        deal: {
                          ...p.config.deal,
                          availabiltyDisposition: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                padding: '15px',
                justifyContent: 'space-around',
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Show in Routes Screen
                </Text>
                <Switch
                  checked={CRMObj.enablePindrop}
                  onChange={() =>
                    setCRMObj((p) => ({
                      ...p,
                      enablePindrop: !p.enablePindrop,
                    }))
                  }
                />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                  Show in Deals Screen
                </Text>
                <Switch
                  checked={CRMObj.enableDeal}
                  onChange={() =>
                    setCRMObj((p) => ({
                      ...p,
                      enableDeal: !p.enableDeal,
                    }))
                  }
                />
              </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setOpenFormEditor(true)}
                style={{
                  backgroundColor: '#827ffc',
                  margin: '5px',
                  color: 'white',
                  padding: '5px 25px',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                {flexFields.length ? 'Modify Form' : 'Create Form'}
              </Button>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={saveCRMChanges}
                variant="contained"
                disabled={!changed}
                style={{
                  //   backgroundColor: '#827ffc',
                  margin: '5px',
                  color: 'white',
                  padding: '5px 25px',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                SAVE CHANGES
              </Button>
            </Box>
            {openFormEditor && (
              <CreateFlexForm
                open={openFormEditor}
                categoryID={CRM.id}
                stageDispositions={dealSetupStageDispositions}
                exit={() => setOpenFormEditor(false)}
                flexFields={flexFields}
                setter={(val) => setFlexFields(val)}
                flexAttributes={flexAttributes}
                flexAttributesSetter={(val) => setFlexAttributes(val)}
                updateDisplayFields={(newFlexFields) => {
                  const newDisplayFields = newFlexFields
                    .filter((ff) => ff.displayField)
                    .map((ff) => ff.name);
                  setFlexAttributes((p) => ({
                    config: {
                      deal: {
                        ...p.config.deal,
                        displayFields: newDisplayFields,
                      },
                    },
                  }));
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Snackbar
        open={snackbarObj.open}
        message={snackbarObj.message}
        severity="success"
      />
    </View>
  );
}
