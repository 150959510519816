import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { BackIcon, GamifyIcon } from '../../components/GamifyIcon';

export default function NoTeamDisplay({ isDeleted }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/teams');
  };

  return (
    <>
      <Box
        style={{
          height: '100vh',
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          //   background:
          //     'radial-gradient(circle at 50% 80%, #8785EA 0%, #A0C5F9 50%, #BDD4EF 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            width: 210,
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'profile-about-page-back-button'}
            onClick={(e) => handleBack(e)}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {'Back to teams'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#5f27cd26',
              height: 70,
              width: 70,
              borderRadius: 1000,
              marginBottom: 16,
            }}
          >
            <GamifyIcon icon={'noResults'} color={'#5f27cd'}></GamifyIcon>
          </Box>
          <Typography style={{ fontSize: '18px' }}>
            {isDeleted
              ? 'This team has been deleted,'
              : 'There is no team with this ID,'}
          </Typography>
          <Typography style={{ fontSize: '18px' }}>
            {'please go back to Teams'}
          </Typography>
        </Box>
        <Box
          style={{
            width: 210,
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
    </>
  );
}
