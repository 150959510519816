import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import Utilities from '../common/Utilities';
import KPILeaderboard from '../domain/KPILeaderboard';

export default class DashboardRepository {
  static async fetchDashboards(
    reportName,
    levelIDFilter,
    ourNextItem,
    resetList,
    circleIDFilter,
    subLeaderboard,
    KPIRuleID
  ) {
    const [reportType, subjectType, dateType, dateOffset] =
      reportName.split(':');
    console.log(
      'split reportName ',
      reportType,
      subjectType,
      dateType,
      dateOffset
    );

    const startTime = new Date();
    if (dateOffset === '-1') {
      //
      //  Subtract 1 Day, Week, Month, Year from today's date
      //
      console.log('***startTime b4: ', startTime);
      if (dateType === 'D') {
        startTime.setDate(startTime.getDate() - 1);
      } else if (dateType === 'W') {
        startTime.setDate(startTime.getDate() - 7);
      } else if (dateType === 'M') {
        console.log('getMonth: ', startTime.getUTCMonth());
        startTime.setMonth(startTime.getUTCMonth() - 1);
      }
    }
    console.log('***startTime: ', startTime);
    //
    //  Get ALL circle missions for our org
    //
    // kpi:person:wtd

    let dateParm;
    if (dateType === 'D') {
      dateParm = Utilities.getLocalDateTimeFormattedFromDate(
        'YYYY-MM-DD',
        startTime
      );
    } else if (dateType === 'M') {
      dateParm = Utilities.getLocalDateTimeFormattedFromDate(
        'YYYY-MM',
        startTime
      );
    } else if (dateType === 'Y') {
      let curYear = Utilities.getLocalDateTimeFormattedFromDate(
        'YYYY',
        startTime
      );
      const curMonth = Utilities.getLocalDateTimeFormattedFromDate(
        'MM',
        startTime
      );
      let fiscalYearMonthEnd = 12;
      if (global.appSettings.fiscalYearMonthEnd !== undefined) {
        fiscalYearMonthEnd = global.appSettings.fiscalYearMonthEnd;
      }
      if (curMonth > fiscalYearMonthEnd) {
        curYear++;
      }
      dateParm = curYear;
    } else if (dateType === 'W') {
      //
      //  The current week always starts on Monday
      //
      const now = new Date(startTime);
      const curMonth = now.getUTCMonth() + 1;
      const curYear = now.getUTCFullYear();
      const curDayOfWeek = now.getUTCDay();
      const curDay = now.getUTCDate();

      console.log('now: ', now);
      console.log('curMonth: ', curMonth);
      console.log('curYear: ', curYear);
      console.log('curDayOfWeek: ', curDayOfWeek);
      console.log('curDay: ', curDay);
      let weekStartDay = 0;
      if (global.appSettings.weekStartDay !== undefined) {
        //
        //  weekStartDay is the PYTHON week where 0=Monday, 6=Sunday
        //
        weekStartDay = global.appSettings.weekStartDay;
        //
        //  Convert to the Javascript week where 0=Sunday, 6=Saturday
        //
        weekStartDay += 1;
        if (weekStartDay > 6) {
          weekStartDay = 0;
        }
      }
      console.log('weekStartDay: ', weekStartDay);

      const offset = (((curDayOfWeek - weekStartDay) % 7) + 7) % 7;
      const first = now.getDate() - offset;

      console.log('+!+offset: ', offset);
      console.log('first: ', first);
      //
      //  if we're dealing with last week, subtract 7 from the first day and last day
      //
      const offsetDays = 0;
      const firstday = new Date(now.setDate(first - offsetDays)).toUTCString();
      dateParm = Utilities.graphqlDateFormat(firstday);
    }

    if (resetList) {
      ourNextItem = '';
    }

    console.log('!!KPIRuleID: ', KPIRuleID);
    console.log('!!subLeaderboard: ', subLeaderboard);

    if (KPIRuleID !== '' && subLeaderboard) {
      ourNextItem = KPIRuleID;
    }
    console.log('dateParm ', dateParm);
    console.log('circleIDFilter: ', circleIDFilter);
    console.log('global.me.myCircles: ', global.me.myCircles);
    console.log('global.me: ', global.me);
    const res = await API.graphql({
      query: queries.getDashboard,
      // variables: {
      //   request: JSON.stringify({
      //
      //     subjectType,
      //     dateType,
      //     dateParm,
      //     orgID: global.me.orgID,
      //     levelIDFilter,
      //     myUserID: global.me.id,
      //     myCircles: circleIDFilter !== 'all' ? [circleIDFilter] : global.me.myCircles.concat(global.me.myChildCircles),
      //     all: circleIDFilter === 'all',
      //     nextItem: ourNextItem,
      //     useShortList: false,
      //     KPIRuleID
      //   })
      // }
    });
    const dashboard = JSON.parse(res.data.getDashboard);
    const newDashboard = [];
    for (const leaderboard of dashboard) {
      newDashboard[newDashboard.length] = new KPILeaderboard(leaderboard);
    }
    return newDashboard;
  }
}
