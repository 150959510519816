import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography, Skeleton } from '@mui/material';
import { BackIcon } from '../../components/GamifyIcon';

export default function DealFormPageSkeleton(props) {
  const { /* setShowModal, */ isModal } = props;
  const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    // NOTE: No need to navigate now that DealForm is now on a modal
    navigate('/deals');
    // setShowModal(false);
  };

  return (
    <Box
      className={
        isModal ? 'deal-form-modal-container' : 'deal-form-page-container'
      }
    >
      <Box
        className={
          isModal
            ? 'deal-form-modal-top-container'
            : 'deal-form-page-top-container'
        }
      >
        <Box
          className={
            isModal
              ? 'deal-form-modal-cancel-container'
              : 'deal-form-page-cancel-container'
          }
        >
          <Box className={'deal-form-cancel-button-container'}>
            <Button
              className={
                isModal
                  ? 'deal-form-modal-cancel-button'
                  : 'deal-form-page-cancel-button'
              }
              onClick={(e) => handleCancel(e)}
              disableRipple={true}
            >
              <BackIcon></BackIcon>
              <Typography
                className={
                  isModal
                    ? 'deal-form-modal-cancel-button-text'
                    : 'deal-form-page-cancel-button-text'
                }
              >
                Cancel
              </Typography>
            </Button>
          </Box>
          <Box
            className={
              isModal
                ? 'deal-form-modal-title-container'
                : 'deal-form-page-title-container'
            }
          >
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'rounded'}
              width={150}
              height={48}
            ></Skeleton>
          </Box>
          <Box className={'deal-form-place-holder-container'}></Box>
        </Box>
      </Box>
      <Box
        className={
          isModal ? 'modal-deal-form-container' : 'page-deal-form-container'
        }
      >
        {/* <FlexFields
        dealType={dealType}
        dealTypesConfigMap={dealTypesConfigMap}
        values={formikProps.values}
        validateFields={validateFields}
        // TO DO: canSave also used in onSubmit of create/edit button
        // canSave={(val) => {
        //   setCanSave(val);
        // }}
        canSave={canSave}
        setCanSave={setCanSave}
        fieldSetter={(val) => {
          formikProps.setFieldValue('flexFieldData', val);
        }}
        hasSubmitted={hasSubmitted}
        setHasSubmitted={setHasSubmitted}
      ></FlexFields> */}
        <Box
          style={{
            backgroundColor: '#F0F0F3',
            borderRadius: 8,
            paddingBottom: 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              backgroundColor: '#D0D2D8',
              padding: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'rounded'}
              width={50}
              height={27}
            ></Skeleton>
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'circular'}
              width={24}
              height={24}
            ></Skeleton>
          </Box>
          <Box style={{ padding: '24px 16px 8px 16px' }}>
            {['', '', '', '', ''].map((input, i) => (
              <Box
                key={i}
                style={{
                  position: 'relative',
                  width: '100%',
                  marginBottom: 16,
                }}
              >
                <Skeleton
                  sx={{ bgcolor: '#0B0B0C', marginBottom: '8px' }}
                  variant={'rounded'}
                  width={75}
                  height={18}
                ></Skeleton>
                <Box
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 56,
                    padding: '12px 16px',
                    borderRadius: 8,
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: '#D0D2D8' }}
                    variant={'rounded'}
                    width={644}
                    height={32}
                  ></Skeleton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={'save-deal-button-container'}>
          <Box className={'skeleton-save-deal-button'}>
            <Skeleton
              sx={{ bgcolor: '#ffffff' }}
              variant={'rounded'}
              width={123}
              height={24}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
