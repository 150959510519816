export const GET_KPI_VALUES_QUERY = `
  query GetKPIValues(
    $dimensionType: String!
    $dimensionTimeframe: String!
    $dimensionValue: String!
    $nextToken: String
    $limit: Int
    $sortColumn: String
    $sortOrder: String
    $circleLevelID: String
    $circleID: String
  ) {
    getKPIValues(
      dimensionType: $dimensionType
      dimensionTimeframe: $dimensionTimeframe
      dimensionValue: $dimensionValue
      nextToken: $nextToken
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      circleLevelID: $circleLevelID
      circleID: $circleID
    )
  }
`;

export const GET_MY_CIRCLE_LEVELS_QUERY = `
query MyQuery($orgID: ID!) {
  listCircleLevelByOrg(
    orgID: $orgID
    filter: { isDeleted: { eq: false } }
    limit: 10000
  ) {
    items {
      id
      name
    }
  }
}
`;

export const SEARCH_CIRCLES_QUERY = `
query MyQuery($orgID: ID!, $userID: ID!) {
  searchCircles(
    filter: {
      name: { wildcard: "*" },
      orgID: { eq: $orgID },
      isDeleted: { eq: false }
    },
    limit: 10000,
    sort: { direction: asc, field: name }
  ) {
    items {
      id
      name
      users(filter: {id: {eq: $userID}}) {
        items {
          id
          userID
          status
          memberType
        }
      }
    }
    nextToken
  }
}`;
