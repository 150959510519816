import styled from 'styled-components';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d0d2d8;
`;

export const RulesAndRewardsViewContainer = styled.div`
  margin-top: 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const NextRoundButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 16px;
  font-weight: 700;
  cursor: pointer;
  gap: 10px;
  border: ${({ disabled }) =>
    disabled ? '2px solid #D0D2D8' : '2px solid #5f27cd'};
  color: ${({ disabled }) => (disabled ? '#D0D2D8' : '#5f27cd')};
`;

export const NextRoundIcon = styled.img`
  width: 20px;
  height: 20px;
`;
