import React from 'react';
import styled from 'styled-components';

const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;

  & + & {
    cursor: pointer;
    text-indent: -9999px;
    width: 200px;
    height: 100px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 90px;
      height: 90px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    &:checked {
      background: #5f27cd;

      &:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
      }
    }
  }
`;

const LabelForCheckBox = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 34px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    right: 0;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  ${ToggleSwitch}:checked + & {
    background: #5f27cd;

    &::after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
`;

interface Props {
  label: string;
  value: boolean;
  onChange: (newValue: any) => void;
}

const CustomToggleSwitch: React.FC<Props> = ({ label, onChange, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      <div style={{ fontSize: 14 }}>{label}</div>
      <ToggleSwitch checked={value} onChange={() => {}} />
      <LabelForCheckBox onClick={() => onChange(!value)} htmlFor="switch">
        Toggle
      </LabelForCheckBox>
    </div>
  );
};

export default CustomToggleSwitch;
