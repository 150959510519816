import React, { useEffect, useState } from 'react';
import CommentsView from './Comments.view';
import { useLocation, useNavigate } from 'react-router';
import CommentType from '../_types/CommentType';
import addComment from '../feed-helpers/addComment';
import { LOG } from '../../../common/Utilities';
import AddCommentArgs from '../_types/AddCommentArgs';
import removeComment from '../feed-helpers/removeComment';
import { useGetUserInformationQuery } from '../../../store/api/GraphQlQuery';
import Me from '../../../_types/Me';
import { GamifyToast } from '../../../common/CustomToasts';
import { useAlert } from 'react-alert-with-buttons';
import getMyPersons from '../feed-helpers/getMyPersons';
import MyPerson from '../_types/MyPerson';

const CommentsContainer = () => {
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const title = location.state.title;
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const [isLoadingGetCircles, setIsLoadingGetCircles] = useState(true);
  console.log(isLoadingGetCircles);
  const activityFeedID = location.state.activityFeedID;
  const [comments, setComments] = useState(
    location.state.comments as CommentType[]
  );
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [myPersons, setMyPersons] = useState<MyPerson[]>([]);

  const [selectedComment, setSelectedComment] = useState<CommentType | null>(
    location.state.selectedComment ? location.state.selectedComment : null
  );

  const onAddComment = async (args: AddCommentArgs) => {
    try {
      setLoading(true);
      const newComment = await addComment({
        ...args,
        commentParentCommentId: selectedComment ? selectedComment.id : '',
      });
      setComments([newComment, ...comments]);
    } catch (error) {
      LOG.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSelectedComment = (comment: CommentType) => {
    setSelectedComment(comment);
  };

  const onDeleteComment = async (deletedComment: CommentType) => {
    alert.open({
      message: 'Are you sure you wish to delete this comment?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const newComments = comments.filter(
              (comment) => comment.id !== deletedComment.id
            );

            setComments(newComments);
            GamifyToast.success('Comment deleted');
            alert.close();

            await removeComment(deletedComment);
          },
          style: {
            borderRadius: 16,
            backgroundColor: '#5F27CD',
            marginRight: 20,
            color: '#fff',
            fontWeight: 700,
          },
        },
        {
          label: 'No',
          onClick: () => alert.close(),
          style: {
            borderRadius: 16,
            backgroundColor: '#FF5C77',
            color: '#fff',
            fontWeight: 700,
          },
        },
      ],
    });
  };

  useEffect(() => {
    setComments(location.state.comments);
  }, [location]);

  useEffect(() => {
    setSelectedComment(location.state.selectedComment);
  }, [location.state.selectedComment]);

  useEffect(() => {
    const fetchMyPersons = async () => {
      if (isLoadingGetUserInformation || !data) {
        return;
      }

      try {
        const myPersons = await getMyPersons({
          orgID: data.me.orgID,
        });

        setMyPersons(myPersons);
      } catch (error) {
        LOG.debug(error);
      } finally {
        setIsLoadingGetCircles(false);
      }
    };

    fetchMyPersons();
  }, [isLoadingGetUserInformation, data]);

  return (
    <CommentsView
      myPersons={myPersons}
      me={data?.me as Me}
      loading={loading}
      activityFeedID={activityFeedID}
      title={title}
      comments={comments}
      onNavigateBack={() => {
        if (!isEditing) {
          navigate(-1);
          return;
        }

        alert.open({
          message: 'Are you sure you wish to exit?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                navigate(-1);
                alert.close();
              },
              style: {
                borderRadius: 16,
                backgroundColor: '#5F27CD',
                marginRight: 20,
                color: '#fff',
                fontWeight: 700,
              },
            },
            {
              label: 'No',
              onClick: () => alert.close(),
              style: {
                borderRadius: 16,
                backgroundColor: '#FF5C77',
                color: '#fff',
                fontWeight: 700,
              },
            },
          ],
        });
      }}
      onAddComment={onAddComment}
      onChangeSelectedComment={onChangeSelectedComment}
      onDeleteComment={onDeleteComment}
      onChangeIsEditing={(newIsEditing: boolean) => setIsEditing(newIsEditing)}
    />
  );
};

export default CommentsContainer;
