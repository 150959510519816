import React, { useEffect, useState } from 'react';
import {
  Bracket,
  IRenderSeedProps,
  Seed,
  SeedItem,
  SeedTeam,
} from 'react-brackets';
import {
  CustomSeedValue,
  CustomSeedValueImage,
  CustomSeedValueText,
  ViewContainer,
} from './BracketContestants.styles';
import generateGameBracket from './generateGameBracket';
import Contestant from '../../../_types/Contestant';
import NewGameState from '../../_types/NewGameState';

const CustomSeed = ({
  seed,
  breakpoint,
  onClickAdd,
  seedIndex,
  contestants,
  newGameState,
  roundIndex,
}: IRenderSeedProps & {
  onClickAdd: (args: { seedIndex: number; seedTeamIndex: number }) => void;
  contestants: Contestant[];
  newGameState: NewGameState;
}) => {
  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not
  // mobileBreakpoint is required to be passed down to a seed
  const [contestant1Data, setContestant1Data] = useState<Contestant | null>(
    null
  );
  const [contestant2Data, setContestant2Data] = useState<Contestant | null>(
    null
  );

  const shouldDisableSeed = (teamName: string | undefined): boolean => {
    if (!teamName) return true;

    return teamName.includes('Round');
  };

  useEffect(() => {
    (async () => {
      if (roundIndex > 0) return;

      const contestant1ID =
        newGameState.bracket.bracket[0][seedIndex].competitors[0];
      const contestant2ID =
        newGameState.bracket.bracket[0][seedIndex].competitors[1];

      if (contestant1ID) {
        const data = contestants.filter(
          (contestant) => contestant.value === contestant1ID
        )[0];

        setContestant1Data(data);
      } else {
        setContestant1Data(null);
      }

      if (contestant2ID) {
        const data = contestants.filter(
          (contestant) => contestant.value === contestant2ID
        )[0];

        setContestant2Data(data);
      } else {
        setContestant2Data(null);
      }
    })();
  }, [seedIndex]);

  return (
    <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 14 }}>
      <SeedItem style={{ backgroundColor: '#fff' }}>
        <div>
          <SeedTeam
            onClick={() => {
              if (shouldDisableSeed(seed.teams[0]?.name)) return;

              onClickAdd({ seedIndex, seedTeamIndex: 0 });
            }}
            style={{
              color: '#000',
              backgroundColor: '#fff',
              borderBottom: '2px solid #f0f0f3',
              fontWeight: 600,
              cursor: shouldDisableSeed(seed.teams[0]?.name)
                ? 'default'
                : 'pointer',
            }}
          >
            <CustomSeedValue>
              <CustomSeedValueImage
                src={
                  contestant1Data
                    ? contestant1Data.avatar
                    : seed.teams[0]?.avatar
                }
              />
              <CustomSeedValueText style={{ fontWeight: 500 }}>
                {contestant1Data
                  ? contestant1Data.label
                  : seed.teams[0]?.name || '-----'}
              </CustomSeedValueText>
            </CustomSeedValue>
          </SeedTeam>
          <SeedTeam
            onClick={() => {
              if (shouldDisableSeed(seed.teams[1]?.name)) return;

              onClickAdd({ seedIndex, seedTeamIndex: 1 });
            }}
            style={{
              fontWeight: 600,
              color: '#000',
              backgroundColor: '#fff',
              boxShadow: 'none',
              cursor: shouldDisableSeed(seed.teams[1]?.name)
                ? 'default'
                : 'pointer',
            }}
          >
            <CustomSeedValue>
              <CustomSeedValueImage
                src={
                  contestant2Data
                    ? contestant2Data.avatar
                    : seed.teams[1]?.avatar
                }
              />
              <CustomSeedValueText style={{ fontWeight: 500 }}>
                {contestant2Data
                  ? contestant2Data.label
                  : seed.teams[1]?.name || '-----'}
              </CustomSeedValueText>
            </CustomSeedValue>
          </SeedTeam>
        </div>
      </SeedItem>
    </Seed>
  );
};

interface Props {
  totalNumberOfContestants: any;
  onClickAdd: (args: { seedIndex: number; seedTeamIndex: number }) => void;
  contestants: Contestant[];
  newGameState: NewGameState;
}

const GameBracket: React.FC<Props> = ({
  totalNumberOfContestants,
  onClickAdd,
  contestants,
  newGameState,
}) => {
  const rounds = generateGameBracket(totalNumberOfContestants);

  return (
    <ViewContainer>
      <Bracket
        rounds={rounds}
        renderSeedComponent={(props) => (
          <CustomSeed
            {...props}
            onClickAdd={onClickAdd}
            contestants={contestants}
            newGameState={newGameState}
          />
        )}
      />
    </ViewContainer>
  );
};

export default GameBracket;
