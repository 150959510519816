export const pinsList = [
  'Seed-Appointment',
  'Seed-Call-Back',
  'Seed-Come-Back',
  'Seed-Credit-Failed',
  'Seed-Current-Customer',
  'Seed-DNK2',
  'Seed-Doesnt-Qualify',
  'Seed-Follow-Up-UPS2',
  'Seed-Full-Pitch-Completed',
  'Seed-Go-Back',
  'Seed-Good-Look',
  'Seed-HQ-Lead2',
  'Seed-Half-Pitch-Completed',
  'Seed-Has-Solar',
  'Seed-Home-Owner',
  'Seed-Interested',
  'Seed-Language-Barrier',
  'Seed-Mild-Interested',
  'Seed-Moving',
  'Seed-New-Referral',
  'Seed-No-Alarm',
  'Seed-No-Appointment',
  'Seed-No-Soliciting',
  'Seed-Not-At-Home',
  'Seed-Not-Home-AM',
  'Seed-Not-Home-Eve',
  'Seed-Not-Home-PM',
  'Seed-Not-Home-UPS2',
  'Seed-Not-Home',
  'Seed-Not-Interested-PM-UPS2',
  'Seed-Not-Interested',
  'Seed-Not-interested',
  'Seed-Old-Referral',
  'Seed-Old',
  'Seed-One-Legged-Presentation',
  'Seed-Referral',
  'Seed-Renter',
  'Seed-Shading',
  'Seed-Solar-Home-Competitor',
  'Seed-Sold',
  'Seed-Talk-Completed',
  'Seed-Vacant-Lot',
  'Seed-doesnt-qualify',
  // new addition
  'Seed-Call-Back-2',
  'Seed-Not-Home-4',
  'Seed-Not-Home-3',
  'Seed-Renter-2',
  'Seed-Matt-E-Leads',
  'Seed-Other',
  'Seed-Not-Home-2',
  'Seed-Go-Back-2',
  'Seed-Not-Interested-Full-Pitch',
  'Seed-Not-Interested',
  'Seed-Not-Interested-No pitch',
  'Seed-Not-Home-1',
  'Seed-Moving-2',
  'Seed-No-Knock-List',
  'Seed-Bad-Roof',
  'Seed-Appointment-Set',
  'Seed-Perfect-Packet',
  'Seed-No-Soliciting-2',
  'Seed-Other-Solar-Customer',
  'Seed-Previous-Set-Appointment',
];
