import React, { useState } from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import { EllipsisIcon, GamifyIcon } from '../../components/GamifyIcon';
import DealOptionsMenu from './DealOptionsMenu';

export default function DealCard({ props }) {
  const {
    fullName,
    dealStage,
    attrs,
    // ownerProfilePicture,
    // initials,
    // assigneeName,
    setter,
    closer,
    updatedAt,
    ourDeal,
    setAddDealOpen,
    setSortOpen,
    // setSelectedDealOptionsOpen,
    setShowEditDealFormModal,
    getData,
    // onTriggerDealsRefetch,
    triggerSelectedDealRefetch,
    onTriggerSelectedDealRefetch,
    userId,
    searchStage,
    dealType,
    dealTypesCategoryIdMap,
    dealTypesConfigMap,
    dealFilter,
    categoryId,
    searchString,
    fromDealPage,
    setIsLoaded,
  } = props;
  // const [dealOptionsOpen, setDealOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // console.log('DealCard dealStage: ', dealStage);
  // console.log('DealCard ourDeal: ', ourDeal);
  // console.log(
  //   'DealCard getData and args for reset on deal delete: ',
  //   getData,
  //   userId,
  //   searchStage,
  //   dealTypesCategoryIdMap,
  //   dealFilter,
  //   categoryId,
  //   searchString,
  //   fromDealPage,
  //   setIsLoaded
  // );

  // const handleDealOptionsClick = () => {
  //   setAddDealOpen(false);
  //   setSortOpen(false);
  //   setDealOptionsOpen(!dealOptionsOpen);
  //   setSelectedDealOptionsOpen(false);
  // };

  // const handleClickOffDealOptionMenu = () => {
  //   setDealOptionsOpen(false);
  // };

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setAddDealOpen(false);
    setSortOpen(false);
  };

  return (
    <>
      {/* {dealOptionsOpen && (
        <Box
          onClick={handleClickOffDealOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box className={'deal-card-container'}>
        <Box>
          <Box className={'deal-card-name-stage-section'}>
            <Typography className={'deal-card-full-name-text'}>
              {fullName}
            </Typography>
            <Box className={'deal-card-name-stage-section-right'}>
              <Box
                style={{
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  backgroundColor: '#e2d8ff',
                  borderRadius: 1000,
                }}
              >
                <Typography className={'deal-card-stage-text'}>
                  {dealStage}
                </Typography>
              </Box>
              {/* Only admin, closer, or setter should be able to view deal options */}
              {(global.me.isAdmin ||
                global.me.id === setter?.user?.id ||
                global.me.id === closer?.user?.id) && (
                <>
                  <Button
                    disableRipple={true}
                    // onClick={handleDealOptionsClick}
                    onClick={(e) => handleMenuOpen(e)}
                    className={'ellipsis-button'}
                  >
                    <EllipsisIcon></EllipsisIcon>
                  </Button>
                  <DealOptionsMenu
                    props={{
                      // setDealOptionsOpen,
                      anchorEl,
                      setAnchorEl,
                      setShowEditDealFormModal,
                      getData,
                      // onTriggerDealsRefetch,
                      triggerSelectedDealRefetch,
                      onTriggerSelectedDealRefetch,
                      userId,
                      searchStage,
                      dealType,
                      dealTypesCategoryIdMap,
                      dealTypesConfigMap,
                      dealFilter,
                      categoryId,
                      searchString,
                      fromDealPage,
                      setIsLoaded,
                      ourDeal,
                      setter,
                      closer,
                    }}
                  ></DealOptionsMenu>
                </>
              )}
              {/* NOTE: This was replaced with the code above when DealOptionsMenu switched to be CustomeMenu and CustomMenuItems */}
              {/* {dealOptionsOpen && (
                <DealOptionsMenu
                  props={{
                    setDealOptionsOpen,
                    getData,
                    onTriggerSelectedDealRefetch,
                    userId,
                    searchStage,
                    dealType,
                    dealTypesCategoryIdMap,
                    dealTypesConfigMap,
                    dealFilter,
                    categoryId,
                    searchString,
                    fromDealPage,
                    setIsLoaded,
                    ourDeal,
                  }}
                ></DealOptionsMenu>
              )} */}
            </Box>
          </Box>
          {attrs.length > 0 ? (
            attrs.map((attr, i) => (
              <Box key={i}>
                <Typography className={'deal-card-attribute-text'}>
                  {attr}
                </Typography>
              </Box>
            ))
          ) : (
            <></>
          )}
        </Box>
        <Box className={'deal-card-owner-section'}>
          <Box className={'deal-card-owner-section-left'}>
            <Box style={{ display: 'flex' }}>
              <Typography
                style={{ fontSize: '14px', fontWeight: 500, marginRight: 4 }}
              >
                Setter:
              </Typography>
              {setter?.profilePicture ? (
                <Avatar
                  className={'deal-card-owner-profile-picture'}
                  src={setter?.profilePicture}
                />
              ) : (
                <Avatar className={'deal-card-owner-profile-picture'}>
                  <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                    {setter?.user?.initials}
                  </Typography>
                </Avatar>
              )}
              <Typography className={'deal-card-owner-text'}>
                {setter?.user?.name}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', marginTop: 12 }}>
              <Typography
                style={{ fontSize: '14px', fontWeight: 500, marginRight: 4 }}
              >
                Closer:
              </Typography>
              {closer ? (
                closer.profilePicture ? (
                  <Avatar
                    className={'deal-card-owner-profile-picture'}
                    src={closer.profilePicture}
                  />
                ) : (
                  <Avatar className={'deal-card-owner-profile-picture'}>
                    <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                      {closer.user.initials}
                    </Typography>
                  </Avatar>
                )
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#bdbdbd',
                    width: 24,
                    height: 24,
                    borderRadius: 1000,
                    marginRight: 8,
                  }}
                >
                  <GamifyIcon
                    icon={'user'}
                    width={'14'}
                    height={'16'}
                  ></GamifyIcon>
                </Box>
              )}
              <Typography className={'deal-card-owner-text'}>
                {closer ? closer?.user?.name : 'Unassigned'}
              </Typography>
            </Box>
            {/* NOTE: The following was replaced to show both setter and closer, not just the most recent owner */}
            {/* {ownerProfilePicture ? (
              <Avatar
                className={'deal-card-owner-profile-picture'}
                src={ownerProfilePicture}
              />
            ) : (
              <Avatar className={'deal-card-owner-profile-picture'}>
                <Typography>{initials}</Typography>
              </Avatar>
            )}
            <Typography className={'deal-card-owner-text'}>
              {assigneeName}
              {ourDeal.assigneeName}
            </Typography> */}
          </Box>
          <Box className={'deal-card-owner-section-right'}>
            <Typography className="deal-card-updated-at-text">
              {updatedAt}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
