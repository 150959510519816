import React from 'react';
import AdvanceOptionsView from './AdvanceOptions.view';
import NewGameState from '../../_types/NewGameState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const AdvanceOptionsContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  return (
    <AdvanceOptionsView
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default AdvanceOptionsContainer;
