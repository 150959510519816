import React, { useEffect, useState } from 'react';
import BracketContestantsView from './BracketContestants.view';
import NewGameState from '../../_types/NewGameState';
import { useGetUserInformationQuery } from '../../../../../store/api/GraphQlQuery';
import getContestants from '../../../games-helpers/getContestants';
import { LOG } from '../../../../../common/Utilities';
import Contestant from '../../../_types/Contestant';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const BracketContestantsContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const { data: userData, isLoading } = useGetUserInformationQuery({});
  const [contestants, setContestants] = useState<Contestant[]>([]);
  const selectedContestants = newGameState.bracket.bracket
    .flat() // Flatten the outer array
    .map((match) => match.competitors) // Extract competitors arrays from each match
    .flat() // Flatten the array of competitors arrays
    .filter(Boolean); // Remove null or undefined values
  console.log('selectedContestants', selectedContestants);

  useEffect(() => {
    const fetchContestants = async () => {
      if (isLoading || !userData) {
        return;
      }

      try {
        const contestants = await getContestants({
          newGameState,
          me: userData.me,
        });
        setContestants(contestants);
      } catch (error) {
        LOG.error(error);
      }
    };

    fetchContestants();
  }, [newGameState.scope, isLoading]);

  if (isLoading || !userData) {
    return null;
  }

  return (
    <BracketContestantsView
      contestants={contestants}
      selectedContestants={selectedContestants}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default BracketContestantsContainer;
