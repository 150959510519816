import React, { useState, useEffect } from 'react';
import './Index.css';
import { orgMemberQuery } from './rewardQueries';
import { API } from 'aws-amplify';
import moment from 'moment';
import * as utils from '../../common/Utilities';
import gql from 'graphql-tag';
import treatAsUTC from '../../common/utils/treatAsUTC';
import {
  GridContainer,
  HeaderContainer,
  NewRewardButton,
  NoImageText,
  NoRewardsText,
  Title,
  ViewContainer,
  AddModalContainer,
  AddModalIcon,
  AddModalItem,
  AddModalItemLeftSide,
  AddModalItemRightSide,
  AddModalItemTitle,
} from './Rewards.styles';
import CenteredProgress from '../../components/CenteredProgress';
import DisplayMedia from '../../components/DisplayMedia';
import { useNavigate } from 'react-router';

export default function RewardsList() {
  const [myRewards, setMyRewards] = useState([]);
  const startBefore = new Date();
  const endAfter = new Date();
  const [loading, setLoading] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);
  const navigate = useNavigate();
  const addModalChoices = [
    {
      label: 'New Reward',
      icon: '/images/new-reward.svg',
      onClick: () => navigate('/rewards/new'),
      disabled: false,
    },
    {
      label: 'New from Catalog',
      icon: '/images/new-from-catalog.svg',
      onClick: () => navigate('/games/new-incentive'),
      disabled: true,
    },
    {
      label: 'New Gift Card',
      icon: '/images/new-gift-card.svg',
      onClick: () => navigate('/games/new-incentive'),
      disabled: true,
    },
  ];

  async function fetchMyRewards() {
    try {
      const orgMemberQueryRes = await API.graphql({
        query: orgMemberQuery,
        variables: {
          userID: global.me.id,
          orgID: global.me.orgID,
          limit: 100000,
        },
      });

      const orgPoints =
        orgMemberQueryRes.data.OrgMemberByUser.items[0].orgPoints;
      const lifeTimeOrgPoints =
        orgMemberQueryRes.data.OrgMemberByUser.items[0].lifeTimeOrgPoints;

      const newCircleMemberRewardQuery = gql`
        query ListCircleMembers(
          $userID: ID = "a8b8e888-a0cf-4a2d-9929-935428fb8010"
          $startBefore: String!
          $endAfter: String!
        ) {
          circleMemberByUserID(userID: $userID, limit: 10000) {
            items {
              circle {
                rewards(
                  filter: {
                    launchDate: { le: $startBefore }
                    validTo: { ge: $endAfter }
                  }
                ) {
                  items {
                    isDeleted
                    approverType
                    cost
                    costType
                    description
                    id
                    imageName
                    imageType
                    isActive
                    launchDate
                    limitCount
                    location
                    name
                    ownerID
                    ownerType
                    personLimit
                    remainingCount
                    tags
                    rewardType
                    validFrom
                    validTo
                    value
                    valueType
                    createdBy
                    orgID
                    tangoAccountID
                  }
                }
              }
            }
          }
        }
      `;

      const listCircleMembersRes = await API.graphql({
        query: newCircleMemberRewardQuery,
        variables: { userID: global.me.id, startBefore, endAfter },
      });
      const circleMembers =
        listCircleMembersRes.data.circleMemberByUserID.items;
      console.log('circleMembers', circleMembers);

      const ourCircles = [];
      const ourRewards = [];

      for (const circleMember of circleMembers) {
        const circle = circleMember.circle;
        if (circle == null) {
          continue;
        }
        const rewards = circle.rewards.items;
        // var ourRewards = [];

        console.log('***rewards: ', rewards);
        for (const reward of rewards) {
          if (!reward.isDeleted) {
            const now = treatAsUTC(new Date());
            const validFrom = new Date(reward.validFrom);
            const gmtValidToDate = new Date(reward.validTo);
            const validTo = moment(gmtValidToDate)
              .add(1, 'day')
              .subtract(1, 'second');

            reward.remaingDaysAvailable = utils.daysBetween(now, validTo);
            reward.daysBeforeAvailable = utils.daysBetween(now, validFrom);
            reward.secondsBeforeStart = utils.secondsBetween(now, validFrom);
            reward.remainingSecondsInMission = utils.secondsBetween(
              now,
              validTo
            );

            // TODO:  Use orgPoints
            reward.myCirclePoints = orgPoints;
            reward.purchaseCount = 0;
            reward.isVisible = true;
            if (reward.isVisible) {
              ourRewards[ourRewards.length] = reward;
            }
          }
          ourCircles[0] = {
            name: '',
            // TODO:  Use orgPoints
            circlePoints: orgPoints,
            id: '',
            isActive: true,
            // TODO:  Use orgPoints
            lifetimeCirclePoints: lifeTimeOrgPoints,
            memberType: '',
            status: '',
            rewards: ourRewards,
          };
        }
      }
      console.log('!!ourCircles: ', ourCircles);

      setMyRewards(ourCircles);
    } catch (err) {
      console.error('fetchMyRewards(): error fetching my Catalog' + err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMyRewards();
  }, []);

  if (loading) {
    return (
      <div>
        <CenteredProgress />
      </div>
    );
  }

  return (
    <ViewContainer>
      <HeaderContainer>
        <Title>Rewards</Title>
        <NewRewardButton
          onClick={() => {
            setOpenAddModal(!openAddModal);
          }}
        >
          <img src="/images/new-reward-plus.svg" width={22} height={22} />
          <div>New reward</div>
        </NewRewardButton>
        {openAddModal && (
          <AddModalContainer>
            {addModalChoices.map((choice) => {
              return (
                <AddModalItem
                  key={choice.label}
                  onClick={() => {
                    if (choice.disabled) return;

                    choice.onClick();
                  }}
                >
                  <AddModalItemLeftSide>
                    <AddModalItemTitle disabled={choice.disabled}>
                      {choice.label}
                    </AddModalItemTitle>
                  </AddModalItemLeftSide>
                  <AddModalItemRightSide>
                    {choice.disabled && (
                      <div
                        style={{
                          color: '#FF3535',
                          fontWeight: 600,
                          fontSize: 12,
                          display: 'flex',
                          alignSelf: 'center',
                        }}
                      >
                        Coming soon!
                      </div>
                    )}
                    {!choice.disabled && (
                      <AddModalIcon>
                        <img
                          width={20}
                          height={20}
                          src={choice.icon}
                          alt={choice.label}
                        />
                      </AddModalIcon>
                    )}
                  </AddModalItemRightSide>
                </AddModalItem>
              );
            })}
          </AddModalContainer>
        )}
      </HeaderContainer>
      {loading ? (
        <div>
          <CenteredProgress />
        </div>
      ) : null}
      {myRewards.length === 0 && !loading ? (
        <NoRewardsText>You have no rewards</NoRewardsText>
      ) : null}
      {myRewards.length !== 0 && !loading ? (
        <GridContainer>
          {myRewards.map((circle) =>
            circle.rewards.map((reward) => {
              if (reward.remaingDaysAvailable < 0) return null;

              const shouldLock = reward.ownerType === 'mission';

              return (
                <div
                  key={reward.id}
                  style={{
                    border: '1px solid #D0D2D8',
                    borderRadius: 6,
                    cursor: shouldLock ? 'default' : 'pointer',
                    position: 'relative',
                  }}
                  onClick={() =>
                    navigate('/rewards/details', { state: reward })
                  }
                >
                  {/* {global.me.isAdmin && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src="/images/ellipse-reward.svg"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        width={22}
                        height={22}
                      />
                    </div>
                  )} */}

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {reward.imageName !== '' ? (
                      <DisplayMedia
                        imageName={reward.imageName}
                        imageType={reward.imageType}
                        objectFit="cover"
                      />
                    ) : (
                      <NoImageText>No Image Available</NoImageText>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '12px 12px 2px 12px',
                      marginTop: 4,
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontWeight: 700, fontSize: 14 }}>
                      {reward.name}
                    </div>
                    {shouldLock && (
                      <div
                        style={{
                          backgroundColor: '#F0F0F3',
                          width: 'fit-content',
                          height: 'fit-content',
                          padding: '4px 8px',
                          borderRadius: 20,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                        }}
                      >
                        <img src="/images/lock.svg" />
                        <div style={{ fontWeight: 700, fontSize: 12 }}>
                          Locked
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: '#868686',
                      fontWeight: 500,
                      paddingLeft: 12,
                      paddingBottom: 12,
                    }}
                  >
                    {formatPoints(reward.cost)}
                  </div>
                </div>
              );
            })
          )}
        </GridContainer>
      ) : null}
    </ViewContainer>
  );
}

function formatPoints(number) {
  if (number <= 0) return '';

  if (number === 1) {
    return number + ' pt';
  } else {
    return number + ' pts';
  }
}
