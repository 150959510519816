import React from 'react';
import { KPILeaderboardState } from '../../_types';
import {
  CustomSelect,
  CustomFilterContainer,
  CustomFilterTitle,
} from '../KPIFilter.styles';

interface FilterOrganizationProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const FilterOrganization = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: FilterOrganizationProps) => {
  return (
    <CustomFilterContainer>
      <CustomFilterTitle>Organization</CustomFilterTitle>
      <CustomSelect
        value={kpiLeaderboardState.circleID}
        onChange={(e) => {
          onChangeKpiLeaderboardState({
            ...kpiLeaderboardState,
            circleID: e.target.value,
            currentPage: 0,
          });
          resetTokens();
        }}
      >
        <option value="" selected>
          Select
        </option>
        {kpiLeaderboardState.organizations.length > 0 &&
          kpiLeaderboardState.organizations.map((organization) => {
            return (
              <option key={organization.id} value={organization.id}>
                {organization.name}
              </option>
            );
          })}
      </CustomSelect>
    </CustomFilterContainer>
  );
};

export default FilterOrganization;
