import React from 'react';
import { Box, Card } from '@mui/material';
import './TextImage.css';
import { useNavigate } from 'react-router-dom';

export default function TextImage(props) {
  const user = props.user;
  const imageCache = props.imageCache;
  const navigate = useNavigate();

  let imageUrl = '/images/default-avatar.png';
  if (imageCache !== undefined && imageCache.hasImage(user)) {
    imageUrl = imageCache.obtainImageUrl(user);
  }
  return (
    <Card
      style={{ backgroundColor: 'transparent' }}
      onClick={() => {
        navigate('/profile/' + user.getId());
        navigate(0);
      }}
    >
      <Box
        className="container"
        style={{
          backgroundImage: `url("${imageUrl}")`,
          backgroundSize: 'contain',
        }}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {user.getPosition() && (
          <Box
            className="image-overlay"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {user.getPosition()}
          </Box>
        )}
      </Box>
    </Card>
  );
}
