import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, useLocation } from 'react-router';
// import gql from 'graphql-tag';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
// import {
//   SelectFromList /*, SelectPerson, SelectCircleLevelNew */,
// } from '../../common/PickerSearch';
// import { API, graphqlOperation } from "aws-amplify";
// import { createCircle, createCircleMember } from "../../../graphql/mutations";
// import * as mutations from "../../../graphql/mutations";
import { Formik } from 'formik';
import * as yup from 'yup';
import * as utils from '../../common/Utilities';
import fetchTeam from './fetchTeam';
import fetchTeamLevels from './fetchTeamLevels';
import fetchTeamLevelMaps from './fetchTeamLevelMaps';
import fetchTeamMembers from './fetchTeamMembers';
import fetchPeople from './fetchPeople';
import fetchGiftCardAccounts from './fetchGiftCardAccounts';
import DisplayMedia from '../../components/DisplayMedia';
import TeamFormPageSkeleton from './TeamFormPageSkeleton';
// import CancelTeamFormModal from './CancelTeamFormModal';
import TeamFormAddImageSkeleton from './TeamFormAddImageSkeleton';
import AddMemberLinkTeamModal from './AddMemberLinkTeamModal';
import TeamMemberCard from './TeamMemberCard';
import LinkedTeamCard from './LinkedTeamCard';
import { GamifyToast } from '../../common/CustomToasts';
import { GamifyIcon } from '../../components/GamifyIcon';
import {
  FormInput,
  FormSelectPerson,
  FormLevelsInput,
  FormScopeInput,
  FormWhoCanInviteInput,
  FormWhoCanApproveInput,
  FormSelectGiftcardAccount,
  FormModalInput,
} from '../../common/FormInputs';
import './TeamFormPage.css';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';

export default function TeamFormPage(props) {
  const { teamId, setShowTeamFormModal, onTriggerTeamsRefetch, isModal } =
    props;
  // NOTE: The following is needed for if TeamFormPage is used by itself at an 'edit/<teamID>' route, but not anymore as a modal is used now
  // const { teamId } = useParams();
  // const location = useLocation();
  // const [routeBackToTeamPage, setRouteBackToTeamPage] = useState(false);
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showLinkTeamModal, setShowLinkTeamModal] = useState(false);

  const [members, setMembers] = useState([]);
  const [isMemberListLoading, setIsMemberListLoading] = useState(false);

  const [linkedTeams, setLinkedTeams] = useState([]);
  //   const [initialLinkedTeam, setInitialLinkedTeam] = useState([]);
  const [isLinkedTeamListLoading, setIsLinkedTeamListLoading] = useState(false);

  const [membersShowAll, setMembersShowAll] = useState(false);
  const [linkedTeamsShowAll, setLinkedTeamsShowAll] = useState(false);

  async function doSubmit(values, actions) {
    // console.log('doSubmit args (values, actions): ', values, actions);
    actions.setSubmitting(true);
    setIsLoading(true);

    const ret = await createMyTeam(values);
    if (ret) {
      // Note: Doing this to ensure that the records are saved already, to avoid race condition
      setTimeout(() => {
        // route.params.onExit();
        onTriggerTeamsRefetch();
        setShowTeamFormModal(false);

        // NOTE: Use the navigates in the following code if switching back to using TeamFormPage at '/edit/<teamID>', no longer needed while the form is used in a modal
        if (teamId) {
          GamifyToast.success(`${values.name} team updated successfully`);
          // if (routeBackToTeamPage) {
          //   navigate(`/teams/${teamId}`);
          // } else {
          //   navigate('/teams');
          // }
        } else {
          GamifyToast.success(`${values.name} team created successfully`);
          // navigate(`/teams`);
        }
      }, 500);
    }

    setTimeout(() => {
      actions.resetForm();
      setHasSubmitted(false);
      actions.setSubmitting(false);
      setIsLoading(false);
    }, 500);
  }

  const [teamLevels, setTeamLevels] = useState([]);
  const [teamLevelNumIdMap, setTeamLevelNumIdMap] = useState([]);
  const [teamLevelNumNameMap, setTeamLevelNumNameMap] = useState([]);
  const [levelNum, setLevelNum] = useState(null);

  const [people, setPeople] = useState([]);
  const [giftCardAccounts, setGiftCardAccounts] = useState([]);

  const handleCancel = () => {
    setCancelOpen(true);
  };

  const newTeam = {
    id: '',
    name: '',
    description: '',
    rewardCount: '1',
    uploadloadingPicture: false,
    uploadPictureTitle: 'Upload Picture / Video',
    scope: 'public',
    inviterType: 'anyone',
    approverType: 'auto',
    imageName: '',
    imageType: '',
    avatarImageName: '',
    status: '',
    isActive: '',
    communityID: '',
    startDate: '',
    endDate: '',
    launchDate: '',
    location: '',
    orgID: global.me.orgID,
    levelID: '',
    ownerID: global.me.id,
    tangoAccountID: '',
    isHidden: false,
    extID: '',
    isLeague: false,
  };

  const [initialValues, setInitialValues] = useState(newTeam);
  //   const [viewAdvanced, setViewAdvanced] = useState(false);

  const createTeamValidationSchema = yup.object().shape({
    name: yup
      .string()
      .label('Name')
      .required()
      .min(2, 'The name is too short.  Please enter at least 2 characters')
      .max(
        32,
        'The team name is too long.  Are you trying to enter a description?'
      ),
    description: yup
      .string()
      .label('Description')
      .max(
        1024,
        'Your description is too long.  Please use less than 1024 characters'
      ),
    location: yup
      .string()
      .label('Location')
      .max(
        64,
        'Your location is too long.  Please use less than 64 characters'
      ),
    startDate: yup
      .string()
      .label('Start Date')
      .matches(
        utils.newDateRegExp,
        'Please enter a valid date in the form ' + global.appSettings.dateFormat
      ),
    endDate: yup
      .string()
      .label('End Date')
      .matches(
        utils.newDateRegExp,
        'Please enter a valid date in the form ' + global.appSettings.dateFormat
      ),
    launchDate: yup
      .string()
      .label('Launch Date')
      .matches(
        utils.newDateRegExp,
        'Please enter a valid date in the form ' + global.appSettings.dateFormat
      ),
  });

  useEffect(() => {
    //     getCircleForScreen(circleID);

    // NOTE: location state is only needed when TeamFormPage is used at a separate 'edit/<teamID>' route, now it is a modal
    // use location state to determine whether user navigated from TeamPage of TeamsScreen for correct rerouting on cancel/update
    // if (location?.state?.teamPage) setRouteBackToTeamPage(true);

    async function prepareFormData() {
      setIsFormDataLoading(true);

      const levels = await fetchTeamLevels();
      const [levelNumIdMap, levelNumNameMap] = await fetchTeamLevelMaps(
        global.me.orgID
      );

      setTeamLevels(levels);
      setTeamLevelNumIdMap(levelNumIdMap);
      setTeamLevelNumNameMap(levelNumNameMap);

      const gcAccounts = await fetchGiftCardAccounts();
      if (gcAccounts.length === 0) {
        if (global.me.isAdmin) {
          GamifyToast.error(
            'Please create a gift card account using the Gift Card Dashboard'
          );
        } else {
          GamifyToast.error(
            'Please ask your admin to create a gift card account using the Gift Card Dashboard'
          );
        }
      }
      setGiftCardAccounts(gcAccounts);
      //       setIsLoading(false);

      const orgPeople = await fetchPeople('');
      setPeople(orgPeople);

      if (teamId) {
        //         const teamMembers = await fetchCircleMembers(circleID);
        //         const initialMembers = teamMembers.map((team) => {
        //           return team.user;
        //         });
        const teamMembers = await fetchTeamMembers(teamId);
        const initialMembers = teamMembers.filter(
          (member) => member.status === 'active'
        );

        //         const circleDetails = await fetchCircleDetails(circleID);
        //         console.log(
        //           "🚀 ~ file: CreateTeamsScreen.view.tsx:533 ~ prepareFormData ~ circleDetails:",
        //           circleDetails
        //         );
        //         const linkedCircles = circleDetails?.children?.items || [];
        const team = await fetchTeam(teamId);
        const linkedTeams = team?.children?.items || [];

        // use team.levelID of existing team to set current levelNum for Link Teams modal
        for (const level of levels) {
          if (level.value === team.levelID) setLevelNum(level.levelNum);
        }

        setInitialValues(team);
        setMembers(initialMembers);
        setLinkedTeams(linkedTeams);
        //         setInitialLinkedTeam(linkedCircles);
      }
      setIsFormDataLoading(false);
    }

    prepareFormData();
  }, [teamId]);

  // console.log('TeamFormPage useLocation: ', location);
  // console.log('TeamFormPage routeBackToTeamPage: ', routeBackToTeamPage);

  async function updateOurTeam(values) {
    // const teamID = values.id;
    const ourTeam = {
      id: values.id,
      name: values.name,
      description: values.description,
      location: values.location,
      scope: values.scope,
      inviterType: values.inviterType,
      approverType: values.approverType,
      ownerType: values.ownerType,
      ownerID: values.ownerID,
      status: values.status,
      isActive: values.isActive,
      imageName: values.imageName,
      avatarImageName: values.avatarImageName,
      imageType: values.imageType,
      communityID: values.communityID,
      startDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(values.startDate, utils.MIN_DATE)
      ),
      endDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(values.endDate, utils.MAX_DATE)
      ),
      launchDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(
          values.launchDate,
          utils.defaultIfEmpty(values.startDate, utils.MIN_DATE)
        )
      ),
      orgID: values.orgID,
      levelID: values.levelID,
      tangoAccountID:
        values.tangoAccountID !== '' ? values.tangoAccountID : 'NA',
      isHidden: values.isHidden,
      extID: values.extID,
      isLeague: values.isLeague,
    };
    try {
      // This is called generalUpdateRequest in lyferize, res isn't used so doesn't need to be a variable...
      await API.graphql({
        query: mutations.updateCircle,
        variables: { input: ourTeam },
      });
      ourTeam.startDate = utils.defaultDateFormat(
        utils.blankIfValue(ourTeam.startDate, utils.MIN_DATE)
      );
      ourTeam.endDate = utils.defaultDateFormat(
        utils.blankIfValue(ourTeam.endDate, utils.MAX_DATE)
      );
      ourTeam.launchDate = utils.defaultDateFormat(
        utils.blankIfValue(ourTeam.launchDate, utils.MIN_DATE)
      );

      // Add Members and Add Child Teams done in onSubmit fxn of each AddMemberLinkTeamModal
      // if (members.length) {
      //   await addMembersToTeam(members, teamID);
      // }
      // if (linkedTeams.length) {
      //   await addChildTeams(linkedTeams, teamID);
      // }

      setInitialValues(ourTeam);
    } catch (err) {
      console.error(
        'updateOurTeam(): error saving ' +
          global.appSettings.labels.circle.singular +
          ': ' +
          err.message
      );
      GamifyToast.error(
        'There was an error saving your ' +
          global.appSettings.labels.circle.singular +
          '.'
      );
      return false;
    }
    return true;
  }

  async function createMyTeam(values) {
    if (values.id !== '') {
      const ret = await updateOurTeam(values);
      return ret;
    }
    const ourTeam = {
      name: values.name,
      description: values.description,
      location: values.location,
      scope: values.scope,
      inviterType: values.inviterType,
      approverType: values.approverType,
      status: 'created',
      isActive: true,
      imageName: values.imageName,
      avatarImageName: values.avatarImageName,
      imageType: values.imageType,
      communityID: '',
      startDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(values.startDate, utils.MIN_DATE)
      ),
      endDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(values.endDate, utils.MAX_DATE)
      ),
      launchDate: utils.graphqlDateFormat(
        utils.defaultIfEmpty(
          values.launchDate,
          utils.defaultIfEmpty(values.startDate, utils.MIN_DATE)
        )
      ),
      orgID: values.orgID,
      isDeleted: false,
      levelID: values.levelID,
      tangoAccountID:
        values.tangoAccountID !== '' ? values.tangoAccountID : 'NA',
      isHidden: values.isHidden,
      extID: values.name, // There is no extID on create, so use the name
      isLeague: values.isLeague,
    };

    try {
      const createTeamRes = await API.graphql(
        graphqlOperation(mutations.createCircle, { input: ourTeam })
      );
      const teamData = createTeamRes.data.createCircle;

      const teamMember = {
        userID: values.ownerID,
        circleID: teamData.id,
        status: 'approved',
        memberType: 'owner',
        isActive: true,
        circlePoints: 0.0,
        lifetimeCirclePoints: 0.0,
        orgID: values.orgID,
        isDeleted: false,
      };

      await API.graphql(
        graphqlOperation(mutations.createCircleMember, { input: teamMember })
      );
      setInitialValues(newTeam);

      if (members.length) {
        await addMembersToTeam(members, teamData.id);
      }

      if (linkedTeams.length) {
        await addChildTeams(linkedTeams, teamData.id);
      }

      //       feedBlast({
      //         userID: global.me.id,
      //         header:
      //           "you created the " +
      //           circleData.name +
      //           " " +
      //           global.appSettings.labels.circle.singular +
      //           ".",
      //         details:
      //           "You should now invite some friends to join the " +
      //           " " +
      //           global.appSettings.labels.circle.singular +
      //           ".",
      //         imageName: circleData.imageName,
      //         imageType: circleData.imageType,
      //         blastEvent: {
      //           action: "createCircle",
      //           circleID: circleData.id,
      //           enabled:
      //             global.appSettings.notifications.acceptedCircleMember.enabled,
      //           image: global.appSettings.notifications.acceptedCircleMember.image,
      //         },
      //       });
    } catch (err) {
      console.error(
        'createMyTeam(): error saving ' +
          global.appSettings.labels.circle.singular +
          ':' +
          err.message
      );
      GamifyToast.error(
        'There was an error saving your ' +
          global.appSettings.labels.circle.singular +
          '.'
      );
      return false;
    }
    return true;
  }

  async function addMembersToTeam(users, teamID) {
    console.log('addMembersToTeam users, teamID: ', users, teamID);
    if (!users.length) {
      return;
    }

    const requests = [];
    for (const user of users) {
      requests.push(
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: teamID,
            personID: user.id,
            status: 'accepted',
            role: '', // TO DO: set roles on form?
          },
        })
      );
    }

    const allPromise = Promise.all(requests);

    allPromise
      .then((values) => {
        console.log('!!!returned values: ', values);
      })
      .catch((error) => {
        console.log('!!!returned error: ', error);
      });
  }

  const onSubmitMembers = async (newMembers) => {
    // console.log('onSubmit newMembers: ', newMembers);
    // console.log('onSubmit nembers: ', members);
    const numNewMembers = newMembers.length - members.length;
    // no new members added, just return
    if (numNewMembers === 0) return;

    setMembers(newMembers);
    // Match lyferize by doing query before 'Save/Update'
    if (teamId) await addMembersToTeam(newMembers, teamId);

    if (numNewMembers === 1) {
      const newMemberArr = newMembers.filter(
        (newMember) => !members.find((member) => member.id === newMember.id)
      );
      const newMember = newMemberArr[0]; // there will only be one elemet in the array since there's only one new member
      GamifyToast.success(
        teamId
          ? `${newMember.name} added successfully`
          : `${newMember.name} added successfully. Save changes below to ensure ${newMember.name} is added.`
      );
    } else {
      GamifyToast.success(
        teamId
          ? `${numNewMembers} members added successfully`
          : `${numNewMembers} members added successfully. Save changes below to ensure team members are added.`
      );
    }
  };

  async function addChildTeams(teams, parentTeamID) {
    // console.log('addChildTeams teams, parentTeamID: ', teams, parentTeamID);
    if (!teams.length) {
      return;
    }

    const requests = [];

    for (const team of teams) {
      // // TO DO: see if found and initialLinkedTeam are needed...
      // const found = initialLinkedTeam.find((t) => t.id === team.id);

      // Note: This means we skip toggling the specific team.
      // How the API works atm is it toggles the adding and removing of the team.
      requests.push(
        API.graphql({
          query: mutations.updateCircle,
          variables: {
            input: {
              id: team.id,
              parentCircleID: parentTeamID,
            },
          },
        })
      );
    }

    try {
      const values = await Promise.all(requests);
      console.log('!!!returned values: ', values);
    } catch (error) {
      console.log('!!!returned error: ', error);
    }
  }

  const onSubmitChildTeams = async (newLinkedTeams) => {
    // console.log('onSubmit newLinkedTeams: ', newLinkedTeams);
    // console.log('onSubmit linkedTeams: ', linkedTeams);
    const numNewLinkedTeams = newLinkedTeams.length - linkedTeams.length;
    // no new members added, just return
    if (numNewLinkedTeams === 0) return;

    setLinkedTeams(newLinkedTeams);
    // Match lyferize by doing query before 'Save/Update'
    if (teamId) await addChildTeams(newLinkedTeams, teamId);

    if (numNewLinkedTeams === 1) {
      const newLinkedTeamArr = newLinkedTeams.filter(
        (newLinkedTeam) =>
          !linkedTeams.find((linkedTeam) => linkedTeam.id === newLinkedTeam.id)
      );
      const newLinkedTeam = newLinkedTeamArr[0]; // there will only be one elemet in the array since there's only one new linked team
      GamifyToast.success(
        teamId
          ? `${newLinkedTeam.name} linked successfully`
          : `${newLinkedTeam.name} linked successfully. Save changes below to ensure ${newLinkedTeam.name} is linked.`
      );
    } else {
      teamId
        ? GamifyToast.success(`${numNewLinkedTeams} teams linked successfully`)
        : GamifyToast.success(
            `${numNewLinkedTeams} teams linked successfully. Save changes below to ensure teams are linked.`
          );
    }
  };

  const onDropTeamImage = async (
    droppedFile,
    formikProps,
    setImageIsLoading
  ) => {
    setImageIsLoading(true);
    const formats = ['jpeg', 'jpg', 'png', 'gif'];
    const image = droppedFile[0]; // the imageFile
    console.log('onDropDealImage image: ', image);
    const imageName = image.name;
    if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
      GamifyToast.error(
        'Team image format must be one of the following - png, gif, jpeg'
      );
      return;
    }

    // generate random string and take image format to create new file name to match file names on deals in lyferize
    const imageNameFormat = imageName.split('.').pop().toLowerCase();
    const randomString = utils.generateRandomImageFileNameString();
    const newImageName = `${randomString}.${imageNameFormat}`;

    // upload image
    const uploadedImage = await Storage.put(newImageName, image, {
      contentType: image.type,
    });

    // set formikProps
    formikProps.setFieldValue('imageName', uploadedImage.key);
    formikProps.setFieldValue('imageType', image.type);
    setImageIsLoading(false);
  };

  function onDeleteImage(formikProps) {
    // formikProps.setFieldValue('avatar', '');
    formikProps.setFieldValue('imageName', '');
    formikProps.setFieldValue('imageType', '');
  }

  function AddImage({ formikProps, setImageIsLoading }) {
    return (
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: isModal ? 'flex-start' : 'center',
        }}
      >
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#E2D8FF',
            width: 240,
            height: 240,
            borderRadius: 2000,
            // width: '100%',
            // height: 361,
            // borderRadius: 8,
            marginBottom: 24,
          }}
        >
          <GamifyIcon icon={'addImage'}></GamifyIcon>
          <Typography
            style={{ color: '#5F27CD', fontSize: 18, fontWeight: 700 }}
          >
            Add Image
          </Typography>
          <Dropzone
            onDrop={(droppedFile) =>
              onDropTeamImage(droppedFile, formikProps, setImageIsLoading)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                className={'team-form-file-upload-container'}
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  border: '1px solid #D0D2D8',
                  borderRadius: 1000,
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <GamifyIcon icon={'edit'}></GamifyIcon>
              </Box>
            )}
          </Dropzone>
        </Box>
      </Box>
    );
  }

  function ImagePreview({ formikProps }) {
    const [imageName, setImageName] = useState(formikProps.values.imageName);
    const [imageIsLoading, setImageIsLoading] = useState(false);
    console.log('ImagePreview formikProps: ', formikProps);

    useEffect(() => {
      setImageName(formikProps.values.imageName);
    }, [formikProps.values.imageName]);

    if (imageIsLoading) {
      return (
        <TeamFormAddImageSkeleton isModal={true}></TeamFormAddImageSkeleton>
      );
    }

    if (imageName) {
      return (
        <>
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: isModal ? 'flex-start' : 'center',
            }}
          >
            <Box style={{ position: 'relative', marginBottom: 24 }}>
              <DisplayMedia
                //   imageName={formikProps.values.imageName}
                imageName={imageName}
                imageType={formikProps.values.imageType}
                width={240}
                height={240}
                borderRadius={1000}
                // width={'100%'}
                // height={361}
                // borderRadius={'16px'}
              ></DisplayMedia>
              <Dropzone
                onDrop={(droppedFile) =>
                  onDropTeamImage(droppedFile, formikProps, setImageIsLoading)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    className={'team-form-file-upload-container'}
                    style={{
                      position: 'absolute',
                      bottom: 16,
                      right: 16,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      width: 40,
                      minWidth: 40,
                      height: 40,
                      border: '1px solid #D0D2D8',
                      borderRadius: 1000,
                    }}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <GamifyIcon icon={'edit'}></GamifyIcon>
                  </Box>
                )}
              </Dropzone>
              <Box
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: -34,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  border: '1px solid #D0D2D8',
                  borderRadius: 1000,
                }}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteImage(formikProps);
                }}
              >
                <GamifyIcon icon={'trash'}></GamifyIcon>
              </Box>
            </Box>
          </Box>
        </>
      );
    } else {
      return (
        <AddImage
          formikProps={formikProps}
          setImageIsLoading={setImageIsLoading}
        ></AddImage>
      );
    }
  }

  function AboutForm({ formikProps }) {
    // console.log('AboutForm formikProps: ', formikProps);

    return (
      <Box
        style={{
          backgroundColor: '#F0F0F3',
          borderRadius: 8,
          paddingBottom: 0,
          marginBottom: 24,
        }}
      >
        <Box
          style={{
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: '#D0D2D8',
            padding: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
          >
            About
          </Typography>
          <GamifyIcon icon={'about'} color="#323232" />
        </Box>
        <Box style={{ padding: '24px 16px 8px 16px' }}>
          <FormInput
            inputName={'name'}
            formikProps={formikProps}
            hasSubmitted={hasSubmitted}
            required={true}
          ></FormInput>
          <FormInput
            inputName={'description'}
            formikProps={formikProps}
            hasSubmitted={hasSubmitted}
          ></FormInput>
          {formikProps.values.id === '' ? (
            <FormSelectPerson
              value={formikProps.values.ownerID}
              fieldSetter={(value) => {
                // console.log('value: ', value);
                if (value !== '') {
                  formikProps.setFieldValue('ownerID', value);
                }
              }}
              people={people}
              title="Owner (Manager)"
            ></FormSelectPerson>
          ) : (
            <Box
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: 16,
              }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#0B0B0C',
                  marginBottom: 8,
                }}
              >
                {'Owner (Manager)'}
              </Typography>
              <Typography style={{ fontSize: 12, marginTop: 10 }}>
                Use the Show Members button to change the owner
              </Typography>
            </Box>
          )}
          <FormLevelsInput
            value={formikProps.values.levelID}
            fieldSetter={(value) => formikProps.setFieldValue('levelID', value)}
            hasSubmitted={hasSubmitted}
            linkedTeams={linkedTeams}
            teamLevels={teamLevels}
            setLevelNum={setLevelNum}
            title={'*Team Level'}
            required={true}
          ></FormLevelsInput>
          <FormSelectGiftcardAccount
            value={formikProps.values.tangoAccountID}
            fieldSetter={(value) => {
              formikProps.setFieldValue('tangoAccountID', value);
            }}
            giftCardAccounts={giftCardAccounts}
            title={'Which account is paying for gift cards?'}
          ></FormSelectGiftcardAccount>
          {/* {fields.map((el, i) => (
                  <FlexFieldInput
                    key={i}
                    flexField={el}
                    flexData={flexData}
                    setFlexData={setFlexData}
                    validateAlFields={validateAlFields}
                    errorMessages={errorMessages}
                    // setErrorMessages={setErrorMessages}
                    hasSubmitted={props.hasSubmitted}
                    canSave={props.canSave}
                  />
                ))} */}
        </Box>
      </Box>
    );
  }

  const onRemoveMemberFromState = (memberId) => {
    console.log('onRemoveMemberFromState members: ', members);
    setMembers(members.filter((member) => member.id !== memberId));
  };

  const onRemoveMembers = async (members = [], teamId, onComplete) => {
    // console.log('RemoveMembers members: ', members);
    if (!members.length) return;

    const numRemovedMembers = members.length;

    try {
      for (const member of members) {
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: teamId,
            personID: member.id,
            status: 'removed',
            role: '',
          },
        });

        onRemoveMemberFromState(member.id);
      }
      // Success toast in MemberOptionMenu
      onComplete(true);
      return true;
    } catch (err) {
      console.error('onRemoveMember error: ', err);
      if (numRemovedMembers === 1) {
        GamifyToast.error(
          `We couldn't remove ${members[0].name}. Please try again later.`
        );
      } else {
        GamifyToast.error(
          `We couldn't remove ${members.length} members. Please try again later.`
        );
      }
      onComplete(false);
      return false;
    }
  };

  function MembersForm({ formikProps }) {
    console.log('MembersForm formikProps: ', formikProps);
    // const [selectedTeamMember, setSelectedTeamMember] = useState(null);
    // const [checkedTeamMembers, setCheckedTeamMembers] = useState([]);
    // const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    // const [isBulkRemove, setIsBulkRemove] = useState(false);
    // const [isSaving, setIsSaving] = useState(false);

    // const handleTeamMemberCheck = (event, teamMember) => {
    //   if (event.target.checked) {
    //     setCheckedTeamMembers((prevCheckedTeamMembers) => [
    //       ...prevCheckedTeamMembers,
    //       teamMember,
    //     ]);
    //   } else {
    //     setCheckedTeamMembers((prevCheckedTeamMembers) =>
    //       prevCheckedTeamMembers.filter(
    //         (checkedTeamMember) => checkedTeamMember.id !== teamMember.id
    //       )
    //     );
    //   }
    // };

    // console.log('TeamFormPage checkedTeamMembers: ', checkedTeamMembers);
    // console.log('TeamFormPage selectedTeamMember: ', selectedTeamMember);

    return (
      <Box
        style={{
          backgroundColor: '#F0F0F3',
          borderRadius: 8,
          paddingBottom: 0,
          marginBottom: 24,
        }}
      >
        <Box
          style={{
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: '#D0D2D8',
            padding: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
          >
            {`Members (${members.length})`}
          </Typography>
          <GamifyIcon icon={'users'} color="#323232" />
        </Box>
        <Box style={{ padding: '24px 16px 8px 16px' }}>
          <FormModalInput
            onClick={() => setShowAddMemberModal(true)}
            gamifyIcon={'addUser'}
            label={'Add members'}
            hasItems={members.length > 0}
          ></FormModalInput>
          {isMemberListLoading && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: members.length > 0 ? '8px' : '20px',
              }}
            >
              <CircularProgress style={{ color: 'black' }}></CircularProgress>
            </Box>
          )}
          {members
            .map((member, i) =>
              // If there are initialValues (team), then an existing team is being edited. Otherwise it is a team that is being created and can just remove the members from the members state.
              initialValues ? (
                <TeamMemberCard
                  key={i}
                  team={initialValues}
                  member={member}
                  onRemoveMemberFromState={onRemoveMemberFromState}
                  onRemoveMembers={onRemoveMembers}
                  // selectedTeamMember={selectedTeamMember}
                  // setSelectedTeamMember={setSelectedTeamMember}
                  // setCheckedTeamMembers={setCheckedTeamMembers}
                  // handleTeamMemberCheck={handleTeamMemberCheck}
                  // checkedTeamMembers={checkedTeamMembers}
                  setIsMemberListLoading={setIsMemberListLoading}
                  // onTriggerRefetch={onTriggerRefetch}
                  isOnTeamForm={true}
                ></TeamMemberCard>
              ) : (
                <TeamMemberCard
                  key={i}
                  member={member}
                  onRemoveMemberFromState={onRemoveMemberFromState}
                  onRemoveMembers={onRemoveMembers}
                  // selectedTeamMember={selectedTeamMember}
                  // setSelectedTeamMember={setSelectedTeamMember}
                  // setCheckedTeamMembers={setCheckedTeamMembers}
                  // handleTeamMemberCheck={handleTeamMemberCheck}
                  // checkedTeamMembers={checkedTeamMembers}
                  setIsMemberListLoading={setIsMemberListLoading}
                  // onTriggerRefetch={onTriggerRefetch}
                  isOnTeamForm={true}
                ></TeamMemberCard>
              )
            )
            .slice(0, membersShowAll ? members.length : 5)}
          {members.length > 5 && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <Box
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: 'fit-contnet',
                }}
              >
                <Typography
                  onClick={() => setMembersShowAll(!membersShowAll)}
                  style={{ color: '#5F27CD', fontSize: 16, fontWeight: 500 }}
                >
                  {!membersShowAll ? 'Show All' : 'Hide All'}
                </Typography>
                {/* {checkedTeamMembers.length > 0 && (
                  <Box
                    onClick={() => {
                      setIsBulkRemove(true);
                      setShowConfirmRemove(true);
                    }}
                    style={{
                      backgroundColor: '#FF5C77',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 228,
                      height: 52,
                      padding: '24px 16px 24px 16px',
                      borderRadius: 8,
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      style={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      {checkedTeamMembers.length === 1
                        ? `Remove (${checkedTeamMembers.length}) member`
                        : `Remove (${checkedTeamMembers.length}) members`}
                    </Typography>
                  </Box>
                )} */}
              </Box>
            </Box>
          )}
          <AddMemberLinkTeamModal
            title={'Add Members'}
            team={null} // not needed for adding members
            teamLevelNumIdMap={null} // not needed for adding members
            teamLevelNumNameMap={null} // not needed for adding members
            noAutoSelect={true}
            pickCircles={false}
            isLinkTeams={false}
            pickUsers={true}
            update={null}
            filter={global.me.orgID}
            itemValue={''}
            onSubmit={(newMembers) => onSubmitMembers(newMembers)}
            hideInitialChecked
            initialActiveMembers={members}
            setShowModal={setShowAddMemberModal}
            showModal={showAddMemberModal}
          ></AddMemberLinkTeamModal>
        </Box>
      </Box>
    );
  }

  const onRemoveTeamFromState = (teamId) => {
    // console.log('onRemoveTeamFromState members: ', linkedTeams);
    setLinkedTeams(linkedTeams.filter((team) => team.id !== teamId));
  };

  const onRemoveTeam = async (childTeam) => {
    try {
      const teamData = {
        id: childTeam.id,
        parentCircleID: null,
      };
      await API.graphql({
        query: mutations.updateCircle,
        variables: { input: teamData },
      });

      onRemoveTeamFromState(childTeam.id);
      // Success toast in LinkedTeamOptionMenu
      return true;
    } catch (err) {
      console.error('onRemoveTeam error: ', err);
      GamifyToast.error(
        `We couldn't unlink ${childTeam.name}. Please try again later.`
      );
      return false;
    }
  };

  function LinkedTeamsForm({ formikProps, levelNum }) {
    // console.log('LinkedTeamsForm formikProps: ', formikProps);
    // console.log('LinkedTeamsForm showLinkTeamModal: ', showLinkTeamModal);

    return (
      <Box
        style={{
          backgroundColor: '#F0F0F3',
          borderRadius: 8,
          paddingBottom: 0,
          marginBottom: 24,
        }}
      >
        <Box
          style={{
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: '#D0D2D8',
            padding: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
          >
            {`Linked Teams (${linkedTeams.length})`}
          </Typography>
          <GamifyIcon icon={'link'} color="#323232" />
        </Box>
        <Box style={{ padding: '24px 16px 8px 16px' }}>
          <FormModalInput
            onClick={() => {
              if (formikProps.values.levelID === '') {
                GamifyToast.error(
                  'Please select a Team Level before trying to link teams'
                );
              } else {
                setShowLinkTeamModal(true);
              }
            }}
            gamifyIcon={'link'}
            label={'Link teams'}
            hasItems={linkedTeams.length > 0}
          ></FormModalInput>
          {isLinkedTeamListLoading && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: linkedTeams.length > 0 ? '8px' : '20px',
              }}
            >
              <CircularProgress style={{ color: 'black' }}></CircularProgress>
            </Box>
          )}
          {linkedTeams
            .map((linkedTeam, i) =>
              // If teamId, then an existing team is being edited. Otherwise it is a team that is being created and can just remove the linked teams from the linkedTeams state.
              teamId ? (
                <LinkedTeamCard
                  key={i}
                  parentTeamId={teamId}
                  linkedTeam={linkedTeam}
                  onRemoveTeamFromState={onRemoveTeamFromState}
                  onRemoveTeam={onRemoveTeam}
                  setIsLinkedTeamListLoading={setIsLinkedTeamListLoading}
                  // onTriggerRefetch={onTriggerRefetch}
                  // isOnTeamForm={true}
                ></LinkedTeamCard>
              ) : (
                <LinkedTeamCard
                  key={i}
                  linkedTeam={linkedTeam}
                  onRemoveTeamFromState={onRemoveTeamFromState}
                  onRemoveTeam={onRemoveTeam}
                  setIsLinkedTeamListLoading={setIsLinkedTeamListLoading}
                  // onTriggerRefetch={onTriggerRefetch}
                  // isOnTeamForm={true}
                ></LinkedTeamCard>
              )
            )
            .slice(0, linkedTeamsShowAll ? linkedTeams.length : 5)}
          {linkedTeams.length > 5 && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <Box
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: 'fit-contnet',
                }}
              >
                <Typography
                  onClick={() => setLinkedTeamsShowAll(!linkedTeamsShowAll)}
                  style={{ color: '#5F27CD', fontSize: 16, fontWeight: 500 }}
                >
                  {!linkedTeamsShowAll ? 'Show All' : 'Hide All'}
                </Typography>
              </Box>
            </Box>
          )}
          <AddMemberLinkTeamModal
            title={'Link Teams'}
            team={initialValues.id ? initialValues : { level: { levelNum } }} // team is only needed for the team's levelNum, find and pass in the levelNum for the selected level when creating a team
            teamLevelNumIdMap={teamLevelNumIdMap}
            teamLevelNumNameMap={teamLevelNumNameMap}
            noAutoSelect={true}
            pickCircles={true}
            isLinkTeams={true}
            pickUsers={false}
            update={null}
            filter={global.me.orgID}
            itemValue={''}
            onSubmit={(newLinkedTeams) => onSubmitChildTeams(newLinkedTeams)}
            hideInitialChecked
            initialActiveMembers={linkedTeams}
            setShowModal={setShowLinkTeamModal}
            showModal={showLinkTeamModal}
          ></AddMemberLinkTeamModal>
        </Box>
      </Box>
    );
  }

  function AdvancedOptionsForm({ formikProps }) {
    console.log('AdvancedOptionsForm formikProps: ', formikProps);
    const [showOptions, setShowOptions] = useState(false);

    const handleAdvancedClick = () => {
      setShowOptions(!showOptions);
    };

    return (
      <Box
        style={{
          backgroundColor: '#F0F0F3',
          borderRadius: 8,
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          style={
            showOptions
              ? {
                  width: '100%',
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  backgroundColor: '#D0D2D8',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }
              : {
                  width: '100%',
                  borderRadius: 8,
                  backgroundColor: '#D0D2D8',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }
          }
          onClick={handleAdvancedClick}
        >
          <Typography
            style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
          >
            {showOptions
              ? 'Advanced Options'
              : 'Advanced Options (Click to Open)'}
          </Typography>
          <GamifyIcon icon={'gear'} color="#323232" />
        </Box>
        {showOptions && (
          <Box style={{ padding: '24px 16px 8px 16px' }}>
            <FormInput
              inputName={'location'}
              formikProps={formikProps}
              hasSubmitted={hasSubmitted}
            ></FormInput>
            {/* NOTE: Start and End Date not used outside game creation for now */}
            {/* <FormDatePicker
              title="Start Date"
              fieldSetter={(date) => {
                formikProps.setFieldValue('startDate', date);
              }}
              dateInputProps={{
                minimumDate: new Date(),
              }}
              value={formikProps.values.startDate}
              // mode={"date"}
              // containerStyle={{}}
              // hideTime
              isStartDate
            />
            <FormDatePicker
              title="End Date"
              fieldSetter={(date) => {
                formikProps.setFieldValue('endDate', date);
              }}
              dateInputProps={{
                minimumDate: endMinimumDate,
              }}
              value={formikProps.values.endDate}
              // containerStyle={{
              //   marginTop: 0,
              // }}
              // hideTime
              isEndDate
            /> */}
            {/* {global.appSettings.features.circle.supportLeagues && (
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      {
                        marginRight: 10,
                        paddingLeft: 4,
                        paddingTop: 6,
                        textShadowColor: "white",
                        textShadowRadius: 3,
                      },
                      masterStyles.light,
                    ]}
                  >
                    Is this a League?
                  </Text>

                  <Switch
                    style={{
                      transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
                    }}
                    trackColor={{ false: "lightgray", true: "lightgray" }}
                    thumbColor={formikProps.values.isLeague ? "black" : "black"}
                    ios_backgroundColor="lightgray"
                    onValueChange={() => {
                      formikProps.setFieldValue(
                        "isLeague",
                        !formikProps.values.isLeague
                      );
                    }}
                    value={formikProps.values.isLeague}
                  />
                </View>
              )} */}
            <FormScopeInput
              value={formikProps.values.scope}
              fieldSetter={(value) => formikProps.setFieldValue('scope', value)}
              hasSubmitted={hasSubmitted}
              title={'Who can see this team?'}
            ></FormScopeInput>
            <FormWhoCanInviteInput
              value={formikProps.values.inviterType}
              fieldSetter={(value) =>
                formikProps.setFieldValue('inviterType', value)
              }
              hasSubmitted={hasSubmitted}
              title={'Who can invite members?'}
            ></FormWhoCanInviteInput>
            <FormWhoCanApproveInput
              value={formikProps.values.approverType}
              fieldSetter={(value) =>
                formikProps.setFieldValue('approverType', value)
              }
              hasSubmitted={hasSubmitted}
              title={'Who can approve members?'}
            ></FormWhoCanApproveInput>
          </Box>
        )}
      </Box>
    );
  }

  if (isFormDataLoading) {
    return (
      <TeamFormPageSkeleton
        teamId={teamId}
        isModal={isModal}
        setShowTeamFormModal={setShowTeamFormModal}
      ></TeamFormPageSkeleton>
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnMount={true} // Need this to validate before first submit attempt
      onSubmit={(values, actions) => {
        // console.log('TeamFormPage onSubmit');

        if (values.levelID === '') {
          GamifyToast.error('Please select a Team Level before saving');
          actions.setSubmitting(false);
        } else {
          doSubmit(values, actions);
        }
      }}
      validationSchema={createTeamValidationSchema}
    >
      {(formikProps) => (
        <Box
          className={
            isModal ? 'team-form-modal-container' : 'team-form-page-container'
          }
        >
          {/* <CancelTeamFormModal
            props={{ cancelOpen, setCancelOpen, teamId, routeBackToTeamPage }}
          ></CancelTeamFormModal> */}
          <DynamicConfirmModal
            showDynamicConfirmModal={cancelOpen}
            setShowDynamicConfirmModal={setCancelOpen}
            zIndex={100002}
            leftAlignText={true}
            title={'Leave without saving changes?'}
            subtitle={`If you leave without saving, your progress will be lost.`}
            cancelButtonText={'Leave'}
            onCancel={() => {
              setShowTeamFormModal(false);
              setCancelOpen(false);
              formikProps.resetForm();

              // NOTE: The following is only needed if TeamFormPage is used at a separate 'edit/<teamID>' route, but it is now used in a modal
              // if (teamId) {
              //   if (routeBackToTeamPage) {
              //     navigate(`/teams/${teamId}`);
              //   } else {
              //     navigate('/teams');
              //   }
              // } else {
              //   navigate('/teams');
              // }
            }}
            confirmButtonText={'Keep editing'}
            onConfirm={() => {
              setCancelOpen(false);
            }}
          ></DynamicConfirmModal>
          <Box
            className={
              isModal
                ? 'team-form-modal-top-container'
                : 'team-form-page-top-container'
            }
          >
            <Box
              className={
                isModal
                  ? 'team-form-modal-cancel-container'
                  : 'team-form-page-cancel-container'
              }
            >
              <Box className={'team-form-cancel-button-container'}>
                <Button
                  className={
                    isModal
                      ? 'team-form-modal-cancel-button'
                      : 'team-form-page-cancel-button'
                  }
                  onClick={() => {
                    if (formikProps.dirty) {
                      handleCancel();
                    } else {
                      setShowTeamFormModal(false);

                      // NOTE: The following is only needed if TeamFormPage is used at a separate 'edit/<teamID>' route, but now it is used in a modal
                      // // No changes that need to be saved, can cancel w/o the cancel modal
                      // if (teamId) {
                      //   if (routeBackToTeamPage) {
                      //     // Back to the team page
                      //     navigate(`/teams/${teamId}`);
                      //   } else {
                      //     // Back to the teams screen if that's where user navigated from
                      //     navigate('/teams');
                      //   }
                      // } else {
                      //   // Back to the teams screen
                      //   navigate('/teams');
                      // }
                    }
                  }}
                  disableRipple={true}
                >
                  <GamifyIcon icon={'back'}></GamifyIcon>
                  <Typography
                    className={
                      isModal
                        ? 'team-form-modal-cancel-button-text'
                        : 'team-form-page-cancel-button-text'
                    }
                  >
                    Cancel
                  </Typography>
                </Button>
              </Box>
              <Box
                className={
                  isModal
                    ? 'team-form-modal-title-container'
                    : 'team-form-page-title-container'
                }
              >
                <Typography className={'team-form-title-text'}>
                  {teamId ? `Edit Team` : `New Team`}
                </Typography>
              </Box>
              <Box className={'team-form-place-holder-container'}></Box>
            </Box>
          </Box>
          <Box
            className={
              isModal ? 'modal-team-form-container' : 'page-team-form-container'
            }
          >
            <ImagePreview formikProps={formikProps}></ImagePreview>
            <AboutForm formikProps={formikProps}></AboutForm>
            <MembersForm formikProps={formikProps}></MembersForm>
            {levelNum !== null && levelNum !== teamLevels.length - 1 && (
              <LinkedTeamsForm
                formikProps={formikProps}
                levelNum={levelNum}
              ></LinkedTeamsForm>
            )}
            <AdvancedOptionsForm
              formikProps={formikProps}
            ></AdvancedOptionsForm>
            <Box className={'save-team-button-container'}>
              <Button
                className={'save-team-button'}
                onClick={() => {
                  // console.log(
                  //   'TeamFormPage onClick formikProps.values: ',
                  //   formikProps.values
                  // );

                  setHasSubmitted(true);

                  // console.log(
                  //   'TeamFormPage onClick formikProps.isValid: ',
                  //   formikProps.isValid
                  // );

                  if (!formikProps.isValid) {
                    GamifyToast.error('Please fix all errors and try again');
                  }

                  formikProps.handleSubmit();
                }}
                disableRipple={true}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{ color: 'white', height: 24, width: 24 }}
                  ></CircularProgress>
                ) : (
                  <Typography className={'save-team-button-text'}>
                    {/* TO DO: change text based on design */}
                    {teamId ? 'Update team' : 'Save changes'}
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}
