import React from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Button,
} from '@mui/material';
import StyledTable from '../../components/StyledTable';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import TextImage from '../../components/TextImage';
import './Index.css';
import LeaderAvatar from '../../components/LeaderAvatar';
import Utilities from '../../common/Utilities';
import CenteredProgress from '../../components/CenteredProgress';
import LeaderboardRepository from '../../repository/LeaderboardRepository';
import ImageCache from '../../domain/ImageCache';
import CenteredComponent from '../../components/CenteredComponent';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../components/withRouter';
import { withUseParams } from '../../components/withUseParams';
import { withUseSearchParams } from '../../components/useSearchParams';
import addRewardImage from '../rewards/add-reward.png';
import listViewImage from './list.svg';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.handleLeaderboardChange = this.onLeaderboardChange.bind(this);
    this.state = {
      leaderboardList: [],
      leaderboard: null,
      imageCache: null,
      isLoading: true,
      activeLeaderboardId: null,
      startBetween: [],
      endBetween: [],
      showOld: false,
    };
  }

  componentDidMount() {
    const params = this.props.params;
    const searchParams = this.props.searchParams;
    const showOld = searchParams.get('showOld') === 'true';
    const MAX_DATE = '2099-12-31';
    const MIN_DATE = '1900-01-01';
    const missionId = params.missionId === undefined ? '' : params.missionId;

    const startBetween = showOld
      ? [MIN_DATE, Utilities.localToIsoDateFormat(new Date())]
      : [MIN_DATE, Utilities.localToIsoDateFormat(new Date())];
    const endBetween = showOld
      ? [MIN_DATE, Utilities.localToIsoDateFormat(new Date())]
      : [Utilities.localToIsoDateFormat(new Date()), MAX_DATE];
    LeaderboardRepository.fetchLeaderboards(
      missionId,
      startBetween,
      endBetween
    ).then((response) => {
      const [_leaderboardList, leaderboard] = response;
      if (leaderboard === null || leaderboard.data === null) {
        this.setState({
          isLoading: false,
          leaderboardList: _leaderboardList,
          leaderboard: null,
          showOld,
        });
      } else {
        const promisedImages = leaderboard
          .collateImageMetadata()
          .map((metadata) => metadata.loadImage());
        Promise.all(promisedImages)
          .then((loadedImages) => new ImageCache(loadedImages))
          .then((imageCache) =>
            this.setState({
              isLoading: false,
              leaderboardList: _leaderboardList,
              activeLeaderboardId:
                missionId === '' ? _leaderboardList[0].id : missionId,
              showOld,
              leaderboard,
              imageCache,
            })
          );
      }
    });
  }

  onLeaderboardChange(event) {
    const navigate = this.props.navigate;
    console.log('***event.target.value: ', event.target.value);
    // navigate('/leaderboard/' + event.target.value + this.state.showOld ? '?showOld=true' : '?showOld=false');
    navigate(
      '/leaderboard/' +
        event.target.value +
        (this.state.showOld ? '?showOld=true' : '?showOld=false')
    );
    navigate(0);
  }

  buildColumns() {
    const translation = this.props.t;
    const leaderboard = this.state.leaderboard;
    const actionType = leaderboard.getActionTypePluralized()
      ? leaderboard.getActionTypePluralized()
      : translation('tableColumnNames.value');
    return [
      {
        field: 'position',
        headerName: translation('tableColumnNames.position'),
        width: 180,
        headerAlign: 'center',
        align: 'center',
        cellClassName: 'row-position-overlay',
        renderCell: (params) => {
          return (
            <Box className={'row-profile-image-overlay'}>
              <TextImage
                className={'row-profile-image'}
                user={params.value.user}
                imageCache={params.value.imageCache}
              ></TextImage>
            </Box>
          );
        },
      },
      {
        field: 'name',
        headerName: translation('tableColumnNames.name'),
        width: 600,
      },
      {
        field: 'value',
        headerName: actionType,
        width: 120,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'goal',
        headerName: translation('tableColumnNames.goal'),
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <CircularProgressWithLabel value={params.value} />
        ),
      },
    ];
  }

  buildLeaderboard() {
    console.log('this.state.leaderboard: ', this.state.leaderboard);
    const rows = this.buildLeaderboardRows();
    return (
      <Grid container className={'leaderboard-box'}>
        <Grid container justifyContent="center">
          <Grid container sx={{ mt: 4 }} justifyContent="center">
            <StyledTable
              className={'leaderboard-overlay'}
              density={'comfortable'}
              autoGenerateIds={true}
              columns={this.buildColumns()}
              rows={rows}
              rowsPerPage={rows.length}
              disableColumnMenu={true}
              header={this.buildTableHeader()}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  buildLeaderboardRows() {
    console.log('this.state.leaderboard: ', this.state.leaderboard);
    return this.state.leaderboard.getUsers().map((user) => {
      return {
        position: { user, imageCache: this.state.imageCache },
        name: user.getFirstName(),
        value: user.getCurrentAchievement(),
        goal: user.calculatePercentComplete(),
      };
    });
  }

  buildTableHeader() {
    const imageCache = this.state.imageCache;
    const reward = this.state.leaderboard.getRewards()[0];
    const users = this.state.leaderboard.getUsers();
    const userPlaceFirst = (
      <LeaderAvatar leader={users[0]} imageCache={imageCache} />
    );
    const userPlaceSecond =
      users.length > 1 ? (
        <LeaderAvatar leader={users[1]} imageCache={imageCache} />
      ) : (
        <></>
      );
    // const translation = this.props.t;

    const rewardHeader = imageCache.hasImage(reward)
      ? this.buildRewardHeaderWithImage(reward, imageCache)
      : this.buildRewardHeaderWithoutImage(reward);

    return (
      <>
        <Grid
          container
          alignItems={{ xs: 'center', sm: 'center', md: 'flex-direction' }}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Grid
            item
            xs={6}
            md={2}
            order={{ xs: 2, sm: 2, md: 1 }}
            mt={{ xs: 1, sm: 2, md: 0 }}
          ></Grid>
          <Grid item xs={8} order={{ xs: 1, sm: 1, md: 2 }}>
            <Grid
              container
              flexDirection="column"
              justifyContent="right"
              alignItems="center"
            >
              <FormControl size="small">
                <Select
                  className={'leaderboard-dropdown'}
                  value={
                    this.state.activeLeaderboardId !== null
                      ? this.state.activeLeaderboardId
                      : this.state.leaderboardList[0].id
                  }
                  onChange={this.handleLeaderboardChange}
                >
                  {this.state.leaderboardList.map((leaderboard) => (
                    <MenuItem
                      className={'leaderboard-dropdown-item'}
                      key={leaderboard.id}
                      value={leaderboard.id}
                    >
                      {leaderboard.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                className={'show-old-contests-button'}
                onClick={() => {
                  const navigate = this.props.navigate;
                  console.log('this.state.showOld: ', this.state.showOld);

                  navigate(
                    '/leaderboard' +
                      (!this.state.showOld ? '?showOld=true' : '?showOld=false')
                  );
                  navigate(0);
                }}
              >
                {this.state.showOld
                  ? 'Show Current Contests'
                  : 'Show Old Contests'}
              </Button>
            </Grid>
            <Button className={'list-view-button'}>
              <img
                className={'list-view-photo'}
                src={listViewImage}
                onClick={() => {
                  window.location.href = '/campaigns';
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          className="table-header"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-evenly"
          alignItems="center"
          pt={2}
          sx={{ mt: 4 }}
          flexGrow="1"
        >
          <Grid item xs={2} md={2}>
            {userPlaceFirst}
          </Grid>
          <Grid className={'reward-overlay'} item xs={8} sm={7} md={5}>
            {rewardHeader}
          </Grid>
          <Grid item xs={2} md={2}>
            {userPlaceSecond}
          </Grid>
          <Button className={'add-campaign-button'}>
            <img
              className={'add-campaign-photo'}
              src={addRewardImage}
              onClick={() => {
                window.location.href = '/campaigns/new';
              }}
            />
          </Button>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 3, mb: 4 }}
          >
            {this.state.leaderboard.getDescription() && (
              <Box className={'leaderboard-description-box'}>
                <Typography
                  className={'leaderboard-description'}
                  sx={{ mt: 2 }}
                >
                  {this.state.leaderboard.getDescription()}
                </Typography>
              </Box>
            )}
            {!this.state.leaderboard.getDescription() && (
              <Box className={'leaderboard-description-filler'}></Box>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  buildRewardHeaderWithoutImage(reward) {
    return (
      <Grid
        container
        direction="row"
        className="reward"
        alignItems="center"
        justifyContent="center"
        height={{ sm: '180px' }}
        p={{ xs: 1, sm: 0 }}
      >
        <Typography fontSize={Utilities.fontSizes}>
          {reward !== undefined ? reward.getName() : 'Points'}
        </Typography>
      </Grid>
    );
  }

  buildRewardHeaderWithImage(reward) {
    return (
      <Grid container direction="row" className="reward">
        <Grid item xs={7}>
          <Box
            className="reward-image"
            component="img"
            src={this.state.imageCache.obtainImageUrl(reward)}
            sx={{ p: 1 }}
          />
        </Grid>
        <Grid item xs={5} className={'reward-title-box'} display="flex">
          <Grid container className={'reward-title-overlay'} direction="column">
            <Grid item xs={6} className={'reward-title'} display="flex">
              <Typography
                className={'reward-title-text'}
                fontSize={Utilities.fontSizes}
              >
                {reward.getName()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const translation = this.props.t;
    if (this.state.isLoading) {
      return <CenteredProgress />;
    }
    if (this.state.leaderboard === null) {
      return (
        <CenteredComponent>
          <Typography variant="h5">{translation('noLeaderboards')}</Typography>
        </CenteredComponent>
      );
    }

    return this.buildLeaderboard();
  }
}

export default withTranslation('', {
  keyPrefix: 'routerComponents.home.index',
})(withRouter(withUseParams(withUseSearchParams(Index))));
