import React from 'react';
import CustomToggleSwitch from '../../../custom-inputs/CustomToggleSwitch';
import CustomSelect from '../../../custom-inputs/CustomSelect';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';
import ItemSelectInput from '../../../custom-inputs/ItemSelectInput';
import RoundState from '../../../_types/RoundState';
import { RulesAndRewardsViewContainer, SectionTitle } from './Round.styles';
import NewGameState from '../../../_types/NewGameState';

interface Props {
  onChangeRoundState: (roundState: RoundState) => void;
  roundState: RoundState;
  kpis: { label: string; value: string }[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const RoundRules: React.FC<Props> = ({
  onChangeRoundState,
  roundState,
  kpis,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  return (
    <RulesAndRewardsViewContainer>
      <SectionTitle>Rules</SectionTitle>
      <CustomToggleSwitch
        label="Use Multiple KPIs"
        value={roundState.useMultipleKPIs}
        onChange={(newValue: any) => {
          onChangeRoundState({
            ...roundState,
            useMultipleKPIs: newValue,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].useMultipleKPIs =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              useMultipleKPIs: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
      />
      <CustomSelect
        label="KPI"
        value={roundState.KPIRuleID}
        onChange={(newValue: any) => {
          onChangeRoundState({ ...roundState, KPIRuleID: newValue });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleID =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              KPIRuleID: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={kpis}
      />
      <CustomSelect
        label="Tie Breaker KPI"
        value={roundState.tieKPIRuleID}
        onChange={(newValue: any) => {
          onChangeRoundState({ ...roundState, tieKPIRuleID: newValue });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].tieKPIRuleID =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              tieKPIRuleID: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={kpis}
      />
      <ItemSelectInput
        label="Goal"
        selectedItem={
          roundState.isActionCountCustom ? 'custom' : roundState.actionCount
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeRoundState({
              ...roundState,
              isActionCountCustom: true,
              actionCount: '',
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].isActionCountCustom = true;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount = '';
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                isActionCountCustom: true,
                actionCount: '',
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            onChangeRoundState({
              ...roundState,
              isActionCountCustom: false,
              actionCount: newSelectedItem.value,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].isActionCountCustom = false;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount =
                newSelectedItem.value;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                isActionCountCustom: false,
                actionCount: newSelectedItem.value,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={[
          { label: '10', value: '10' },
          { label: '25', value: '25' },
          { label: '50', value: '50' },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {roundState.isActionCountCustom && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={roundState.actionCount}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, actionCount: newValue });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount =
                newValue;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                actionCount: newValue,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
        />
      )}
    </RulesAndRewardsViewContainer>
  );
};

export default RoundRules;
