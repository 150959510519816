import Reward from './Reward';
import User from './User';

export default class Leaderboard {
  constructor(data) {
    this.data = data;
  }

  getName() {
    return this.data.name;
  }

  getDescription() {
    return this.data.description;
  }

  getActionTypePluralized() {
    return this.data.actionTypePlural;
  }

  collateImageMetadata() {
    const rewardImageMetadata = this.getRewards().map((reward) =>
      reward.createImageMetadata()
    );
    const userImageMetadata = this.getUsers().map((user) =>
      user.createImageMetadata()
    );
    return [].concat(rewardImageMetadata, userImageMetadata);
  }

  getRewards() {
    console.log('this.data: ', this.data);
    if (!this.rewards) {
      this.rewards = this.data.rewards.items.map(
        (reward) => new Reward(reward)
      );
    }
    return this.rewards;
  }

  getUsers() {
    if (!this.users) {
      this.users = this.data.users.items.map((userItem) => new User(userItem));
    }
    return this.users;
  }
}
