import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import getUserAvatar from '../common/utils/getUserAvatar';

export default class DealListRepository {
  static async fetchDeals(
    assigneeID,
    ourSearchStage,
    _categoryID,
    ourSortOrder,
    ourSearchString,
    myNextToken,
    resetList,
    from
    // _stageDispositions
  ) {
    // console.log(
    //   'fetchDeals: ',
    //   assigneeID,
    //   ourSearchStage,
    //   _categoryID,
    //   ourSearchString,
    //   myNextToken,
    //   resetList,
    //   from
    //   // _stageDispositions
    // );

    let stageCategoryNextToken = null;
    // used for default categoryID
    const stageCategories = [];
    // used for dealType to display/flex field maps
    const dealTypeNamesAndIds = [];

    let categoryID = _categoryID;
    if (_categoryID === '') {
      const getDefaultCategoryId = gql`
        query MyQuery($orgID: ID!, $nextToken: String) {
          listStageCategorys(
            filter: { orgID: { eq: $orgID } }
            nextToken: $nextToken
          ) {
            items {
              id
              title
              enableDeal
            }
            nextToken
          }
        }
      `;

      do {
        const getDefaultCategoryIdRes = await API.graphql({
          query: getDefaultCategoryId,
          variables: {
            orgID: global.me.orgID,
            nextToken: stageCategoryNextToken,
          },
        });

        stageCategories.push(
          ...getDefaultCategoryIdRes.data.listStageCategorys.items
        );
        stageCategoryNextToken =
          getDefaultCategoryIdRes.data.listStageCategorys.nextToken;

        // console.log('getDefaultCategoryIdRes: ', getDefaultCategoryIdRes);
        // console.log('global me orgId: ', global.me.orgID);
        // console.log('global me: ', global.me);
        // console.log('stageCategoryNextToken: ', stageCategoryNextToken);
      } while (stageCategoryNextToken);

      // console.log('stageCategories: ', stageCategories);

      if (stageCategories.length > 0) {
        // categoryID = stageCategories[0].id;
        // Set the default categoryID (first stageCategory.enableDeal === true) so the deal type filter sets correctly
        for (let i = 0; i < stageCategories.length; i++) {
          if (stageCategories[i].enableDeal) {
            categoryID = stageCategories[i].id;
            break;
          }
        }

        // If there's multiple enableDeal === true stage categories, build the map for display and flex fields here instead of DealsList:
        for (let i = 0; i < stageCategories.length; i++) {
          if (stageCategories[i].enableDeal) {
            dealTypeNamesAndIds.push([
              stageCategories[i].id,
              stageCategories[i].title,
            ]);
          }
        }
      }
    }

    // console.log('!!!categoryID: ', categoryID);
    const getFlexAttributes = gql`
      query MyQuery($categoryID: ID!) {
        getStageCategory(id: $categoryID) {
          flexAttributes
        }
      }
    `;

    // Get the flex attributes for the default category/deal type ID
    const getFlexAttributesRes = await API.graphql({
      query: getFlexAttributes,
      variables: { categoryID },
    });
    // console.log('!!!getFlexAttributesRes: ', getFlexAttributesRes);
    // console.log('!!!getFlexAttributesRes.data: ', getFlexAttributesRes.data);
    const categoryFlexAttributes = JSON.parse(
      getFlexAttributesRes.data.getStageCategory.flexAttributes
    );

    // console.log('!!!categoryFlexAttributes: ', categoryFlexAttributes);

    // Also get flex attributes for all categories to build the deal types map for configs using the same query:
    const mapDealTypeToConfig = {};

    // console.log('dealTypeNamesAndIds: ', dealTypeNamesAndIds);
    for (let i = 0; i < dealTypeNamesAndIds.length; i++) {
      const dealTypeID = dealTypeNamesAndIds[i][0];
      const dealTypeTitle = dealTypeNamesAndIds[i][1];

      // console.log('dealType: ', dealTypeID);

      const dealTypesFlexAttributesRes = await API.graphql({
        query: getFlexAttributes,
        variables: { categoryID: dealTypeID },
      });

      const dealTypeFlexAttributes = JSON.parse(
        dealTypesFlexAttributesRes.data.getStageCategory.flexAttributes
      );

      // console.log('!!!dealTypeFlexAttributes: ', dealTypeFlexAttributes);
      mapDealTypeToConfig[dealTypeTitle] = dealTypeFlexAttributes.config.deal;
    }

    // console.log('DLR mapDealTypeToConfig: ', mapDealTypeToConfig);

    // NO LONGER NEEDED: cannot use stage disposition ids to filter for valid deals
    // let stageDispositionNextToken = null;
    // let stageDispositions = _stageDispositions;

    // if (!stageDispositions.length) {
    //   const getStageDispositionIds = gql`
    //     query MyQuery($orgID: ID!, $nextToken: String) {
    //       listStageDispositions(
    //         filter: {
    //           orgID: { eq: $orgID }
    //           isDeleted: { eq: false }
    //           isSetupDeal: { eq: true }
    //         }
    //         nextToken: $nextToken
    //       ) {
    //         items {
    //           id
    //           isDeleted
    //           orgID
    //           categoryID
    //           isSetupDeal
    //         }
    //         nextToken
    //       }
    //     }
    //   `;
    //   do {
    //     const res = await API.graphql({
    //       query: getStageDispositionIds,
    //       variables: {
    //         orgID: global.me.orgID,
    //         nextToken: stageDispositionNextToken,
    //       },
    //     });
    //     const stageDispositionsRes = res.data.listStageDispositions.items;
    //     stageDispositions = stageDispositions.concat(stageDispositionsRes);
    //     stageDispositionNextToken = res.data.listStageDispositions.nextToken;
    //   } while (stageDispositionNextToken);
    // }

    // console.log('stageDispositions: ', stageDispositions);

    // query for stageIDs w/ sequence < 0 to filter out in deals query
    const excludeStageIds = [];

    const getStages = gql`
      query MyQuery($categoryID: ID!) {
        listStages(
          filter: { categoryID: { eq: $categoryID } }

          limit: 10000
        ) {
          items {
            id
            sequence
            title
            flexAttributes
            description
            orgID
            categoryID
            isDisplay
            isActive
          }
        }
      }
    `;
    const getStagesRes = await API.graphql({
      query: getStages,
      variables: { categoryID },
    });
    const stages = getStagesRes.data.listStages.items;
    // console.log('!!!getHiddenStageIdsRes: ', getStagesRes);
    // console.log('!!!getHiddenStageIdsRes items: ', stages);

    for (const stage of stages) {
      // NOTE: Need to exclude a stage if its sequence < 0 OR if the stage has isDisplay set to false
      if (stage.sequence < 0 || stage.isDisplay === false) {
        excludeStageIds.push(stage.id);
      }
    }
    // console.log('!!!getHiddenStageIdsRes excluded ids: ', excludeStageIds);

    let filter = '{categoryID: {eq: "' + categoryID + '"}';
    if (ourSearchString !== '') {
      filter +=
        ', searchString: {wildcard: "*' +
        ourSearchString.replace(/[\W_]+/g, '') +
        '*"}';
    }

    // // NO LONGER NEEDED: filter out stage dispositions to avoid filtering out stage sequence < 0 after the query
    // if (stageDispositions.length > 0) {
    //   filter += ', or: [';

    //   for (let i = 0; i < stageDispositions.length; i++) {
    //     const disposition = stageDispositions[i];
    //     filter += `{currentDispositionID: {eq: "${disposition.id}"}}`;

    //     if (i < stageDispositions.length - 1) {
    //       filter += ', ';
    //     }
    //   }
    //   filter += ']';
    // }

    if (!resetList && myNextToken === null) {
      return [];
    }
    let ourNextToken = myNextToken;
    if (myNextToken === '' && !resetList) {
      return [];
    }

    if (resetList) {
      ourNextToken = null;
    }
    if (assigneeID === 'all') {
      if (!global.me.isAdmin) {
        //
        //  If the user is looking for all deals and they're NOT an admin, filter so that they see only the deals
        //  for the teams that they're in
        //
        filter += ', or: [';
        let first = true;
        for (const circleID of global.me.myCircles) {
          if (!first) {
            filter += ',';
          }
          filter += '{currentOwnerID: {eq: "' + circleID + '"}}';
          first = false;
        }
        //
        //  Include all the circles under the user
        //
        for (const circleID of global.me.myChildCircles) {
          if (circleID !== global.me.leagueID) {
            //
            //  Allow the user to see all leads for any of their teams EXCEPT for their
            //  league.  This allows us to group people into leagues without allowing everyone
            //  in the same league to see everyone else's leads
            //
            if (!first) {
              filter += ',';
            }
            filter += '{currentOwnerID: {eq: "' + circleID + '"}}';
            first = false;
          }
        }
        filter += ']';
      }
      //
      //  There is no else here as the admin will see everything with no filter
      //
    } else {
      //
      //  We're looking for a specific assignee's deals
      //
      filter += ', or: [';
      filter += '{currentOwnerID: {eq: "' + assigneeID + '"}}';
      filter += ',{ownersString: {match: "' + assigneeID + '"}}';
      // for (const circleID of global.me.myManagerCircles) {
      //   filter += ',{ownersString: {match: "' + circleID + '"}}';
      // }
      filter += ']';
    }
    filter += ', and: [';
    if (ourSearchStage !== 'all' && ourSearchStage !== 'active') {
      filter += '{currentStageID: {eq: "' + ourSearchStage + '"}}, ';
    } else {
      // NOTE: Need to filter out stage IDs that have a sequence < 0 for 'all' and 'active' stages
      if (excludeStageIds.length > 0) {
        for (let i = 0; i < excludeStageIds.length; i++) {
          const excludeStageId = excludeStageIds[i];
          filter += `{currentStageID: {ne: "${excludeStageId}"}}`;
          filter += ', ';
        }
      }
    }
    filter += '{name: {ne: "Pindrop"}}';
    filter += ', {isDeleted: {eq: false}}';
    if (global.me.orgID === 'communitycollective') {
      filter += ', {description: {ne: "NA"}}';
    }
    filter += ']';
    filter += '}';

    // used to use _ instead of - in sort descriptions, switched to match DealsList fetches
    if (ourSortOrder === 'date-desc') {
      filter += ', sort: {direction: desc, field: updatedAt}';
    } else if (ourSortOrder === 'date-asc') {
      filter += ', sort: {direction: asc, field: updatedAt}';
    } else if (ourSortOrder === 'name-asc') {
      filter += ', sort: {direction: asc, field: sortName}';
    } else if (ourSortOrder === 'name-desc') {
      filter += ', sort: {direction: desc, field: sortName}';
    } else if (ourSortOrder === 'owner-asc') {
      filter += ', sort: {direction: asc, field: currentOwnerID}';
    } else if (ourSortOrder === 'owner-desc') {
      filter += ', sort: {direction: desc, field: currentOwnerID}';
    }
    // // NOTE: sort owner by id not name, should it be by ownerString like below?
    // else if (ourSortOrder === 'owner-asc') {
    //   filter += ', sort: {direction: asc, field: ownerString}';
    // } else if (ourSortOrder === 'owner-desc') {
    //   filter += ', sort: {direction: desc, field: ownerString}';
    // }

    let dealList = [];

    // created         # created, not ready to be used
    // ready           # ready to be used
    // started         # started, in use
    // complete        # completed, not in use
    // scheduled       # scheduled to be used
    // paused          # paused
    let stageTitle = '';
    let dealsNextToken = null;

    // console.log('@@filter1: ', filter);
    // TO DO: ADD TO MyQuery ARGS AFTER UPDTING FILTER W/ ORS FOR DISPOSITIONS ---> , $stageDispositionID: String
    const listDealsQuery = gql`
      query MyQuery ($from: Int, $nextToken: String) {
        searchDeals(filter: ${filter}, nextToken: $nextToken, from: $from, limit: 25) {
          nextToken
          items {
            id
            name
            notes
            status
            value
            extID
            flexAttributes
            description
            currentStageID
            currentOwnerID
            currentDispositionID
            updatedAt
            ownerHistory(filter: {isActive: {eq: true}, isDeleted: {eq: false}}) {
              items {
                ownerType
                user {
                  id
                  name
                  imageType
                  imageName
                  initials
                }
              }
            }
            circle {
              id
              name
            }
            user {
              id
              name
              imageType
              imageName
              initials
            }
            stage {
              id
              title
              description
              sequence
              pinColor
              iconName
              iconType
              iconColor
              isDisplay
              isActive
            }
          }
        }
      }`;

    // NOTE: The do while has been commented out for the current implementaiton of web deals because
    // each page is a separate query that gets up to 25 deals each time so it is not needed. The queries will have a nextToken that
    // is used when going to the next page of deals. This will be done until the last query is reached and null is returned
    // for nextToken. If the do while loops and tries multiple queries for the same page, the second loop will get no
    // deal data and the nextToken will be null even though there are more deals after that query.

    // do {

    // console.log(
    //   '!!!listDealsQueryRes start of do parameters: ',
    //   from,
    //   dealsNextToken
    // );
    const listDealsQueryRes = await API.graphql({
      query: listDealsQuery,
      variables: { from, nextToken: dealsNextToken },
    });
    // console.log(
    //   'token check listDealsQueryRes at start of the do: ',
    //   listDealsQueryRes
    // );
    // console.log(
    //   'token check newNextToken at start of the do: ',
    //   listDealsQueryRes.data.searchDeals.nextToken
    // );

    const ourDeals = listDealsQueryRes.data.searchDeals.items;
    // console.log('token check ourDeals: ', ourDeals);

    //
    //  We will reset our list of our next token is null (meaning that we're at the start) or if
    //  the resetList parm was set
    //
    if (!(resetList || ourNextToken === null)) {
      // console.log('**retaining list**');
      dealList = ourDeals;
    } else {
      // console.log('**starting new list**');
    }

    if (ourSearchStage === 'all') {
      stageTitle = 'All Stages';
    } else if (ourSearchStage === 'active') {
      stageTitle = 'Active Stages';
    }

    for (const ourDeal of ourDeals) {
      // console.log('ourDeal: ', ourDeal);
      if (ourDeal.stage === null) {
        // console.log('NO stage found: ', ourDeal);
        continue;
      }
      // NOTE: This conditional may not be needed as the excluded stages should be filtered out in the original query. Good to keep in to be safe though.
      if (ourDeal.stage.sequence > 0) {
        //
        //  Sequence <= 0 indicates that we're not creating deals for these stages
        //
        let flexAttributes = null;
        if (ourDeal.flexAttributes !== null && ourDeal.flexAttributes !== '') {
          flexAttributes = JSON.parse(ourDeal.flexAttributes);
        }
        let showDeal = true;
        // console.log('assigneeID: ', assigneeID);
        // console.log('ourSearchStage: ', ourSearchStage);
        // console.log('ourDeal.stage.isActive: ', ourDeal.stage.isActive);
        // console.log('ourDeal.currentOwnerID: ', ourDeal.currentOwnerID);

        if (showDeal) {
          let stageOK = false;
          if (ourSearchStage === ourDeal.currentStageID) {
            stageTitle = ourDeal.stage.title;
            // console.log('stage matches');
            stageOK = true;
          }

          if (ourSearchStage === 'all') {
            // console.log('show all stages');
            stageOK = true;
          }

          if (ourSearchStage === 'active' && ourDeal.stage.isActive) {
            // console.log('show all active stage');
            stageOK = true;
          }

          if (!stageOK) {
            showDeal = false;
          }
        }

        //
        //  If isDisplay is FALSE, never show the deal
        //
        // NOTE: Similar to the stage sequence filter above, this may not be needed since excluded stages (sequence < 0 and isDisplay === false) are filtered out in the query. Good to have as a safety measure though.
        if (!ourDeal.stage.isDisplay) {
          // console.log('NOT display');
          showDeal = false;
        }

        if (showDeal) {
          // console.log('show ourDeal: ', ourDeal);

          let setterOwner = null;
          let closerOwner = null;
          for (const owner of ourDeal.ownerHistory.items) {
            if (owner.ownerType === 'setter') {
              setterOwner = owner;
            } else if (owner.ownerType === 'closer') {
              closerOwner = owner;
            }
          }
          // console.log('!!setterOwner: ', setterOwner);
          // console.log('@@closerOwner: ', closerOwner);

          if (ourDeal.user != null) {
            // NOTE: The following commented out code was replaced to show both the closer and setter on a deal
            // // console.log('is a user owner');
            // // console.log(
            // //   'profilePictureInfo: ',
            // //   ourDeal.user.id,
            // //   ourDeal.user.imageName
            // // );
            // NOTE: comment out everything before ourDeal.profileType after everything is set
            ourDeal.assigneeType = 'person';
            ourDeal.assigneeID = ourDeal.user.id;
            ourDeal.assigneeName = ourDeal.user.name;
            ourDeal.initials = ourDeal.user.initals;
            ourDeal.profilePicture = await getUserAvatar(
              ourDeal?.user?.imageName,
              ourDeal?.user?.imageType,
              true
            );

            ourDeal.profileType = 'person';
            if (setterOwner === null) {
              //
              //  There is no owner history, so use the current owner as the setter
              //
              ourDeal.setter = { user: ourDeal.user };
              ourDeal.setter.assigneeType = 'person';
              ourDeal.setter.profilePicture = await getUserAvatar(
                // ourDeal.user.id,
                ourDeal?.user?.imageName,
                ourDeal?.user?.imageType,
                true
              );
            } else {
              //
              // Use the setter from the owner history
              //
              ourDeal.setter = setterOwner;
              ourDeal.setter.assigneeType = 'person';
              ourDeal.setter.profilePicture = await getUserAvatar(
                // setterOwner.user.id,
                setterOwner?.user?.imageName,
                setterOwner?.user?.imageType,
                true
              );
            }

            if (closerOwner !== null) {
              //
              // Use the closer from the owner history
              //
              ourDeal.closer = closerOwner;
              ourDeal.closer.assigneeType = 'person';
              ourDeal.closer.profilePicture = await getUserAvatar(
                // closerOwner.user.id,
                closerOwner?.user?.imageName,
                closerOwner?.user?.imageType,
                true
              );
            }
          } else if (ourDeal.circle != null) {
            // console.log('is a circle owner');
            // console.log(
            //   'profilePictureInfo: ',
            //   ourDeal.circle.id,
            //   ourDeal.circle.imageName
            // );
            ourDeal.assigneeType = 'circle';
            ourDeal.assigneeID = ourDeal.circle.id;
            ourDeal.assigneeName = ourDeal.circle.name;
            ourDeal.initials = ourDeal.circle.name.substring(0, 2);
            ourDeal.profileType = 'circle';
            ourDeal.profilePicture = await getUserAvatar(
              ourDeal?.circle?.imageName,
              ourDeal?.circle?.imageType,
              true
            );
          } else {
            // console.log(
            //   'is a ??? owner: ',
            //   ourDeal.currentOwnerType,
            //   ourDeal.currentOwnerID
            // );
            ourDeal.assigneeID = '';
            ourDeal.assigneeType = 'unknown';
            ourDeal.assigneeName = 'unknown';
            ourDeal.initials = 'UNK';
            ourDeal.profilePicture = '';
            ourDeal.profileType = 'person';
          }
          if (flexAttributes !== null && flexAttributes.name !== undefined) {
            ourDeal.name = flexAttributes.name;
          }
          ourDeal.config = categoryFlexAttributes.config.deal;
          ourDeal.iconName = ourDeal.stage.iconName;
          ourDeal.iconType = ourDeal.stage.iconType;
          ourDeal.iconColor = ourDeal.stage.iconColor;
          ourDeal.color = ourDeal.stage.pinColor;
          if (flexAttributes !== null && 'Zip' in flexAttributes) {
            ourDeal.zipCode = flexAttributes.Zip;
          } else {
            ourDeal.zipCode = '';
          }

          // initialize to keep first and last name out of attrs if they're in flexAttributes, need them on same line
          // get initials from first and last name
          let fullName = '';
          // let initials = '';

          const pickerDisplayFields =
            categoryFlexAttributes.config.deal.pickerDisplayFields;
          const attrs = [];
          // console.log('DLR pickerDisplayFields: ', pickerDisplayFields);
          if (flexAttributes !== {} && pickerDisplayFields.length > 0) {
            for (const attr of pickerDisplayFields) {
              // console.log('!!attr: ', attr);
              // console.log('!!flexAttributes: ', flexAttributes);
              if (flexAttributes != null) {
                if (attr in flexAttributes) {
                  if (
                    flexAttributes[attr] !== '' &&
                    flexAttributes[attr] !== null &&
                    flexAttributes[attr] !== attr
                  ) {
                    if (attr === 'First Name') {
                      fullName += flexAttributes[attr];
                      // initials += flexAttributes[attr][0];
                    } else if (attr === 'Last Name') {
                      fullName += ` ${flexAttributes[attr]}`;
                      // initials += flexAttributes[attr][0];
                    } else {
                      attrs[attrs.length] = flexAttributes[attr];
                    }
                  }
                }
              }
            }
          }
          // console.log('```attrs: ', attrs);
          // console.log('```fullName: ', fullName);

          // const assigneeName =
          //   ourDeal.assigneeID !== assigneeID ? ourDeal.assigneeName : '';
          // const assigneeName = ourDeal.assigneeName;
          // const dealStage =
          //   ourSearchStage === 'all' ? ourDeal.stage.title : ourSearchStage;
          const dealStage = ourDeal.stage.title;
          // // const dealStage = ourSearchStage === 'all' ? ourDeal.stage.title : '';
          // const ownerProfilePicture = ourDeal.profilePicture;

          const setter = ourDeal.setter;
          const closer = ourDeal.closer;

          const unformattedUpdatedAt = ourDeal.updatedAt.split('T');
          const dealYear = unformattedUpdatedAt[0].slice(0, 4);
          const dealMonth = unformattedUpdatedAt[0].slice(5, 7);
          const dealDay = unformattedUpdatedAt[0].slice(8, 10);
          const dealHour = unformattedUpdatedAt[1].slice(0, 2);
          const dealMinute = unformattedUpdatedAt[1].slice(3, 5);
          const updatedAt = `${dealMonth}/${dealDay}/${dealYear} ${dealHour}:${dealMinute}`;

          // display JSX is now in Deal Card functon component
          const display = {
            fullName,
            dealStage,
            attrs,
            // ourDeal, // TO DO: display becomes property of ourDeal... remove this after looking at side effects in DealCard
            // ownerProfilePicture,
            // initials,
            // assigneeName,
            setter,
            closer,
            updatedAt,
          };

          ourDeal.display = display;

          // console.log('token check before adding to dealsList: ', dealList);
          // console.log(
          //   'token check dealsNextToken ourDeal before adding to dealList: ',
          //   ourDeal
          // );
          // console.log(
          //   'token check dealsNextToken before adding to dealList: ',
          //   listDealsQueryRes.data.searchDeals.nextToken
          // );

          dealList.push(ourDeal);
        }
      } else {
        // console.log(
        //   'token check dealsNextToken invalid stage sequence: ',
        //   ourDeal
        // );
      }
    }

    // console.log(
    //   'token check listDealsQueryRes dealsNextToken: ',
    //   listDealsQueryRes.data.searchDeals
    // );

    if (listDealsQueryRes.data.searchDeals.nextToken !== null) {
      // console.log(
      //   'token check dealsNextToken before assignment: ',
      //   listDealsQueryRes.data.searchDeals.nextToken
      // );
      dealsNextToken = listDealsQueryRes.data.searchDeals.nextToken.toString();
    } else {
      // console.log(
      //   'token check dealsNextToken is now null, no more deals: ',
      //   listDealsQueryRes.data.searchDeals.nextToken
      // );
      dealsNextToken = null;
    }
    // console.log('token check dealList: ', dealList);
    // console.log(
    //   'token check dealsNextToken at the end of the do: ' + dealsNextToken
    // );
    // from += 25;
    // console.log('from: ', from);

    // } while (dealList.length < 25 && dealsNextToken);

    return [
      dealList,
      stageTitle,
      dealsNextToken,
      stageCategories,
      // stageDispositions,
      mapDealTypeToConfig,
    ];
  }

  static async fetchDealTypes() {
    let stageCategoriesNextToken = null;
    const stageCategories = [];
    let defaultCategoryID;

    const getCategoryIds = gql`
      query MyQuery($orgID: ID!, $nextToken: String) {
        listStageCategorys(
          filter: { orgID: { eq: $orgID } }
          nextToken: $nextToken
        ) {
          items {
            id
            title
            enableDeal
          }
          nextToken
        }
      }
    `;

    do {
      const getCategoryIdsRes = await API.graphql({
        query: getCategoryIds,
        variables: {
          orgID: global.me.orgID,
          nextToken: stageCategoriesNextToken,
        },
      });

      stageCategories.push(...getCategoryIdsRes.data.listStageCategorys.items);
      stageCategoriesNextToken =
        getCategoryIdsRes.data.listStageCategorys.nextToken;

      // console.log('getCategoryIdsRes: ', getCategoryIdsRes);
      // console.log('global me orgId: ', global.me.orgID);
      // console.log('stageCategoriesNextToken: ', stageCategoriesNextToken);
    } while (stageCategoriesNextToken);

    // console.log('stageCategories: ', stageCategories);

    if (stageCategories.length > 0) {
      for (let i = 0; i < stageCategories.length; i++) {
        if (stageCategories[i].enableDeal) {
          defaultCategoryID = stageCategories[i].id;
          break;
        }
      }
    }
    return [defaultCategoryID, stageCategories];
  }
}
