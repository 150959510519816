import React, { useEffect, useState } from 'react';
import DisplayBracketView from './DisplayBracket.view';
import { format } from 'date-fns';
import { BracketReward, BracketTitle } from './DisplayBracket.styles';
import Game from '../../_types/Game';
import getBracketActuals from '../../games-helpers/getBracketActuals';
import getUserAvatar from '../../../../common/utils/getUserAvatar';

interface Props {
  game: Game;
}

const DisplayBracketContainer = ({ game }: Props) => {
  const [brackets, setBrackets] = useState<any>([]);

  useEffect(() => {
    const fetchBracketActuals = async () => {
      try {
        const bracketActuals = await getBracketActuals({ missionID: game.id });

        if (!bracketActuals?.bracketData) {
          return;
        }

        const generateBracketTitle = (item: any) => {
          const startDate = item.startDate
            ? format(new Date(item.startDate), 'MMMM d')
            : 'N/A';
          const endDate = item.endDate
            ? format(new Date(item.endDate), 'MMMM d')
            : 'N/A';

          return (
            <BracketTitle>
              {item.startDate ? `${startDate} - ${endDate}` : null}
              {item.reward1 ? (
                <BracketReward>{item.reward1.name}</BracketReward>
              ) : null}
            </BracketTitle>
          );
        };

        const fetchCompetitorAvatar = async (competitor: any) => {
          if (!competitor) return { name: '' };
          const avatar = await getUserAvatar(
            competitor.imageName,
            competitor.imageType
          );
          return { id: competitor.id, name: competitor.name, avatar };
        };

        const newBrackets = await Promise.all(
          bracketActuals.bracketData.map(async (item, index) => {
            const seeds = await Promise.all(
              bracketActuals.bracket[index].map(async (data) => {
                const teams = await Promise.all(
                  data.competitors.map(fetchCompetitorAvatar)
                );
                return { id: data.id, teams };
              })
            );
            return { title: generateBracketTitle(item), seeds };
          })
        );
        setBrackets(newBrackets);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBracketActuals();
  }, [game.id]);

  return <DisplayBracketView brackets={brackets} />;
};

export default DisplayBracketContainer;
