import React from 'react';
import {
  StandingDetail,
  StandingDetailContainer,
  StandingHeader,
  StandingHeaderContainer,
  StandingsContainer,
  StandingsTitle,
} from '../NormalDetails.styles';
import isTeamGame from '../../../games-helpers/isTeamGame';
import Game from '../../../_types/Game';
import isIndividualGameUser from '../../../games-helpers/isIndividualGameUser';
import { useNavigate } from 'react-router';

interface Props {
  game: Game;
}

const Standings = ({ game }: Props) => {
  const sortedUsers = isTeamGame(game)
    ? game.circles
      ? game.circles.items.sort((a, b) => b.doneActionCount - a.doneActionCount)
      : []
    : game.users
    ? game.users.items.sort((a, b) => a.position - b.position)
    : [];
  const navigate = useNavigate();

  return (
    <StandingsContainer>
      <StandingsTitle>Standing</StandingsTitle>
      <StandingHeaderContainer>
        <div style={{ display: 'flex', gap: 90 }}>
          <StandingHeader>Rk</StandingHeader>
          <StandingHeader>Name</StandingHeader>
        </div>
        <StandingHeader>Score</StandingHeader>
      </StandingHeaderContainer>
      {sortedUsers.map((user, index) => {
        if (isIndividualGameUser(user)) {
          return (
            <StandingDetailContainer key={index} isEven={index % 2 === 0}>
              <div style={{ display: 'flex', gap: 100 }}>
                <StandingDetail>{user.position}</StandingDetail>
                <StandingDetail
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/profile/${user.userID}`);
                  }}
                >
                  {user.user.name}
                </StandingDetail>
              </div>
              <StandingDetail>{user.doneActionCount}</StandingDetail>
            </StandingDetailContainer>
          );
        }

        return (
          <StandingDetailContainer key={index} isEven={index % 2 === 0}>
            <div style={{ display: 'flex', gap: 100 }}>
              <StandingDetail>{index + 1}</StandingDetail>
              <StandingDetail
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/profile/${user.id}`);
                }}
              >
                {user.circle.name}
              </StandingDetail>
            </div>
            <StandingDetail>{user.doneActionCount}</StandingDetail>
          </StandingDetailContainer>
        );
      })}
    </StandingsContainer>
  );
};

export default Standings;
