import React from 'react';
import { Backdrop, Box } from '@mui/material';
import TeamFormPage from './TeamFormPage';

export default function TeamFormModal(props) {
  const {
    showTeamFormModal,
    setShowTeamFormModal,
    onTriggerTeamsRefetch,
    teamId,
    zIndex = 10,
  } = props;

  return (
    <Backdrop
      sx={{ zIndex }}
      open={showTeamFormModal}
      onClick={(e) => {
        // e.stopPropagation();
        if (
          !e.target.closest('.team-form-backdrop') &&
          !e.target.closest('.MuiMenuItem-root')
        ) {
          setShowTeamFormModal(false);
        }
      }}
    >
      <Box
        className={'team-form-backdrop'}
        style={{
          width: 958,
          height: '70vh',
          borderRadius: 8,
          overflowY: 'auto',
        }}
      >
        <TeamFormPage
          teamId={teamId}
          setShowTeamFormModal={setShowTeamFormModal}
          onTriggerTeamsRefetch={onTriggerTeamsRefetch}
          isModal={true}
        ></TeamFormPage>
      </Box>
    </Backdrop>
  );
}
