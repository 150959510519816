import React, { useState } from 'react';
import {
  EditContestantsArea,
  EditContestantsText,
  ViewContainer,
} from './BracketContestants.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import NewGameState from '../../_types/NewGameState';
import Contestant from '../../../_types/Contestant';
import CustomModal from '../../custom-inputs/CustomModal';
import GameBracket from './GameBracket';
import ContestantList from './ContestantList';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  contestants: Contestant[];
  selectedContestants: string[];
}

const BracketContestantsView: React.FC<Props> = ({
  newGameState,
  contestants,
  onChangeNewGameState,
  selectedContestants,
}) => {
  const [showContestantsBracketModal, setShowContestantsBracketModal] =
    useState(false);
  const [showContestantList, setShowContestantList] = useState(false);
  const [currentSeedIndexToEdit, setCurrentSeedIndexToEdit] =
    useState<any>(null);
  const [currentSeedTeamIndexToEdit, setCurrentSeedTeamIndexToEdit] =
    useState<any>(null);

  console.log('newGameState', newGameState);
  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Bracket Contestants</InputTitleText>
        <InputTitleIcon src="/images/contestants.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        {selectedContestants.length ? (
          <div style={{ marginBottom: 10, fontSize: 14 }}>
            {selectedContestants.length} Contestants have been added to this
            game
          </div>
        ) : null}

        <EditContestantsArea
          onClick={() => {
            setShowContestantsBracketModal(true);
          }}
        >
          <img src="/images/edit-contestants.svg" />
          <EditContestantsText>Edit Contestants</EditContestantsText>
        </EditContestantsArea>
      </InputBodyContainer>
      <CustomModal
        width={1000}
        isOpen={showContestantsBracketModal}
        title="Edit Contestants"
        onClose={() => setShowContestantsBracketModal(false)}
      >
        {showContestantList ? (
          <>
            <div
              onClick={() => setShowContestantList(false)}
              style={{
                display: 'flex',
                gap: 20,
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src="/images/back.svg" />
              <div style={{ fontWeight: 600, fontSize: 18 }}>Back</div>
            </div>
            <ContestantList
              contestants={contestants}
              selectedContestants={selectedContestants}
              onSelect={(contestant: Contestant) => {
                const newGameStateCopy = { ...newGameState };
                const totalCompetitors =
                  newGameStateCopy.bracket.bracket[0][currentSeedIndexToEdit]
                    .competitors.length;

                // update competitor if already exist with the new contestant
                if (
                  newGameStateCopy.bracket.bracket[0][currentSeedIndexToEdit]
                    .competitors[currentSeedTeamIndexToEdit]
                ) {
                  newGameStateCopy.bracket.bracket[0][
                    currentSeedIndexToEdit
                  ].competitors[currentSeedTeamIndexToEdit] = contestant.value;
                } else {
                  // otherwise, add competitor
                  if (totalCompetitors >= 2) {
                    return;
                  }

                  newGameStateCopy.bracket.bracket[0][
                    currentSeedIndexToEdit
                  ].competitors.push(contestant.value);
                }

                onChangeNewGameState(newGameStateCopy);
                setShowContestantList(false);
              }}
            />
          </>
        ) : (
          <GameBracket
            newGameState={newGameState}
            contestants={contestants}
            totalNumberOfContestants={newGameState.bracket.totalCompetitors}
            onClickAdd={(args: {
              seedIndex: number;
              seedTeamIndex: number;
            }) => {
              const { seedIndex, seedTeamIndex } = args;
              setCurrentSeedIndexToEdit(seedIndex);
              setCurrentSeedTeamIndexToEdit(seedTeamIndex);
              setShowContestantList(true);
            }}
          />
        )}
      </CustomModal>
    </ViewContainer>
  );
};

export default BracketContestantsView;
