import React, { useState } from 'react';
import {
  Avatar,
  Backdrop,
  Box,
  //   Button,
  CardMedia,
  Typography,
} from '@mui/material';
// import { EllipsisIcon } from '../../components/GamifyIcon';
import DisplayMedia from '../../components/DisplayMedia';
import {
  formattedLocalShortestDate,
  formattedLocalDateTimeShort,
  defaultDateFormat,
} from '../../common/Utilities';
// import MemberOptionsMenu from './MemberOptionsMenu';

export default function RewardCard({
  user,
  reward,
  //   setIsRewardListLoading,
  //   onTriggerRefetch,
  //   setCurrentReward,
  //   setDisplayDetails,
  //   setRewardChecked,
  //   isOnTeamForm,
}) {
  //   const [rewardOptionsOpen, setRewardOptionsOpen] = useState(false);
  const [showRewardDisplay, setShowRewardDisplay] = useState(false);
  //   console.log('RewardCard reward: ', reward);
  //   console.log('RewardCard user: ', user);

  //   const handleClickOffRewardOptionMenu = () => {
  //     setRewardOptionsOpen(false);
  //   };

  return (
    <>
      <RewardDisplayModal
        reward={reward}
        showRewardDisplay={showRewardDisplay}
        setShowRewardDisplay={setShowRewardDisplay}
      ></RewardDisplayModal>
      {/* {rewardOptionsOpen && (
        <Box
          onClick={handleClickOffRewardOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box
        onClick={(e) => {
          // Check if the click is not on the rewards-option-button
          if (!e.target.closest('.rewards-option-button')) {
            setShowRewardDisplay(true);
          }
        }}
        sx={{ cursor: 'pointer' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 15,
          minWidth: 'fit-content',
          border: '1px solid #D0D2D8',
          borderRadius: 8,
          marginBottom: 12,
        }}
      >
        <Box className={'rewards-list-item-left-container'}>
          {/* Display member avatar if they have one, otherwise display initials */}
          {/* Account for image type to account for potential gifs */}
          {user.avatarFile ? (
            user.imageType === 'picture' ||
            user.imageType.split('/')[0] === 'image' ? (
              <Avatar
                src={user.avatarFile}
                sx={{ margin: '5px 12px 5px 5px' }}
              />
            ) : (
              <CardMedia
                image={user.imageName}
                component="img"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 1000,
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  margin: '5px 12px 5px 5px',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{ backgroundColor: '#868686', margin: '5px 12px 5px 5px' }}
            >
              {user.initials}
            </Avatar>
          )}
          <Box className={'rewards-list-item-left-text-container'}>
            <Typography
              style={{
                color: '#222428',
                fontSize: 16,
                fontWeight: 600,
                whiteSpace: 'nowrap',
              }}
            >
              {reward.purchaseCount} x {reward.reward.name}
            </Typography>
            <Typography
              style={{
                color: '#868686',
                fontSize: 14,
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {`Won on ${formattedLocalShortestDate(reward.createdAt)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {/* TO DO: need color palette for approved, error, requested, denied, delivered, returned, shipped, and backordered */}
          {reward.status !== '' && (
            <Box
              style={{
                backgroundColor: '#E2D8FF',
                padding: '4px 8px 4px 8px',
                borderRadius: '1000px',
              }}
            >
              <Typography
                style={{ color: '#5F27CD', fontSize: 12, fontWeight: 700 }}
              >
                {reward.status[0].toUpperCase() + reward.status.slice(1)}
              </Typography>
            </Box>
          )}
          {/* <Button
            className={'rewards-option-button'}
            onClick={() => setRewardOptionsOpen(!rewardOptionsOpen)}
            disableRipple={true}
          >
            <EllipsisIcon color={'#868686'}></EllipsisIcon>
          </Button>
          {rewardOptionsOpen && (
              <MemberOptionsMenu
              setMemberOptionsOpen={setMemberOptionsOpen}
              teamId={teamId}
              user={member}
              setIsMemberListLoading={setIsMemberListLoading}
              onTriggerRefetch={onTriggerRefetch}
              onRemoveMemberFromState={onRemoveMemberFromState}
              onRemoveMember={onRemoveMember}
            ></MemberOptionsMenu>
          )} */}
        </Box>
      </Box>
    </>
  );
}

function RewardDisplayModal({
  reward,
  showRewardDisplay,
  setShowRewardDisplay,
}) {
  const handleCloseDisplayClick = () => {
    setShowRewardDisplay(false);
  };
  return (
    <>
      <Backdrop
        sx={{ zIndex: 100001 }}
        open={showRewardDisplay}
        onClick={() => {
          //   if (
          //     !e.target.closest('.MuiInputBase-root') &&
          //     !e.target.closest('.send-coins-button')
          //   ) {
          handleCloseDisplayClick();
          //   }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto',
            backgroundColor: 'white',
            width: 574,
            maxHeight: '80vh',
            padding: '32px 40px 32px 40px',
            borderRadius: 8,
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              {reward.reward.name}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: 16,
            }}
          >
            {reward.reward.imageName !== '' && (
              <DisplayMedia
                imageName={reward.reward.imageName}
                imageType={reward.reward.imageType}
                width={'100%'}
                height={'auto'}
                objectFit={'contain'}
              />
            )}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                color: '#222428',
                fontSize: '16px',
                fontWeight: 500,
                width: '100%',
                wordWrap: 'break-word',
              }}
            >
              Description: {reward.reward.description}
            </Typography>
            <Typography
              style={{
                color: '#222428',
                fontSize: '16px',
                fontWeight: 500,
                width: '100%',
                wordWrap: 'break-word',
              }}
            >
              Reward Limit: {reward.reward.remainingCount}/
              {reward.reward.limitCount} available until{' '}
              {defaultDateFormat(reward.reward.validTo)}
            </Typography>
            <Typography
              style={{
                color: '#222428',
                fontSize: '16px',
                fontWeight: 500,
                width: '100%',
                wordWrap: 'break-word',
              }}
            >
              Personal Limit: {reward.purchaseCount}/{reward.reward.personLimit}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                color: '#222428',
                fontSize: '24px',
                fontWeight: 500,
                textAlign: 'center',
                marginBottom: 16,
              }}
            >
              {' '}
              {reward.user.name} Request Information{' '}
            </Typography>
          </Box>
          {reward.details.items.length > 0 && (
            <Box style={{ display: 'flex' }}>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: '16px',
                  fontWeight: 700,
                  width: '35%',
                  textAlign: 'start',
                }}
              >
                Won on
              </Typography>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: '16px',
                  fontWeight: 700,
                  width: '15%',
                  textAlign: 'start',
                }}
              >
                Count
              </Typography>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: '16px',
                  fontWeight: 700,
                  width: '20%',
                  textAlign: 'start',
                }}
              >
                Type
              </Typography>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: '16px',
                  fontWeight: 700,
                  width: '30%',
                  textAlign: 'start',
                }}
              >
                Status
              </Typography>
            </Box>
          )}
          {reward.details.items.map((detail, i) => (
            <Box
              key={i}
              style={{
                paddingTop: 8,
                paddingBottom: 8,
                borderTop: '1px solid #868686',
              }}
            >
              {detail.mission !== null && (
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#F0F0F3',
                      width: 'fit-content',
                      borderRadius: 1000,
                      padding: 8,
                      marginBottom: 8,
                    }}
                  >
                    <Typography
                      style={{
                        color: '#222428',
                        fontSize: '16px',
                        fontWeight: 500,
                        textAlign: 'center',
                      }}
                    >
                      Mission: {detail.mission.name}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 4,
                  marginLeft: 5,
                }}
              >
                <Typography
                  style={{
                    color: '#222428',
                    fontSize: '16px',
                    fontWeight: 500,
                    width: '35%',
                    textAlign: 'start',
                  }}
                >
                  {formattedLocalDateTimeShort(detail.createdAt)}
                </Typography>
                <Typography
                  style={{
                    color: '#222428',
                    fontSize: '16px',
                    fontWeight: 500,
                    width: '15%',
                    textAlign: 'start',
                  }}
                >
                  {detail.purchaseCount}
                </Typography>
                <Typography
                  style={{
                    color: '#222428',
                    fontSize: '16px',
                    fontWeight: 500,
                    width: '20%',
                    textAlign: 'start',
                  }}
                >
                  {detail.purchaseType[0].toUpperCase() +
                    detail.purchaseType.slice(1)}
                </Typography>
                <Typography
                  style={{
                    color: '#222428',
                    fontSize: '16px',
                    fontWeight: 500,
                    width: '30%',
                    textAlign: 'start',
                  }}
                >
                  {detail.status[0].toUpperCase() + detail.status.slice(1)}
                </Typography>
              </Box>
              {detail.status === 'error' && (
                <Typography
                  style={{
                    color: 'red',
                    fontSize: '16px',
                    fontWeight: 500,
                    marginLeft: 30,
                    marginRight: 10,
                  }}
                >
                  {detail.errorMessages}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Backdrop>
    </>
  );
}
