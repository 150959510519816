import styled from '@emotion/styled';

export const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`;

export const CustomInputLabel = styled.div`
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const TextInputCustom = styled.input`
  padding: 12px;
  font-weight: 400;
  font-family: Manrope;
  border: 0;
  outline: 0;
  border-radius: 12px;
`;

export const ItemsLabel = styled.div`
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
`;

export const Items = styled.div<{ numColumns: number }>`
  display: grid;
  grid-template-columns: ${({ numColumns }) => `repeat(${numColumns}, 1fr)`};
  gap: 10px;
`;

export const Item = styled.div<{ isSelected: boolean }>`
  border: 12px;
  width: 100%;
  background-color: ${({ isSelected }) => (isSelected ? '#E2D8FF' : '#fff')};
  color: ${({ isSelected }) => (isSelected ? '#5F27CD' : '#222428')};
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const EmptyDateValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  padding: 12px;
  width: 100%;
  font-size: 14px;
`;

export const EmptyDateValueText = styled.div`
  font-weight: 400;
  font-family: Manrope;
`;

export const EmptyDateValueIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const CustomSelectWrapper = styled.select`
  border-color: #d9d9d9;
  border-radius: 10px;
  padding: 10px 30px 10px 10px !important;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/images/chevron-down.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;

  :focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.div`
  color: #ff5c77;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 700;
`;

export const CustomTextArea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 20px;
  border-radius: 10px;
`;
