import React, { useEffect, useState } from 'react';
import {
  Card,
  Slider,
  Box,
  List,
  ListItem,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Select,
  Switch,
  MenuItem,
  TextField,
} from '@mui/material';
import { API } from 'aws-amplify';
import { Text, View } from '@aws-amplify/ui-react';
import './orgList.css';
import { CSSTransition } from 'react-transition-group';
import gql from 'graphql-tag';
import StageDispositionsConfig from './StageDispositionConfig';
const permissionsArray = [
  { key: 'canCreateAreas', label: 'Can Create Areas' },
  { key: 'canCreateCircles', label: 'Can Create Circles' },
  { key: 'canCreateMissions', label: 'Can Create Missions' },
  { key: 'canCreatePeople', label: 'Can Create People' },
  { key: 'canCreateRewards', label: 'Can Create Rewards' },
  { key: 'canSeeOthersAreas', label: "Can See Others' Areas" },
  { key: 'canSeePins', label: 'Can See Pins' },
];

export default function GeneralConfig(props) {
  const { orgID } = props;
  const [entitlementsObj, setEntitlementsObj] = useState(null);
  const [ogEntitlementsObj, setOgEntitlementsObj] = useState(null);
  const [entitlementsChanged, setEntitlementsChanged] = useState(false);
  const [defaultScheduleObj, setDefaultScheduleObj] = useState(null);
  const [ogDefaultScheduleObj, setOgDefaultScheduleObj] = useState(null);
  const [ogOrgObj, setOgOrgObj] = useState(null);
  const [featuresList, setFeaturesList] = useState([]);
  const [ogFeaturesList, setOgFeaturesList] = useState([]);
  const [org, setOrg] = useState(null);
  const [changed, setChanged] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orgUSers, setOrgUsers] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [orgTiles, setOrgTiles] = useState([]);
  const [ogOrgTiles, setOgOrgTiles] = useState([]);
  const [orgPermissions, setOrgPermissions] = useState({});
  const [ogOrgPermissions, setOgOrgPermissions] = useState({});
  // const [defaultScheduler, setDefaultScheduler] = useState('');

  const defaultScheduleTemp = {
    default: [
      {
        days: [0, 1, 2, 3, 4, 5],
        defaultMeetingLength: 90,
        endTime: 1260,
        maximumMeetingLength: 600,
        minimumMeetingLength: 30,
        minutesBetweenMeetings: 0,
        startTime: 540,
      },
    ],
  };

  async function getOrgData(orgID) {
    const myQuery = gql`
      query GetOrg($id: ID!) {
        getOrg(id: $id) {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          users {
            items {
              id
              userID
              orgID
              status
              memberType
              orgPoints
              lifetimeOrgPoints
              isActive
              createdBy
              lastUpdatedBy
              createdAt
              updatedAt
              isDeleted
            }
            nextToken
          }
          preferences {
            items {
              id
              orgID
              isHidden
              preferenceType
              name
              entityType
              entityID
              preferences
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
      }
    `;
    const res = await API.graphql({
      query: myQuery,
      variables: { id: orgID },
    });
    orgStatesSetter(res.data.getOrg);
  }

  async function getOrgMembers() {
    if (fetchingData) return;
    setFetchingData(true);
    const myQuery = gql`
      query ListUsersByOrg(
        $orgID: ID
        $email: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $limit: Int
        $nextToken: String
      ) {
        listUsersByOrg(
          orgID: $orgID
          email: $email
          sortDirection: $sortDirection
          filter: { status: { ne: "deleted" } }
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            username
            email
            name
          }
          nextToken
        }
      }
    `;
    const usersList = [];
    let ourNextToken = null;
    do {
      const res = await API.graphql({
        query: myQuery,
        variables: { orgID, nextToken: ourNextToken },
      });
      console.log(res);
      usersList.push(...res.data.listUsersByOrg.items);
      ourNextToken = res.data.listUsersByOrg.nextToken;
    } while (ourNextToken);
    usersList.sort((a, b) => a.name.localeCompare(b.name));
    setOrgUsers(usersList);
    setFetchingData(false);
  }

  const orgStatesSetter = (org) => {
    setOrg(org);
    setOgOrgObj(org);
    const { features, entitlements, defaultSchedule, tiles, permissions } =
      JSON.parse(org.configParms);
    console.log('PERMISSION!!', permissions);
    const entObj = entitlements.reduce((acc, str) => {
      const value = !str.startsWith('-');
      const key = str.startsWith('-') ? str.slice(1) : str;
      acc[key] = value;
      return acc;
    }, {});
    setEntitlementsObj(() => entObj);
    setOgEntitlementsObj(() => entObj);
    setFeaturesList(() => features);
    setOgFeaturesList(() => features);
    setDefaultScheduleObj(() => defaultSchedule);
    setOgDefaultScheduleObj(() => defaultSchedule);
    setOrgTiles(tiles);
    setOgOrgTiles(tiles);
    setOrgPermissions(permissions || {});
    setOgOrgPermissions(permissions || {});
  };

  const formatTime = (time) => {
    let hour = Math.floor(time / 60);
    let min = Math.floor(time % 60);
    let ampm = 'PM';
    if (hour < 12) {
      ampm = 'AM';
    } else if (hour > 12) {
      hour = hour - 12;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }

    if (min < 10) {
      min = '0' + min;
    }
    return `${hour}:${min} ${ampm}`;
  };

  useEffect(() => {
    getOrgData(orgID);
    // getDispositions();
  }, []);

  useEffect(() => {
    setChanged(
      () =>
        JSON.stringify(org) !== JSON.stringify(ogOrgObj) ||
        entitlementsChanged ||
        JSON.stringify(featuresList) !== JSON.stringify(ogFeaturesList) ||
        JSON.stringify(defaultScheduleObj) !==
          JSON.stringify(ogDefaultScheduleObj) ||
        orgTiles.length !== ogOrgTiles.length ||
        JSON.stringify(orgPermissions) !== JSON.stringify(ogOrgPermissions)
    );
    if (
      featuresList?.schedule?.enabled &&
      featuresList?.schedule?.autoSchedule &&
      orgUSers === null
    ) {
      getOrgMembers();
    }
  }, [
    featuresList,
    entitlementsChanged,
    org,
    defaultScheduleObj,
    orgTiles,
    orgPermissions,
  ]);

  useEffect(() => {
    setEntitlementsChanged(
      () =>
        JSON.stringify(entitlementsObj) !== JSON.stringify(ogEntitlementsObj)
    );
  }, [entitlementsObj]);

  const onSubmit = async () => {
    const myMutation = gql`
      mutation UpdateOrg($input: UpdateOrgInput!) {
        updateOrg(input: $input) {
          id
          name
          contactName
          contactPhone
          contactEmail
          configParms
        }
      }
    `;
    const newConfigParms = {
      ...JSON.parse(org.configParms),
      features: featuresList,
      permissions: orgPermissions,
      entitlements: mapEntitlements(entitlementsObj),
      defaultSchedule: defaultScheduleObj,
      tiles: orgTiles,
    };
    const res = await API.graphql({
      query: myMutation,
      variables: {
        input: {
          id: org.id,
          name: org.name,
          contactName: org.contactName,
          contactPhone: org.contactPhone,
          contactEmail: org.contactEmail,
          configParms: JSON.stringify(newConfigParms),
        },
      },
    });
    const updatedOrg = res.data.updateOrg;
    orgStatesSetter(updatedOrg);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };
  const mapEntitlements = (obj) =>
    Object.entries(obj).map((el) => (el[1] ? el[0] : `-${el[0]}`));

  const entitleChange = (e) => {
    const temp = {
      ...entitlementsObj,
      [e.target.name]: !entitlementsObj[e.target.name],
    };
    setEntitlementsObj(() => temp);
  };

  if (!org || !entitlementsObj) return <CircularProgress color="inherit" />;
  return (
    <View style={{ position: 'relative', width: '100%' }}>
      <CSSTransition
        in={changed}
        timeout={300}
        classNames="slide"
        unmountOnExit
      >
        <Box className="save-changes-box">
          <Button onClick={onSubmit} variant="contained">
            Save Changes
          </Button>
          <Box style={{ width: '3px' }} />
          <Button variant="contained" onClick={() => orgStatesSetter(ogOrgObj)}>
            Reset Org
          </Button>
        </Box>
      </CSSTransition>
      <Box
        style={{
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '15px',
        }}
      >
        <Box id="general-info-box">
          <Box style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
            <Text style={{ width: '12vw', fontWeight: 'bold' }}>Name</Text>
            <TextField
              style={{ width: '90%' }}
              variant="standard"
              name="name"
              value={org.name}
              onChange={(e) => setOrg(() => ({ ...org, name: e.target.value }))}
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
            <Text style={{ width: '12vw', fontWeight: 'bold' }}>
              Contact Name
            </Text>
            <TextField
              style={{ width: '90%' }}
              variant="standard"
              name="contactName"
              value={org.contactName}
              onChange={(e) =>
                setOrg(() => ({ ...org, contactName: e.target.value }))
              }
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
            <Text style={{ width: '12vw', fontWeight: 'bold' }}>
              Contact Phone
            </Text>
            <TextField
              style={{ width: '90%' }}
              variant="standard"
              name="contactPhone"
              value={org.contactPhone}
              onChange={(e) =>
                setOrg(() => ({ ...org, contactPhone: e.target.value }))
              }
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
            <Text style={{ width: '12vw', fontWeight: 'bold' }}>
              Contact Email
            </Text>
            <TextField
              style={{ width: '90%' }}
              variant="standard"
              name="contactEmail"
              value={org.contactEmail}
              onChange={(e) =>
                setOrg(() => ({ ...org, contactEmail: e.target.value }))
              }
            />
          </Box>
        </Box>
      </Box>

      <Card id="features-box">
        <Box className="center-content">
          <Text
            onClick={() => {
              console.log('LETS DO IT');
              getOrgMembers();
            }}
            style={{ fontWeight: 'bold', fontSize: '1.8rem', color: '#5f27cd' }}
          >
            FEATURES
          </Text>
        </Box>
        <Box id="pin-dropping-box">
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
              Pin Dropping
            </Text>
            <Switch
              checked={featuresList.route.enabled}
              onChange={() =>
                setFeaturesList(() => ({
                  ...featuresList,
                  route: {
                    ...featuresList.route,
                    enabled: !featuresList.route.enabled,
                  },
                }))
              }
            />
          </Box>
          {featuresList.route.enabled && (
            <Box>
              <StageDispositionsConfig orgID={orgID} />
              <Box
                style={{
                  display: 'flex',
                  gap: 55,
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Only creator can change disposition</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={
                        featuresList.route.onlyCreatorCanChangeDisposition
                      }
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            onlyCreatorCanChangeDisposition:
                              !featuresList.route
                                .onlyCreatorCanChangeDisposition,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Only creator can delete disposition</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={featuresList.route.onlyCreatorCanDelete}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            onlyCreatorCanDelete:
                              !featuresList.route.onlyCreatorCanDelete,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Only creator can view details</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={featuresList.route.onlyCreatorCanViewDetails}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            onlyCreatorCanViewDetails:
                              !featuresList.route.onlyCreatorCanViewDetails,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    name="enhance-door-data"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Enhance Door Data</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={featuresList.route.enhancedDoorData}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            enhancedDoorData:
                              !featuresList.route.enhancedDoorData,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    name="enable-wandermode"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Wander mode Enabled</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={featuresList.route.wanderMode || false}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            wanderMode: !featuresList.route.wanderMode,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    name="disable-search"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Disable Search</Text>
                    <Switch
                      disabled={!featuresList.route.enabled}
                      checked={featuresList.route.disableSearch}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          route: {
                            ...featuresList.route,
                            disableSearch: !featuresList.route.disableSearch,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Box>
                  <Box
                    name="radius-box"
                    style={{ display: 'flex', width: '30vw', gap: 25 }}
                  >
                    <Text>Radius</Text>
                    <Slider
                      disabled={!featuresList.route.enabled}
                      valueLabelDisplay="auto"
                      min={1000}
                      max={5000}
                      step={100}
                      value={featuresList.route.radius}
                      onChange={(e, newValue) =>
                        setFeaturesList((prevState) => ({
                          ...prevState,
                          route: {
                            ...prevState.route,
                            radius: newValue,
                          },
                        }))
                      }
                    />
                    <Text style={{ whiteSpace: 'nowrap' }}>
                      {featuresList.route.radius + ' M'}
                    </Text>
                  </Box>
                  <Box
                    name="max-pin-distance"
                    style={{
                      width: '20vw',
                      display: 'flex',
                      // alignItems: 'center',
                    }}
                  >
                    <Text>Max Pin Distance</Text>
                    <Slider
                      disabled={!featuresList.route.enabled}
                      valueLabelDisplay="auto"
                      min={10}
                      max={50}
                      step={10}
                      value={featuresList.route.maxPinDistance}
                      onChange={(e, newValue) =>
                        setFeaturesList((prevState) => ({
                          ...prevState,
                          route: {
                            ...prevState.route,
                            maxPinDistance: newValue,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box id="feed-box">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '1.5rem',
              }}
            >
              Default Schedule
            </Text>
            {!defaultScheduleObj?.default && (
              <Box
                className="create-default-schedule-button"
                onClick={() => setDefaultScheduleObj(defaultScheduleTemp)}
              >
                Create default schedule
              </Box>
            )}
          </Box>
          {defaultScheduleObj?.default && (
            <Box>
              <Box>
                <Text style={{ fontWeight: 'bold' }}>Available Days</Text>
                <Box style={{ display: 'flex', gap: 5 }}>
                  {[
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ].map((day, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        // console.log(index);
                        const temp = [...defaultScheduleObj.default[0].days];
                        if (temp.includes(index)) {
                          const ind = temp.indexOf(index);
                          temp.splice(ind, 1);
                        } else {
                          temp.push(index);
                          temp.sort();
                        }
                        // console.log(temp);
                        setDefaultScheduleObj((p) => ({
                          default: [{ ...p.default[0], days: temp }],
                        }));
                      }}
                      className={`default-schedule-day-single-box ${
                        defaultScheduleObj.default[0].days.includes(index)
                          ? ''
                          : 'un'
                      }selected-single-day`}
                    >
                      {day}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '3%',
                  marginTop: 5,
                }}
              >
                <Box style={{ width: '24%' }}>
                  <Box
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Default Meeting Length
                    </Text>
                    <Text>
                      {defaultScheduleObj.default[0].defaultMeetingLength}
                    </Text>
                  </Box>
                  <Slider
                    min={10}
                    max={360}
                    step={10}
                    value={
                      defaultScheduleObj.default[0].defaultMeetingLength || 0
                    }
                    onChange={(e, newVal) => {
                      // console.log(newVal);
                      setDefaultScheduleObj((p) => ({
                        default: [
                          { ...p.default[0], defaultMeetingLength: newVal },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box style={{ width: '24%' }}>
                  <Box
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Minimum Meeting Length
                    </Text>
                    <Text>
                      {defaultScheduleObj.default[0].minimumMeetingLength}
                    </Text>
                  </Box>
                  <Slider
                    min={10}
                    max={1000}
                    step={10}
                    value={
                      defaultScheduleObj.default[0].minimumMeetingLength || 0
                    }
                    onChange={(e, newVal) => {
                      // console.log(newVal);
                      setDefaultScheduleObj((p) => ({
                        default: [
                          { ...p.default[0], minimumMeetingLength: newVal },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box style={{ width: '24%' }}>
                  <Box
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Maximum Meeting Length
                    </Text>
                    <Text>
                      {defaultScheduleObj.default[0].maximumMeetingLength}
                    </Text>
                  </Box>
                  <Slider
                    min={10}
                    max={1000}
                    step={10}
                    value={
                      defaultScheduleObj.default[0].maximumMeetingLength || 0
                    }
                    onChange={(e, newVal) => {
                      // console.log(newVal);
                      setDefaultScheduleObj((p) => ({
                        default: [
                          { ...p.default[0], maximumMeetingLength: newVal },
                        ],
                      }));
                    }}
                  />
                </Box>
                <Box style={{ width: '24%' }}>
                  <Box
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Minutes Between Meetings
                    </Text>
                    <Text>
                      {defaultScheduleObj.default[0].minutesBetweenMeetings}
                    </Text>
                  </Box>
                  <Slider
                    min={0}
                    max={100}
                    step={5}
                    value={
                      defaultScheduleObj.default[0].minutesBetweenMeetings || 0
                    }
                    onChange={(e, newVal) => {
                      setDefaultScheduleObj((p) => ({
                        default: [
                          { ...p.default[0], minutesBetweenMeetings: newVal },
                        ],
                      }));
                    }}
                  />
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: '10%' }}>
                <Box style={{ width: '45%' }}>
                  <Box style={{ display: 'flex', gap: 10 }}>
                    <Text style={{ fontWeight: 'bold' }}>Start Time</Text>
                    <Text>
                      {formatTime(defaultScheduleObj.default[0].startTime)}
                    </Text>
                  </Box>
                  <Slider
                    min={0}
                    max={1439}
                    step={5}
                    value={defaultScheduleObj.default[0].startTime || 0}
                    onChange={(e, newVal) => {
                      setDefaultScheduleObj((p) => ({
                        default: [{ ...p.default[0], startTime: newVal }],
                      }));
                    }}
                  />
                </Box>
                <Box style={{ width: '45%' }}>
                  <Box style={{ display: 'flex', gap: 10 }}>
                    <Text style={{ fontWeight: 'bold' }}>End Time</Text>
                    <Text>
                      {formatTime(defaultScheduleObj.default[0].endTime)}
                    </Text>
                  </Box>
                  <Slider
                    min={0}
                    max={1439}
                    step={5}
                    value={defaultScheduleObj.default[0].endTime || 0}
                    onChange={(e, newVal) => {
                      setDefaultScheduleObj((p) => ({
                        default: [{ ...p.default[0], endTime: newVal }],
                      }));
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box id="feed-features-box">
          <Box id="feed-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Feed
              </Text>
              <Switch
                checked={featuresList.feed.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    feed: {
                      ...featuresList.feed,
                      enabled: !featuresList.feed.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.feed.enabled && (
              <>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Comment</Text>
                  <Switch
                    checked={featuresList.feed.comment}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        feed: {
                          ...featuresList.feed,
                          comment: !featuresList.feed.comment,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Like</Text>
                  <Switch
                    checked={featuresList.feed.like}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        feed: {
                          ...featuresList.feed,
                          like: !featuresList.feed.like,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Video</Text>
                  <Switch
                    checked={featuresList.feed.uploadVideo}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        feed: {
                          ...featuresList.feed,
                          uploadVideo: !featuresList.feed.uploadVideo,
                        },
                      }))
                    }
                  />
                </Box>
              </>
            )}
          </Box>

          <Box id="feedblast-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Feed Blast
              </Text>
              <Switch
                checked={featuresList.feedBlast.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    feedBlast: {
                      ...featuresList.feedBlast,
                      enabled: !featuresList.feedBlast.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.feedBlast.enabled && (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <Box>
                  <Box className="flex-centered space-b g-hov">
                    <Text style={{ fontWeight: 'bold' }}>Admin Only</Text>
                    <Switch
                      checked={featuresList.feedBlast.adminOnly}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          feedBlast: {
                            ...featuresList.feedBlast,
                            adminOnly: !featuresList.feedBlast.adminOnly,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box className="flex-centered space-b g-hov">
                    <Text style={{ fontWeight: 'bold' }}>
                      Default Post To All
                    </Text>
                    <Switch
                      checked={featuresList.feedBlast.deafultPostToAll}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          feedBlast: {
                            ...featuresList.feedBlast,
                            deafultPostToAll:
                              !featuresList.feedBlast.deafultPostToAll,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box className="flex-centered space-b g-hov">
                    <Text style={{ fontWeight: 'bold' }}>Schedule Send</Text>
                    <Switch
                      checked={featuresList.feedBlast.scheduleSend}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          feedBlast: {
                            ...featuresList.feedBlast,
                            scheduleSend: !featuresList.feedBlast.scheduleSend,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Box className="w60">
                  <Box className="flex-centered">
                    <Text style={{ fontWeight: 'bold' }}>Circle</Text>
                    <Select
                      //   className="select-comp"
                      value={featuresList.feedBlast.circle}
                      onChange={(e) =>
                        setFeaturesList((prevState) => ({
                          ...prevState,
                          feedBlast: {
                            ...prevState.feedBlast,
                            circle: e.target.value,
                          },
                        }))
                      }
                      label="Circle"
                      className="config-select-field"
                      // style={{ backgroundColor: 'white', border: 'none', }}
                    >
                      {['User', 'Moderator', 'Admin'].map((el, i) => (
                        <MenuItem
                          value={el.toLowerCase()}
                          key={i}
                          style={{
                            backgroundColor: 'white',
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = 'lightgray')
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = 'white')
                          }
                        >
                          {el}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value="user">User</MenuItem>
                      <MenuItem value="moderator">Moderator</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem> */}
                    </Select>
                  </Box>
                  <Box className="flex-centered">
                    <Text style={{ fontWeight: 'bold' }}>Individual</Text>
                    <Select
                      value={featuresList.feedBlast.individual}
                      onChange={(e) =>
                        setFeaturesList((prevState) => ({
                          ...prevState,
                          feedBlast: {
                            ...prevState.feedBlast,
                            individual: e.target.value,
                          },
                        }))
                      }
                      label="Individual"
                      className="config-select-field"
                      // style={{ backgroundColor: 'white', border: 'none', }}
                    >
                      {['User', 'Moderator', 'Admin'].map((el, i) => (
                        <MenuItem
                          value={el.toLowerCase()}
                          key={i}
                          style={{
                            backgroundColor: 'white',
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = 'lightgray')
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = 'white')
                          }
                        >
                          {el}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value="user">User</MenuItem>
                      <MenuItem value="moderator">Moderator</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem> */}
                    </Select>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box id="features-grid">
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Person
              </Text>
              <Switch
                checked={featuresList.person.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    person: {
                      ...featuresList.person,
                      enabled: !featuresList.person.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.person.enabled && (
              <Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Picture</Text>
                  <Switch
                    checked={featuresList.person.uploadPicture}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        person: {
                          ...featuresList.person,
                          uploadPicture: !featuresList.person.uploadPicture,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Video</Text>
                  <Switch
                    checked={featuresList.person.uploadVideo}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        person: {
                          ...featuresList.person,
                          uploadVideo: !featuresList.person.uploadVideo,
                        },
                      }))
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Circle
              </Text>
              <Switch
                checked={featuresList.circle.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    circle: {
                      ...featuresList.circle,
                      enabled: !featuresList.circle.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.circle.enabled && (
              <Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Picture</Text>
                  <Switch
                    checked={featuresList.circle.uploadPicture}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        circle: {
                          ...featuresList.circle,
                          uploadPicture: !featuresList.circle.uploadPicture,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Video</Text>
                  <Switch
                    checked={featuresList.circle.uploadVideo}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        circle: {
                          ...featuresList.circle,
                          uploadVideo: !featuresList.circle.uploadVideo,
                        },
                      }))
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Reward
              </Text>
              <Switch
                checked={featuresList.reward.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    reward: {
                      ...featuresList.reward,
                      enabled: !featuresList.reward.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.reward.enabled && (
              <Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Picture</Text>
                  <Switch
                    checked={featuresList.reward.uploadPicture}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        reward: {
                          ...featuresList.reward,
                          uploadPicture: !featuresList.reward.uploadPicture,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Upload Video</Text>
                  <Switch
                    checked={featuresList.reward.uploadVideo}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        reward: {
                          ...featuresList.reward,
                          uploadVideo: !featuresList.reward.uploadVideo,
                        },
                      }))
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Deal
              </Text>
              <Switch
                checked={featuresList.deal.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    deal: {
                      ...featuresList.deal,
                      enabled: !featuresList.deal.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.deal.enabled && (
              <Box>
                <Box style={{ display: 'flex', gap: 15 }}>
                  <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Disable Circle Filter
                    </Text>
                    <Switch
                      checked={featuresList.deal.disableCircleFilter}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            disableCircleFilter:
                              !featuresList.deal.disableCircleFilter,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Disable User Filter
                    </Text>
                    <Switch
                      checked={featuresList.deal.disableUserFilter}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            disableUserFilter:
                              !featuresList.deal.disableUserFilter,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 15 }}>
                  <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>Sign On Device</Text>
                    <Switch
                      checked={featuresList.deal.signOnDevice || false}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            signOnDevice: !featuresList.deal.signOnDevice,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>Sign Using Email</Text>
                    <Switch
                      checked={featuresList.deal.signUsingEmail || false}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            signUsingEmail: !featuresList.deal.signUsingEmail,
                          },
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 15 }}>
                  {/* <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Hide Followup Date Field
                    </Text>
                    <Switch
                      checked={featuresList.deal.hideFollowupDateField}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            hideFollowupDateField:
                              !featuresList.deal.hideFollowupDateField,
                          },
                        }))
                      }
                    />
                  </Box> */}
                  <Box
                    className="flex-centered space-b g-hov"
                    style={{ width: '50%' }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Disable Change Stage
                    </Text>
                    <Switch
                      checked={featuresList.deal.disableChangeStage}
                      onChange={() =>
                        setFeaturesList(() => ({
                          ...featuresList,
                          deal: {
                            ...featuresList.deal,
                            disableChangeStage:
                              !featuresList.deal.disableChangeStage,
                          },
                        }))
                      }
                    />
                  </Box>
                  <Box width="50%" />
                </Box>

                <Box>
                  <Text
                    onClick={() => console.log(featuresList.deal)}
                    style={{ fontWeight: 'bold' }}
                  >
                    Order Table Fields
                  </Text>
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 4,
                      border: '1px solid gray',
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    {[
                      { value: 'header', label: 'Header' },
                      { value: 'sellToInfo', label: 'Sell To Info' },
                      { value: 'quantity', label: 'Quantity' },
                      { value: 'description', label: 'Description' },
                      { value: 'unitPrice', label: 'Unit Price' },
                      { value: 'discount', label: 'Discount' },
                      { value: 'extPrice', label: 'Ext Price' },
                      { value: 'totalRow', label: 'Total Row' },
                    ].map(({ label, value }, i) => (
                      <Box
                        key={i}
                        className="hov"
                        onClick={() => {
                          if (!featuresList.deal.orderTableFields) {
                            setFeaturesList((p) => ({
                              ...p,
                              deal: {
                                ...p.deal,
                                orderTableFields: [value],
                              },
                            }));
                            return;
                          }
                          const index =
                            featuresList.deal.orderTableFields.indexOf(value);
                          if (index === -1) {
                            setFeaturesList((p) => ({
                              ...p,
                              deal: {
                                ...p.deal,
                                orderTableFields: [
                                  ...p.deal.orderTableFields,
                                  value,
                                ],
                              },
                            }));
                          } else {
                            const newFeatures = { ...featuresList };
                            newFeatures.deal.orderTableFields.splice(index, 1);
                            setFeaturesList(newFeatures);
                          }
                        }}
                        style={{
                          padding: '2px 8px',
                          borderRadius: 4,
                          backgroundColor:
                            featuresList.deal.orderTableFields?.includes(value)
                              ? '#6177c1'
                              : 'lightgray',
                        }}
                      >
                        <Text color="white">{label}</Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Schedule
              </Text>
              <Switch
                checked={featuresList.schedule.enabled}
                onChange={() =>
                  setFeaturesList(() => ({
                    ...featuresList,
                    schedule: {
                      ...featuresList.schedule,
                      enabled: !featuresList.schedule.enabled,
                    },
                  }))
                }
              />
            </Box>
            {featuresList.schedule.enabled && (
              <>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Auto Schedule</Text>
                  <Switch
                    checked={featuresList.schedule.autoSchedule || false}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        schedule: {
                          ...featuresList.schedule,
                          autoSchedule: !featuresList.schedule.autoSchedule,
                        },
                      }))
                    }
                  />
                </Box>
                {featuresList?.schedule?.autoSchedule && (
                  <Box>
                    {fetchingData || !orgUSers ? (
                      <Text>LOADING USERS LIST</Text>
                    ) : (
                      <Select
                        placeholder="pick something!"
                        style={{ width: '100%' }}
                        value={featuresList.schedule.automaticCloserID || ''}
                        onChange={(e) =>
                          setFeaturesList((p) => ({
                            ...p,
                            schedule: {
                              ...p.schedule,
                              automaticCloserID: e.target.value,
                            },
                          }))
                        }
                        MenuProps={{
                          style: {
                            zIndex: 100002,
                          },
                          MenuListProps: {
                            sx: {
                              maxHeight: 300,
                              overflowY: 'auto',
                              paddingTop: 0,
                              paddingBottom: 0,
                              borderRadius: '8px',
                              border: '1px solid #D0D2D8',
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value={''}
                          style={{
                            backgroundColor:
                              featuresList?.schedule?.automaticCloserID ===
                                '' || !featuresList?.schedule?.automaticCloserID
                                ? 'lightblue'
                                : 'white',
                          }}
                        >
                          {'Select scheduler'}
                        </MenuItem>
                        {orgUSers.map((user) => (
                          <MenuItem
                            value={user.id}
                            key={user.id}
                            style={{
                              backgroundColor:
                                user.id ===
                                featuresList.schedule.automaticCloserID
                                  ? 'lightblue'
                                  : 'white',
                            }}
                            // onMouseEnter={(e) =>
                            //   (e.target.style.backgroundColor = 'lightgray')
                            // }
                            // onMouseLeave={(e) =>
                            //   (e.target.style.backgroundColor = 'white')
                            // }
                          >
                            {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Box>
                )}
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Use Round Robin</Text>
                  <Switch
                    checked={featuresList.schedule.useRoundRobin || false}
                    onChange={() =>
                      setFeaturesList(() => ({
                        ...featuresList,
                        schedule: {
                          ...featuresList.schedule,
                          useRoundRobin: !featuresList.schedule.useRoundRobin,
                        },
                      }))
                    }
                  />
                </Box>
                <Box className="flex-centered space-b g-hov">
                  <Text style={{ fontWeight: 'bold' }}>Scheduler Role</Text>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      border: '1px solid lightgray',
                      margin: '0 5px',
                      borderRadius: 4,
                      padding: 5,
                    }}
                  >
                    {/* {featuresList.schedule.schedulerRole?.map((role, i) => ( */}
                    {['scheduler', 'manager', 'setter', 'closer'].map(
                      (role, i) => {
                        const roleActive =
                          featuresList.schedule.schedulerRole?.includes(role);
                        return (
                          <Text
                            className="hov"
                            style={{
                              margin: '0 4px',
                              backgroundColor: roleActive
                                ? '#6177c1'
                                : 'lightgray',
                              borderRadius: 4,
                              padding: '2px 5px',
                              color: 'white',
                            }}
                            onClick={() => {
                              const temp = featuresList.schedule.schedulerRole
                                ? [...featuresList.schedule.schedulerRole]
                                : [];
                              if (roleActive) {
                                const index = temp.indexOf(role);
                                temp.splice(index, 1);
                              } else {
                                temp.push(role);
                              }
                              setFeaturesList((p) => ({
                                ...p,
                                schedule: {
                                  ...p.schedule,
                                  schedulerRole: temp,
                                },
                              }));
                            }}
                            key={i}
                          >
                            {role}
                          </Text>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className="multi-att-feature-box">
            <Box className="flex-centered space-b">
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
                onClick={() => {
                  console.log(orgTiles);
                  console.log(ogOrgTiles);
                }}
              >
                {orgTiles.find((el) => el.title === 'Calendar')
                  ? 'Disable Calendar'
                  : 'Enable Calendar'}
              </Text>
              <Switch
                checked={orgTiles.find((el) => el.title === 'Calendar')}
                onChange={() => {
                  const newOrgTiles = [...orgTiles];
                  const tile = newOrgTiles.find(
                    (el) => el.title === 'Calendar'
                  );
                  if (tile) {
                    const tileIndex = newOrgTiles.indexOf(tile);
                    newOrgTiles.splice(tileIndex, 1);
                  } else {
                    newOrgTiles.unshift({
                      color: 'black',
                      iconName: 'play',
                      permission: 'everyone',
                      screen: 'CalendarScreen',
                      title: 'Calendar',
                      type: 'font-awesome-5',
                    });
                  }
                  setOrgTiles(newOrgTiles);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
      {/* Permissions */}
      <Box
        style={{
          marginTop: 10,
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '15px',
          padding: 15,
        }}
      >
        <Box className="center-content">
          <Text
            style={{ fontWeight: 'bold', fontSize: '1.8rem', color: '#5f27cd' }}
          >
            PERMISSIONS
          </Text>
        </Box>
        <Box style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {permissionsArray.map((per, i) => (
            <Box key={i} style={{ width: '18%' }}>
              <Text style={{ fontWeight: 600 }}>{per.label}</Text>
              <Select
                value={orgPermissions[per.key] || ''}
                onChange={(e) => {
                  setOrgPermissions({
                    ...orgPermissions,
                    [per.key]: e.target.value,
                  });
                }}
                style={{ width: '100%' }}
              >
                {!orgPermissions[per.key] && (
                  <MenuItem value="" style={{ backgroundColor: 'red' }}>
                    <em>Not selected</em>
                  </MenuItem>
                )}
                {['admin', 'moderator', 'everyone'].map((opt, j) => (
                  <MenuItem
                    style={{
                      backgroundColor: 'white',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                    value={opt}
                    key={j}
                  >
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Entitlements */}
      <Box id="entitlements-box">
        <Box
          style={{
            backgroundColor: 'rgb(245, 245, 245)',
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '15px',
            borderRadius: '15px',
          }}
        >
          <Text>Entitlements</Text>
        </Box>
        <List id="entitlements-list">
          {entitlementsObj &&
            Object.entries(entitlementsObj).map((el, i) => (
              <ListItem className="flex-centered space-b g-hov" key={i}>
                <Box>
                  <Text>{el[0]}</Text>
                </Box>
                <Switch
                  name={el[0]}
                  checked={entitlementsObj[el[0]]}
                  onChange={entitleChange}
                />
              </ListItem>
            ))}
        </List>
        {/* <image src={image} />
        <Text style={{ color: 'white' }}>hahahahahah</Text> */}
      </Box>

      {changed && (
        <Box id="bottom-save-buttons-box">
          <Button onClick={onSubmit} variant="contained">
            Save Changes
          </Button>
          <Box style={{ width: '3px' }} />
          <Button variant="contained" onClick={() => orgStatesSetter(ogOrgObj)}>
            Reset Org
          </Button>
        </Box>
      )}
      <Snackbar open={success} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: '100%', padding: '15px 20px' }}>
          Org Successfully Updated!
        </Alert>
      </Snackbar>
    </View>
  );
}
