import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  padding: 125px 100px 20px 100px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 125px 50px 20px 50px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
  font-family: Space Grotesk;
`;

export const FilterIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const FilterIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const TeamPeopleTabContainer = styled.div`
  background-color: #f0f0f3;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 300px;
  border-radius: 10px;
  margin-bottom: 30px;
`;

export const TeamPeopleTab = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#5F27CD' : '#5F5F5F')};
  font-weight: 700;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`;

export const TimeframeTabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
`;

export const TimeframeTab = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const TimeframeTabTitle = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#5F27CD' : '#868686')};
  font-weight: 700;
  font-family: Space Grotesk;
`;

export const TimeframeTabIndicator = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#5F27CD' : 'transparent')};
  width: 10px;
  height: 10px;
  border-radius: 100%;
`;

export const BadgeFilterCount = styled.div`
  position: relative;
  bottom: 8px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #ff5c77;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
`;
