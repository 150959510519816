import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  // Mobile
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 500px;
  margin-bottom: 4px;

  @media (min-width: 600px) {
    width: 760px;
  }
`;

export const InputAndActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 50px;
`;

export const PostButton = styled.div`
  color: #fff;
  background-color: #5f27cd;
  padding: 10px 50px;
  text-align: center;
  font-weight: 700;
  border-radius: 12px;
  cursor: pointer;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export const CustomTextArea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
`;

export const FilePreviewContainer = styled.div`
  position: relative;
  width: fit-content;
`;
export const FilePreviewCloseIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  position: absolute;
  border-radius: 100%;
  right: -20px;
  top: -10px;
`;

export const FilterPersonsList = styled.div`
  position: absolute;
  top: -20px;
  background-color: #fff;
  padding: 20px;
  z-index: 999;
  border-radius: 20px;
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const FilterPersonsListItem = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
  align-items: center;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
