import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as utilities from '../../common/Utilities';
import {
  // Autocomplete,
  Backdrop,
  Box,
  // CircularProgress,
  Typography,
  // TextField,
} from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { GamifyToast } from '../../common/CustomToasts';

export default function OwnerDealStageModal(props) {
  // NOTE: props that may be needed until SelectStage is redesigned: showAll, activeOption, allOption, minSequence, ignoreNoDealStages
  // NOTE: props that may be needed until NewSelectCircleOrPerson is redesigned: showAll, pickUsers, pickCircles, hideAssigned, allOption, allowNone
  const {
    dealID,
    categoryID,
    showOwnerDealStageModal,
    setShowOwnerDealStageModal,
    // setDealOptionsOpen,
    // setAnchorEl,
    triggerSelectedDealRefetch,
    onTriggerRefetch,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [dealStages, setDealStages] = useState([]);
  const [normalizedDealStages, setNormalizedDealStages] = useState({});
  const [dealPeople, setDealPeople] = useState([]);
  const [dealOwners, setDealOwners] = useState({
    closerID: '',
    setterID: '',
    currentStageID: '',
  });
  // NOTE: Disposition only used with pins... not on web for now
  // const [currentDispositionID, setCurrentDispositionID] = useState(null);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showDynamicConfirmModal, setShowDynamicConfirmModal] = useState(false);
  const [showCloserRequiredModal, setShowCloserRequiredModal] = useState(false);
  const [stage, setStage] = useState({});

  const modalsConfig = {
    warning: {
      title: `Are you sure you want to change the stage of this deal?`,
      subtitle: `WARNING: The "${stage.label}" deal stage is a predecessor to the current stage.\nIf you change to this stage, KPIs and games may be impacted.`,
    },
    inform: {
      title: `Are you sure you want to change the stage of this deal?`,
      subtitle: `If you change this deal stage to "${stage.label}" you cannot change back to a previous stage.`,
    },
    noStageChange: {
      title: `You haven't changed the stage of this deal yet`,
      subtitle: `Are you sure you want to change the setter or closer without changing the stage?`,
    },
  };

  async function fetchOwners(dealID) {
    // console.log('fetchOwners: ', dealID);
    //
    //  Get the list of all current deal owners in the owner history
    //
    const dealOwnersQuery = gql`
      query MyQuery($dealID: ID = "") {
        getDeal(id: $dealID) {
          id
          currentOwnerID
          currentOwnerType
          currentDispositionID
          currentStageID
          createdBy
          comments {
            items {
              comment
              contactType
              createdAt
              createdBy
              dispositionID
              id
              imageName
              imageType
              disposition {
                title
              }
              user {
                id
                imageName
                imageType
                initials
                name
                title
              }
            }
          }
          stage {
            categoryID
            sequence
            requireCloser
          }
          ownerHistory {
            items {
              ownerType
              userID
              ownerType
            }
          }
        }
      }
    `;

    const dealOwnersRes = await API.graphql({
      query: dealOwnersQuery,
      variables: { dealID },
    });
    const deal = dealOwnersRes.data.getDeal;
    // console.log('!!deal: ', deal);
    if (deal.ownerHistory.items.length === 0) {
      // deal.setterID = deal.currencreatetOwnerID;
      deal.setterID = deal.createdBy;
    }
    for (const owner of deal.ownerHistory.items) {
      // console.log('!!owner: ', owner);
      if (owner.ownerType === 'setter') {
        deal.setterID = owner.userID;
      } else if (owner.ownerType === 'closer') {
        deal.closerID = owner.userID;
      } else {
        deal.setterID = owner.userID;
      }
    }
    if (deal.closerID === undefined) {
      deal.closerID = '';
    }
    const sortedComments = deal.comments.items.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    deal.comments = sortedComments;

    deal.newComment = '';
    deal.dealID = dealID;
    // console.log('dealOwners: ', deal);
    // setDealOwners(deal);
    // setIsLoaded(true);
    return deal;
  }

  async function fetchStages(categoryID, dealOwners) {
    // console.log('fetchStages: ', categoryID);

    let myQuery;
    let filter;
    if (!props.showAll) {
      filter = ', userSelectable: {ne: false}';
    } else {
      filter = '';
    }

    if (categoryID !== undefined) {
      myQuery = gql`
        query MyQuery($categoryID: ID!) {
          listStageByCategorySequence(
            categoryID: $categoryID
            filter: { isDeleted: { eq: false } ${filter}}
            limit: 10000
          ) {
            items {
              id
              sequence
              title
              flexAttributes
              description
              changeToDispositionID
            }
          }
        }
      `;
    } else {
      myQuery = gql`
        query MyQuery($orgID: ID!) {
          listStages(
            filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } ${filter} }
            limit: 10000
          ) {
            items {
              id
              sequence
              title
              flexAttributes
              description
              changeToDispositionID
            }
          }
        }
      `;
    }

    const getStageRes = await API.graphql({
      query: myQuery,
      variables: { orgID: global.me.orgID, categoryID },
    });
    // console.log ("getKPIList",getKPIList);
    let stages;
    if (categoryID !== undefined) {
      stages = getStageRes.data.listStageByCategorySequence.items;
    } else {
      stages = getStageRes.data.listStages.items;
    }

    // console.log('fetchStages: ', stages);

    // console.log ("KPIs",KPIs);
    const assigneeList = [];
    if (props.activeOption) {
      assigneeList[assigneeList.length] = {
        label: '- Active Only',
        id: 'active',
        value: 'active',
      };
    }
    if (props.allOption) {
      assigneeList[assigneeList.length] = {
        label: '- All',
        id: 'all',
        value: 'all',
      };
    }

    let minSequence;
    if (props.minSequence !== undefined) {
      minSequence = props.minSequence;
    } else if (global.me.isAdmin) {
      minSequence = 0;
    } else if (dealOwners) {
      minSequence = dealOwners.stage.sequence;
    } else if (props.ignoreNoDealStages) {
      minSequence = 1;
    } else {
      minSequence = -1000;
    }
    for (const stage of stages) {
      if (stage.sequence >= minSequence) {
        //
        //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
        //
        // console.log ("stage.id: ", stage.id + " === props.value: " + props.value + "?")
        // if ( stage.id === props.value ) {
        //   //console.log ("YES: ", stage.title)
        //   setName ( stage.title );
        // }
        const listEntry = {
          id: stage.id,
          name: stage.title,
          value: stage.id,
          label: stage.title,
          display: stage.title,
          config: stage.flexAttributes,
          categoryID,
          sequence: stage.sequence,
          changeToDispositionID: stage.changeToDispositionID,
        };
        assigneeList[assigneeList.length] = listEntry;
      }
    }

    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.sequence < b.sequence) return -1;
      if (a.sequence > b.sequence) return 1;
      return 0;
    });
    // console.log ("sortedAssigneeList: ", sortedAssigneeList);
    // setItemList(sortedAssigneeList);
    return sortedAssigneeList;
  }

  // TO DO: Make this an Autocomplete, put it into a reusable component in PickerSearch (to replace NewSelectCircleOrPerson)
  let fetchPromise = null;
  const [itemValue, setItemValue] = useState(props.itemValue);
  console.log('setItemValue placeholder: ', setItemValue);
  const [selectedName, setSelectedName] = useState('');
  console.log('selectedName placeholder: ', selectedName);

  async function fetchPeople(ourSearchString) {
    console.log('fetchPeople: ', ourSearchString);
    if (fetchPromise !== null) {
      // console.log("Cancelling Request");
      try {
        API.cancel(fetchPromise, 'New Request');
      } catch (err) {
        console.warn('Cancel Error: ', err);
      }
      fetchPromise = null;
    }

    const filteredAssigneeList = [];

    // if (props.pickCircles) {
    //   let filter = "{";
    //   filter += "name: {wildcard: $searchString},";
    //   filter += "orgID: {eq: $orgID},";
    //   filter += " isDeleted: {eq: false}";
    //   if (props.hideCircleLevels !== undefined) {
    //     for (let circleLevelID of props.hideCircleLevels) {
    //       filter += ', levelID: {ne: "' + circleLevelID + '"}';
    //     }
    //   }

    //   if (
    //     !global.me.isAdmin &&
    //     (props.useCirclesList || props.useChildCirclesList)
    //   ) {
    //     //
    //     //  If the user is looking for all deals and they're NOT an admin, filter so that they see only the deals
    //     //  for the teams that they're in
    //     //
    //     filter += ", or: [";
    //     let first = true;

    //     if (props.useCirclesList) {
    //       for (let circleID of global.me.myCircles) {
    //         if (!first) {
    //           filter += ",";
    //         }
    //         filter += '{id: {eq: "' + circleID + '"}}';
    //         first = false;
    //       }
    //     }

    //     if (props.useChildCirclesList) {
    //       //
    //       //  Include all the circles under the user
    //       //
    //       for (let circleID of global.me.myChildCircles) {
    //         if (circleID !== global.me.leagueID) {
    //           //
    //           //  Allow the user to see all leads for any of their teams EXCEPT for their
    //           //  league.  This allows us to group people into leagues without allowing everyone
    //           //  in the same league to see everyone else's leads
    //           //
    //           if (!first) {
    //             filter += ",";
    //           }
    //           filter += '{id: {eq: "' + circleID + '"}}';
    //           first = false;
    //         }
    //       }
    //     }
    //     filter += "]";
    //   }
    //   filter += "}";

    //   LOG.debug("SelectCircle filter:", filter);
    //   const myQuery = gql`
    //     query MyQuery($orgID: ID!, $searchString: String!, $userID: ID!) {
    //       searchCircles(
    //         filter: ${filter},
    //         limit: 50,
    //         sort: { direction: asc, field: name })

    //         {
    //         items {
    //           id
    //           name
    //           users(filter: {id: {eq: $userID}}) {
    //             items {
    //               id
    //               userID
    //               status
    //               memberType
    //             }
    //           }
    //         }
    //         nextToken
    //       }
    //     }`;
    //   const mySearchString = ourSearchString + "*";

    //   fetchPromise = API.graphql({
    //     query: myQuery,
    //     variables: {
    //       orgID: global.me.orgID,
    //       searchString: mySearchString,
    //       userID: global.me.id,
    //     },
    //   });
    //   const circleData = await fetchPromise;

    //   LOG.debug("---circleData: ", circleData);
    //   const circles = circleData.data.searchCircles.items;
    //   LOG.debug("---circles: ", circles);

    //   let assigneeList = [];
    //   let userID = global.me.id;
    //   if (props.userID !== undefined) {
    //     //LOG.debug ("setting userID to props");
    //     userID = props.userID;
    //   }

    //   for (let circle of circles) {
    //     //LOG.debug ("circle.name", circle.name);
    //     LOG.debug("circle.id: ", circle.id);
    //     if (circle.id === global.me.orgID && !props.allowAllCircle) {
    //       continue;
    //     }
    //     //const inheritedCircleAdmin = true;
    //     const inheritedCircleAdmin = global.me.myChildCircles.includes(
    //       circle.id
    //     );
    //     //console.log ("inheritedCircleAdmin: ", inheritedCircleAdmin?"True":"False");
    //     let userHasRecord = false;
    //     //LOG.debug ("global.me.myChildCircles: ", global.me.myChildCircles);
    //     //
    //     //  The user is approved if they're the admin, an inherited admin, or we're not checking permissions
    //     //
    //     let userApproved =
    //       global.me.isAdmin ||
    //       inheritedCircleAdmin ||
    //       !props.checkCreatePermissions;

    //     for (let user of circle.users.items) {
    //       //
    //       //  If the user ia attached to this circle, the user is an admin, or the user inherits admin to this cicle,
    //       //  by default we're approved
    //       //
    //       if (
    //         user.userID === userID ||
    //         global.me.isAdmin ||
    //         inheritedCircleAdmin
    //       ) {
    //         if (user.status === "approved" || user.status === "accepted") {
    //           userApproved = true;
    //           if (
    //             props.checkCreatePermissions &&
    //             user.memberType === "member" &&
    //             !global.me.isAdmin &&
    //             !inheritedCircleAdmin
    //           ) {
    //             //
    //             // Members cannot create stuff using this circle, so do not approve them
    //             //
    //             LOG.debug("User rejected");
    //             userApproved = false;
    //             break;
    //           }
    //         }
    //         if (user.userID === userID) {
    //           userHasRecord = true;
    //         }
    //       }
    //       if (props.showAll) {
    //         userApproved = true;
    //       }
    //       LOG.debug(
    //         "props.checkCreatePermissions: ",
    //         props.checkCreatePermissions
    //       );
    //       LOG.debug("user.memberType: ", user.memberType);
    //     }
    //     LOG.debug("user approved:", userApproved);
    //     LOG.debug("user has record:", userHasRecord);
    //     if (userApproved && props.skipCirclesUserBelongsTo === undefined) {
    //       LOG.debug("case 1");
    //       assigneeList[assigneeList.length] = {
    //         name: circle.name,
    //         id: circle.id,
    //         value: circle.id,
    //         label: circle.name,
    //         type: "circle",
    //       };
    //     }
    //     if (userApproved && props.skipCirclesUserBelongsTo !== undefined) {
    //       LOG.debug("case 2");
    //       if (!userHasRecord) {
    //         assigneeList[assigneeList.length] = {
    //           name: circle.name,
    //           id: circle.id,
    //           value: circle.id,
    //           label: circle.name,
    //           type: "circle",
    //         };
    //       }
    //     }
    //   }
    //   LOG.debug("!!!assigneeList: ", assigneeList);
    //   LOG.debug("!!!props.hideAssigned: ", props.hideAssigned);
    //   for (const assignee of assigneeList) {
    //     LOG.debug("!!assignee: ", assignee, itemValue);
    //     if (assignee.value === itemValue) {
    //       setSelectedName(assignee.label);
    //     }
    //   }

    //   filteredAssigneeList = assigneeList;
    //   if (props.hideAssigned !== undefined) {
    //     filteredAssigneeList = [];

    //     for (let item of assigneeList) {
    //       if (!props.hideAssigned.includes(item.id)) {
    //         filteredAssigneeList[filteredAssigneeList.length] = item;
    //       }
    //     }
    //   }
    //   LOG.debug("!!!filteredAssigneeList: ", filteredAssigneeList);

    //   for (let item of filteredAssigneeList) {
    //     LOG.debug("**item ", item);
    //     if (item.id === props.value) {
    //       if (searchString !== item.name) {
    //         LOG.debug("setting name");
    //         setSearchString(item.name);
    //       }
    //     }
    //     if (
    //       filteredAssigneeList.length === 1 &&
    //       searchString !== item.name &&
    //       !props.noAutoSelect
    //     ) {
    //       //
    //       //  If there is only one circle in our list, select it by default
    //       //
    //       setSearchString(item.name);
    //       props.fieldSetter(item.id, item.name, searchString);
    //     }
    //   }
    // }

    // if (props.pickUsers) {
    const myPersonQuery = gql`
      query MyQuery($orgID: ID!, $searchString: String!) {
        searchUsers(
          filter: { name: { wildcard: $searchString }, orgID: { eq: $orgID } }
          limit: 999
          sort: { direction: asc, field: name }
        ) {
          items {
            id
            name
            status
          }
          nextToken
        }
      }
    `;
    const mySearchString = ourSearchString + '*';
    fetchPromise = await API.graphql({
      query: myPersonQuery,
      variables: { orgID: global.me.orgID, searchString: mySearchString },
    });
    const userData = await fetchPromise;

    // console.log('userData: ', userData);
    const users = userData.data.searchUsers.items;

    const personAssigneeList = [];
    for (const user of users) {
      // console.log('!!!user: ', user);
      if (user.status === 'active') {
        personAssigneeList[personAssigneeList.length] = {
          value: user.id,
          id: user.id,
          label: user.name,
          name: user.name,
          type: 'user',
        };
      }
    }
    // console.log('***personAssigneeList: ', personAssigneeList);
    // console.log('***props.hideAssigned: ', props.hideAssigned);
    for (const assignee of personAssigneeList) {
      // console.log('!!assignee: ', assignee, itemValue);
      if (assignee.value === itemValue) {
        // console.log('!!!found: ', assignee.label);
        setSelectedName(assignee.label);
      }
    }
    if (props.hideAssigned !== undefined) {
      for (const item of personAssigneeList) {
        if (!props.hideAssigned.includes(item.id)) {
          filteredAssigneeList[filteredAssigneeList.length] = item;
        }
      }
    } else {
      for (const item of personAssigneeList) {
        filteredAssigneeList[filteredAssigneeList.length] = item;
      }
    }
    // }
    // console.log('***filteredAssigneeList: ', filteredAssigneeList);

    let sortedAssigneeList = filteredAssigneeList;
    if (props.pickUsers && props.pickCircles) {
      //
      //  sort the combined list
      //
      sortedAssigneeList = filteredAssigneeList.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
    }
    if (props.allOption) {
      sortedAssigneeList.unshift({
        label: 'All ' + global.appSettings.labels.circle.plural,
        value: 'all',
        name: 'All ' + global.appSettings.labels.circle.plural,
        id: 'all',
      });
    }
    if (props.allowNone) {
      sortedAssigneeList.unshift({
        label: 'None',
        value: '',
        name: 'None',
        id: '',
      });
    }

    // setItemList(sortedAssigneeList);
    // setGoodToGo(true);
    return sortedAssigneeList;
  }

  useEffect(() => {
    async function prepareModalData() {
      const tempDealOwners = await fetchOwners(dealID);
      const tempDealStages = await fetchStages(categoryID, tempDealOwners);
      const tempDealPeople = await fetchPeople(''); // TO DO: replace '' with searchString once an Autocomplete is used OR remove search from query and search the total results w/ Autocomplete
      const tempNormalizedDealStages = tempDealStages.reduce((acc, stage) => {
        acc[stage.id] = stage;
        return acc;
      }, {});

      // console.log('OwnerDealStageModal tempDealOwners: ', tempDealOwners);
      // console.log('OwnerDealStageModal tempDealStages: ', tempDealStages);
      // console.log('OwnerDealStageModal tempPeople: ', tempDealPeople);
      // console.log(
      //   'SelectDealStage tempNormalizedDealStages: ',
      //   tempNormalizedDealStages
      // );

      setDealOwners({
        ...tempDealOwners,
        originalStageID: tempDealOwners.currentStageID,
        originalSetterID: tempDealOwners.setterID,
        originalCloserID: tempDealOwners.closerID,
      });
      setDealStages(tempDealStages);
      setNormalizedDealStages(tempNormalizedDealStages);
      setDealPeople(tempDealPeople);
    }
    prepareModalData();
  }, [dealID, triggerSelectedDealRefetch]);

  const handleCancelChangeClick = () => {
    setShowOwnerDealStageModal(false);
  };

  const createFeedItem = async (dealID, stageID, userID, onSave) => {
    console.log('createFeedItem: ', dealID, stageID, userID);
    // global.analytics.track("CreateManualKPI");
    try {
      // const now = new Date();
      const completeMissionInput = {
        dealID,
        stageID,
        userID,
        tmst: utilities.newLocalToISODateFormat(utilities.getLocalDateTime()),
      };
      // console.log('completeMissionInput: ', completeMissionInput);
      const completeMissionRes = await API.graphql({
        query: mutations.completeMission,
        variables: {
          missionInfo: JSON.stringify(completeMissionInput),
        },
      });
      const completeMissionStatus = JSON.parse(
        completeMissionRes.data.completeMission
      );

      // console.log('completeMissionStatus: ', completeMissionStatus);
      if (completeMissionStatus.status === 'failed') {
        GamifyToast.error(
          global.appSettings.labels.mission.proper + ' save failed'
        );
        onSave(false);
      } else if (completeMissionStatus.status === 'available') {
        alert(global.appSettings.labels.mission.proper + ' Abandoned');
        console.warning(
          global.appSettings.labels.mission.proper + ' Abandoned'
        );
        // props.fetch(true);
        onSave(true);

        // NOTE: Disposition only used with pins... not on web for now
        // if (currentDispositionID !== null && currentDispositionID !== '') {
        //   props.setCurrentDispositionID(currentDispositionID);
        // }
      } else if (completeMissionStatus.status === 'success') {
        onSave(true);

        // NOTE: Disposition only used with pins... not on web for now
        // if (currentDispositionID !== null && currentDispositionID !== '') {
        //   props.setCurrentDispositionID(currentDispositionID);
        // }
      } else if (completeMissionStatus.status.startsWith('WARNING:')) {
        GamifyToast.error(completeMissionRes.data.completeMission);
        console.error(completeMissionRes.data.completeMission);
        onSave(false);
      } else {
        GamifyToast.error(
          completeMissionRes.data.completeMission +
            '.   Gamify support has been notified.'
        );
        console.error(
          'error in completeMission(): ' +
            completeMissionRes.data.completeMission
        );
        onSave(false);
      }
    } catch (err) {
      console.error('error in completeMission(): ' + err.message);
      GamifyToast.error(err);
    }
  };

  const saveDealOwners = async (dealOwners, onComplete) => {
    // console.log('saveDealOwners: ', dealOwners);
    const ourDealOwners = JSON.parse(JSON.stringify(dealOwners));
    ourDealOwners.updatedAt = utilities.getCurrentUTCDate();
    // console.log('!!!ourDealOwners: ', ourDealOwners);

    // NOTE: This updates deal in the DB, but will still need to do completeMission (in createFeedItem below) to ensure outbound integrations fire
    try {
      await API.graphql({
        query: mutations.maintainDealOwners,
        variables: {
          request: JSON.stringify(ourDealOwners),
        },
      });
    } catch (err) {
      console.error(err);
      GamifyToast.error(
        'There was an error updating your deal, please try again'
      );
      onComplete(false);
      return;
    }

    // NOTE: createFeedItem used on mobile, is done by completeMission function in the backend
    // // let currentUserID =
    // //   _dealOwners.closerID !== "" ? _dealOwners.closerID : _dealOwners.setterID;

    const currentUserID = global.me.id;

    await createFeedItem(
      dealOwners.id,
      dealOwners.currentStageID,
      currentUserID,
      (status) => {
        onComplete(status);
      }
    );
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: 100002 }}
        open={showOwnerDealStageModal}
        onClick={(e) => {
          if (
            !e.target.closest('.save-owner-deal-stage-button') &&
            !e.target.closest('.MuiSelect-select') &&
            !e.target.closest('.MuiMenuItem-root') &&
            !e.target.closest('.MuiAutocomplete-root') // NOTE: not needed until Autocomplete implemented... need to figure out how Autcomplete works and how to style it and get rid of global styling on it first...
          ) {
            handleCancelChangeClick();
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            width: 624,
            maxHeight: '70vh',
            padding: '24px 40px 32px 24px',
            borderRadius: 8,
            overflowY: 'auto',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              Change deal stage
            </Typography>
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Deal stage
            </Typography>
            {/* TO DO: Update SelectStage in PickerSearch and replace this with that */}
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                console.log('SelectDealStage dealOwners: ', dealOwners);
                // console.log('SelectDealStage: ', dealStages);
                // console.log('SelectDealStage: ', e.target.value);

                // Set current stage for DynamicConfirmModal
                const tempStage = normalizedDealStages[e.target.value];
                setStage(tempStage);
                setDealOwners((p) => ({
                  ...p,
                  currentStageID: e.target.value,
                }));
                // NOTE: Disposition only used with pins... not on web for now
                // if (
                //   tempStage.changeToDispositionID !== null &&
                //   tempStage.changeToDispositionID !== ''
                // ) {
                //   setCurrentDispositionID(tempStage.changeToDispositionID);
                // }
              }}
              value={dealOwners.currentStageID}
              name="dealStage"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {dealStages.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label[0].toUpperCase() + opt.label.slice(1)}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Setter
            </Typography>
            {/* TO DO: Replace with a styled Autocomplete or redesigned NewSelectCircleOrPeople... figure out what is putting styling rules onto Autocomplete... see if it can be removed */}
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                // console.log(e.target.value);
                setDealOwners((p) => ({
                  ...p,
                  setterID: e.target.value,
                }));
              }}
              value={dealOwners.setterID}
              name="setter"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {dealPeople.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label[0].toUpperCase() + opt.label.slice(1)}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {/* <Autocomplete
              style={{ width: '100%' }}
              value={dealOwners.setterID}
              onChange={(event, newValue) => {
                setDealOwners((prevState) => ({
                  ...prevState,
                  setterID: newValue,
                }));
              }}
              options={dealPeople}
              // getOptionLabel={(option) => {
              //   console.log('option: ', option);
              //   option.label;
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Select Setter"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <StyledMenuItem {...props}>
                  {option.label[0].toUpperCase() + option.label.slice(1)}
                </StyledMenuItem>
              )}
              MenuProps={{
                style: { zIndex: 100003 },
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            /> */}
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Closer
            </Typography>
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                // console.log(e.target.value);
                setDealOwners((p) => ({
                  ...p,
                  closerID: e.target.value,
                }));
              }}
              value={dealOwners.closerID}
              name="closer"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {dealPeople.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label}
                  {/* {opt[0].toUpperCase() + opt.slice(1)} */}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => handleCancelChangeClick()}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 262,
                padding: 12,
                border: '1px solid #cfcfcf',
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: '#323232', fontWeight: 700 }}>
                Cancel
              </Typography>
            </Box>
            <Box
              className={'save-owner-deal-stage-button'}
              onClick={() => {
                const {
                  currentStageID,
                  originalStageID,
                  originalCloserID,
                  closerID,
                  originalSetterID,
                  setterID,
                } = dealOwners;

                // Inform the user a closer is required if they don't have one selected and it is required for the stage
                if (
                  dealOwners.stage.requireCloser &&
                  dealOwners.closerID === ''
                ) {
                  setShowCloserRequiredModal(true);
                  return;
                }

                // Don't open the DynamicConfirmModal or do anything if nothing was changed
                if (
                  currentStageID === originalStageID &&
                  closerID === originalCloserID &&
                  setterID === originalSetterID
                ) {
                  setShowOwnerDealStageModal(false);
                  return;
                }

                if (currentStageID === originalStageID) {
                  // There is not a change in stage, but setter and/or closer changed
                  setDynamicModalObj(modalsConfig.noStageChange);
                } else {
                  // There is a change in stage and/or setter and/or closer
                  // Set dynamicModalObj to either warning if setting to an earlier sequence or inform to say cannot change back to a previous stage
                  if (
                    normalizedDealStages[currentStageID].sequence <
                    dealOwners.stage.sequence
                  ) {
                    // Warning about KPI and game impact
                    setDynamicModalObj(modalsConfig.warning);
                  } else {
                    // Inform user they cannot change back to a previous stage
                    setDynamicModalObj(modalsConfig.inform);
                  }
                }

                // Close the OwnerDealStageModal, open the DynamicConfirmModal
                setShowOwnerDealStageModal(false);
                setShowDynamicConfirmModal(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#5F27CD',
                width: 262,
                padding: 12,
                borderRadius: 8,
              }}
            >
              {/* {isSaving ? (
                <CircularProgress
                  style={{ color: 'white', width: 24, height: 24 }}
                ></CircularProgress>
              ) : ( */}
              <Typography style={{ color: 'white', fontWeight: 700 }}>
                Save
              </Typography>
              {/* )} */}
            </Box>
          </Box>
        </Box>
      </Backdrop>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDynamicConfirmModal}
        setShowDynamicConfirmModal={setShowDynamicConfirmModal}
        zIndex={100004}
        leftAlignText={true}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        onConfirm={async () => {
          setIsSaving(true);
          await saveDealOwners(dealOwners, (successful) => {
            if (successful) {
              setIsSaving(false);
              setShowDynamicConfirmModal(false);
              // setDealOptionsOpen(false);
              // setAnchorEl(null);

              // Update deal owners' original values with newly updated values
              setDealOwners({
                ...dealOwners,
                originalStageID: dealOwners.currentStageID,
                originalSetterID: dealOwners.setterID,
                originalCloserID: dealOwners.closerID,
              });

              setTimeout(() => {
                onTriggerRefetch();
              }, 500);
            }
          });

          GamifyToast.success('Deal updated successfully');
        }}
        onCancel={() => {
          // Will want the OwnerDealStageModal to open again after cancelling from DynamicConfirmModal
          setShowDynamicConfirmModal(false);
          setShowOwnerDealStageModal(true);
        }}
        isSaving={isSaving}
      ></DynamicConfirmModal>
      <DynamicConfirmModal
        showDynamicConfirmModal={showCloserRequiredModal}
        setShowDynamicConfirmModal={setShowCloserRequiredModal}
        zIndex={100008}
        leftAlignText={true}
        title={`A closer is required.`}
        subtitle={'Please select a closer and try again.'}
        confirmButtonText={'OK'}
        onConfirm={() => {
          // Close the modal after confirmation to get back to the OwnerDealStageModal
          setShowCloserRequiredModal(false);
        }}
        noCancelButton={true}
        isSaving={isSaving}
      ></DynamicConfirmModal>
    </>
  );
}
