import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Grid,
  Card,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import DealListRepository from '../../repository/DealListRepository';
// import DealRepository from '../../repository/DealRepository';
import fetchDealStages from './fetchDealStages';
import fetchPeople from '../teams/fetchPeople';
import fetchCirclesOrPeople from './fetchCirclesOrPeople';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DealItem from './Item';
// import DealFormModal from './DealFormModal';
import './Index.css';
// import StyledTextField from '../../components/StyledTextField';
// import SelectStage from '../../components/Select/SelectStage';
// import SelectCircleOrPerson from '../../components/Select/SelectCircleOrPerson';
import {
  SearchInput,
  // StyledSelect,
  // StyledMenuItem,
} from '../users/StyledInputs';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import {
  AddIcon,
  PreviewIcon,
  SortIcon,
  SearchIcon,
  // DollarIcon,
  // ProfilesIcon,
  DeleteIcon,
  EllipsisIcon,
} from '../../components/GamifyIcon';
import DealCard from './DealCard';
import DealsListSkeleton from './DealsListSkeleton';
import BetaIcon from '../../common/BetaIcon';

export default function DealsList(props) {
  console.log('props: ', props);
  // console.log('useParams: ', useParams());
  // console.log('using useParams()');
  const params = useParams();
  // console.log('params: ', params);
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(
    searchParams.get('userId')
      ? searchParams.get('userId')
      : global.me.isAdmin
      ? 'all'
      : global.me.id
  );
  const [searchStage, setSearchStage] = useState(
    searchParams.get('stageId') ? searchParams.get('stageId') : 'all'
  );
  const [categoryId, setCategoryID] = useState(
    searchParams.get('categoryId') ? searchParams.get('categoryId') : ''
  );
  // const [dealDispositions, setDealDispositions] = useState([]);
  // console.log('userId: ', userId);
  // console.log('searchStage: ', searchStage);
  // console.log('categoryId (deal type id): ', categoryId);
  const [deals, setDeals] = useState([]);
  const [dealsNextToken, setDealsNextToken] = useState(null);
  const [dealTypes, setDealTypes] = useState([]);
  const [dealTypesCategoryIdMap, setDealTypesCategoryIdMap] = useState({});
  const [dealTypesConfigMap, setDealTypesConfigMap] = useState({});
  const [dealID, setDealID] = useState(null);
  const [dealStages, setDealStages] = useState([]);
  const [dealOwners, setDealOwners] = useState([]);
  const [triggerDealsRefetch, setTriggerDealsRefetch] = useState(0);
  const [triggerSelectedDealRefetch, setTriggerSelectedDealRefetch] =
    useState(0);
  const [stageTitle, setStageTitle] = useState('');
  const [fromDealPage, setFromDealPage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [addDealOpen, setAddDealOpen] = useState(false);
  // NOTE: for GG-1039, the 'Owner' filter was updated to the 'Who' filter to match mobile, the dealOwner key is for this filter
  const [dealFilter, setDealFilter] = useState({
    dealType: 'all', // TO DO: Is all even possible? Default to first valid dealType in dealTypes if not.
    dealSort: 'date-desc',
    dealStage: 'all',
    dealOwner: 'all',
  });
  const [sortOpen, setSortOpen] = useState(false);
  const [newestSort, setNewestSort] = useState(true);
  const [oldestSort, setOldestSort] = useState(false);
  const [aZSort, setAZSort] = useState(false);
  const [zASort, setZASort] = useState(false);
  const [ownerAZSort, setOwnerAZSort] = useState(false);
  const [ownerZASort, setOwnerZASort] = useState(false);
  // const [selectedDealOptionsOpen, setSelectedDealOptionsOpen] = useState(false);
  const [selectedDealMetaDataOpen, setSelectedDealMetaDataOpen] =
    useState(false);
  // const [showNewDealFormModal, setShowNewDealFormModal] = useState(false);
  // const [showEditDealFormModal, setShowEditDealFormModal] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const navigate = useNavigate();

  console.log('searchStage: ', searchStage);

  const onTriggerSelectedDealRefetch = () => {
    setTriggerSelectedDealRefetch((refetch) => refetch + 1);
  };

  const onTriggerDealsRefetch = () => {
    setTriggerDealsRefetch((refetch) => refetch + 1);
  };

  const resetPaginator = () => {
    setFromDealPage(0);
    setMaxPage(0);
  };

  useEffect(() => {
    setDealID(params.dealId);
  }, [params?.dealId]);

  const handleAddDealClick = () => {
    setAddDealOpen(!addDealOpen);
    setSortOpen(false);
    // setSelectedDealOptionsOpen(false);
  };

  const handleNewDeal = (
    e,
    dealType,
    dealTypesCategoryIdMap,
    dealTypesConfigMap
  ) => {
    e.preventDefault();

    // NOTE: A modal is used now, so no need to navigate to /pipeline/new now
    // setShowNewDealFormModal(true);
    navigate('/pipeline/new', {
      // TO DO: add in other props for DealFormPage, see CreateDealScreen component in lyferize MyDealScreen.tsx for reference
      state: {
        dealType,
        dealTypesCategoryIdMap,
        dealTypesConfigMap,
      },
    });
  };

  const handleClearSearch = () => {
    setSearchString('');
    resetPaginator();
    getData(
      userId,
      searchStage,
      categoryId,
      'date-desc',
      searchString,
      null,
      true,
      fromDealPage * 25
    );
  };

  // NOTE: Do NOT use for Autocomplete components as e is different, this function works with Select components
  const handleDealFilterChange = (e) => {
    e.preventDefault();
    setDealFilter((p) => ({ ...p, [e.target.name]: e.target.value }));
    // console.log('***deal filter: ', dealFilter);

    resetPaginator();
  };

  const handleSortClick = () => {
    setSortOpen(!sortOpen);
    setAddDealOpen(false);
    // setSelectedDealOptionsOpen(false);
  };

  const handleNewestFirstClick = () => {
    setNewestSort(true);
    setOldestSort(false);
    setAZSort(false);
    setZASort(false);
    setOwnerAZSort(false);
    setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'date-desc' }));

    resetPaginator();
  };

  const handleOldestFirstClick = () => {
    setOldestSort(true);
    setNewestSort(false);
    setAZSort(false);
    setZASort(false);
    setOwnerAZSort(false);
    setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'date-asc' }));

    resetPaginator();
  };

  const handleAZClick = () => {
    setAZSort(true);
    setNewestSort(false);
    setOldestSort(false);
    setZASort(false);
    setOwnerAZSort(false);
    setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'name-asc' }));

    resetPaginator();
  };

  const handleZAClick = () => {
    setZASort(true);
    setNewestSort(false);
    setOldestSort(false);
    setAZSort(false);
    setOwnerAZSort(false);
    setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'name-desc' }));

    resetPaginator();
  };

  const handleOwnerAZClick = () => {
    setOwnerAZSort(true);
    setNewestSort(false);
    setOldestSort(false);
    setAZSort(false);
    setZASort(false);
    setOwnerZASort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'owner-asc' }));

    resetPaginator();
  };

  const handleOwnerZAClick = () => {
    setOwnerZASort(true);
    setNewestSort(false);
    setOldestSort(false);
    setAZSort(false);
    setZASort(false);
    setOwnerAZSort(false);
    setSortOpen(false);

    setDealFilter((prev) => ({ ...prev, dealSort: 'owner-desc' }));

    resetPaginator();
  };

  const handleDealSelection = (e, dealId) => {
    e.preventDefault();
    setSelectedDealMetaDataOpen(false);
    // setSelectedDealOptionsOpen(false);
    navigate(`/pipeline/${dealId}`);
  };

  const capitalizeFirstLetter = (word) => {
    const n = word.length;

    if (n < 1) return word;

    return `${word.slice(0, 1).toUpperCase()}${word.slice(1, n)}`;
  };

  const cancelIconAdornment =
    searchString.length > 0 ? (
      <Button
        className={'clear-search-button'}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleClearSearch}
        disableRipple={true}
      >
        <InputAdornment position="end">
          <DeleteIcon></DeleteIcon>
        </InputAdornment>
      </Button>
    ) : (
      <></>
    );

  // console.log('searchString: ', searchString);

  async function getData(
    userId,
    searchStage,
    categoryID,
    sortOrder,
    searchString,
    nextToken,
    resetList,
    from
    // dealDispositions
  ) {
    DealListRepository.fetchDeals(
      userId,
      searchStage,
      categoryID,
      sortOrder,
      searchString,
      nextToken,
      resetList,
      from
      // dealDispositions
    ).then((_deals) => {
      // console.log('_deals: ', _deals);
      if (!_deals || _deals.length !== 0) {
        const tempDeals = _deals[0];

        // console.log('tempDeals: ', tempDeals);

        setDeals(tempDeals);
        // console.log('_deals[1] Stage Title: ', _deals[1]);
        setStageTitle(_deals[1]);
        // console.log('_deals[2] Deals Next Token: ', _deals[2]);
        setDealsNextToken(_deals[2]);

        const tempStageCategories = _deals[3];
        const dealTypesFromStageCategories = [];
        const mapDealTypeToCategoryId = {};

        if (tempDeals.length > 0) {
          for (let i = 0; i < tempStageCategories.length; i++) {
            if (tempStageCategories[i].enableDeal) {
              dealTypesFromStageCategories.push(tempStageCategories[i].title);
              mapDealTypeToCategoryId[tempStageCategories[i].title] =
                tempStageCategories[i].id;
            }
          }
        }

        // Conditional is needed to prevent losing those 4 deal types states... only set on original query when category id is ''
        if (tempStageCategories.length > 0) {
          // console.log('deal types SHOULD BE SETTING: ', tempStageCategories);
          setDealTypes(dealTypesFromStageCategories);
          setDealTypesCategoryIdMap(mapDealTypeToCategoryId);
          setDealTypesConfigMap(_deals[4]);
        }

        // // NO LONGER NEEDED
        // setDealDispositions(_deals[4]);
      }
      setIsLoaded(true);
      setPaginationLoading(false);
    });
  }

  // TO DO: use dealTypesCAtegoryIdMap in the deal Type filter
  // console.log('deal types: ', dealTypes);
  // console.log('deal types cat id map: ', dealTypesCategoryIdMap);
  // console.log('deal types config map: ', dealTypesConfigMap);
  // console.log('deal dispositions: ', dealDispositions);
  // console.log('deal filter: ', dealFilter);

  // needed to set default deal type if more than one enabled deal type
  async function getDealTypes() {
    const _dealTypes = await DealListRepository.fetchDealTypes();
    const [defaultCategoryID, stageCategories] = _dealTypes;

    for (const stageCategory of stageCategories) {
      if (stageCategory.id === defaultCategoryID) {
        setCategoryID(defaultCategoryID);
        setDealFilter((p) => ({
          ...p,
          dealType: stageCategory.title,
        }));
        break;
      }
    }

    return defaultCategoryID;
  }

  function Paginator(props) {
    const { currentPage, setPage, showNextButton } = props;
    const rows = [];
    let displayedRows = [];
    for (let i = 0; i <= maxPage; i++) {
      rows.push(
        <Button
          key={i}
          className={
            currentPage === i ? 'pagination-active' : 'pagination-inactive'
          }
          onClick={() => {
            setPage(i);
          }}
        >
          {currentPage === i && paginationLoading ? (
            <CircularProgress
              style={{ color: '#5f27cd', width: 16, height: 16 }}
            ></CircularProgress>
          ) : (
            <Typography
              className={
                currentPage === i
                  ? 'pagination-active-text'
                  : 'pagination-inactive-text'
              }
            >
              {i + 1}
            </Typography>
          )}
        </Button>
      );
    }

    //  TO DO: reset rows after every filter change
    //  rows state variable --> infinite loop that keeps filling rows state w/ first page buttons...
    //  rows outside paginator scope and reset in filter change use effect --> each button is put into rows twice b/c rendering cycle?
    //  try switching to an array of nextTokens instead?
    if (rows.length > 5) {
      if (currentPage > rows.length - 4) {
        displayedRows = [
          rows[0],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#5F27CD'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 4],
          rows[rows.length - 3],
          rows[rows.length - 2],
          rows[rows.length - 1],
        ];
      } else if (currentPage < 3) {
        displayedRows = [
          rows[0],
          rows[1],
          rows[2],
          rows[3],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#5F27CD'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 1],
        ];
      } else {
        displayedRows = [
          rows[0],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#5F27CD'}></EllipsisIcon>
          </Box>,
          rows[currentPage - 1],
          rows[currentPage],
          rows[currentPage + 1],
          <Box
            key={'ellipsis'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EllipsisIcon color={'#5F27CD'}></EllipsisIcon>
          </Box>,
          rows[rows.length - 1],
        ];
      }
    }
    return (
      <Box
        style={{ display: 'flex', flexDirection: 'row' }}
        className="pagination"
      >
        {currentPage > 0 && (
          <Button
            className={'pagination-previous-page-button'}
            onClick={() => {
              setPage(currentPage - 1);
            }}
          >
            &laquo;
          </Button>
        )}
        {rows.length <= 5 ? rows : displayedRows}
        {showNextButton && (
          <Button
            className={'pagination-next-page-button'}
            onClick={() => {
              setPage(currentPage + 1);
            }}
          >
            &raquo;
          </Button>
        )}
      </Box>
    );
  }

  // Default value for Stage filter
  let defaultStage = '';
  for (const stage of dealStages) {
    if (stage.id === searchStage) {
      defaultStage = stage.label;
      break;
    }
  }

  // Default value for Owner filter
  let defaultOwner = '';
  if (global.me.isAdmin) {
    defaultOwner = 'All ' + global.appSettings.labels.person.plural;
  } else {
    defaultOwner = global.me.name;
  }

  useEffect(() => {
    async function prepareFilters() {
      // NOTE: getDealTypes sets default deal type in dealFilter's type key and sets categoryID to the defaultCategoryID when more than one possible deal type
      const tempDefaultCategoryID = await getDealTypes();

      const tempDealStages = await fetchDealStages(
        tempDefaultCategoryID,
        null, // showAll
        true, // activeOptiom
        true, // allOption
        null, // minSequence
        true // ignoreNoDealStages
      );

      const allOption = global.me.isAdmin;
      const useChildCirclesList = true;

      if (global.me.isAdmin) {
        const tempPeople = await fetchPeople('', allOption);
        setDealOwners(tempPeople);
      } else {
        let pickUsers, pickCircles, useCirclesList;

        if (!global.appSettings.features.deal.disableUserFilter) {
          pickUsers = true;
        }
        if (!global.appSettings.features.deal.disableCircleFilter) {
          pickCircles = true;
          useCirclesList = true;
        }

        const tempCirclesOrPeople = await fetchCirclesOrPeople(
          '',
          allOption,
          pickUsers,
          pickCircles,
          useCirclesList,
          useChildCirclesList
        );
        setDealOwners(tempCirclesOrPeople);
      }

      setCategoryID(tempDefaultCategoryID);
      setDealStages(tempDealStages);
    }
    prepareFilters();

    // console.log('!!!userId: ', userId);
    // // BELOW NOT NEEDED, other useEffect below is activated by searchString/dealFilter setting on first render
    // getData(
    //   userId,
    //   searchStage,
    //   categoryId,
    //   // 'date-desc',
    //   dealFilter.dealSort,
    //   '',
    //   null,
    //   true,
    //   fromDealPage * 25,
    //   dealDispositions
    // );
  }, []);

  useEffect(() => {
    async function prepareDeals() {
      await getData(
        userId,
        searchStage,
        // categoryId,
        dealTypesCategoryIdMap[dealFilter.dealType]
          ? dealTypesCategoryIdMap[dealFilter.dealType]
          : categoryId, // pass in corresponding category id from deal type filter if it exists, otherwise use categoryId (for now it is always '' until searchParams implemented)
        // 'date-desc',
        dealFilter.dealSort,
        searchString,
        null,
        true,
        fromDealPage * 25
        // dealDispositions
      );
    }

    prepareDeals();
  }, [searchString, dealFilter, triggerDealsRefetch]);

  // console.log('!!!deals: ', deals);
  // for (let i = 0; i < deals.length; i++) {
  //   console.log(`!!!!!!DEAL DISPLAY ${i}: `, deals[i].display);
  // }

  // console.log('!!!searchParams: ', searchParams);
  // console.log('!!!stageTitle: ', stageTitle);

  if (!isLoaded) {
    return <DealsListSkeleton></DealsListSkeleton>;
  }
  return (
    <>
      {/* NOTE: all will not be an option for dealType in dealFilter as it doesn't make sense to combine deals across dealTypes/CRMs, it starts as 'all' until it can fetch the first enabled dealType/CRM */}
      {/* NOTE: Also need dealTypesConfigMap to be populated to avoid error in FlexFields */}
      {dealFilter.dealType !== 'all' &&
        Object.keys(dealTypesConfigMap).length > 0 && (
          <>
            {/* NOTE: Until issue with resetting modal form after new deal creation and clicking into text inputs without tabbing twice for editing deals is resolved, the deal form will not be a modal */}
            {/* <DealFormModal
              showDealFormModal={showNewDealFormModal}
              setShowDealFormModal={setShowNewDealFormModal}
              onTriggerDealsRefetch={onTriggerDealsRefetch}
              onTriggerSelectedDealRefetch={onTriggerSelectedDealRefetch}
              dealId={null}
              dealType={dealFilter.dealType}
              dealTypesCategoryIdMap={dealTypesCategoryIdMap}
              dealTypesConfigMap={dealTypesConfigMap}
              zIndex={100000}
            ></DealFormModal>
            {dealID && (
              <DealFormModal
                showDealFormModal={showEditDealFormModal}
                setShowDealFormModal={setShowEditDealFormModal}
                onTriggerDealsRefetch={onTriggerDealsRefetch}
                onTriggerSelectedDealRefetch={onTriggerSelectedDealRefetch}
                dealId={dealID}
                dealType={dealFilter.dealType}
                dealTypesCategoryIdMap={dealTypesCategoryIdMap}
                dealTypesConfigMap={dealTypesConfigMap}
                zIndex={100000}
              ></DealFormModal>
            )} */}
          </>
        )}
      <Box className={'deal-list-container'}>
        <Box className={'deal-list-overlay-left'}>
          <Box className={'deal-title-and-add-deal-section'}>
            <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Typography className={'deal-title'}>Pipeline</Typography>
              <Box style={{ marginLeft: 16, marginBottom: 16 }}>
                <BetaIcon></BetaIcon>
              </Box>
            </Box>

            <Button
              disableRipple={true}
              onClick={(e) =>
                dealTypes.length > 1
                  ? handleAddDealClick()
                  : handleNewDeal(
                      e,
                      dealTypes[0],
                      dealTypesCategoryIdMap,
                      dealTypesConfigMap
                    )
              }
              className={'add-deal-button'}
            >
              <AddIcon></AddIcon>
              New deal
            </Button>
            {addDealOpen && (
              <Box className={'add-deal-menu-container'}>
                {dealTypes.map((dealType, i, arr) => {
                  if (i === 0) {
                    return (
                      <Box key={i} className={'add-deal-menu-first-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) =>
                            handleNewDeal(
                              e,
                              dealType,
                              dealTypesCategoryIdMap,
                              dealTypesConfigMap
                            )
                          }
                        >
                          {/* <DollarIcon></DollarIcon> */}
                          <Typography className={'add-deal-menu-text'}>
                            {dealType}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  } else if (i === arr.length - 1) {
                    return (
                      <Box key={i} className={'add-deal-menu-last-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) =>
                            handleNewDeal(
                              e,
                              dealType,
                              dealTypesCategoryIdMap,
                              dealTypesConfigMap
                            )
                          }
                        >
                          {/* <DollarIcon></DollarIcon> */}
                          <Typography className={'add-deal-menu-text'}>
                            {dealType}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  } else {
                    return (
                      <Box key={i} className={'add-deal-menu-middle-container'}>
                        <Button
                          disableRipple={true}
                          className={'add-deal-menu-button'}
                          onClick={(e) =>
                            handleNewDeal(
                              e,
                              dealType,
                              dealTypesCategoryIdMap,
                              dealTypesConfigMap
                            )
                          }
                        >
                          {/* <DollarIcon></DollarIcon> */}
                          <Typography className={'add-deal-menu-text'}>
                            {dealType}
                          </Typography>
                        </Button>
                      </Box>
                    );
                  }
                })}
              </Box>
            )}
          </Box>
          <Box
            className={'lead-search-overlay'}
            container
            item
            justifyContent="center"
          >
            <SearchInput
              id="leadSearch"
              name="leadSearch"
              // label="Search"
              placeholder="Search"
              type="text"
              fullWidth
              size="small"
              onChange={(key) => {
                // getData(
                //   userId,
                //   searchStage,
                //   categoryId,
                //   'date-desc',
                //   key.nativeEvent.target.value,
                //   null,
                //   true,
                //   fromDealPage * 25,
                //   dealDispositions
                // );
                setSearchString(key.nativeEvent.target.value);

                resetPaginator();
              }}
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" paddingLeft="0">
                    <SearchIcon></SearchIcon>
                  </InputAdornment>
                ),
                endAdornment: cancelIconAdornment,
              }}
              InputLabelProps={{
                shrink: false,
                marginLeft: '24px',
              }}
            />
          </Box>

          <Box
            className={'deal-list-settings-overlay'}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            // rowSpacing={2}
            gridTemplateColumns={
              dealTypes.length > 1
                ? 'repeat(3, minmax(0, 1fr))'
                : 'repeat(2, minmax(0, 1fr))'
            }
          >
            {dealTypes.length > 1 && (
              <Box className={'deal-list-settings-container'}>
                <Typography className={'deal-settings-title-text'}>
                  Type
                </Typography>
                <StyledSelect
                  // style={{ maxWidth: '157px' }}
                  // placeholder="HELLO!!"
                  onChange={(e) => handleDealFilterChange(e)}
                  value={dealFilter.dealType}
                  name="dealType"
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        boxShadow: '0px 4px 24px 0px #ababab5e;',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        paddingTop: '0px',
                        marginTop: '8px',
                      },
                    },
                  }}
                >
                  {dealTypes.map((opt, i) => (
                    <StyledMenuItem value={opt} key={i}>
                      {capitalizeFirstLetter(opt)}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </Box>
            )}
            <Box className={'deal-list-settings-container'}>
              <Typography className={'deal-settings-title-text'}>
                Stage
              </Typography>
              {!searchParams.get('stageId') && (
                <Box>
                  <>
                    {dealStages.length > 0 && (
                      <Autocomplete
                        id={'deal-autocomplete'}
                        classes={{
                          inputRoot: 'scorecard-dropdown-box',
                          paper: 'autocomplete-menu',
                        }}
                        sx={{ width: 270 }}
                        onChange={(e, val) => {
                          if (searchStage !== val.id) {
                            // LOG.debug ("@@stageFilter: ", val.id);
                            // console.log('***setSearchStage: ', val);

                            setDealFilter((p) => ({
                              ...p,
                              dealStage: val.id,
                            }));
                            setSearchStage(val.id);

                            resetPaginator();

                            // setSearchStageName(val.label);
                            // setIsLoaded(false);
                            // setDeals([]);
                            // getData(
                            //   userId,
                            //   val,
                            //   categoryId,
                            //   // 'date-desc',
                            //   dealFilter.dealSort,
                            //   searchString,
                            //   null,
                            //   true,
                            //   fromDealPage * 25
                            //   // dealDispositions
                            // );
                          }
                        }}
                        options={dealStages}
                        defaultValue={defaultStage}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  </>
                </Box>
              )}
              {/* NOTE: The following is an Autocomplete for selecting stages, but has the fetch built in so loads after the rest of the page... */}
              {/* {!searchParams.get('stageId') && (
              <SelectStage
                className={'my-stage'}
                categoryID={categoryId}
                ignoreNoDealStages={true}
                allOption={true}
                activeOption={true}
                filter={global.me.orgID}
                value={searchStage}
                fieldSetter={(value) => {
                  if (searchStage !== value) {
                    // LOG.debug ("@@stageFilter: ", value.id);
                    // console.log('***setSearchStage: ', value);
                    setSearchStage(value);
                    // setSearchStageName(value.label);
                    setIsLoaded(false);
                    setDeals([]);
                    getData(
                      userId,
                      value,
                      categoryId,
                      // 'date-desc',
                      dealFilter.dealSort,
                      searchString,
                      null,
                      true,
                      fromDealPage * 25
                      // dealDispositions
                    );
                  }
                }}
              />
            )} */}
            </Box>
            <Box className={'deal-list-settings-container'}>
              <Typography className={'deal-settings-title-text'}>
                Who
              </Typography>
              {!searchParams.get('userId') && (
                <Box>
                  <Autocomplete
                    id={'deal-autocomplete'}
                    classes={{
                      inputRoot: 'scorecard-dropdown-box',
                      paper: 'autocomplete-menu',
                    }}
                    sx={{ width: 270 }}
                    onChange={(e, val) => {
                      if (userId !== val.value) {
                        // LOG.debug ("@@stageFilter: ", val.id);
                        // console.log('***setSearchStage: ', val);

                        setDealFilter((p) => ({
                          ...p,
                          dealOwner: val.value,
                        }));
                        setUserId(val.value);

                        resetPaginator();

                        // setSearchStageName(val.label);
                        // setIsLoaded(false);
                        // setDeals([]);
                        // getData(
                        //   userId,
                        //   val,
                        //   categoryId,
                        //   // 'date-desc',
                        //   dealFilter.dealSort,
                        //   searchString,
                        //   null,
                        //   true,
                        //   fromDealPage * 25
                        //   // dealDispositions
                        // );
                      }
                    }}
                    options={dealOwners}
                    defaultValue={defaultOwner}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              )}
              {/* NOTE:  The following is an Autocomplete for selecting a person or team for the deal owner, but has the fetch built in so loads after the rest of the page... */}
              {/* {!searchParams.get('userId') && (
              <>
                {global.me.orgID === 'ercsp' && global.me.isAdmin ? (
                  <SelectCircleOrPerson
                    allOption={global.me.isAdmin}
                    pickUsers={true}
                    pickCircles={false}
                    useCirclesList={false}
                    useChildCirclesList={false}
                    filter={global.me.orgID}
                    value={userId}
                    fieldSetter={(id, name, type) => {
                      console.log(
                        '***id: ',
                        id + ', name: ' + name + ', type: ' + type
                      );
                      if (userId !== id) {
                        setUserId(id);
                        getData(
                          id,
                          searchStage,
                          categoryId,
                          // 'date-desc',
                          dealFilter.dealSort,
                          searchString,
                          null,
                          true,
                          fromDealPage * 25
                          // dealDispositions
                        );
                      }
                    }}
                  />
                ) : (
                  <SelectCircleOrPerson
                    allOption={global.me.isAdmin}
                    pickUsers={
                      global.me.isAdmin ||
                      (!global.me.isAdmin &&
                        !global.appSettings.features.deal.disableUserFilter)
                    }
                    pickCircles={
                      !global.appSettings.features.deal.disableCircleFilter
                    }
                    useCirclesList={true}
                    useChildCirclesList={true}
                    filter={global.me.orgID}
                    value={userId}
                    fieldSetter={(id, name, type) => {
                      console.log(
                        '***id: ',
                        id + ', name: ' + name + ', type: ' + type
                      );
                      if (userId !== id) {
                        setUserId(id);
                        getData(
                          id,
                          searchStage,
                          categoryId,
                          // 'date-desc',
                          dealFilter.dealSort,
                          searchString,
                          null,
                          true,
                          fromDealPage * 25
                          // dealDispositions
                        );
                      }
                    }}
                  />
                )}
              </>
            )} */}
            </Box>
          </Box>
          <Box className={'deal-count-and-sort-section-container'}>
            <Typography className={'deal-count'}>
              {deals.length}
              {dealsNextToken !== null && '+'} Results
            </Typography>
            <Button
              disableRipple={true}
              onClick={handleSortClick}
              className={'sort-deals-button'}
            >
              <SortIcon></SortIcon>
            </Button>
            {sortOpen && (
              <Box className={'sort-menu-container'}>
                <Box className={'sort-menu-first-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-top-button'}
                    onClick={(e) => handleNewestFirstClick(e)}
                  >
                    <Typography
                      className={
                        newestSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Newest first
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={(e) => handleOldestFirstClick(e)}
                  >
                    <Typography
                      className={
                        oldestSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Oldest First
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={(e) => handleAZClick(e)}
                  >
                    <Typography
                      className={
                        aZSort ? 'selected-sort-menu-text' : 'sort-menu-text'
                      }
                    >
                      From A-Z
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={(e) => handleZAClick(e)}
                  >
                    <Typography
                      className={
                        zASort ? 'selected-sort-menu-text' : 'sort-menu-text'
                      }
                    >
                      From Z-A
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-middle-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-button'}
                    onClick={(e) => handleOwnerAZClick(e)}
                  >
                    <Typography
                      className={
                        ownerAZSort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Owner From A-Z
                    </Typography>
                  </Button>
                </Box>
                <Box className={'sort-menu-last-container'}>
                  <Button
                    disableRipple={true}
                    className={'sort-menu-bottom-button'}
                    onClick={(e) => handleOwnerZAClick(e)}
                  >
                    <Typography
                      className={
                        ownerZASort
                          ? 'selected-sort-menu-text'
                          : 'sort-menu-text'
                      }
                    >
                      Owner From Z-A
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          {/* Filtered by stage */}
          {searchParams.get('stageId') && (
            <Typography
              className={'deal-list-title'}
              variant={'h2'}
              marginBottom={'5%'}
              align={'center'}
            >
              {stageTitle}
            </Typography>
          )}

          <Grid
            container
            className={'deal-list-column-container'}
            // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            // rowSpacing={2}
            // justifyContent="flex-start"
            width="100%"
          >
            <List className={'deal-list-column'}>
              {deals.map((deal, i) => (
                <Card
                  className={'deal-list-card'}
                  key={i}
                  onClick={(e) => {
                    handleDealSelection(e, deal.id);
                  }}
                >
                  <Box
                    className={
                      deal.id === dealID
                        ? 'deal-list-inside-box-selected'
                        : 'deal-list-inside-box'
                    }
                  >
                    <ListItem className={'deal-list-text-overlay'} key={i}>
                      <ListItemText
                        className={'deal-list-text'}
                        primary={
                          <DealCard
                            props={{
                              ...deal.display,
                              ourDeal: deal,
                              setAddDealOpen,
                              setSortOpen,
                              // setSelectedDealOptionsOpen,
                              // setShowEditDealFormModal,
                              getData,
                              onTriggerDealsRefetch,
                              triggerSelectedDealRefetch,
                              onTriggerSelectedDealRefetch,
                              userId,
                              searchStage,
                              dealType: dealTypes[0], // TO DO: Replace dealTypes[0] with dealType filter selection
                              dealTypesCategoryIdMap,
                              dealTypesConfigMap,
                              dealFilter,
                              categoryId,
                              searchString,
                              fromDealPage,
                              setIsLoaded,
                            }}
                          ></DealCard>
                        }
                      />
                    </ListItem>
                  </Box>
                </Card>
              ))}
            </List>
          </Grid>
          {/* Paginator only renders when there's a nextToken for page 2 when on page 1 */}
          {fromDealPage === 0 && dealsNextToken === null ? (
            <></>
          ) : (
            <Paginator
              showNextButton={dealsNextToken !== null}
              currentPage={fromDealPage}
              setPage={(val) => {
                // console.log(
                //   'pagination set page args: ',
                //   userId,
                //   searchStage,
                //   categoryId,
                //   searchString,
                //   dealsNextToken,
                //   val
                // );
                setPaginationLoading(true);
                getData(
                  userId,
                  searchStage,
                  categoryId,
                  // 'date-desc',
                  dealFilter.dealSort,
                  searchString,
                  dealsNextToken,
                  true,
                  val * 25
                  // dealDispositions
                );
                setFromDealPage(val);
                if (val > maxPage) {
                  setMaxPage(val);
                }
              }}
            />
          )}
        </Box>
        <Box className={'deal-list-overlay-right'}>
          {dealID ? (
            <Box className={'deal-selected-card'}>
              <DealItem
                // className={'deal-selected-card-item'}
                props={{
                  dealId: dealID,
                  searchStage,
                  searchUserId: userId,
                }}
                stateProps={{
                  setAddDealOpen,
                  setSortOpen,
                  // selectedDealOptionsOpen,
                  // setSelectedDealOptionsOpen,
                  selectedDealMetaDataOpen,
                  setSelectedDealMetaDataOpen,
                  // setShowEditDealFormModal,
                  getData,
                  // onTriggerDealsRefetch,
                  triggerSelectedDealRefetch,
                  onTriggerSelectedDealRefetch,
                  userId,
                  searchStage,
                  dealType: dealTypes[0], // TO DO: Replace dealTypes[0] with dealType filter selection
                  dealTypesCategoryIdMap,
                  dealTypesConfigMap,
                  dealFilter,
                  categoryId,
                  searchString,
                  fromDealPage,
                  setIsLoaded,
                }}
              />
            </Box>
          ) : (
            <Box className={'select-to-see-preview-container'}>
              <Box className={'preview-icon-container'}>
                <PreviewIcon></PreviewIcon>
              </Box>
              <Typography className={'preview-text'}>
                Select a deal to preview
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
