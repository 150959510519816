import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  CardMedia,
  // IconButton,
  // Table as MuiTable,
  // TableBody,
  // TableContainer,
  // TableHead,
  Avatar,
  Typography,
  CircularProgress,
  // Checkbox,
  // InputAdornment,
  // CircularProgress,
} from '@mui/material';
import { Text } from '@aws-amplify/ui-react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import fetchTeam from './fetchTeam';
import fetchTeamMembers from './fetchTeamMembers';
import fetchTeamLevelMaps from './fetchTeamLevelMaps';
// import { getCircle } from '../../../graphql/queries';
// import getUserAvatar from '../../common/utils/getUserAvatar';
import { BackIcon } from '../../components/GamifyIcon';
import AddMemberLinkTeamModal from './AddMemberLinkTeamModal';
import './teams.css';
import { GamifyToast } from '../../common/CustomToasts';
import TeamMemberCard from './TeamMemberCard';
import TeamPageSkeleton from './TeamPageSkeleton';
import NoTeamDisplay from './NoTeamDisplay';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import TeamFormModal from './TeamFormModal';

export default function TeamPage() {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log('TeamPage teamId: ', teamId);

  const [myTeam, setMyTeam] = useState(null);
  const [members, setMembers] = useState([]);
  const [teamLevelNumIdMap, setTeamLevelNumIdMap] = useState({});
  const [teamLevelNumNameMap, setTeamLevelNumNameMap] = useState({});
  const [subPage, setSubPage] = useState('about');
  const [showTeamFormModal, setShowTeamFormModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showLinkTeamModal, setShowLinkTeamModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMemberListLoading, setIsMemberListLoading] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const [triggerMemberRefetch, setTriggerMemberRefetch] = useState(0);

  const [routeBackToProfileAboutPage, setRouteBackToProfileAboutPage] =
    useState(false);
  const [userId, setUserId] = useState(null);

  // const fetchTeam = async () => {
  //   const res = await API.graphql({
  //     query: getCircle,
  //     variables: { id: teamId },
  //   });
  //   const teamRes = res.data.getCircle;

  //   // team members are set in fetchTeamMembers

  //   teamRes.imageFile = await getUserAvatar(
  //     teamRes.imageName,
  //     teamRes.imageType,
  //     true
  //   );

  //   // // this prevents team image updates when navigating to new team page from team tree
  //   // if (myTeam) {
  //   //   teamRes.imageFile = myTeam.imageFile;
  //   // } else {
  //   //   teamRes.imageFile = await getUserAvatar(
  //   //     teamRes.imageName,
  //   //     teamRes.imageType,
  //   //     true
  //   //   );
  //   // }

  //   if (teamRes.children.items.length > 0) {
  //     for (const childTeam of teamRes.children.items) {
  //       childTeam.imageFile = await getUserAvatar(
  //         childTeam.imageName,
  //         childTeam.imageType,
  //         true
  //       );
  //     }
  //   }
  //   setMyTeam(teamRes);
  // };

  // console.log('TeamPage myTeam: ', myTeam);
  // console.log('TeamPage members: ', members);
  // console.log('Team Page teamLevelNumIdMap: ', teamLevelNumIdMap);
  // console.log('Team Page teamLevelNumNameMap: ', teamLevelNumNameMap);

  const onTriggerRefetch = () => {
    setTriggerRefetch((refetch) => refetch + 1);
  };

  const onTriggerMemberRefetch = () => {
    setTriggerMemberRefetch((refetch) => refetch + 1);
  };

  useEffect(() => {
    if (!teamId) return;
    // Check location state to see if user came from the Teams subpage on the ProfileAboutPage
    if (location?.state?.profileAboutPage) setRouteBackToProfileAboutPage(true);
    // Check for the userId of the ProfileAboutPage navigated from
    if (location?.state?.userId) setUserId(location.state.userId);

    async function prepareTeam() {
      setIsLoading(true);
      const team = await fetchTeam(teamId);

      // Handle team userId
      if (!team) {
        setIsLoading(false);
        return;
      }

      setMyTeam(team);

      const teamMembers = await fetchTeamMembers(teamId);
      const activeMembers = teamMembers.filter(
        (member) => member.status === 'active'
      );

      setMembers(activeMembers);

      const [tempTeamLevelNumIdMap, tempTeamLevelNumNameMap] =
        await fetchTeamLevelMaps(global.me.orgID);
      setTeamLevelNumIdMap(tempTeamLevelNumIdMap);
      setTeamLevelNumNameMap(tempTeamLevelNumNameMap);
      // console.log('Team Page teamLevels: ', teamLevels);
      setIsLoading(false);
    }
    prepareTeam();
    // console.log(`ON page for -> ${teamId}`);
  }, [teamId, triggerRefetch]);

  // No need to refetch all the data when just team members are updated, separate useEffect to avoid rendering TeamPageSkeleton after updating team members
  useEffect(() => {
    async function prepareMembers() {
      setIsMemberListLoading(true);
      const teamMembers = await fetchTeamMembers(teamId);
      const activeMembers = teamMembers.filter(
        (member) => member.status === 'active'
      );

      setMembers(activeMembers);
      setIsMemberListLoading(false);
    }
    prepareMembers();
  }, [triggerMemberRefetch]);

  useEffect(() => {
    console.log(myTeam);
  }, [myTeam]);

  const handleBack = (e) => {
    e.preventDefault();
    if (routeBackToProfileAboutPage) {
      navigate(`/users/about/${userId}`);
    } else {
      navigate('/teams');
    }
  };

  const addMembersToTeam = async (users) => {
    // console.log('addMembersToTeam users: ', users);
    if (!users.length) return;

    const requests = [];
    for (const user of users) {
      requests.push(
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: teamId,
            personID: user.id,
            status: 'accepted',
            role: '',
          },
        })
      );
    }

    const allPromise = Promise.all(requests);

    allPromise
      .then(() => {
        onTriggerMemberRefetch();
      })
      .catch((error) => {
        console.error('addMembersToTeam error: ', error.message);
      });
  };

  const onSubmitMembers = async (newMembers) => {
    // console.log('onSubmitMembers newMembers: ', newMembers);
    // console.log('onSubmitMembers initialAcitveMembers: ', initialActiveMembers);
    setIsMemberListLoading(true);
    await addMembersToTeam(newMembers);

    const numNewMembers = newMembers.length - initialActiveMembers.length;

    setTimeout(() => {
      onTriggerMemberRefetch();
    }, 500);

    setTimeout(() => {
      if (numNewMembers === 0) {
        console.log('No new members added');
      } else if (numNewMembers === 1) {
        const newMemberArr = newMembers.filter(
          (newMember) =>
            !members.find((member) => {
              return member.user
                ? member.user.id === newMember.id
                : member.id === newMember.id;
            })
        );
        const newMember = newMemberArr[0]; // there will only be one elemet in the array since there's only one new member
        GamifyToast.success(`${newMember.name} added successfully`);
      } else {
        GamifyToast.success(`${numNewMembers} members added successfully`);
      }
      setIsMemberListLoading(false);
    }, 750);
  };

  // members are the initialActiveMembers... can change name back to initialMembers?
  const initialActiveMembers = members;

  const onSubmitChildTeams = async (teams) => {
    // console.log('onSubmitChildTeams teams: ', teams);
    const numNewTeams = teams.length - childTeams.length;

    // console.log('onSubmitChildTeams numNewTeams: ', numNewTeams);
    const parentTeamID = teamId;

    if (!teams.length) return;

    if (numNewTeams === 0) return;

    const requests = [];

    for (const team of teams) {
      requests.push(
        API.graphql({
          query: mutations.updateCircle,
          variables: {
            input: {
              id: team.id,
              parentCircleID: parentTeamID,
            },
          },
        })
      );
    }

    try {
      await Promise.all(requests);

      onTriggerRefetch();

      if (numNewTeams === 1) {
        GamifyToast.success(`Team linked successfully`);
      } else {
        GamifyToast.success(`${numNewTeams} teams linked successfully`);
      }
    } catch (error) {
      console.error('onSubmitChildTeams error: ', error.message);
    }
  };

  let childTeams = myTeam?.children?.items || [];

  // Filter out deleted childTeams
  childTeams = childTeams.filter((childTeam) => childTeam.isDeleted === false);

  if (isLoading)
    return (
      <TeamPageSkeleton
        routeBackToProfileAboutPage={routeBackToProfileAboutPage}
        userId={userId}
      ></TeamPageSkeleton>
    );

  // At a url with a userId for a user that doesn't exist
  if (!myTeam) return <NoTeamDisplay isDeleted={false}></NoTeamDisplay>;

  // At a url for a user that has been deleted
  if (myTeam.isDeleted) return <NoTeamDisplay isDeleted={true}></NoTeamDisplay>;

  return (
    <>
      <TeamFormModal
        showTeamFormModal={showTeamFormModal}
        setShowTeamFormModal={setShowTeamFormModal}
        onTriggerTeamsRefetch={onTriggerRefetch}
        teamId={teamId}
        zIndex={100000}
      ></TeamFormModal>
      <Box
        style={{
          // height: '50vh',
          height: 500,
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          // backgroundColor: 'red',
          background:
            'radial-gradient(circle at 50% 80%, #8785EA 0%, #A0C5F9 50%, #BDD4EF 100%)',
          display: 'flex',
          // flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'team-page-back-button'}
            onClick={(e) => handleBack(e)}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {routeBackToProfileAboutPage ? 'Back to user' : 'Back to teams'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            width: '40vw',
            height: '100%',
            padding: '15px 15px 45px 15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {/* <Box
              style={{
                // width: '100%',
                height: '250.75px',
                aspectRatio: '16/9',
                // objectFit: 'cover',
                backgroundImage: `url(${myTeam.imageFile})`,
                backgroundSize: '100% 100%',
                borderRadius: '8px',
              }}
            /> */}
          {/* Teams created on web get imageType 'image/<jpeg, png, etc...>', account for that */}
          {myTeam.avatarFile ? (
            myTeam.imageType === 'picture' ||
            myTeam.imageType.split('/')[0] === 'image' ? (
              <Box
                component={'img'}
                sx={{
                  // height: myTeam.description === '' ? '75%' : '70%',
                  width: 240,
                  height: 240,
                  borderRadius: 2000,
                  objectFit: 'cover',
                  // borderRadius: '8px',
                }}
                alt={`${myTeam.name} Team`}
                src={`${myTeam.avatarFile}`}
              />
            ) : (
              <CardMedia
                image={myTeam.imageName}
                component="img"
                sx={{
                  // borderRadius: '8px',
                  // height: myTeam.description === '' ? '75%' : '70%',
                  // width: 'fit-content',
                  width: 240,
                  height: 240,
                  borderRadius: 2000,
                  objectFit: 'cover',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#868686',
                fontSize: '96px',
                // height: myTeam.description === '' ? '75%' : '70%',
                // width: '250px',
                // borderRadius: '8px',
                height: 240,
                width: 240,
                borderRadius: 2000,
              }}
              variant="circular"
            >
              {myTeam.name[0].toUpperCase()}
            </Avatar>
          )}
          <Box style={{ display: 'flex' }}>
            <Text
              style={{
                fontSize: '36px',
                fontWeight: 700,
                marginTop: '16px',
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              {myTeam.name} Team
            </Text>
          </Box>
          <Text
            style={{
              fontSize: '18px',
              fontWeight: 400,
              whiteSpace: 'nowrap',
            }}
          >
            {myTeam.description}
          </Text>
        </Box>
        {/* Box below keeps team image, name, and description centered */}
        <Box
          style={{
            display: 'flex',
            minWidth: routeBackToProfileAboutPage ? 151.05 : 164.59,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '-30px',
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '30vw',
            minWidth: 275,
            justifyContent: 'space-around',
          }}
        >
          {/* Only render Edit, Add members, and Link team for Admin, Moderator, or Team Manager (don't think Team Owner Id exists in DB so can't check for Team Owner) */}
          {(global.me.isAdmin ||
            global.me.isModerator ||
            global.me.functionalRoles?.includes('manager')) &&
            Object.values({
              edit: {
                icon: '/images/Edit_Pencil.svg',
                title: 'Edit',
                clickFunction: function () {
                  // console.log('Clicked Edit');
                  setShowTeamFormModal(true);

                  // NOTE: No longer have TeamFormPage at a separate 'edit/<teamID>' route, now it is a modal
                  // navigate(`/teams/edit/${myTeam.id}`, {
                  //   // Indicate that user is editing from TeamPage so they reroute back here when cancelling or updating
                  //   state: { teamPage: true },
                  // });
                },
              },
              addPeople: {
                icon: '/images/add-people-icon.svg',
                title: 'Add members',
                clickFunction: function () {
                  // console.log('Clicked Add people');
                  setShowAddMemberModal(true);
                },
              },
              linkTeam: {
                icon: '/images/link-icon.svg',
                title: 'Link team',
                clickFunction: function () {
                  // console.log('Clicked Link Team');
                  setShowLinkTeamModal(true);
                },
              },
            }).map((button, i) => (
              <Box
                key={i}
                onClick={button.clickFunction}
                className="hov"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 92, // keep width uniform to stay centered
                }}
              >
                <Box
                  style={{
                    height: '56px',
                    width: '56px',
                    borderRadius: '50%',
                    boxShadow: '-1px 1px 4px 0 rgba(0, 0, 0, 0.1)',
                    // border: '1px solid red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={button.icon}
                    style={{ width: '50%', height: '50%' }}
                  />
                </Box>
                <Text
                  style={{ color: '#5F27CD', fontWeight: '600', fontSize: 14 }}
                >
                  {button.title}
                </Text>
              </Box>
            ))}
          <AddMemberLinkTeamModal
            title={'Add Members'}
            team={null} // not needed for adding members
            teamLevelNumIdMap={null} // not needed for adding members
            teamLevelNumNameMap={null} // not needed for adding members
            noAutoSelect={true}
            pickCircles={false}
            isLinkTeams={false}
            pickUsers={true}
            update={null}
            filter={global.me.orgID}
            itemValue={''}
            onSubmit={(newMembers) => onSubmitMembers(newMembers)}
            hideInitialChecked
            initialActiveMembers={initialActiveMembers}
            setShowModal={setShowAddMemberModal}
            showModal={showAddMemberModal}
          ></AddMemberLinkTeamModal>
          <AddMemberLinkTeamModal
            title={'Link Teams'}
            team={myTeam}
            teamLevelNumIdMap={teamLevelNumIdMap}
            teamLevelNumNameMap={teamLevelNumNameMap}
            noAutoSelect={true}
            pickCircles={true}
            isLinkTeams={true}
            pickUsers={false}
            update={null}
            filter={global.me.orgID}
            itemValue={''}
            onSubmit={(newMembers) => onSubmitChildTeams(newMembers)}
            hideInitialChecked
            initialActiveMembers={childTeams}
            setShowModal={setShowLinkTeamModal}
            showModal={showLinkTeamModal}
          ></AddMemberLinkTeamModal>
        </Box>
        <Box style={{ width: '40vw', height: '100%', marginTop: '4ch' }}>
          <Box style={{ borderBottom: '1px solid #868686', display: 'flex' }}>
            <Box
              className="team-page-section-button hov"
              style={{
                borderBottom:
                  subPage === 'about' ? '3px solid #5f27cd' : 'none',
                color: subPage === 'about' ? '#5f27cd' : '#868686',
              }}
              onClick={() => setSubPage('about')}
            >
              About
            </Box>

            <Box
              className="team-page-section-button hov"
              onClick={() => setSubPage('members')}
              style={{
                borderBottom:
                  subPage === 'members' ? '3px solid #5f27cd' : 'none',
                color: subPage === 'members' ? '#5f27cd' : '#868686',
              }}
            >{`Members (${members.length})`}</Box>
          </Box>
        </Box>
        <Box
          style={{
            width: '40vw',
            marginTop: '2ch',
          }}
        >
          {subPage === 'about' && myTeam && (
            <AboutCard
              team={myTeam}
              childTeams={childTeams}
              isMemberListLoading={isMemberListLoading}
            />
          )}
          {subPage === 'members' && myTeam && (
            <TeamMembers
              team={myTeam}
              members={members}
              isMemberListLoading={isMemberListLoading}
              setIsMemberListLoading={setIsMemberListLoading}
              onTriggerMemberRefetch={onTriggerMemberRefetch}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

function LinkedAboutItem({ item }) {
  const navigate = useNavigate();
  // console.log('TeamPage LinkedAboutItem item: ', item);

  const handleTeamTreeClick = (e, teamId) => {
    navigate(`/teams/${teamId}`);
  };

  return (
    <Button
      className={'linked-about-item-team-button'}
      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
      onClick={(e) => handleTeamTreeClick(e, item.id)}
    >
      {/* Account for image type to account for gifs */}
      {item.avatarFile ? (
        item.imageType === 'picture' ||
        item.imageType.split('/')[0] === 'image' ? (
          <Box
            component={'img'}
            sx={{
              height: '40px',
              width: '40px',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
            alt={`${item.name} Team`}
            src={`${item.avatarFile}`}
          />
        ) : (
          <CardMedia
            image={item.imageName}
            component="img"
            sx={{
              backgroundColor: 'black',
              borderRadius: '8px',
              height: 40,
              width: 40,
              objectFit: 'contain',
              margin: '5px 12px 5px 5px',
            }}
          />
        )
      ) : (
        <Avatar variant="rounded" style={{ borderRadius: 8 }}>
          {item.name[0]}
        </Avatar>
      )}
      <Typography
        style={{
          color: '#222428',
          fontWeight: 500,
          marginLeft: 8,
        }}
      >
        {item.name}
      </Typography>
    </Button>
  );
}

function LinkedTeamTree({ team, childTeams }) {
  // console.log('TeamPage LinkedTeamTree team: ', team);
  // console.log('TeamPage LinkedTeamTree childTeams: ', childTeams);
  return (
    <Box
      // style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      style={{ marginLeft: '45%', minWidth: 'fit-content' }}
    >
      <Box style={{ marginBottom: 12 }}>
        <LinkedAboutItem item={team}></LinkedAboutItem>
      </Box>

      {childTeams.map((childCircle, i) => (
        <Box
          key={i}
          style={{
            borderLeft: '1px solid #D0D2D8',
            marginLeft: 12,
            paddingTop: 12,
          }}
        >
          <Box style={{ marginLeft: 12 }}>
            <LinkedAboutItem item={childCircle}></LinkedAboutItem>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function AboutCard({ team, childTeams, isMemberListLoading }) {
  // console.log('AboutCard team: ', team);

  const [showTree, setShowTree] = useState(false);

  return (
    <>
      {isMemberListLoading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <CircularProgress style={{ color: 'black' }}></CircularProgress>
        </Box>
      )}
      <Box id="about-card">
        {[
          { label: 'Location', value: team.location || 'N/A' },
          {
            label: 'Visability',
            value: `Public - Approver: ${team.approverType}` || 'N/A',
          },
          // { label: 'Owner', value: team.level.name },
          { label: 'Parent Teams', value: team.parent?.name || 'None' },
          { label: 'Level', value: team.level.name },
        ].map((el, i) => (
          <Box
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 500,
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                color: '#222428',
                fontSize: 16,
                fontWeight: 500,
                textAlign: 'right',
                whiteSpace: 'nowrap',
              }}
            >
              {el.label + ':'}
            </Typography>
            <Typography
              style={{
                color: '#696975',
                fontSize: 16,
                fontWeight: 500,
                textAlign: 'right',
                whiteSpace: 'nowrap',
              }}
            >
              {el.value}
            </Typography>
          </Box>
        ))}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 500,
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              color: '#222428',
              fontSize: 16,
              fontWeight: 500,
              textAlign: 'right',
            }}
          >
            Linked Teams:
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {childTeams.length > 0 ? (
              <>
                {childTeams.map((childTeam, i) => (
                  <Typography
                    key={i}
                    style={{
                      color: '#696975',
                      fontSize: 16,
                      fontWeight: 500,
                      textAlign: 'right',
                      marginBottom: 8,
                    }}
                  >
                    {childTeam.name}
                  </Typography>
                ))}
                <Button
                  className={'show-tree-button'}
                  style={{ padding: 0 }}
                  onClick={() => setShowTree(!showTree)}
                  disableRipple={true}
                >
                  <Typography style={{ color: '#5F27CD', fontWeight: 500 }}>
                    {showTree ? 'Hide tree' : 'Show tree'}
                  </Typography>
                </Button>
              </>
            ) : (
              <Typography
                style={{
                  color: '#696975',
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: 'right',
                }}
              >
                None
              </Typography>
            )}
          </Box>
        </Box>
        {childTeams.length > 0 && showTree && (
          <LinkedTeamTree team={team} childTeams={childTeams}></LinkedTeamTree>
        )}
      </Box>
    </>
  );
}

function TeamMembers({
  team,
  members,
  isMemberListLoading,
  setIsMemberListLoading,
  onTriggerMemberRefetch,
}) {
  // console.log('TeamMembers members: ', members);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [checkedTeamMembers, setCheckedTeamMembers] = useState([]);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [isBulkRemove, setIsBulkRemove] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleTeamMemberCheck = (event, teamMember) => {
    if (event.target.checked) {
      setCheckedTeamMembers((prevCheckedTeamMembers) => [
        ...prevCheckedTeamMembers,
        teamMember,
      ]);
    } else {
      setCheckedTeamMembers((prevCheckedTeamMembers) =>
        prevCheckedTeamMembers.filter(
          (checkedTeamMember) => checkedTeamMember.id !== teamMember.id
        )
      );
    }
  };

  // console.log('TeamPage checkedTeamMembers: ', checkedTeamMembers);
  // console.log('TeamPage selectedTeamMember: ', selectedTeamMember);

  const onRemoveMembers = async (members = [], teamId, onComplete) => {
    // console.log('RemoveMembers members: ', members);
    if (!members.length) return;

    const numRemovedMembers = members.length;

    try {
      for (const member of members) {
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: teamId,
            personID: member.id,
            status: 'removed',
            role: '',
          },
        });
      }
    } catch (err) {
      console.error('onRemoveMember error: ', err);
      if (numRemovedMembers === 1) {
        GamifyToast.error(
          `We couldn't remove ${members[0].name}. Please try again later.`
        );
      } else {
        GamifyToast.error(
          `We couldn't remove ${members.length} members. Please try again later.`
        );
      }

      onComplete(false);
      return false;
    }

    // Success toast in MemberOptionMenu for non-bulk remove and DynamicConfirmModal for bulk remove
    onComplete(true);
    return true;
  };

  return (
    <>
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmRemove}
        setShowDynamicConfirmModal={setShowConfirmRemove}
        zIndex={100002}
        title={
          isBulkRemove
            ? checkedTeamMembers.length === 1
              ? `Remove ${checkedTeamMembers[0].name}?`
              : `Remove ${checkedTeamMembers.length} members?`
            : `Remove ${selectedTeamMember?.name}?`
        }
        subtitle={`You are able to add members back\nafter removal.`}
        // subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        // confirmButtonColor={'#FF5C77'}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={
          isBulkRemove
            ? () => {
                setIsBulkRemove(false);
                setShowConfirmRemove(false);
              }
            : () => {
                setShowConfirmRemove(false);
              }
        }
        onConfirm={() => {
          setIsSaving(true);
          onRemoveMembers(
            isBulkRemove ? checkedTeamMembers : [selectedTeamMember],
            team.id,
            (res) => {
              setIsSaving(false);
              if (res) {
                setShowConfirmRemove(false);

                setTimeout(() => {
                  onTriggerMemberRefetch();
                }, 750);

                GamifyToast.success(
                  isBulkRemove
                    ? checkedTeamMembers.length === 1
                      ? `${checkedTeamMembers[0].name} removed successfully`
                      : `${checkedTeamMembers.length} members removed successfully`
                    : `${selectedTeamMember.name} removed successfully`
                );

                if (isBulkRemove) {
                  // Clear checkedTeamMembers if a bulk team removal was done
                  setCheckedTeamMembers([]);
                } else {
                  // Otherwise, filter the one deleted selectedTeamMember from checkedTeamMembers
                  setCheckedTeamMembers((prevCheckedTeamMembers) =>
                    prevCheckedTeamMembers.filter(
                      (checkedTeamMember) =>
                        checkedTeamMember.id !== selectedTeamMember.id
                    )
                  );
                }
              }
            }
          );
        }}
      ></DynamicConfirmModal>
      <Box id="team-members-list">
        {isMemberListLoading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <CircularProgress style={{ color: 'black' }}></CircularProgress>
          </Box>
        )}
        {members.map((member, i) => (
          <TeamMemberCard
            key={i}
            team={team}
            member={member}
            setIsMemberListLoading={setIsMemberListLoading}
            onTriggerMemberRefetch={onTriggerMemberRefetch}
            onRemoveMembers={onRemoveMembers}
            selectedTeamMember={selectedTeamMember}
            setSelectedTeamMember={setSelectedTeamMember}
            setCheckedTeamMembers={setCheckedTeamMembers}
            handleTeamMemberCheck={handleTeamMemberCheck}
            checkedTeamMembers={checkedTeamMembers}
          ></TeamMemberCard>
        ))}
      </Box>
      {checkedTeamMembers.length > 0 && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: 116,
            // borderTop: '1px solid #D0D2D8',
            // marginTop: '24px',
          }}
        >
          <Box
            onClick={() => {
              setIsBulkRemove(true);
              setShowConfirmRemove(true);
            }}
            style={{
              // backgroundColor: '#FF5C77',
              backgroundColor: '#E35050',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 228,
              height: 52,
              padding: '24px 16px 24px 16px',
              borderRadius: 8,
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              style={{ color: 'white', fontSize: '16px', fontWeight: 700 }}
            >
              {checkedTeamMembers.length === 1
                ? `Remove (${checkedTeamMembers.length}) member`
                : `Remove (${checkedTeamMembers.length}) members`}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
