import React from 'react';
import Utilities from '../../../../common/Utilities';

interface Props {
  fullName: string;
  shortName: string;
  createdAt: string;
}

const Header = ({ fullName, shortName, createdAt }: Props) => {
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <div style={{ fontWeight: 700, fontSize: 18 }}>
        {shortName || 'GamifyBot'}
      </div>
      <div style={{ color: '#737373' }}>@{fullName}</div>
      <div>·</div>
      <div style={{ color: '#737373' }}>
        {Utilities.elapsedTimeSince(createdAt)}
      </div>
    </div>
  );
};

export default Header;
