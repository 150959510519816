import React, { useRef, useState } from 'react';
import {
  ButtonsContainer,
  CustomTextArea,
  DetailsContainer,
  FilePreviewCloseIcon,
  FilePreviewContainer,
  FilterPersonsList,
  FilterPersonsListItem,
  IconsContainer,
  InputAndActionsContainer,
  ViewContainer,
} from './EditPost.styles';
import Item from '../_types/Item';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import UserProfilePicture from '../../../components/UserProfilePicture';
import UpdatePostArgs from '../_types/UpdatePostArgs';
import { useAlert } from 'react-alert-with-buttons';
import { Icon } from '../FeedItem/contents/Actions/Actions.styles';
import GifSearch from '../GifSearch';

interface Props {
  post: UpdatePostArgs;
  me: Me;
  myPersons: MyPerson[];
  filePreview: any;
  openGif: boolean;
  onChangePost: (post: UpdatePostArgs) => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenCloseGif: () => void;
  onUpdatepost: () => Promise<void>;
  onRemoveFilePreview: () => void;
  feedItem: Item;
  onChangeIsEditing: (newIsEditing: boolean) => void;
}
const EditPostView = ({
  filePreview,
  me,
  myPersons,
  onChangePost,
  onOpenCloseGif,
  onUpdatepost,
  onUpload,
  openGif,
  post,
  onRemoveFilePreview,
  onChangeIsEditing,
}: Props) => {
  const uploadRef = useRef<any>();
  const [filteredMyPersons, setFilteredMyPersons] = useState<MyPerson[]>([]);
  const alert = useAlert();

  const handleTextChange = (value: string) => {
    onChangePost({ ...post, header: value });

    const lastWord = (value.split(' ').pop() || '').toLowerCase();

    if (lastWord.startsWith('@')) {
      setFilteredMyPersons(
        myPersons.filter((myPerson) =>
          myPerson.name.toLowerCase().includes(lastWord.slice(1))
        )
      );
    } else {
      setFilteredMyPersons([]);
    }
  };

  return (
    <>
      <ViewContainer>
        <UserProfilePicture imageName={me.imageName || ''} />
        <DetailsContainer>
          <CustomTextArea
            maxLength={255}
            value={post.header}
            onChange={(e) => handleTextChange(e.target.value)}
            placeholder={`What's happening?`}
          />
          {filePreview ? (
            <FilePreviewContainer>
              <FilePreviewCloseIcon
                onClick={(e) => {
                  e.stopPropagation();

                  alert.open({
                    message: 'Are you sure you wish to remove this attachment?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => {
                          onRemoveFilePreview();
                          onChangePost({
                            ...post,
                            imageName: '',
                            imageType: '',
                          });
                          uploadRef.current.value = '';
                          alert.close();
                        },
                        style: {
                          borderRadius: 16,
                          backgroundColor: '#5F27CD',
                          marginRight: 20,
                          color: '#fff',
                          fontWeight: 700,
                        },
                      },
                      {
                        label: 'No',
                        onClick: () => alert.close(),
                        style: {
                          borderRadius: 16,
                          backgroundColor: '#FF5C77',
                          color: '#fff',
                          fontWeight: 700,
                        },
                      },
                    ],
                  });
                }}
                src="/images/close.svg"
              />
              {post.imageType === 'video' ? (
                <video width="320" height="240" controls>
                  <source src={filePreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={filePreview} alt="Preview" width="200" />
              )}
            </FilePreviewContainer>
          ) : null}

          <InputAndActionsContainer>
            <IconsContainer>
              <input
                onChange={(e) => onUpload(e)}
                style={{ display: 'none' }}
                accept="image/*,video/*"
                type="file"
                ref={(ref) => (uploadRef.current = ref)}
              />
              <div style={{ position: 'relative' }}>
                <Icon
                  onClick={() => handleTextChange('@')}
                  src="/images/icon-at.svg"
                />
                {filteredMyPersons.length > 0 ? (
                  <FilterPersonsList>
                    {filteredMyPersons.map((myPerson) => (
                      <FilterPersonsListItem
                        onClick={() => {
                          const currentText = post.header;
                          const newText = currentText.replace(
                            /@\w*$/,
                            `@${myPerson.name} `
                          );
                          handleTextChange(newText);
                        }}
                        key={myPerson.id}
                      >
                        <UserProfilePicture imageName={myPerson.imageName} />
                        <div style={{ fontWeight: 600 }}>{myPerson.name}</div>
                      </FilterPersonsListItem>
                    ))}
                  </FilterPersonsList>
                ) : null}
              </div>

              <Icon
                onClick={() => uploadRef.current.click()}
                src="/images/icon-image.svg"
              />
              <div style={{ position: 'relative' }}>
                <Icon onClick={onOpenCloseGif} src="/images/icon-gif.svg" />
                <GifSearch
                  open={openGif}
                  onOpenClose={onOpenCloseGif}
                  onSelect={(gif: any) => {
                    onChangePost({
                      ...post,
                      imageName: gif.url,
                      imageType: 'gif_url',
                    });
                  }}
                />
              </div>
            </IconsContainer>

            <ButtonsContainer>
              <div
                onClick={() => onChangeIsEditing(false)}
                style={{
                  cursor: 'pointer',
                  borderRadius: 12,
                  backgroundColor: 'transparent',
                  border: '1px solid #5F27CD',
                  color: '#5F27CD',
                  fontWeight: 600,
                  padding: '10px 20px',
                  textAlign: 'center',
                }}
              >
                Cancel
              </div>
              <div
                onClick={onUpdatepost}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#5F27CD',
                  color: '#fff',
                  fontWeight: 600,
                  padding: '10px 20px',
                  borderRadius: 12,
                  textAlign: 'center',
                }}
              >
                Save
              </div>
            </ButtonsContainer>
          </InputAndActionsContainer>
        </DetailsContainer>
      </ViewContainer>
    </>
  );
};

export default EditPostView;
