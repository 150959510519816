import React from 'react';

// TO DO: Implement height and width like with UserIcon. Also implement backgroundColor where applicable like with AddIcon
const iconsDict = {
  question: (color) => <QuestionMarkIcon color={color} />,
  add: (color, backgroundColor) => (
    <AddIcon color={color} backgroundColor={backgroundColor} />
  ),
  ellipsis: (color) => <EllipsisIcon color={color} />,
  preview: (color) => <PreviewIcon color={color} />,
  search: (color) => <SearchIcon color={color} />,
  sort: (color) => <SortIcon color={color} />,
  dollar: (color) => <DollarIcon color={color} />,
  profiles: (color) => <ProfilesIcon color={color} />,
  delete: (color, backgroundColor) => (
    <DeleteIcon color={color} backgroundColor={backgroundColor} />
  ),
  back: (color) => <BackIcon color={color} />,
  about: (color) => <AboutIcon color={color} />,
  area: (color) => <AreaIcon color={color} />,
  kws: (color) => <KWsIcon color={color} />,
  power: (color) => <PowerIcon color={color} />,
  notes: (color) => <NotesIcon color={color} />,
  files: (color) => <FilesIcon color={color} />,
  calendar: (color) => <CalendarIcon color={color} />,
  x: (color) => <XIcon color={color} />,
  newX: (color) => <NewXIcon color={color} />,
  noResults: (color) => <NoResultsIcon color={color} />,
  rightArrow: (color) => <RightArrowIcon color={color} />,
  photo: (color) => <PhotoIcon color={color} />,
  user: (color, width, height) => (
    <UserIcon color={color} width={width} height={height} />
  ),
  users: (color) => <UsersIcon color={color} />,
  addUser: (color) => <AddUserIcon color={color} />,
  addImage: (color) => <AddImageIcon color={color} />,
  edit: (color) => <EditIcon color={color} />,
  link: (color) => <LinkIcon color={color} />,
  gear: (color) => <GearIcon color={color} />,
  role: (color) => <RoleIcon color={color} />,
  coins: (color) => <CoinsIcon color={color} />,
  send: (color) => <SendIcon color={color} />,
  trash: (color) => <TrashIcon color={color} />,
  check: (color) => <CheckIcon color={color} />,
  warning: (color) => <WarningIcon color={color} />,
  activateUser: (color) => <ActivateUserIcon color={color} />,
  deactivateUser: (color) => <DeactivateUserIcon color={color} />,
};

export function GamifyIcon({ icon, color, backgroundColor, width, height }) {
  if (iconsDict[icon]) {
    return iconsDict[icon](color, backgroundColor, width, height);
  } else {
    return iconsDict.question(color);
  }
}

export const QuestionMarkIcon = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 21.5V21.5C7.529 21.5 3.5 17.471 3.5 12.5V12.5C3.5 7.529 7.529 3.5 12.5 3.5V3.5C17.471 3.5 21.5 7.529 21.5 12.5V12.5C21.5 17.471 17.471 21.5 12.5 21.5Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 13.749V13.499C12.5 12.682 13.005 12.239 13.511 11.899C14.005 11.566 14.5 11.132 14.5 10.332C14.5 9.22703 13.605 8.33203 12.5 8.33203C11.395 8.33203 10.5 9.22703 10.5 10.332"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.499 16.5C12.361 16.5 12.249 16.612 12.25 16.75C12.25 16.888 12.362 17 12.5 17C12.638 17 12.75 16.888 12.75 16.75C12.75 16.612 12.638 16.5 12.499 16.5"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddIcon = ({ color, backgroundColor }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 25C6.372 25 1 19.628 1 13C1 6.372 6.372 1 13 1C19.628 1 25 6.372 25 13C25 19.628 19.628 25 13 25Z"
      fill={backgroundColor || '#5F27CD'}
      stroke={backgroundColor || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9987 7.6665V18.3332"
      stroke={color || 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3346 13.0002H7.66797"
      stroke={color || 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  // <svg
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M12 8V16"
  //     stroke={color || 'white'}
  //     strokeWidth="1.5"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M16 12H8"
  //     stroke={color || 'white'}
  //     strokeWidth="1.5"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
  //     stroke={color || 'white'}
  //     strokeWidth="1.5"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>
);

export const EllipsisIcon = ({ color }) => (
  <svg
    width="17"
    height="4"
    viewBox="0 0 17 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 2.64307C8.66421 2.64307 9 2.30728 9 1.89307C9 1.47885 8.66421 1.14307 8.25 1.14307C7.83579 1.14307 7.5 1.47885 7.5 1.89307C7.5 2.30728 7.83579 2.64307 8.25 2.64307Z"
      fill={color || 'white'}
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 2.64307C15.1642 2.64307 15.5 2.30728 15.5 1.89307C15.5 1.47885 15.1642 1.14307 14.75 1.14307C14.3358 1.14307 14 1.47885 14 1.89307C14 2.30728 14.3358 2.64307 14.75 2.64307Z"
      fill={color || 'white'}
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 2.64307C2.16421 2.64307 2.5 2.30728 2.5 1.89307C2.5 1.47885 2.16421 1.14307 1.75 1.14307C1.33579 1.14307 1 1.47885 1 1.89307C1 2.30728 1.33579 2.64307 1.75 2.64307Z"
      fill={color || 'white'}
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PreviewIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14H7"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 17H9"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 19.316V19.5C16 20.3284 15.3284 21 14.5 21H3.5C2.67157 21 2 20.3284 2 19.5V4.5C2 3.67157 2.67157 3 3.5 3H14.5C15.3284 3 16 3.67157 16 4.5V14"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 9H17.2918C17.7575 9 18.2169 9.10843 18.6334 9.31671L19.2874 9.64371C19.7544 9.87719 20.1523 10.2285 20.4419 10.6629L22 13"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 14L16.0019 13.997L13.6229 11.4763C13.3413 11.1778 12.9508 11.0061 12.5405 11.0002C12.1302 10.9942 11.735 11.1546 11.4448 11.4448V11.4448C10.9335 11.9561 10.8536 12.757 11.254 13.3592L14.2411 17.8526C14.7341 18.5943 15.4605 19.1502 16.3052 19.4322L21 21"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 14H16"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7.81918V9.5C9 9.77614 8.77614 10 8.5 10H5.5C5.22386 10 5 9.77614 5 9.5V7.78077C5 7.47698 5.1381 7.18966 5.37532 6.99989L6.35132 6.21912C6.71653 5.92696 7.23547 5.92696 7.60068 6.21912L8.62468 7.0383C8.8619 7.22807 9 7.51539 9 7.81918Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4355 19.48C15.8538 19.48 19.4355 15.8983 19.4355 11.48C19.4355 7.0617 15.8538 3.47998 11.4355 3.47998C7.01727 3.47998 3.43555 7.0617 3.43555 11.48C3.43555 15.8983 7.01727 19.48 11.4355 19.48Z"
      stroke={color || '#868686'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4359 21.4799L17.0859 17.1299"
      stroke={color || '#868686'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SortIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8L7 5L4 8"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 19V5"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 16L17 19L20 16"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 5V19"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DollarIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6V7.5"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18V16.5"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 14.255V14.255C9 15.498 10.007 16.505 11.25 16.505H12.893C14.056 16.505 15 15.562 15 14.398V14.398C15 13.432 14.343 12.59 13.406 12.355L10.594 11.65C9.657 11.415 9 10.573 9 9.607V9.607C9 8.443 9.943 7.5 11.107 7.5H12.75C13.993 7.5 15 8.507 15 9.75V9.75"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfilesIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9556 21.0038H5.04185C3.93639 21.0038 3.04102 20.1084 3.04102 19.003V7.99839C3.04102 6.89293 3.93639 5.99756 5.04185 5.99756H18.9556C20.0611 5.99756 20.9565 6.89293 20.9565 7.99839V19.003C20.9565 20.1084 20.0601 21.0038 18.9556 21.0038Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9991 8.99878C13.3807 8.99878 14.5001 10.1182 14.5001 11.4998C14.5001 12.8814 13.3807 14.0009 11.9991 14.0009C10.6175 14.0009 9.49805 12.8814 9.49805 11.4998C9.49805 10.1182 10.6175 8.99878 11.9991 8.99878"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4998 18.0026C16.3688 17.6734 16.1657 17.3773 15.9056 17.1362V17.1362C15.4664 16.728 14.8912 16.502 14.2909 16.502C13.2905 16.502 10.7054 16.502 9.70501 16.502C9.10476 16.502 8.53052 16.729 8.09034 17.1362V17.1362C7.83023 17.3773 7.62715 17.6734 7.49609 18.0026"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.77734 5.99759V4.99717C4.77734 3.89171 5.67272 2.99634 6.77818 2.99634H17.2175C18.323 2.99634 19.2184 3.89171 19.2184 4.99717V5.99759"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIcon = ({ color, backgroundColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={backgroundColor || '#D0D2D8'}
    />
    <path
      d="M15 9L9 15"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9L15 15"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BackIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8L10 12L14 16"
      stroke={color || '#060606'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AboutIcon = ({ color }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9924 3.00122V16.0066"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9963 16.0066V20.0083"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9945 3.00122C20.547 3.00122 20.9949 3.44912 20.9949 4.00164V15.0062C20.9949 15.5587 20.547 16.0066 19.9945 16.0066H6.98911C5.88408 16.0066 4.98828 16.9024 4.98828 18.0075V18.0075C4.98828 19.1125 5.88408 20.0083 6.98911 20.0083"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99219 20.0083H12.9947"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9961 20.0083H20.9978"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9922 18.5078H16.9939V22.5095L14.993 21.5091L12.9922 22.5095V18.5078Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.98828 18.0075V5.00205C4.98828 3.89702 5.88408 3.00122 6.98911 3.00122H19.9945"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AreaIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1853 7.41789C13.8398 8.07241 13.8398 9.13359 13.1853 9.78811C12.5308 10.4426 11.4696 10.4426 10.8151 9.78811C10.1606 9.13359 10.1606 8.07241 10.8151 7.41789C11.4696 6.76337 12.5308 6.76337 13.1853 7.41789"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4856 8.39001V8.39001C17.4856 9.88001 16.8236 11.292 15.6796 12.246L13.8806 13.745C13.1636 14.343 12.6276 15.128 12.3326 16.014L12.0006 17.01L11.6686 16.014C11.3736 15.129 10.8376 14.343 10.1206 13.745L8.32162 12.246C7.17663 11.292 6.51562 9.88001 6.51562 8.39001V8.39001V8.41801C6.51562 4.99601 9.34963 3.01001 12.0006 3.01001C14.6516 3.01001 17.4856 4.99601 17.4856 8.41801"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14.6079C4.591 15.2349 3 16.2979 3 17.5099C3 19.4429 7.029 21.0099 12 21.0099C16.971 21.0099 21 19.4429 21 17.5099C21 16.2979 19.409 15.2339 17 14.6079"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const KWsIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11.0097H12.0025"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.00851H9.00125"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50063 17.0122V17.0122C6.67198 17.0119 6.0003 16.3403 6 15.5116V15.5116C6.0003 14.683 6.67198 14.0113 7.50063 14.011V14.011C8.32927 14.0113 9.00095 14.683 9.00125 15.5116V15.5116C9.00095 16.3403 8.32927 17.0119 7.50063 17.0122V17.0122Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5002 17.2622H15.0013V21.0138H12.5002C12.224 21.0138 12 20.7898 12 20.5136V17.7624C12 17.4862 12.224 17.2622 12.5002 17.2622Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5041 13.0105H17.5049C17.7812 13.0105 18.0052 13.2344 18.0052 13.5107V21.0138H15.0039V13.5107C15.0039 13.2344 15.2279 13.0105 15.5041 13.0105Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5041 15.2615H20.5049C20.7812 15.2615 21.0052 15.4854 21.0052 15.7617V20.5137C21.0052 20.7899 20.7812 21.0139 20.5049 21.0139H18.0039V15.7617C18.0039 15.4854 18.2279 15.2615 18.5041 15.2615Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99859 21.0138H4.99693C3.8919 21.0138 2.99609 20.118 2.99609 19.013V5.00718C2.99609 3.90215 3.8919 3.00635 4.99693 3.00635H13.1719C13.7026 3.00635 14.2115 3.21715 14.5867 3.59238L17.4163 6.42198C17.7915 6.79721 18.0023 7.30613 18.0023 7.83679V10.0093"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PowerIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.967 3.01001L4.75 14.01H12L11.033 21.01L19.25 10.01H12L12.967 3.01001Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NotesIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13.01H16"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 17.01H16"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.01001H15.172C15.702 3.01001 16.211 3.22101 16.586 3.59601L19.414 6.42401C19.789 6.79901 20 7.30801 20 7.83801V19.01C20 20.115 19.105 21.01 18 21.01H6C4.895 21.01 4 20.115 4 19.01V5.01001C4 3.90501 4.895 3.01001 6 3.01001Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 8.01001H16C15.448 8.01001 15 7.56201 15 7.01001V3.01001"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.01001H11"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FilesIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 18.01V14.51C18 13.682 18.672 13.01 19.5 13.01V13.01C20.328 13.01 21 13.682 21 14.51V19.01C21 20.667 19.657 22.01 18 22.01V22.01C16.343 22.01 15 20.667 15 19.01V16.01"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16.01H7"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12.01H7"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8.01001H7"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.01001V5.01001C18 3.90501 17.105 3.01001 16 3.01001H5C3.895 3.01001 3 3.90501 3 5.01001V19.01C3 20.115 3.895 21.01 5 21.01H11"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CalendarIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2V6"
      stroke={color || '#222428'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 2V6"
      stroke={color || '#222428'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9H21"
      stroke={color || '#222428'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z"
      stroke={color || '#222428'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const XIcon = ({ color }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.06641 2.84229L3.06641 8.84229"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.06641 2.84229L9.06641 8.84229"
      stroke={color || 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NewXIcon = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1758 7.57629L8.17578 23.5763"
      stroke={color || '#0B0B0C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.17578 7.57629L24.1758 23.5763"
      stroke={color || '#0B0B0C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NoResultsIcon = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.246 15.2895V15.2895C25.246 20.7882 20.7887 25.2455 15.29 25.2455V25.2455C9.79132 25.2455 5.33398 20.7882 5.33398 15.2895V15.2895C5.33398 9.79083 9.79132 5.3335 15.29 5.3335V5.3335C20.7887 5.3335 25.246 9.79083 25.246 15.2895Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6673 26.6668L22.334 22.3335"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4215 12.1587L12.1602 18.42"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4215 18.42L12.1602 12.1587"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrowIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16L14 12L10 8"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhotoIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9979 21H4.00391C2.89891 21 2.00391 20.105 2.00391 19V5C2.00391 3.895 2.89891 3 4.00391 3H19.9979C21.1029 3 21.9979 3.895 21.9979 5V19C21.9979 20.105 21.1019 21 19.9979 21Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.41422 7.58579C10.1953 8.36684 10.1953 9.63317 9.41422 10.4142C8.63317 11.1953 7.36684 11.1953 6.58579 10.4142C5.80474 9.63317 5.80474 8.36684 6.58579 7.58579C7.36684 6.80474 8.63317 6.80474 9.41422 7.58579"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 15.9999L17.781 12.6249C17.35 12.2799 16.72 12.3499 16.375 12.7809L13.039 16.9509C12.694 17.3819 12.065 17.4519 11.633 17.1069L9.765 15.6119C9.34 15.2719 8.721 15.3339 8.372 15.7529L4 20.9999"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIcon = ({ color, width, height }) => (
  <svg
    width={width || '18'}
    height={height || '20'}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4749 2.52513C12.8417 3.89197 12.8417 6.10804 11.4749 7.47488C10.108 8.84172 7.89197 8.84172 6.52513 7.47488C5.15829 6.10804 5.15829 3.89197 6.52513 2.52513C7.89197 1.15829 10.108 1.15829 11.4749 2.52513"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 16.4998V17.4998C1 18.0518 1.448 18.4998 2 18.4998H16C16.552 18.4998 17 18.0518 17 17.4998V16.4998C17 13.4738 13.048 11.5078 9 11.5078C4.952 11.5078 1 13.4738 1 16.4998Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UsersIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7923 9.52368C21.5824 10.3137 21.5824 11.5946 20.7923 12.3846C20.0023 13.1747 18.7214 13.1747 17.9314 12.3846C17.1414 11.5946 17.1414 10.3137 17.9314 9.52368C18.7214 8.73364 20.0023 8.73364 20.7923 9.52368"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2024 5.91236C15.4189 7.12884 15.4189 9.10115 14.2024 10.3176C12.9859 11.5341 11.0136 11.5341 9.79713 10.3176C8.58065 9.10116 8.58065 7.12885 9.79713 5.91236C11.0136 4.69588 12.9859 4.69588 14.2024 5.91236"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.06871 9.52368C6.85874 10.3137 6.85874 11.5946 6.06871 12.3846C5.27868 13.1747 3.99779 13.1747 3.20776 12.3846C2.41773 11.5946 2.41773 10.3137 3.20776 9.52368C3.99779 8.73364 5.27868 8.73364 6.06871 9.52368"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0002 18.9998V17.9038C23.0002 16.5228 21.8812 15.4038 20.5002 15.4038H19.6992"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 18.9998V17.9038C1 16.5228 2.119 15.4038 3.5 15.4038H4.301"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3392 18.9999V17.3989C17.3392 15.4659 15.7722 13.8989 13.8392 13.8989H10.1602C8.22716 13.8989 6.66016 15.4659 6.66016 17.3989V18.9999"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddUserIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 20.0001C7 17.5001 9 15.6001 11.4 15.6001H16.5C19 15.6001 20.9 17.6001 20.9 20.0001"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0004 5.2C18.6004 6.8 18.6004 9.5 17.0004 11.1C15.4004 12.7 12.7004 12.7 11.1004 11.1C9.50039 9.5 9.50039 6.8 11.1004 5.2C12.7004 3.6 15.3004 3.6 17.0004 5.2"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12H6.5"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 14V10"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddImageIcon = ({ color }) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9775 31.749H9.97754C7.49204 31.749 5.47754 29.7345 5.47754 27.249V9.24902C5.47754 6.76352 7.49204 4.74902 9.97754 4.74902H27.9775C30.463 4.74902 32.4775 6.76352 32.4775 9.24902V27.249C32.4775 29.7345 30.463 31.749 27.9775 31.749Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9587 11.5179C16.9838 12.543 16.9838 14.2051 15.9587 15.2302C14.9336 16.2553 13.2715 16.2553 12.2464 15.2302C11.2213 14.205 11.2213 12.543 12.2464 11.5179C13.2715 10.4927 14.9336 10.4927 15.9587 11.5179"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.4775 15.4125C31.8025 15.3165 31.117 15.249 30.415 15.249C22.441 15.249 15.9775 21.7125 15.9775 29.6865C15.9775 30.3885 16.045 31.074 16.141 31.749"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36848 14.9039L16.9351 6.33727C17.586 5.68643 17.586 4.63143 16.9351 3.9806L16.0001 3.04477C15.3493 2.39393 14.2943 2.39393 13.6435 3.04477L5.07598 11.6123C4.95431 11.7339 4.85265 11.8739 4.77348 12.0264L2.58181 16.2731C2.21015 16.9939 2.98265 17.7673 3.70348 17.3964L7.95265 15.2081C8.10681 15.1281 8.24681 15.0264 8.36848 14.9039Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.9248 11.8247L8.15814 15.058"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LinkIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4447 12.7781L19.7777 10.4451C21.4957 8.72709 21.4957 5.94109 19.7777 4.22209V4.22209C18.0597 2.50409 15.2737 2.50409 13.5547 4.22209L11.2217 6.55509"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.88965 15.1101L15.1096 8.89014"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.55509 11.2222L4.22209 13.5552C2.50409 15.2732 2.50409 18.0592 4.22209 19.7782V19.7782C5.94009 21.4962 8.72609 21.4962 10.4451 19.7782L12.7781 17.4452"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GearIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.909 10.0906C14.9634 11.145 14.9634 12.8546 13.909 13.909C12.8546 14.9634 11.145 14.9634 10.0906 13.909C9.0362 12.8546 9.0362 11.145 10.0906 10.0906C11.145 9.0362 12.8546 9.0362 13.909 10.0906"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25036 12C5.25036 12.297 5.27736 12.594 5.31336 12.882L3.72536 14.124C3.37336 14.4 3.27736 14.893 3.50136 15.28L4.91336 17.723C5.13636 18.11 5.61036 18.273 6.02536 18.107L7.44736 17.536C7.72836 17.423 8.04136 17.468 8.29336 17.635C8.51336 17.781 8.74136 17.915 8.97736 18.035C9.24736 18.172 9.44336 18.417 9.48636 18.717L9.70336 20.23C9.76636 20.672 10.1454 21 10.5914 21H13.4084C13.8544 21 14.2334 20.672 14.2964 20.23L14.5134 18.718C14.5564 18.418 14.7544 18.171 15.0254 18.035C15.2604 17.917 15.4874 17.784 15.7064 17.639C15.9604 17.471 16.2744 17.423 16.5564 17.537L17.9754 18.107C18.3894 18.273 18.8634 18.11 19.0874 17.723L20.4994 15.28C20.7234 14.893 20.6274 14.399 20.2754 14.124L18.6874 12.882C18.7234 12.594 18.7504 12.297 18.7504 12C18.7504 11.703 18.7234 11.406 18.6874 11.118L20.2754 9.876C20.6274 9.6 20.7234 9.107 20.4994 8.72L19.0874 6.277C18.8644 5.89 18.3904 5.727 17.9754 5.893L16.5564 6.463C16.2744 6.576 15.9604 6.529 15.7064 6.361C15.4874 6.216 15.2604 6.083 15.0254 5.965C14.7544 5.829 14.5564 5.582 14.5134 5.282L14.2974 3.77C14.2344 3.328 13.8554 3 13.4094 3H10.5924C10.1464 3 9.76736 3.328 9.70436 3.77L9.48636 5.284C9.44336 5.583 9.24636 5.829 8.97736 5.966C8.74136 6.086 8.51336 6.221 8.29336 6.366C8.04036 6.532 7.72736 6.577 7.44636 6.464L6.02536 5.893C5.61036 5.727 5.13636 5.89 4.91336 6.277L3.50136 8.72C3.27736 9.107 3.37336 9.601 3.72536 9.876L5.31336 11.118C5.27736 11.406 5.25036 11.703 5.25036 12V12Z"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RoleIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5267 11.125V9.777C13.5267 8.934 12.8427 8.25 11.9997 8.25V8.25C11.1567 8.25 10.4727 8.934 10.4727 9.777V11.125"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 11.126H10.25C9.698 11.126 9.25 11.574 9.25 12.126V14.25C9.25 14.802 9.698 15.25 10.25 15.25H13.75C14.302 15.25 14.75 14.802 14.75 14.25V12.126C14.75 11.574 14.302 11.126 13.75 11.126Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11.242C20 15.61 16.843 19.704 12.52 20.928C12.182 21.024 11.818 21.024 11.48 20.928C7.157 19.705 4 15.61 4 11.242V7.21399C4 6.40199 4.491 5.66999 5.243 5.36299L10.107 3.37299C11.321 2.87599 12.681 2.87599 13.894 3.37299L18.758 5.36299C19.509 5.66999 20 6.40199 20 7.21399V11.242Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CoinsIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="7.49915"
      cy="8.33274"
      rx="5.00208"
      ry="2.0842"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5012 11.2505C12.5012 12.4016 10.2617 13.3347 7.49915 13.3347C4.73658 13.3347 2.49707 12.4016 2.49707 11.2505"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5031 8.33276C17.5031 9.48383 15.2636 10.417 12.501 10.417"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5012 8.33276V14.5854C12.5012 15.7364 10.2617 16.6696 7.49915 16.6696C4.73658 16.6696 2.49707 15.7364 2.49707 14.5854V8.33276"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5031 5.41479V11.6674C17.5031 12.8185 15.2636 13.7516 12.501 13.7516"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91586 6.25679C7.66837 6.04434 7.5179 5.74039 7.49902 5.41477C7.49902 4.26429 9.74162 3.33057 12.5011 3.33057C15.2606 3.33057 17.5032 4.26429 17.5032 5.41477C17.5032 6.56525 15.2606 7.49897 12.5011 7.49897C12.3594 7.49897 12.2177 7.49897 12.0843 7.49063"
      stroke={color || '#FFC43A'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9.5"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.53369 8.25293L9.50023 12.0001L8.53371 15.7473C8.43333 16.1365 8.57569 16.5479 8.89512 16.7919C9.21455 17.0358 9.64897 17.0648 9.99797 16.8654L16.9962 12.8684C17.3079 12.6904 17.5003 12.359 17.5003 12.0001C17.5003 11.6411 17.3079 11.3097 16.9962 11.1317L9.99794 7.13482C9.64894 6.9355 9.21453 6.96448 8.8951 7.2084C8.57568 7.45231 8.43331 7.86376 8.53369 8.25293Z"
      stroke={color || '#5F27CD'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5319 9H5.46794C4.89994 9 4.43994 8.54 4.43994 7.973V7.027C4.43994 6.46 4.89994 6 5.46794 6H18.5329C19.0999 6 19.5599 6.46 19.5599 7.027V7.972C19.5599 8.54 19.0999 9 18.5319 9V9Z"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9L17.153 19.166C17.066 20.203 16.2 21 15.16 21H8.84C7.8 21 6.933 20.203 6.847 19.166L6 9"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6L9.224 3.553C9.393 3.214 9.739 3 10.118 3H13.882C14.261 3 14.607 3.214 14.776 3.553L16 6"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12V18"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9999 12L14.5899 18"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99996 12L9.40996 18"
      stroke={color || 'red'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
      fill={color || '#01CEAC'}
      stroke={color || '#01CEAC'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 10L11 15L8 12"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3Z"
      fill={color || '#E35050'}
      stroke={color || '#E35050'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.5V7.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 16C11.861 16 11.749 16.112 11.75 16.25C11.75 16.388 11.862 16.5 12 16.5C12.138 16.5 12.25 16.388 12.25 16.25C12.25 16.112 12.138 16 11.999 16"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ActivateUserIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16.75"
      cy="17.75"
      r="4.25"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1794 16.9084L16.3944 18.6924L15.3213 17.6224"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15H7C4.79086 15 3 16.7909 3 19V20"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11"
      cy="7"
      r="4"
      stroke={color || '#323232'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeactivateUserIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7538 14.7434L13.7461 20.7564"
      stroke={color || '#DA2424'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="16.75"
      cy="17.75"
      r="4.25"
      stroke={color || '#DA2424'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15H7C4.79086 15 3 16.7909 3 19V20"
      stroke={color || '#DA2424'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11"
      cy="7"
      r="4"
      stroke={color || '#DA2424'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
