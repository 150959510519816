import React, { useState, useEffect } from 'react';
import FeedRepository from '../../repository/FeedRepository';
import FeedView from './Feed.view';
import Item from './_types/Item';
import { useGetUserInformationQuery } from '../../store/api/GraphQlQuery';
import remoteFeedItem from './feed-helpers/remoteFeedItem';
import { GamifyToast } from '../../common/CustomToasts';
import { useAlert } from 'react-alert-with-buttons';
import MyPerson from './_types/MyPerson';
import { LOG } from '../../common/Utilities';
import getMyPersons from './feed-helpers/getMyPersons';

const FeedContainer = () => {
  const [feedList, setFeedList] = useState<Item[]>([]);
  const [nextToken, setNextToken] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const [isLoadingGetCircles, setIsLoadingGetCircles] = useState(true);
  const alert = useAlert();
  const [myPersons, setMyPersons] = useState<MyPerson[]>([]);
  const [shouldStopFetching, setShouldStopFetching] = useState(false);

  const fetch = async (resetList: boolean) => {
    const response = await FeedRepository.fetchFeed(
      feedList,
      0,
      'feed',
      false,
      new Date(),
      nextToken,
      resetList
    );
    console.log('responsef', response);
    if (response !== undefined) {
      if (!(response[0] === null && response[1] === null)) {
        setNextToken(response[0]);
        setFeedList(response[1]);
      } else {
        setShouldStopFetching(true);
      }
    }
  };

  const onDeleteFeedItem = async (deleteFeedItem: Item) => {
    alert.open({
      message: 'Are you sure you wish to delete this post?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const newFeedList = feedList.filter(
              (feedItem) => feedItem.id !== deleteFeedItem.id
            );

            setFeedList(newFeedList);
            GamifyToast.success('Content deleted');
            alert.close();

            await remoteFeedItem({
              feedItemID: deleteFeedItem.activityFeed.id,
            });
          },
          style: {
            borderRadius: 16,
            backgroundColor: '#5F27CD',
            marginRight: 20,
            color: '#fff',
            fontWeight: 700,
          },
        },
        {
          label: 'No',
          onClick: () => alert.close(),
          style: {
            borderRadius: 16,
            backgroundColor: '#FF5C77',
            color: '#fff',
            fontWeight: 700,
          },
        },
      ],
    });
  };

  useEffect(() => {
    const refetch = async () => {
      setTimeout(async () => {
        if (shouldRefresh) {
          await fetch(true);
          setShouldRefresh(false);
        }
      }, 2000);
    };
    refetch();
  }, [shouldRefresh]);

  useEffect(() => {
    const fetchMyPersons = async () => {
      if (isLoadingGetUserInformation || !data) {
        return;
      }

      try {
        const myPersons = await getMyPersons({
          orgID: data.me.orgID,
        });

        setMyPersons(myPersons);
      } catch (error) {
        LOG.debug(error);
      } finally {
        setIsLoadingGetCircles(false);
      }
    };

    fetchMyPersons();
  }, [isLoadingGetUserInformation, data]);

  if (!data || isLoadingGetCircles) {
    return null;
  }
  return (
    <FeedView
      me={data.me}
      appSettings={data.appSettings}
      feedList={feedList}
      loadMore={fetch}
      refreshFeed={() => {
        setShouldRefresh(true);
      }}
      onDeleteFeedItem={onDeleteFeedItem}
      myPersons={myPersons}
      shouldStopFetching={shouldStopFetching}
    />
  );
};

export default FeedContainer;
