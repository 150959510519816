import React, { useEffect, useState } from 'react';
import RulesView from './Rules.view';
import NewGameState from '../../_types/NewGameState';
import { useGetUserInformationQuery } from '../../../../../store/api/GraphQlQuery';
import getKpis from '../../../games-helpers/getKpis';
import { LOG } from '../../../../../common/Utilities';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const RulesContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const { data: userData, isLoading } = useGetUserInformationQuery({});
  const [kpis, setKpis] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const fetchKpis = async () => {
      if (isLoading || !userData) {
        return;
      }

      try {
        const kpis = await getKpis({
          me: userData.me,
        });

        // Add default kpis
        // onChangeNewGameState({
        //   ...newGameState,
        //   KPIRuleID: kpis[0].value,
        //   tieKPIRuleID: kpis[1].value,
        // });
        setKpis(kpis);
      } catch (error) {
        LOG.error(error);
      }
    };

    fetchKpis();
  }, [newGameState.scope, isLoading]);

  if (isLoading || !userData) {
    return null;
  }

  return (
    <RulesView
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
      kpis={kpis}
    />
  );
};

export default RulesContainer;
