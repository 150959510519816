import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BackButton, ViewContainer } from './Rewards.styles';
import DisplayMedia from '../../components/DisplayMedia';
import { GamifyToast } from '../../common/CustomToasts';
import { API } from 'aws-amplify';
import { format, parse } from 'date-fns';

const RewardDetails = () => {
  const { state: reward } = useLocation();
  const navigate = useNavigate();
  const isSoldOut = reward.remainingCount <= 0;
  console.log('rewardf', reward);

  return (
    <ViewContainer>
      <BackButton
        onClick={() => {
          navigate('/rewards');
        }}
      >
        <img src="/images/back.svg" alt="back" width={10} height={10} />
        <div style={{ marginLeft: 20 }}>Back to rewards</div>
      </BackButton>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 30,
          marginTop: 40,
        }}
      >
        <div>
          {reward.imageName !== '' ? (
            <DisplayMedia
              width={500}
              height={500}
              imageName={reward.imageName}
              imageType={reward.imageType}
              objectFit="contain"
            />
          ) : (
            <div
              style={{
                fontWeight: 700,
                fontSize: 16,
                fontFamily: 'Space Grotesk',
                marginTop: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #D0D2D8',
                width: 300,
                height: 300,
                borderRadius: 10,
              }}
            >
              No Image Available
            </div>
          )}
        </div>
        <div style={{ width: 300, flexWrap: 'wrap', marginTop: 20 }}>
          <div
            style={{
              backgroundColor: '#F0F0F3',
              width: 'fit-content',
              height: 'fit-content',
              padding: '4px 12px',
              borderRadius: 20,
              display: 'flex',
              alignItems: 'center',
              gap: 6,
              fontSize: 12,
              fontWeight: 600,
              marginBottom: 6,
            }}
          >
            {reward.remainingCount}/{reward.limitCount}
          </div>
          <div style={{ fontWeight: 700, fontSize: 28, marginBottom: 6 }}>
            {reward.name}
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: '#696975',
              marginBottom: 14,
            }}
          >
            {reward.description}
          </div>
          <div style={{ fontWeight: 600, fontSize: 14, marginBottom: 30 }}>
            {formatPoints(reward.cost)}
          </div>
          <div
            style={{
              backgroundColor: isSoldOut ? '#737373' : '#5F27CD',
              color: 'white',
              fontWeight: 600,
              borderRadius: 6,
              padding: 10,
              textAlign: 'center',
              cursor: isSoldOut ? 'default' : 'pointer',
              fontSize: 14,
            }}
            onClick={() => {
              if (isSoldOut) return;

              onBuyReward(reward, 'buy', (res: any) => {
                if (res) {
                  GamifyToast.success('Reward Redeemed');
                  navigate('/rewards');
                }
              });
            }}
          >
            Buy now
          </div>
          {isSoldOut && (
            <div
              style={{
                fontSize: 14,
                fontWeight: 700,
                // color: '#ff5c77',
                color: '#E35050',
                marginTop: 4,
              }}
            >
              Sold out
            </div>
          )}
          <div
            style={{
              color: '#696975',
              fontWeight: 400,
              fontSize: 14,
              marginTop: 10,
            }}
          >
            Valid until{' '}
            {format(
              parse(reward.validTo, 'yyyy-MM-dd', new Date()),
              'MM/dd/yy'
            )}
          </div>
        </div>
      </div>
    </ViewContainer>
  );
};

async function onBuyReward(reward: any, action: any, onSuccess: any) {
  await onSuccess(await manageReward(reward, action));
}

async function manageReward(reward: any, action: any) {
  const acceptRewardRes = await API.graphql({
    query: acceptRewardMutation,
    variables: { rewardID: reward.id, action },
  });
  // @ts-ignore
  const res = JSON.parse(acceptRewardRes?.data?.acceptReward);
  if (res.errorMessage === 'success') {
    return true;
  } else {
    if (res.errorMessage === 'RewardLimitExhausted') {
      GamifyToast.error('Sorry, this reward is sold out.');
    } else if (res.errorMessage === 'UserLimitExhausted') {
      GamifyToast.error(
        'Sorry, you have already purchased your limit of this reward.'
      );
    } else if (res.errorMessage === 'NotEnoughCirclePoints') {
      GamifyToast.error(
        'Sorry, you do not have enough points to purchase this reward.'
      );
    } else if (res.errorMessage === 'NotEnoughOrgPoints') {
      GamifyToast.error(
        'Sorry, you do not have enough points to purchase this reward.'
      );
    } else {
      GamifyToast.error(
        'Sorry, we ran into an error.  Gamify support has been notified.'
      );
    }
    return false;
  }
}

function formatPoints(number: number) {
  if (number <= 0) return '';

  if (number === 1) {
    return number + ' point';
  } else {
    return number + ' points';
  }
}

const acceptRewardMutation = /* GraphQL */ `
  mutation AcceptReward($rewardID: String!, $action: String!) {
    acceptReward(rewardID: $rewardID, action: $action)
  }
`;

export default RewardDetails;
