import React from 'react';
import { Box, CardMedia, Stack, Typography, Card } from '@mui/material';
import Utilities from '../common/Utilities';
import './LeaderAvatar.css';
import '../routes/scorecard/Scorecard';
import { useNavigate } from 'react-router-dom';

export default function LeaderAvatar(props) {
  const user = props.leader;
  const imageCache = props.imageCache;
  const imageStyle = props.imageStyle || {};
  const avatarImageUrl = imageCache.hasImage(user)
    ? imageCache.obtainImageUrl(user)
    : '/images/default-avatar.png';
  const navigate = useNavigate();

  return (
    <Stack alignItems="center">
      <Box
        className={'avatar-box-overlay'}
        style={{ width: '100%', borderRadius: '50%' }}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Card
          className={'avatar-image-card-overlay'}
          style={{ borderRadius: '50%' }}
          onClick={() => {
            navigate('/profile/' + user.getId());
          }}
        >
          <CardMedia
            className={'avatar-image-card'}
            style={{
              width: '150px',
              height: '150px',
              borderRadius: '50%',
              boxShadow: '4px 8px 15px #827ffc',
              ...imageStyle,
            }}
            component="img"
            image={avatarImageUrl}
            title="Click Me"
            alt="Avatar"
          />
        </Card>
        <Box
          className="position-overlay"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {user.getPosition()}
        </Box>
      </Box>
      <Typography fontSize={Utilities.fontSizes}>
        {user.getFirstName()}
      </Typography>
    </Stack>
  );
}
