import React, { useState } from 'react';
import { Backdrop, Box } from '@mui/material';
import UserFormPage from './UserFormPage';

export default function UserFormModal(props) {
  const {
    showUserFormModal,
    setShowUserFormModal,
    onTriggerUsersRefetch,
    user,
    userId,
    zIndex = 10,
  } = props;

  // NOTE: This is for the extID alert modal for nivo
  const [focusCounter, setFocusCounter] = useState(0);

  //   console.log('TEST props: ', props);

  return (
    <Backdrop
      sx={{ zIndex }}
      open={showUserFormModal}
      onClick={(e) => {
        // e.stopPropagation();
        if (
          !e.target.closest('.user-form-backdrop') &&
          !e.target.closest('.MuiMenuItem-root')
        ) {
          setShowUserFormModal(false);

          // NOTE: Refocus counter reset to 0, so nivo admin user gets warned every first time they click in the extID field
          setFocusCounter(0);
        }
      }}
    >
      <Box
        className={'user-form-backdrop'}
        style={{
          width: 958,
          height: '70vh',
          borderRadius: 8,
          overflowY: 'auto',
        }}
      >
        <UserFormPage
          user={user}
          userId={userId}
          setShowUserFormModal={setShowUserFormModal}
          onTriggerUsersRefetch={onTriggerUsersRefetch}
          focusCounter={focusCounter}
          setFocusCounter={setFocusCounter}
          isModal={true}
        ></UserFormPage>
      </Box>
    </Backdrop>
  );
}
