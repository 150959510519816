import { API } from 'aws-amplify';

const saveMission = `
  mutation SaveMission($mission: AWSJSON!, $doFeedBlast: Boolean) {
    saveMission(mission: $mission, doFeedBlast: $doFeedBlast)
  }
`;

const saveGame = async (values: any) => {
  const saveMissionRes = await API.graphql({
    query: saveMission,
    variables: {
      mission: JSON.stringify(values),
      doFeedBlast: true,
    },
  });

  // @ts-ignore
  return saveMissionRes.data.saveMission;
};

export default saveGame;
