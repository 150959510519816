import React from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { GamifyIcon } from '../../../components/GamifyIcon';
import featureFlag from '../../../common/featureFlag';

export default function ProfileRewardCardsSkeleton() {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2ch',
        }}
      >
        {/* <Typography
          style={{ color: '#0B0B0C', fontSize: '16px', fontWeight: 600 }}
        >
          All rewards
        </Typography> */}
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box style={{ width: '33%', marginRight: 16 }}>
            <Typography>Status:</Typography>
            <Box
              style={{
                width: '100%',
                border: '1px solid #D0D2D8',
                borderRadius: 8,
                padding: 12,
              }}
            >
              <Skeleton
                variant={'rounded'}
                sx={{ bgcolor: 'black' }}
                width={40}
                height={23}
              ></Skeleton>
            </Box>
          </Box>
          {global.me.isAdmin && (
            <Box style={{ width: '33%' }}>
              <Typography>Person:</Typography>
              <Box
                style={{
                  width: '100%',
                  border: '1px solid #D0D2D8',
                  borderRadius: 8,
                  padding: 12,
                }}
              >
                <Skeleton
                  variant={'rounded'}
                  sx={{ bgcolor: 'black' }}
                  width={40}
                  height={23}
                ></Skeleton>
              </Box>
            </Box>
          )}
        </Box>
        {global.me.isAdmin && featureFlag('sendRewards') ? (
          <Button
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            style={{
              padding: 0,
              minWidth: 140,
            }}
            disableRipple={true}
          >
            <GamifyIcon icon={'send'}></GamifyIcon>
            <Typography
              style={{
                color: '#5F27CD',
                fontSize: '16px',
                fontWeight: 700,
                marginLeft: 7,
              }}
            >
              Send reward
            </Typography>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Box>
      {['', '', '', '', '', ''].map((reward, i) => {
        console.log(reward);
        return <RewardCardSkeleton key={i}></RewardCardSkeleton>;
      })}
    </>
  );
}

function RewardCardSkeleton() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: 15,
        border: '1px solid #D0D2D8',
        borderRadius: 8,
        marginBottom: 12,
      }}
    >
      <Box className={'rewards-list-item-left-container'}>
        <Skeleton
          variant={'circular'}
          sx={{ bgcolor: '#868686', margin: '5px 12px 5px 5px' }}
          width={40}
          height={40}
        ></Skeleton>
        <Box className={'rewards-list-item-left-text-container'}>
          <Skeleton
            variant={'rounded'}
            sx={{ bgcolor: '#222428', marginTop: '2px', marginBottom: '2px' }}
            width={115}
            height={20}
          ></Skeleton>
          <Skeleton
            variant={'rounded'}
            sx={{ bgcolor: '#868686', marginTop: '1px', marginBottom: '1px' }}
            width={55}
            height={19}
          ></Skeleton>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          height: 45,
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {/* TO DO: need color palette for approved, error, requested, denied, delivered, returned, shipped, and backordered */}
        <Box
          style={{
            backgroundColor: '#E2D8FF',
            padding: '4px 8px 4px 8px',
            borderRadius: '1000px',
          }}
        >
          <Skeleton
            style={{
              color: '#5F27CD',
              marginTop: '1px',
              marginBottom: '1px',
            }}
            width={56}
            height={16}
          ></Skeleton>
        </Box>
      </Box>
    </Box>
  );
}
