import React, { useEffect, useState } from 'react';
import CenteredProgress from '../components/CenteredProgress';
import { useGetUserInformationQuery } from '../store/api/GraphQlQuery';
import { Typography } from '@mui/material';
import './ApplicationStateBootstrap.css';
import { avatarUrl } from '../store/data/AvatarUrl';
import { useDispatch } from 'react-redux';
import UserProfile from '../domain/UserProfile';
import CenteredComponent from '../components/CenteredComponent';
import { useTranslation } from 'react-i18next';
import { getMyInfo } from '../common/utils/getMyInfo';
import { useAuthenticator } from '@aws-amplify/ui-react';

const ensureImageExists = async (url) => {
  return (await fetch(url)).ok ? url : null;
};

export default function ApplicationStateBootstrap(props) {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation('', {
    keyPrefix: 'applicationStateBootstrap',
  });
  const { data, isLoading, isError, refetch } = useGetUserInformationQuery();
  const [isImageFetched, setIsImageFetched] = useState(false);
  const userProfile = new UserProfile(data);
  const { signOut, authStatus } = useAuthenticator((context) => [
    context.signOut,
  ]);

  useEffect(() => {
    refetch();
  }, [authStatus]);

  getMyInfo().then((success) => {
    if (!success) {
      console.log('!!!SIGNOUT!!!!');
      signOut();
      alert(
        'A technical error caused you to be logged out.  Please refresh your browser and try again.  If the problem persists, contact Gamify Support.'
      );
      return (
        <CenteredComponent>
          <Typography>
            Technical error logging in. Please refresh your browser and try
            again. Please contact Gamify Support.
          </Typography>
        </CenteredComponent>
      );
    }
  });

  if (isLoading) {
    return <CenteredProgress />;
  } else if (isError) {
    return (
      <CenteredComponent>
        <Typography>{translation('loadingError')}</Typography>
      </CenteredComponent>
    );
  }

  userProfile
    .loadAvatar()
    .then((url) => ensureImageExists(url))
    .then((url) => dispatch(avatarUrl(url)))
    .then(() => setIsImageFetched(true))
    .catch((error) => console.log(error));

  if (!isImageFetched) {
    return <CenteredProgress />;
  }

  return props.children;
}
