import LoadedImage from './LoadedImage';
import { Storage } from 'aws-amplify';

export default class ImageMetadata {
  constructor(imageType, imageId) {
    this.imageType = imageType;
    this.imageId = imageId;
  }

  static get IMAGE_TYPE_PICTURE() {
    return 'picture';
  }

  static get IMAGE_TYPE_GIFTCARD() {
    return 'giftcard_url';
  }

  static get IMAGE_GIF() {
    return 'gif_url';
  }

  loadImage() {
    return new Promise((resolve) => {
      const onErrorLoadingImage = () => {
        console.log(
          `Could not resolve resource of type '${this.imageType}' with ID '${this.imageId}'`
        );
        resolve(null);
      };
      const loadImage = (url) => {
        const image = new Image();
        image.onload = () => resolve(new LoadedImage(this.imageId, image));
        image.onerror = onErrorLoadingImage;
        image.src = url;
      };

      if (this.isPictureImage()) {
        Storage.get(this.imageId).then(loadImage, onErrorLoadingImage);
      } else if (this.isGiftcardImage() || this.isGifUrl()) {
        loadImage(this.imageId);
      } else {
        onErrorLoadingImage();
      }
    });
  }

  getImageId() {
    return this.imageId;
  }

  isPictureImage() {
    return ImageMetadata.IMAGE_TYPE_PICTURE === this.imageType;
  }

  isGiftcardImage() {
    return ImageMetadata.IMAGE_TYPE_GIFTCARD === this.imageType;
  }

  isGifUrl() {
    return ImageMetadata.IMAGE_GIF === this.imageType;
  }
}
