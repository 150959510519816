import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import {
  Box,
  Button,
  Backdrop,
  Checkbox,
  Select,
  Switch,
  MenuItem,
  TextField,
} from '@mui/material';
// import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

import { createKPIRule, updateKPIRule } from '../../../graphql/mutations';
import gql from 'graphql-tag';
import './kpis.css';
import {
  SelectDisposition,
  SelectFlexField,
  SelectKPICategory,
  SelectStage,
} from '../../common/PickerSearch';
import FormulaWizard from './FormulaWizard';
// import { CheckBox } from '@mui/icons-material';

const canSubmit = (obj) => {
  let res = obj.title?.length && obj.abbreviation?.length;
  if (obj.triggerID === '' && !['manual', 'rule'].includes(obj.sourceType))
    res = false;
  return res;
};

export default function CreateGoalDefinition(props) {
  const stageMapping = {
    stage: 'Deal Stage',
    disposition: 'Disposition',
    contact_type: 'Contact Type',
    flexfield: 'Deal Field',
    manual: 'Manual',
    rule: 'Rule',
  };

  const newGoalTemp = {
    title: '',
    abbreviation: '',
    order: 0,
    orgID: props.orgID,
    sourceType: '',
    formula: '',
    triggerID: '',
    flexFields: null,
    categoryID: '',
    format: 'number',
    action: '',
    type: 'always',
    actionPastTense: '',
    valueUnit: '',
    valueUnitPlural: '',
    isHidden: false,
    isDeleted: false,
    timeframe: 'A',
  };
  const viewerRolesTemp = {
    admin: true,
    owner: true,
    moderator: true,
    everyone: true,
  };

  //   const [open, setOpen] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [ogKPIRule, setOgKPIRule] = useState(null);
  //   const [changes, setChanges] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [newKPIGoal, setNewKPIGoal] = useState(null);
  const [freeTextFormula, SetFreeTextFormula] = useState(false);
  const [formulaWizardOpen, setFormulaWizardOpen] = useState(false);
  const [dimensionsObj, setDimensionsObj] = useState({
    org: true,
    circle: true,
    person: true,
  });
  const [ViewerRolesObject, SetViewerRolesObject] = useState({
    scorecardViewerRoles: viewerRolesTemp,
    leaderboardViewerRoles: viewerRolesTemp,
  });
  const { closer } = props;

  const convertDimenssions = () =>
    Object.entries(dimensionsObj)
      .filter((el) => el[1])
      .map((el) => el[0]);

  const convertViewerRolesToObject = (rolesArray) => {
    const temp = { everyone: rolesArray.includes('everyone') };
    if (temp.everyone) {
      return { ...temp, admin: true, owner: true, moderator: true };
    }
    temp.admin = rolesArray.includes('admin');
    temp.owner = rolesArray.includes('owner');
    temp.moderator = rolesArray.includes('moderator');
    return temp;
  };
  const convertViewerRolesToArray = (viewrRoles) =>
    Object.entries(viewrRoles)
      .filter((el) => el[1])
      .map((el) => el[0]);

  useEffect(() => {
    if (props.ruleID) {
      fetchKPIRule();
    } else {
      setNewKPIGoal(newGoalTemp);
      setOgKPIRule({
        rule: newGoalTemp,
        dimenssions: dimensionsObj,
        ...ViewerRolesObject,
      });
    }
  }, []);

  useEffect(() => {
    if (!ogKPIRule) return;
    setSubmitReady(canSubmit(newKPIGoal));
  }, [newKPIGoal, dimensionsObj]);

  const autoAbbreviate = (title) => {
    if (!title.length) return '';
    const titleSplit = title.split(' ');

    const validWords = titleSplit.filter((el) => el.length > 0);

    if (validWords.join('').length < 3)
      return validWords.join('').toUpperCase();

    switch (validWords.length) {
      case 1:
        return validWords[0].slice(0, 3).toUpperCase();
      case 2:
        const [a, b] = validWords;
        const ab =
          a.length >= b.length
            ? (a.slice(0, 2) + b.slice(0, 1)).toUpperCase()
            : (a.slice(0, 1) + b.slice(0, 2)).toUpperCase();
        return ab;
      default:
        const newString = validWords.slice(0, 3).reduce((acc, el) => {
          acc += el[0].toUpperCase();
          return acc;
        }, '');
        return newString;
    }
  };

  const fetchKPIRule = async () => {
    const myQuery = gql`
      query GetKPIRule($id: ID!) {
        getKPIRule(id: $id) {
          id
          title
          abbreviation
          orgID
          order
          sourceType
          formula
          dimensions
          triggerID
          categoryID
          format
          scorecardViewerRoles
          leaderboardViewerRoles
          action
          type
          actionPastTense
          valueUnit
          valueUnitPlural
          isHidden
          isDeleted
          timeframe
        }
      }
    `;
    const res = await API.graphql({
      query: myQuery,
      variables: {
        id: props.ruleID,
      },
    });
    const fetchedKPI = res.data.getKPIRule;

    let ff = null;
    if (fetchedKPI.sourceType === 'flexfield') {
      const myQuery = gql`
        query MyQuery($orgID: ID!) {
          listStageCategorys(
            filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
            limit: 10000
          ) {
            items {
              id
              title
              flexAttributes
            }
          }
        }
      `;
      const listStageCategorysRet = await API.graphql({
        query: myQuery,
        variables: { orgID: fetchedKPI.orgID },
      });
      const categories = listStageCategorysRet.data.listStageCategorys.items;
      const assigneeList = [];
      const flexAttTemp = {};
      for (const category of categories) {
        if (!category.doNotShow) {
          flexAttTemp[category.id] = category.flexAttributes;
          assigneeList[assigneeList.length] = {
            id: category.id,
            value: category.id,
            label: category.title,
            flexAttributes: category.flexAttributes,
          };
        }
      }

      ff = flexAttTemp[fetchedKPI.categoryID];
    }
    // console.log('FETCHED KPI', fetchedKPI);
    setNewKPIGoal(() => ({
      ...fetchedKPI,
      flexFields: ff,
      format: fetchedKPI.format || '',
    }));
    setDimensionsObj(() => ({
      org: fetchedKPI.dimensions.includes('org'),
      circle: fetchedKPI.dimensions.includes('circle'),
      person: fetchedKPI.dimensions.includes('person'),
    }));
    let scorecardViewerRoles;
    let leaderboardViewerRoles;
    if (fetchedKPI.scorecardViewerRoles)
      scorecardViewerRoles = convertViewerRolesToObject(
        fetchedKPI.scorecardViewerRoles
      );
    else scorecardViewerRoles = viewerRolesTemp;
    if (fetchedKPI.leaderboardViewerRoles)
      leaderboardViewerRoles = convertViewerRolesToObject(
        fetchedKPI.leaderboardViewerRoles
      );
    else leaderboardViewerRoles = viewerRolesTemp;
    SetViewerRolesObject(() => ({
      leaderboardViewerRoles,
      scorecardViewerRoles,
    }));
    setOgKPIRule(() => ({
      rule: {
        ...fetchedKPI,
        flexFields: null,
        format: fetchedKPI.format || '',
      },
      dimenssions: {
        org: fetchedKPI.dimensions.includes('org'),
        circle: fetchedKPI.dimensions.includes('circle'),
        person: fetchedKPI.dimensions.includes('person'),
      },
    }));
  };

  const handleViewerRoleChange = (e) => {
    const val = e.currentTarget.getAttribute('name');

    const [type, role] = val.split('.');

    if (
      // (ViewerRolesObject[type].everyone && type !== 'everyone') ||
      role === 'everyone' &&
      !ViewerRolesObject[type][role]
    ) {
      SetViewerRolesObject((previous) => ({
        ...previous,
        [type]: viewerRolesTemp,
      }));
    } else {
      SetViewerRolesObject((previous) => ({
        ...previous,
        [type]: {
          ...previous[type],
          [role]: !previous[type][role],
        },
      }));
    }
  };

  const createNew = async () => {
    const submitObj = {
      ...newKPIGoal,
      dimensions: convertDimenssions(dimensionsObj),
      scorecardViewerRoles: convertViewerRolesToArray(
        ViewerRolesObject.scorecardViewerRoles
      ),
      leaderboardViewerRoles: convertViewerRolesToArray(
        ViewerRolesObject.leaderboardViewerRoles
      ),
    };
    delete submitObj.flexFields;
    if (['manual', 'rule'].includes(submitObj.sourceType))
      submitObj.triggerID = submitObj.sourceType;
    try {
      await API.graphql({
        query: createKPIRule,
        variables: { input: submitObj },
      });
    } catch (err) {
      console.log('ERROR');
      console.log(err);
      return false;
    }
    props.reloadKPIs();
    props.snackbarMessage(`KPI Rule Created - ${submitObj.title}`);
    props.closer(() => false);
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };
  const deleteKPIRule = async () => {
    const title = newKPIGoal.title;
    const deleteObj = {
      id: props.ruleID,
      isDeleted: true,
    };
    try {
      await API.graphql({
        query: updateKPIRule,
        variables: { input: deleteObj },
      });
    } catch (err) {
      console.log('deleteGoalDefinition(): error saving ', err);
      return false;
    }
    props.reloadKPIs();
    props.snackbarMessage(`KPI Rule Deleted - ${title}`);
    props.closer(() => false);
  };

  const updateKPI = async () => {
    const submitObj = {
      ...newKPIGoal,
      dimensions: convertDimenssions(dimensionsObj),
      scorecardViewerRoles: convertViewerRolesToArray(
        ViewerRolesObject.scorecardViewerRoles
      ),
      leaderboardViewerRoles: convertViewerRolesToArray(
        ViewerRolesObject.leaderboardViewerRoles
      ),
    };
    delete submitObj.flexFields;
    try {
      await API.graphql({
        query: updateKPIRule,
        variables: { input: submitObj },
      });
    } catch (err) {
      console.log('updateKPIRule(): error saving ', err);
      return false;
    }

    props.reloadKPIs();
    props.snackbarMessage(`KPI Rule Updated - ${submitObj.title}`);
    props.closer(() => false);
  };

  const submitHandler = () => {
    if (props.ruleID) {
      updateKPI();
    } else {
      createNew();
    }
  };

  if (!newKPIGoal) return null;
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.open}
    >
      <Box id="create-backdrop-box">
        <Box
          className="backdrop-close-button"
          onClick={() => closer(() => false)}
        >
          X
        </Box>
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5px',
          }}
          onClick={() => console.log(newKPIGoal)}
        >
          {props.ruleID ? 'EDIT KPI' : 'CREATE NEW KPI'}
        </Text>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ flex: '3' }}>
            <Text>Title</Text>
            <TextField
              placeholder="KPI Title"
              value={newKPIGoal.title}
              style={{ width: '100%' }}
              onChange={(e) => {
                const newTitle = e.target.value;
                setNewKPIGoal((prevKPIGoal) => ({
                  ...prevKPIGoal,
                  title: newTitle,
                  abbreviation: autoAbbreviate(newTitle),
                }));
              }}
            />
          </Box>
          <Box style={{ width: '40px' }} />
          <Box style={{ flex: '1' }}>
            <Text>Abbreviation</Text>
            <TextField
              placeholder="Abbreviation"
              value={newKPIGoal.abbreviation}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({ ...newKPIGoal, abbreviation: e.target.value })
              }
            />
          </Box>
          <Box style={{ width: '40px' }} />
          <Box style={{ flex: '0.5' }}>
            <Text>Order</Text>
            <TextField
              value={newKPIGoal.order}
              placeholder="Order"
              style={{ width: '100%', textAlign: 'center' }}
              onChange={(e) => {
                if (isNaN(e.target.value)) return;
                setNewKPIGoal({ ...newKPIGoal, order: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box id="select-inputs-box">
            <Box>
              <Text>Source Type</Text>
              <Select
                value={newKPIGoal.sourceType}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setNewKPIGoal({
                    ...newKPIGoal,
                    sourceType: e.target.value,
                    categoryID: '',
                    // flexFields: null,
                  })
                }
              >
                {[
                  'stage',
                  'disposition',
                  'contact_type',
                  'flexfield',
                  'manual',
                  'rule',
                ].map((opt, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid lightgray',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                    value={opt}
                  >
                    {stageMapping[opt]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {newKPIGoal.sourceType === 'stage' && (
              <React.Fragment>
                <Text>CRM Category</Text>
                <SelectKPICategory
                  orgID={props.orgID}
                  value={newKPIGoal.categoryID}
                  setter={setNewKPIGoal}
                />
                {newKPIGoal.categoryID !== '' && (
                  <React.Fragment>
                    <Box>
                      <Text>Deal Stage</Text>
                      <SelectStage
                        key={newKPIGoal.categoryID}
                        categoryID={newKPIGoal.categoryID}
                        value={newKPIGoal.triggerID}
                        // setter={setNewKPIGoal}
                        setter={(e) =>
                          setNewKPIGoal((p) => ({
                            ...p,
                            triggerID: e.target.value,
                          }))
                        }
                      />
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {newKPIGoal.sourceType === 'disposition' && (
              <React.Fragment>
                <Text>Select Disposition</Text>
                <SelectDisposition
                  categoryID={newKPIGoal.categoryID}
                  value={newKPIGoal.triggerID || ''}
                  //   setter={setNewKPIGoal}
                  setter={(e) =>
                    setNewKPIGoal((p) => ({ ...p, triggerID: e.target.value }))
                  }
                />
              </React.Fragment>
            )}
            {newKPIGoal.sourceType === 'contact_type' && (
              <Box>
                <Text>Contact Type</Text>
                <Select
                  style={{ width: '100%' }}
                  value={newKPIGoal.triggerID}
                  onChange={(e) =>
                    setNewKPIGoal({
                      ...newKPIGoal,
                      triggerID: e.target.value,
                    })
                  }
                >
                  {[
                    { label: 'Knock', value: 'knock' },
                    { label: 'Call', value: 'call' },
                    { label: 'Text', value: 'text' },
                    { label: 'Email', value: 'email' },
                    { label: 'Meeting', value: 'meeting' },
                  ].map((el, i) => (
                    <MenuItem
                      style={{ backgroundColor: 'white' }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = 'lightgray')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = 'white')
                      }
                      key={i}
                      value={el.value}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {newKPIGoal.sourceType === 'flexfield' && (
              <React.Fragment>
                <Text>CRM Category</Text>
                <SelectKPICategory
                  orgID={props.orgID}
                  value={newKPIGoal.categoryID}
                  setter={setNewKPIGoal}
                />
                {newKPIGoal.flexFields !== null && (
                  // {newKPIGoal.categoryID !== null && (
                  <React.Fragment>
                    <Text>Field</Text>
                    <SelectFlexField
                      flexFields={newKPIGoal.flexFields}
                      value={newKPIGoal.triggerID}
                      setter={setNewKPIGoal}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <Box>
              <Text>Update Mission</Text>
              <Select
                value={newKPIGoal.type}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setNewKPIGoal({ ...newKPIGoal, type: e.target.value })
                }
              >
                {['Always', 'First', 'Best', 'Goal'].map((el, i) => (
                  <MenuItem
                    key={i}
                    value={el.toLowerCase()}
                    style={{
                      backgroundColor: 'white',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Timeframe</Text>
              <Select
                value={newKPIGoal.timeframe}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setNewKPIGoal({ ...newKPIGoal, timeframe: e.target.value })
                }
              >
                {['All', 'Year', 'Month', 'Week', 'Day'].map((el, i) => (
                  <MenuItem
                    key={i}
                    value={el[0]}
                    style={{
                      backgroundColor: 'white',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Display Format</Text>
              <Select
                value={newKPIGoal.format}
                style={{ width: '100%' }}
                onChange={(e) =>
                  setNewKPIGoal({ ...newKPIGoal, format: e.target.value })
                }
              >
                {['Number', 'Money', 'Percent', 'Check'].map((el, i) => (
                  <MenuItem
                    key={i}
                    value={el.toLowerCase()}
                    style={{
                      backgroundColor: 'white',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box style={{ width: '40px' }} />
          <Box style={{ width: '50%' }}>
            <Box id="dimenssions-box">
              <Box className="single-switch-box">
                <Text>Apply To Org?</Text>
                <Switch
                  name="org"
                  checked={dimensionsObj.org}
                  onChange={(e) =>
                    setDimensionsObj((prev) => ({
                      ...prev,
                      [e.target.name]: !prev[e.target.name],
                    }))
                  }
                />
              </Box>
              <Box className="single-switch-box">
                <Text>Apply To Circle?</Text>
                <Switch
                  name="circle"
                  checked={dimensionsObj.circle}
                  onChange={(e) =>
                    setDimensionsObj((prev) => ({
                      ...prev,
                      [e.target.name]: !prev[e.target.name],
                    }))
                  }
                />
              </Box>
              <Box className="single-switch-box">
                <Text>Apply To Person?</Text>
                <Switch
                  name="person"
                  checked={dimensionsObj.person}
                  onChange={(e) =>
                    setDimensionsObj((prev) => ({
                      ...prev,
                      [e.target.name]: !prev[e.target.name],
                    }))
                  }
                />
              </Box>
              <Box className="single-switch-box">
                <Text>
                  Hide from Users
                  <br /> (Do not show in Leaderboards)?
                </Text>
                <Switch
                  checked={newKPIGoal.isHidden}
                  onChange={() =>
                    setNewKPIGoal((prev) => ({
                      ...prev,
                      isHidden: !prev.isHidden,
                    }))
                  }
                />
              </Box>
            </Box>
            {[
              ['Scorecard Viewer Roles', 'scorecardViewerRoles'],
              ['Leaderboard Viewer Roles', 'leaderboardViewerRoles'],
            ].map((title, i) => (
              <Box
                id="dimenssions-box"
                style={{ marginTop: '5px' }}
                key={title}
                // onClick={() => console.log(ViewerRolesObject)}
              >
                <Text
                  key={i}
                  // onClick={() =>
                  //   console.log(
                  //     convertViewerRolesToArray(ViewerRolesObject[title[1]])
                  //   )
                  // }
                >
                  {title[0]}
                </Text>
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {['admin', 'owner', 'moderator', 'everyone'].map((role) => (
                    <Box
                      key={`${title[1]}${role}`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{ color: 'gray' }}
                      >{`${role[0].toUpperCase()}${role.slice(1)}`}</Text>
                      <Checkbox
                        disabled={
                          ViewerRolesObject[title[1]].everyone &&
                          role !== 'everyone'
                        }
                        checked={ViewerRolesObject[title[1]][role]}
                        style={{ marginTop: '-8px' }}
                        onChange={handleViewerRoleChange}
                        name={`${title[1]}.${role}`}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '100%' }}>
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
              <Text>Action</Text>
              <div style={{ width: '8px' }} />
              <Text style={{ color: 'grey' }}>(ex: knock)</Text>
            </Box>
            <TextField
              type="text"
              placeholder="Action"
              value={newKPIGoal.action}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({ ...newKPIGoal, action: e.target.value })
              }
            />
          </Box>
          <Box style={{ width: '40px' }} />
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
              <Text>Action Past Tense</Text>
              <div style={{ width: '8px' }} />
              <Text style={{ color: 'grey' }}>(ex: knocked)</Text>
            </Box>
            <TextField
              type="text"
              placeholder="Action Past Tense"
              value={newKPIGoal.actionPastTense}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({
                  ...newKPIGoal,
                  actionPastTense: e.target.value,
                })
              }
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '100%' }}>
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
              <Text>Value Unit</Text>
              <div style={{ width: '8px' }} />
              <Text style={{ color: 'grey' }}>(ex: door)</Text>
            </Box>
            <TextField
              type="text"
              placeholder="Action"
              value={newKPIGoal.valueUnit}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({ ...newKPIGoal, valueUnit: e.target.value })
              }
            />
          </Box>
          <Box style={{ width: '40px' }} />
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex' }}>
              <Text>Value Unit Plural</Text>
              <div style={{ width: '8px' }} />
              <Text style={{ color: 'grey' }}>(ex: doors)</Text>
            </Box>
            <TextField
              type="text"
              placeholder="Action Past Tense"
              value={newKPIGoal.valueUnitPlural}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({
                  ...newKPIGoal,
                  valueUnitPlural: e.target.value,
                })
              }
            />
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <Text>Formula</Text>
          <Box style={{ display: 'flex' }}>
            <TextField
              type="text"
              placeholder={
                freeTextFormula
                  ? 'Write rule formula'
                  : 'Use Formula Wizard or Click Free Text to manually type rule formula'
              }
              value={newKPIGoal.formula}
              disabled={!freeTextFormula}
              style={{ width: '100%' }}
              onChange={(e) =>
                setNewKPIGoal({ ...newKPIGoal, formula: e.target.value })
              }
            />
            <Button
              id="formula-button"
              style={{ width: '200px' }}
              onClick={() => setFormulaWizardOpen(true)}
            >
              Formula Wizard
            </Button>
            <Button
              id="formula-button"
              style={{ width: '170px' }}
              onClick={() => SetFreeTextFormula((prev) => !prev)}
            >
              Free Text
            </Button>
          </Box>

          <Button
            disabled={!submitReady}
            variant="outlined"
            color="success"
            onClick={submitHandler}
            style={{
              padding: '10px 20px',
              margin: '15px 20px 0 0',
            }}
          >
            SAVE{props.ruleID ? ' CHANGES' : ''}
          </Button>
          {props.ruleID && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleDelete}
              style={{
                padding: '10px 20px',
                margin: '15px 20px 0 0',
              }}
            >
              DELETE
            </Button>
          )}
        </Box>
      </Box>
      <Backdrop
        open={confirmDelete}
        sx={{
          backgroundColor: 'rgba(0,0,0,0.8)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '10px',
          }}
        >
          <Text
            style={{ fontWeight: 'bold' }}
          >{`Are you sure you want to delete ${newKPIGoal.title}?`}</Text>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px',
            }}
          >
            <Button variant="outlined" color="error" onClick={deleteKPIRule}>
              Yes (Delete Rule)
            </Button>
            <Button variant="outlined" onClick={() => setConfirmDelete(false)}>
              No (Keep Rule)
            </Button>
          </Box>
        </Box>
      </Backdrop>
      <FormulaWizard
        open={formulaWizardOpen}
        closer={setFormulaWizardOpen}
        formula={newKPIGoal.formula}
        categoryID={newKPIGoal.categoryID}
        setFormula={setNewKPIGoal}
        rulesProp={props.rulesProp}
      />
    </Backdrop>
  );
}
