import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function TeamFormAddImageSkeleton(props) {
  const { isModal } = props;

  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: isModal ? 'flex-start' : 'center',
      }}
    >
      <Box
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#E2D8FF',
          // width: '100%',
          // height: 361,
          // borderRadius: 8,
          width: 240,
          height: 240,
          borderRadius: 2000,
          marginBottom: 24,
        }}
      >
        <CircularProgress style={{ color: '#5F27CD' }}></CircularProgress>
      </Box>
    </Box>
  );
}
