import React, { useState } from 'react';
import { useNavigate } from 'react-router';
// import { Box, Button, Typography } from '@mui/material';
// import { CustomMenu, CustomMenuItem } from '../teams/StyledComponent';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import { API, graphqlOperation } from 'aws-amplify';
// import { updateDeal } from '../../../graphql/mutations';
import * as mutations from '../../../graphql/mutations';
import { GamifyToast } from '../../common/CustomToasts';
import OwnerDealStageModal from './OwnerDealStageModal';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
// import DealFormModal from './DealFormModal';

export default function DealOptionsMenu({ props }) {
  const navigate = useNavigate();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showOwnerDealStageModal, setShowOwnerDealStageModal] = useState(false);
  // console.log('deal options menu props: ', props);
  const {
    // setDealOptionsOpen,
    anchorEl,
    setAnchorEl,
    // setShowEditDealFormModal,
    getData,
    // onTriggerDealsRefetch,
    triggerSelectedDealRefetch,
    onTriggerSelectedDealRefetch,
    userId,
    searchStage,
    dealType,
    dealTypesCategoryIdMap,
    dealTypesConfigMap,
    dealFilter,
    categoryId,
    searchString,
    fromDealPage,
    setIsLoaded,
    setter,
    closer,
  } = props;

  let currentOwnerID;
  let dealID;

  // for DealCard
  if (props.ourDeal) {
    currentOwnerID = props.ourDeal.currentOwnerID;
    dealID = props.ourDeal.id;
  }
  // for SelectedDeal
  if (props.currentOwnerID) {
    currentOwnerID = props.currentOwnerID;
    dealID = props.metaData.id;
  }

  // console.log('deal options dealID: ', dealID);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowConfirmDelete(true);
  };

  // const handleCancelDeleteClick = (e) => {
  //   e.stopPropagation();
  //   setShowConfirmDelete(false);
  // };

  const handleConfirmDeleteClick = async (dealID) => {
    // console.log('deal options handleConfirmDeleteClick dealID: ', dealID);
    setIsSaving(true);
    try {
      //
      //  Delete the deal
      //
      await API.graphql(
        graphqlOperation(mutations.deleteDealObject, {
          id: dealID,
        })
      );
      // await API.graphql(
      //   graphqlOperation(updateDeal, {
      //     input: { id: dealID, isDeleted: true },
      //   })
      // );

      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.success(`Deal deleted successfully`);

      // reroute to /pipeline from the /pipeline/<dealID> page of the deleted deal
      navigate('/pipeline');

      // reload the deals data and setIsLoaded to false for showing DealListSkeleton again when deleting from DealCard, not SelectedDealDisplay
      setTimeout(() => {
        setIsLoaded(false);
        getData(
          userId,
          searchStage,
          dealTypesCategoryIdMap[dealFilter.dealType]
            ? dealTypesCategoryIdMap[dealFilter.dealType]
            : categoryId, // pass in corresponding category id from deal type filter if it exists, otherwise use categoryId (for now it is always 'default' until searchParams implemented)
          dealFilter.dealSort,
          searchString,
          null,
          true,
          fromDealPage * 25
        );
      }, 250);
    } catch (err) {
      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.error(`There was an error deleting your deal`);
      console.error('deal options handleConfirmDeleteClick error: ', err);
    }
  };

  return (
    <>
      {/* NOTE:  DynamicConfirmModal replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmDelete}
        setShowDynamicConfirmModal={setShowConfirmDelete}
        zIndex={100002}
        title={`Are you sure you want to delete this deal?`}
        subtitle={`This action cannot be undone.`}
        leftAlignText={true}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
        onConfirm={() => {
          // console.log('onConfirm dealID: ', dealID);
          handleConfirmDeleteClick(dealID);
        }}
      ></DynamicConfirmModal>
      {/* <Backdrop sx={{ zIndex: 100001 }} open={showConfirmDelete}>
        <Box className={'deal-delete-confirmation-container'}>
          <Box className={'deal-delete-confirmation-top-container'}>
            <Typography className={'deal-delete-confirmation-top-text'}>
              Are you sure you want to delete this deal?
            </Typography>
            <Typography className={'deal-delete-confirmation-bottom-text'}>
              This action cannot be undone once confirmed
            </Typography>
          </Box>
          <Box className={'deal-delete-confirmation-bottom-container'}>
            <Button
              className={'cancel-delete-deal-button'}
              onClick={(e) => handleCancelDeleteClick(e)}
              disableRipple={true}
            >
              <Typography className={'deal-delete-button-text'}>
                Cancel
              </Typography>
            </Button>
            <Button
              className={'confirm-delete-deal-button'}
              onClick={(e) => handleConfirmDeleteClick(e, dealID)}
              disableRipple={true}
            >
              <Typography className={'deal-delete-button-text'}>
                Delete
              </Typography>
            </Button>
          </Box>
        </Box>
      </Backdrop> */}

      {dealID && (
        <OwnerDealStageModal
          dealID={dealID}
          categoryID={dealTypesCategoryIdMap[dealFilter.dealType]}
          showOwnerDealStageModal={showOwnerDealStageModal}
          setShowOwnerDealStageModal={setShowOwnerDealStageModal}
          // setDealOptionsOpen={setDealOptionsOpen}
          // setAnchorEl={setAnchorEl}
          triggerSelectedDealRefetch={triggerSelectedDealRefetch}
          onTriggerRefetch={() => {
            getData(
              userId,
              searchStage,
              dealTypesCategoryIdMap[dealFilter.dealType]
                ? dealTypesCategoryIdMap[dealFilter.dealType]
                : categoryId, // pass in corresponding category id from deal type filter if it exists, otherwise use categoryId (for now it is always 'default' until searchParams implemented)
              dealFilter.dealSort,
              searchString,
              null,
              true,
              fromDealPage * 25
            );
            onTriggerSelectedDealRefetch();
          }}
        ></OwnerDealStageModal>
      )}

      {/* NOTE: CustomMenu and CustomMenuItem components replaced the commented out code below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          handleMenuClose();
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();

            // NOTE: This is for if DealFormPage is used on its own at /edit/<dealID>, but it is now used in a modal so no navigation is needed
            // setShowEditDealFormModal(true);
            navigate(`/pipeline/edit/${dealID}`, {
              // TO DO: add in other props for DealFormPage, see CreateDealScreen component in lyferize MyDealScreen.tsx for reference
              state: {
                dealType,
                dealTypesCategoryIdMap,
                dealTypesConfigMap,
              },
            });
          }}
          disableRipple={true}
        >
          Edit
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowOwnerDealStageModal(true);
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          Change Stage
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            console.log('Schedule click');
          }}
          disableRipple={true}
        >
          Schedule
        </CustomMenuItem>
        {/* NOTE: current owner is either the setter or closer, may be redundant to include checking currentOwnerID here */}
        {(global.me.id === currentOwnerID ||
          global.me.id === setter?.user?.id ||
          global.me.id === closer?.user?.id ||
          global.me.isAdmin ||
          global.me.functionalRoles.includes('manager')) && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'red',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(e);
            }}
            disableRipple={true}
          >
            Delete
          </CustomMenuItem>
        )}
      </CustomMenu>
      {/* <Box className={'option-menu-container'}>
        <Box className={'option-menu-edit-container'}>
          <Button
            disableRipple={true}
            className={'option-menu-top-button'}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/pipeline/edit/${dealID}`, {
                // TO DO: add in other props for DealFormPage, see CreateDealScreen component in lyferize MyDealScreen.tsx for reference
                state: {
                  dealType,
                  dealTypesCategoryIdMap,
                  dealTypesConfigMap,
                },
              });
            }}
          >
            <Typography className={'option-menu-text'}>Edit</Typography>
          </Button>
        </Box>
        <Box className={'option-menu-change-stage-container'}>
          <Button
            disableRipple={true}
            className={'option-menu-button'}
            onClick={(e) => {
              // console.log('click Change Stage');
              e.stopPropagation();
              setShowOwnerDealStageModal(true);
            }}
          >
            <Typography className={'option-menu-text'}>Change Stage</Typography>
          </Button>
        </Box>
        <Box className={'option-menu-schedule-container'}>
          <Button
            disableRipple={true}
            className={
              global.me.id === currentOwnerID ||
              global.me.isAdmin ||
              global.me.functionalRoles.includes('manager')
                ? 'option-menu-button'
                : 'option-menu-bottom-button'
            }
            // onClick={(e) => handleScheduleClick(e)}
          >
            <Typography className={'option-menu-text'}>Schedule</Typography>
          </Button>
        </Box>
         Only give access to delete to the deal owner, an admin, or a manager (change schedule button className also)
        {(global.me.id === currentOwnerID ||
          global.me.isAdmin ||
          global.me.functionalRoles.includes('manager')) && (
          <Box className={'option-menu-delete-container'}>
            <Button
              disableRipple={true}
              className={'option-menu-bottom-button'}
              onClick={(e) => handleDeleteClick(e)}
            >
              <Typography className={'option-menu-delete-text'}>
                Delete
              </Typography>
            </Button>
          </Box>
        )}
      </Box> */}
    </>
  );
}
