import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import getUserAvatar from '../../common/utils/getUserAvatar';

const fetchTeamMembers = async (circleID) => {
  //   console.log('fetchTeamMembers: ', circleID);
  try {
    const myQuery = gql`
      query circleMemberByCircleID(
        $circleID: ID = "60d9848f-a489-4287-9808-2bb347370d03"
      ) {
        circleMemberByCircleID(
          filter: { status: { ne: rejected }, status: { ne: removed } }
          limit: 9999
          circleID: $circleID
        ) {
          items {
            id
            isActive
            lifetimeCirclePoints
            memberType
            memberRole
            status
            circlePoints
            createdAt
            userID
            circleID
            circle {
              id
              approverType
              name
            }
            user {
              id
              initials
              name
              imageName
              imageType
              title
              status
              isDeleted
              functionalRoles
            }
          }
        }
      }
    `;

    const res = await API.graphql({
      query: myQuery,
      variables: { circleID },
    });

    const teamMembers = res.data.circleMemberByCircleID.items;

    const members = [];

    for (const teamMember of teamMembers) {
      // Skip if teamMember doesn't have user property or status is deleted
      if (!teamMember.user || teamMember.user?.status === 'deleted') continue;

      const temp = { ...teamMember };
      const newMemberUser = teamMember.user;
      delete temp.user;

      // Format the functional role
      let _functionalRoles = '';
      if (teamMember.user.functionalRoles !== null) {
        for (const role of teamMember.user.functionalRoles) {
          // Skip if blank role
          if (role === '') continue;

          // Put & for multiple roles
          if (_functionalRoles !== '') {
            _functionalRoles += ' & ';
          }
          _functionalRoles += role[0].toUpperCase() + role.slice(1);
        }
      }
      newMemberUser.functionalRoles = _functionalRoles;

      // Get avatar image file for member
      newMemberUser.circleMember = temp;
      newMemberUser.avatarFile = await getUserAvatar(
        teamMember.user?.imageName,
        teamMember.user?.imageType,
        true
      );

      members.push(newMemberUser);
    }

    // Sort members alphabetically to match mobile
    const sortedUsers = members.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    // console.log('fetchTeamMembers result: ', sortedUsers);

    return sortedUsers;
  } catch (err) {
    console.error(
      'fetchTeamMembers(): error fetching Team Members: ',
      err.message
    );
  }
};

export default fetchTeamMembers;
