import React from 'react';
import { ViewContainer } from './Rules.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import ItemSelectInput from '../../custom-inputs/ItemSelectInput';
import CustomSelect from '../../custom-inputs/CustomSelect';
import CustomToggleSwitch from '../../custom-inputs/CustomToggleSwitch';
import NewGameState from '../../_types/NewGameState';
import CustomTextInput from '../../custom-inputs/CustomTextInput';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  kpis: { label: string; value: string }[];
}

const RulesView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  kpis,
}) => {
  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Rules</InputTitleText>
        <InputTitleIcon src="/images/trophy.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomToggleSwitch
          label="Use Multiple KPIs"
          value={newGameState.useMultipleKPIs}
          onChange={(newValue: any) => {
            onChangeNewGameState({
              ...newGameState,
              useMultipleKPIs: newValue,
            });
          }}
        />
        <CustomSelect
          label="KPI"
          value={newGameState.KPIRuleID}
          onChange={(newValue: any) => {
            onChangeNewGameState({ ...newGameState, KPIRuleID: newValue });
          }}
          items={kpis}
        />
        <CustomSelect
          label="Tie Breaker KPI"
          value={newGameState.tieKPIRuleID}
          onChange={(newValue: any) => {
            onChangeNewGameState({ ...newGameState, tieKPIRuleID: newValue });
          }}
          items={kpis}
        />
        <ItemSelectInput
          label="Goal"
          selectedItem={
            newGameState.isActionCountCustom
              ? 'custom'
              : newGameState.actionCount
          }
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            if (newSelectedItem.value === 'custom') {
              onChangeNewGameState({
                ...newGameState,
                isActionCountCustom: true,
                actionCount: '',
              });
            } else {
              onChangeNewGameState({
                ...newGameState,
                isActionCountCustom: false,
                actionCount: newSelectedItem.value,
              });
            }
          }}
          items={[
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: 'Custom', value: 'custom' },
          ]}
          numColumns={4}
        />
        {newGameState.isActionCountCustom && (
          <CustomTextInput
            label="Custom Goal"
            placeholder="E.g. 15"
            value={newGameState.actionCount}
            onChange={(newValue) => {
              onChangeNewGameState({ ...newGameState, actionCount: newValue });
            }}
          />
        )}
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default RulesView;
