import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
} from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../users/StyledInputs';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import { BackIcon, UserIcon } from '../../components/GamifyIcon';
import { GamifyToast } from '../../common/CustomToasts';
import './PowerSupplierForm.css';

export default function PowerSupplierForm(props) {
  const { showPowerSupplierForm, setShowPowerSupplierForm, flexField, onSave } =
    props;

  //  // 3x4
  //   const months = [
  //     ['jan', 'feb', 'mar'],
  //     ['apr', 'may', 'jun'],
  //     ['jul', 'aug', 'sep'],
  //     ['oct', 'nov', 'dec'],
  //   ];
  //  // 4x3
  //   const months = [
  //     ['jan', 'feb', 'mar', 'apr'],
  //     ['may', 'jun', 'jul', 'aug'],
  //     ['oct', 'nov', 'dec', 'sep'],
  //   ];
  // 6x2
  const months = [
    ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
    ['jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  ];

  const [submitted, setSubmitted] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [clickedResult, setClickedResult] = useState(true); // for properly showing 'No results' when typing vs nothing after clicking a result
  const [kwsInfo, setKwsInfo] = useState('Annual Usage');
  const [utilityInfo, setUtilityInfo] = useState({
    utilityInfo: 'Annual Usage',
    info: '',
  });
  const [errors, setErrors] = useState({
    errors: true,
    info: '*fill in usage information',
    company: '*select utility company',
    month: { dec: true, nov: true },
  });
  const [monthlyUsage, setMonthlyUsage] = useState(
    months
      .flatMap((set) => set)
      .reduce((acc, m) => {
        acc[m] = '';
        return acc;
      }, {})
  );

  //   console.log('PowerSupplier flex field: ', flexField);

  //   console.log('months: ', months);

  const handleBack = () => {
    setShowPowerSupplierForm(false);
  };

  useEffect(() => {
    if (showPowerSupplierForm) {
      if (flexField?.id) {
        // if (!flexField.id) return;
        const filedInfo = { ...flexField };
        if (filedInfo.utilityInfo === 'Monthly Usage') {
          const monthlyTemp = { ...filedInfo.info };
          setMonthlyUsage(monthlyTemp);
          delete filedInfo.info;
        }
        setKwsInfo(filedInfo.utilityInfo);
        setUtilityInfo({ ...filedInfo });
        setSearchString(filedInfo.name);
      }
    }
  }, [showPowerSupplierForm]);

  useEffect(() => {
    const newErrors = { errors: false };
    if (!utilityInfo.id) {
      newErrors.errors = true;
      newErrors.company = '*select utility company';
    }
    if (
      utilityInfo.utilityInfo !== 'Monthly Usage' &&
      utilityInfo.info === ''
    ) {
      newErrors.errors = true;
      newErrors.info = '*fill in usage information';
    }
    if (utilityInfo.utilityInfo === 'Monthly Usage') {
      const missingInfo = Object.keys(monthlyUsage).filter(
        (month) => monthlyUsage[month] === ''
      );
      if (missingInfo.length) {
        newErrors.errors = true;
        newErrors.month = missingInfo.reduce((acc, m) => {
          acc[m] = true;
          return acc;
        }, {});
      }
    }
    setErrors(newErrors);
  }, [utilityInfo, monthlyUsage]);

  async function searchUtilityCompany(mySearchString) {
    // console.log(`searchUtilityCompany mySearchString: ${mySearchString}`);
    setSearching(true);
    const ourSearchString = `${mySearchString}`;
    const myQuery = gql`
      query MyQuery($searchString: String!) {
        searchSoloUtilityCompanys(
          filter: { name: { matchPhrasePrefix: $searchString } }
        ) {
          items {
            id
            name
            short_name
            state
            zip
          }
        }
      }
    `;

    try {
      const res = await API.graphql({
        query: myQuery,
        variables: {
          searchString: ourSearchString,
        },
      });
      //   console.log(
      //     'searchUtility res.data.searchSoloUtilityCompanys:',
      //     res.data.searchSoloUtilityCompanys
      //   );
      setSearchResults(res.data.searchSoloUtilityCompanys.items);
    } catch (err) {
      console.error('SearchUtility error:', err);
    }
    setSearching(false);
  }

  const doSubmit = () => {
    setSubmitted(true);
    if (errors.errors) {
      GamifyToast.error('Please fix all errors before saving.');
      return;
    }
    const ourUtilityObject = {
      ...utilityInfo,
    };
    if (ourUtilityObject.utilityInfo === 'Monthly Usage') {
      ourUtilityObject.info = monthlyUsage;
    }
    // console.log(ourUtilityObject);
    onSave(ourUtilityObject);
    setSubmitted(false);
    setShowPowerSupplierForm(false);
  };

  return (
    <Backdrop sx={{ zIndex: 5 }} open={showPowerSupplierForm}>
      <Box className={'power-supplier-form-container'}>
        <Box className={'power-suppler-form-top-container'}>
          <Box className={'power-supplier-form-top-left-container'}>
            <Button
              className={'power-supplier-form-back-button'}
              onClick={handleBack}
              disableRipple={true}
            >
              <BackIcon></BackIcon>
              <Typography
                style={{
                  color: '#0B0B0C',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                }}
              >
                Back
              </Typography>
            </Button>
          </Box>
          <Box className={'power-supplier-form-top-middle-container'}>
            <Typography
              onClick={() =>
                console.log('Power Supplier flexField: ', flexField)
              }
              style={{
                color: '#222428',
                fontWeight: 700,
                fontSize: 36,
                marginTop: 16,
              }}
            >
              Power supplier
            </Typography>
            <Typography
              style={{
                color: '#868686',
                fontWeight: 500,
                fontSize: 16,
                marginBottom: 16,
              }}
            >
              *All the fields are required
            </Typography>
          </Box>
          <Box className={'power-supplier-form-top-right-container'}></Box>
        </Box>
        <Box className={'power-supplier-form-content-container'}>
          <Box className={'power-supplier-form'}>
            <Box className={'power-supplier-form-top'}>
              <Typography
                style={{
                  color: '#222428',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                Company & data
              </Typography>
              <UserIcon></UserIcon>
            </Box>
            <Box className={'power-supplier-form-bottom'}>
              <Box className={'power-supplier-input-label-container'}>
                <Typography
                  style={{
                    color: '#0B0B0C',
                    fontWeight: 700,
                    fontSize: 12,
                    marginBottom: 8,
                  }}
                >
                  Utility Company
                </Typography>
                {submitted && errors.errors && errors.company && (
                  <Typography
                    style={{
                      // color: '#ff5c77',
                      color: '#E35050',
                      fontWeight: 500,
                      fontSize: 10,
                      marginLeft: 8,
                      marginBottom: 8,
                    }}
                  >
                    {errors.company}
                  </Typography>
                )}
              </Box>
              <Input
                className={
                  submitted && errors.errors && errors.company
                    ? 'error-utility-company-input'
                    : 'utility-company-input'
                }
                style={{
                  position: 'relative',
                  width: '100%',
                  padding: 12,
                  border: 0,
                  borderRadius: 8,
                  marginBottom: 16,
                }}
                placeholder={'Type to start'}
                // inputProps={{ maxLength: getMaxLength(flexField) }}
                multiline={false}
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setClickedResult(false);
                  if (e.target.value.length) {
                    searchUtilityCompany(e.target.value);
                  } else {
                    setSearchResults([]);
                  }
                  //   console.log('Utility search string: ', searchString);
                  //   console.log('Utility search searchResults:', searchResults);
                }}
              />
              {searching ? (
                <Box className={'utility-company-search-results-container'}>
                  <Box style={{ padding: 12 }}>
                    <CircularProgress
                      style={{ color: 'black' }}
                    ></CircularProgress>
                  </Box>
                </Box>
              ) : (
                <>
                  {searchResults.length > 0 &&
                    searchString.length > 0 &&
                    !clickedResult && (
                      <Box
                        className={'utility-company-search-results-container'}
                      >
                        {searchResults.map((company, i) => (
                          <Button
                            className={'utility-company-search-result-button'}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '100%',
                              padding: 12,
                            }}
                            key={i}
                            onClick={() => {
                              setSearchString(company.name);
                              setUtilityInfo((p) => ({ ...p, ...company }));
                              setSearchResults([]);
                              setClickedResult(true);
                            }}
                            disableRipple={true}
                          >
                            <Typography
                              style={{
                                color: '#060606',
                                fontWeight: 400,
                                fontSize: 16,
                              }}
                            >
                              {company.name}
                            </Typography>
                          </Button>
                        ))}
                      </Box>
                    )}
                  {searchResults.length === 0 &&
                    searchString.length > 0 &&
                    !clickedResult && (
                      <Box
                        className={'utility-company-search-results-container'}
                      >
                        <Typography
                          style={{
                            color: '#060606',
                            fontWeight: 400,
                            fontSize: 16,
                            padding: 12,
                          }}
                        >
                          No results
                        </Typography>
                      </Box>
                    )}
                </>
              )}
              <Typography
                style={{
                  color: '#0B0B0C',
                  fontWeight: 700,
                  fontSize: 12,
                  marginBottom: 8,
                }}
              >
                Info to provide
              </Typography>
              <StyledSelect
                className={'kws-info-select'}
                style={{ width: '100%', border: 'none', marginBottom: 16 }}
                onChange={(e) => {
                  setUtilityInfo((p) => ({
                    ...p,
                    utilityInfo: e.target.value,
                    info: '',
                  }));
                  setKwsInfo(e.target.value);
                }}
                value={kwsInfo}
                name="kwsInfo"
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                      boxShadow: '0px 4px 24px 0px #ababab5e;',
                    },
                  },
                }}
                // MenuProps={{
                //   MenuListProps: {
                //     sx: {
                //       boxShadow: '0px 4px 24px 0px #ababab5e;',
                //       borderRadius: '4px',
                //       backgroundColor: 'white',
                //       paddingTop: '0px',
                //       marginTop: '8px',
                //     },
                //   },
                // }}
              >
                {['Annual Usage', 'Average Monthly Cost', 'Monthly Usage'].map(
                  (opt, i) => (
                    <StyledMenuItem
                      className={'utility-info-option'}
                      value={opt}
                      key={i}
                    >
                      {opt}
                    </StyledMenuItem>
                  )
                )}
              </StyledSelect>
              {kwsInfo === 'Annual Usage' && (
                <>
                  <Box className={'power-supplier-input-label-container'}>
                    <Typography
                      style={{
                        color: '#0B0B0C',
                        fontWeight: 700,
                        fontSize: 12,
                        marginBottom: 8,
                      }}
                    >
                      Annual usage in KWs
                    </Typography>
                    {submitted && errors.errors && errors.info && (
                      <Typography
                        style={{
                          // color: '#ff5c77',
                          color: '#E35050',
                          fontWeight: 500,
                          fontSize: 10,
                          marginLeft: 8,
                          marginBottom: 8,
                        }}
                      >
                        {errors.info}
                      </Typography>
                    )}
                  </Box>
                  <Input
                    className={
                      submitted && errors.errors && errors.info
                        ? 'error-utility-info-input'
                        : 'utility-info-input'
                    }
                    style={{
                      width: '100%',
                      padding: 12,
                      border: 0,
                      borderRadius: 8,
                    }}
                    placeholder={'E.g 245'}
                    // inputProps={{ maxLength: getMaxLength(flexField) }}
                    multiline={false}
                    value={utilityInfo.info}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      setUtilityInfo((p) => ({ ...p, info: e.target.value }));
                    }}
                  />
                </>
              )}
              {kwsInfo === 'Average Monthly Cost' && (
                <>
                  <Box className={'power-supplier-input-label-container'}>
                    <Typography
                      style={{
                        color: '#0B0B0C',
                        fontWeight: 700,
                        fontSize: 12,
                        marginBottom: 8,
                      }}
                    >
                      KWs Average Monthly Usage
                    </Typography>
                    {submitted && errors.errors && errors.info && (
                      <Typography
                        style={{
                          // color: '#ff5c77',
                          color: '#E35050',
                          fontWeight: 500,
                          fontSize: 10,
                          marginLeft: 8,
                          marginBottom: 8,
                        }}
                      >
                        {errors.info}
                      </Typography>
                    )}
                  </Box>
                  <Input
                    className={
                      submitted && errors.errors && errors.info
                        ? 'error-utility-info-input'
                        : 'utility-info-input'
                    }
                    style={{
                      width: '100%',
                      padding: 12,
                      border: 0,
                      borderRadius: 8,
                    }}
                    placeholder={'Average Monthly Usage'}
                    // inputProps={{ maxLength: getMaxLength(flexField) }}
                    multiline={false}
                    value={utilityInfo.info}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      setUtilityInfo((p) => ({ ...p, info: e.target.value }));
                    }}
                  />
                </>
              )}
              {kwsInfo === 'Monthly Usage' && (
                <>
                  {months.map((set, i) => (
                    <Box
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 10,
                        marginBottom: 5,
                      }}
                    >
                      {set.map((m) => (
                        <Box key={m}>
                          <Box
                            className={'power-supplier-input-label-container'}
                          >
                            <Typography
                              style={{
                                color: '#0B0B0C',
                                fontWeight: 700,
                                fontSize: 12,
                                marginBottom: 8,
                              }}
                            >
                              {m[0].toUpperCase() + m.slice(1)}
                            </Typography>
                            {submitted &&
                              errors.errors &&
                              errors.month &&
                              errors.month[m] && (
                                <Typography
                                  style={{
                                    // color: '#ff5c77',
                                    color: '#E35050',
                                    fontWeight: 500,
                                    fontSize: 10,
                                    marginLeft: 8,
                                    marginBottom: 8,
                                  }}
                                >
                                  {' *'}
                                </Typography>
                              )}
                          </Box>
                          <Input
                            className={
                              submitted &&
                              errors.errors &&
                              errors.month &&
                              errors.month[m]
                                ? 'error-utility-info-input'
                                : 'utility-info-input'
                            }
                            style={{
                              padding: 12,
                              border: 0,
                              borderRadius: 8,
                            }}
                            placeholder={'E.g 20'}
                            // inputProps={{ maxLength: getMaxLength(flexField) }}
                            multiline={false}
                            value={monthlyUsage[m]}
                            onChange={(e) => {
                              if (isNaN(e.target.value)) return;
                              setMonthlyUsage((p) => ({
                                ...p,
                                [m]: e.target.value,
                              }));
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Button
          className={'power-supplier-form-save-button'}
          onClick={doSubmit}
          disableRipple={true}
        >
          <Typography
            style={{ color: '#FFFFFF', fontSize: 16, fontWeight: 700 }}
          >
            Save
          </Typography>
        </Button>
      </Box>
    </Backdrop>
  );
}
