import User from './User';

export default class KPILeaderboard {
  constructor(data) {
    this.data = data;
  }

  getName() {
    return this.data.name;
  }

  getDescription() {
    return this.data.title;
  }

  getActionTypePluralized() {
    return this.data.actionTypePlural;
  }

  collateImageMetadata() {
    const userImageMetadata = this.getUsers().map((user) =>
      user.createImageMetadata()
    );
    return userImageMetadata;
  }

  getUsers() {
    if (!this.users) {
      this.users = this.data.leaderboard.map((userItem) => new User(userItem));
    }
    return this.users;
  }
}
