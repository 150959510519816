import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Typography,
} from '@mui/material';
import { EllipsisIcon } from '../../components/GamifyIcon';
import MemberOptionsMenu from './MemberOptionsMenu';
import RolePositionModal from '../profile/RolePositionModal';
import fetchUserRolePosition from './fetchUserRolePosition';

export default function TeamMemberCard({
  team,
  member,
  setIsMemberListLoading,
  onTriggerMemberRefetch,
  onRemoveMemberFromState,
  onRemoveMembers,
  selectedTeamMember,
  setSelectedTeamMember,
  setCheckedTeamMembers,
  handleTeamMemberCheck,
  checkedTeamMembers,
  isOnTeamForm,
}) {
  const navigate = useNavigate();
  // const [user, setUser] = useState(null);
  // const [memberOptionsOpen, setMemberOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRolePositionModal, setShowRolePositionModal] = useState(false);
  const [rolePositionObj, setRolePositionObj] = useState({
    original: { role: null, position: null },
    form: { role: null, position: null },
  });
  // console.log('TeamMemberCard member: ', member);

  useEffect(() => {
    async function prepareUserRolePosition() {
      const userRolePositionRes = await fetchUserRolePosition(
        member.id,
        team.id
      );
      // console.log('TeamMemberCard userRolePositionRes: ', userRolePositionRes);
      setRolePositionObj({
        original: {
          position: userRolePositionRes?.orgs?.items[0]?.memberType,
          // position: userRolePositionRes?.functionalRole?.toLowerCase(),
          role: userRolePositionRes.memberRole,
        },
        form: {
          position: userRolePositionRes?.orgs?.items[0]?.memberType,
          // position: userRolePosition?.functionalRole?.toLowerCase(),
          role: userRolePositionRes.memberRole,
        },
      });
      // setUser(userRolePosition);
    }
    prepareUserRolePosition();
  }, []);

  // const handleClickOffMemberOptionMenu = () => {
  //   setMemberOptionsOpen(false);
  // };

  let memberRoleColor;
  let memberRoleBackgroundColor;

  if (member.circleMember?.memberRole === 'closer') {
    memberRoleBackgroundColor = '#FF9C4166';
    memberRoleColor = '#DA4100';
  } else if (member.circleMember?.memberRole === 'setter') {
    memberRoleBackgroundColor = '#B3F6FF';
    memberRoleColor = '#005897';
  } else if (member.circleMember?.memberRole === 'manager') {
    memberRoleBackgroundColor = '#AFF3CE';
    memberRoleColor = '#017E6A';
  } else if (member.circleMember?.memberRole === 'scheduler') {
    memberRoleBackgroundColor = '#E8E8E8';
    memberRoleColor = '#060606';
  } else if (member.circleMember?.memberRole === 'other') {
    memberRoleBackgroundColor = '#5F27CD';
    memberRoleColor = '#E2D8FF';
  }

  // console.log('TeamMemberCard rolePositionObj: ', rolePositionObj);
  return (
    <>
      <RolePositionModal
        user={member}
        circleMemberId={member.circleMember?.id}
        // team={team}
        roleOnly={true}
        rolePositionObj={rolePositionObj}
        setRolePositionObj={setRolePositionObj}
        showRolePositionModal={showRolePositionModal}
        setShowRolePositionModal={setShowRolePositionModal}
        onTriggerRefetch={onTriggerMemberRefetch}
      ></RolePositionModal>
      {/* NOTE: This background was only needed for custom built options menu, CustomMenu and CustomMenuItem components do not need this */}
      {/* {memberOptionsOpen && (
        <Box
          onClick={handleClickOffMemberOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box
        sx={{ cursor: isOnTeamForm ? 'default' : 'pointer' }}
        onClick={(e) => {
          // NOTE: For now, only have checkboxes on TeamPage
          if (!isOnTeamForm) setSelectedTeamMember(member);
          // Do not navigate to any profile pages from the TeamFormPage
          if (isOnTeamForm) return;
          // Check if the click is not on the team-member-option-button
          if (
            !e.target.closest('.team-member-option-button') &&
            !e.target.closest('.MuiCheckbox-root')
          ) {
            navigate(`/users/about/${member.id}`, {
              state: { teamPage: true, teamId: team.id },
            });
          }
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: isOnTeamForm ? 'white' : '#f0f0f3',
          padding: 15,
          minWidth: 'fit-content',
          borderRadius: 8,
          marginBottom: isOnTeamForm ? 8 : 5,
        }}
      >
        <Box className={'team-member-list-item-left-container'}>
          {/* {console.log('TeamMemberCard member: ', member)} */}
          {/* NOTE: Only admins, moderators, and managers can check members to remove from the team */}
          {!isOnTeamForm &&
            (global.me.isAdmin ||
              global.me.isModerator ||
              global.me.functionalRoles?.includes('manager')) && (
              <Checkbox
                sx={{
                  // color: '#5f27cd',
                  color: '#868686',
                  '&.Mui-checked': {
                    color: '#5f27cd',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                }}
                checked={checkedTeamMembers.some(
                  (checkedTeamMember) => checkedTeamMember.id === member.id
                )}
                onChange={(e) => {
                  e.stopPropagation();
                  handleTeamMemberCheck(e, member);
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          {/* Display member avatar if they have one, otherwise display initials */}
          {/* Account for image type to account for potential gifs */}
          {member.avatarFile ? (
            member.imageType === 'picture' ||
            member.imageType.split('/')[0] === 'image' ? (
              <Avatar
                src={member.avatarFile}
                sx={{
                  margin: '5px 12px 5px 5px',
                  cursor: isOnTeamForm ? 'default' : 'pointer',
                }}
                onClick={(e) => {
                  // Do not navigate to any profile pages from the TeamFormPage
                  if (isOnTeamForm) return;
                  e.stopPropagation();
                  navigate(`/users/stats/${member.id}`, {
                    state: { teamPage: true, teamId: team.id },
                  });
                }}
              />
            ) : (
              <CardMedia
                image={member.imageName}
                component="img"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 1000,
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  margin: '5px 12px 5px 5px',
                  cursor: isOnTeamForm ? 'default' : 'pointer',
                }}
                onClick={(e) => {
                  // Do not navigate to any profile pages from the TeamFormPage
                  if (isOnTeamForm) return;
                  e.stopPropagation();
                  navigate(`/users/stats/${member.id}`, {
                    state: { teamPage: true, teamId: team.id },
                  });
                }}
              />
            )
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#868686',
                margin: '5px 12px 5px 5px',
                cursor: isOnTeamForm ? 'default' : 'pointer',
              }}
              onClick={(e) => {
                // Do not navigate to any profile pages from the TeamFormPage
                if (isOnTeamForm) return;
                e.stopPropagation();
                navigate(`/users/stats/${member.id}`, {
                  state: { teamPage: true, teamId: team.id },
                });
              }}
            >
              {member.initials}
            </Avatar>
          )}
          <Box className={'team-member-list-item-left-text-container'}>
            <Box style={{ display: 'flex' }}>
              <Typography
                style={{
                  color: '#222428',
                  fontSize: 16,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                {member.name}
              </Typography>
              {member.status === 'disabled' && (
                <Typography
                  style={{
                    // color: '#FF5C77',
                    color: '#E35050',
                    fontSize: 16,
                    fontWeight: 600,
                    marginLeft: 4,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Deactivated
                </Typography>
              )}
            </Box>
            <Typography
              style={{
                color: '#868686',
                fontSize: 14,
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {member.title}
            </Typography>
          </Box>
        </Box>
        <Box
          //   className={
          //     !isOnTeamForm ? 'team-member-list-item-right-container' : ''
          //   }
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: isOnTeamForm ? 'center' : 'flex-start',
          }}
        >
          {/* NOTE: member.functionalRoles is for user-specific which isn't used for roles anymore. member.circleMember.memberRole is the team-specific role to be displayed */}
          {member.circleMember?.memberRole && !isOnTeamForm && (
            <Box
              style={{
                backgroundColor: memberRoleBackgroundColor || '#E2D8FF',
                padding: '4px 8px 4px 8px',
                borderRadius: '1000px',
              }}
            >
              <Typography
                style={{
                  color: memberRoleColor || '#5F27CD',
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {/* {member.functionalRoles} */}
                {member.circleMember?.memberRole[0].toUpperCase() +
                  member.circleMember?.memberRole.slice(1)}
              </Typography>
            </Box>
          )}
          {/* TO DO: limit access to MemberOptionsMenu to admin/moderator/manager */}
          {(global.me.isAdmin ||
            global.me.isModerator ||
            global.me.functionalRoles?.includes('manager')) && (
            <Button
              className={'team-member-option-button'}
              onClick={(e) => {
                // setMemberOptionsOpen(!memberOptionsOpen)
                setAnchorEl(e.currentTarget);
              }}
              disableRipple={true}
            >
              <EllipsisIcon color={'#868686'}></EllipsisIcon>
            </Button>
          )}
          <MemberOptionsMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            team={team}
            user={member}
            selectedTeamMember={selectedTeamMember}
            setCheckedTeamMembers={setCheckedTeamMembers}
            setIsMemberListLoading={setIsMemberListLoading}
            setShowRolePositionModal={setShowRolePositionModal}
            onTriggerMemberRefetch={onTriggerMemberRefetch}
            onRemoveMemberFromState={onRemoveMemberFromState}
            onRemoveMembers={onRemoveMembers}
            isOnTeamForm={isOnTeamForm}
          ></MemberOptionsMenu>
          {/* {memberOptionsOpen && (
            <MemberOptionsMenu
              setMemberOptionsOpen={setMemberOptionsOpen}
              team={team}
              user={member}
              selectedTeamMember={selectedTeamMember}
              setCheckedTeamMembers={setCheckedTeamMembers}
              setIsMemberListLoading={setIsMemberListLoading}
              setShowRolePositionModal={setShowRolePositionModal}
              onTriggerMemberRefetch={onTriggerMemberRefetch}
              onRemoveMemberFromState={onRemoveMemberFromState}
              onRemoveMembers={onRemoveMembers}
              isOnTeamForm={isOnTeamForm}
            ></MemberOptionsMenu>
          )} */}
        </Box>
      </Box>
    </>
  );
}
