import React from 'react';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Box, Pagination } from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';

const StyledDataGrid = styled(DataGrid)(() => ({
  letterSpacing: 'normal',
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundImage: 'var(--application-table-header-background)',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'var(--table-row-background)',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: 'white',
  },
}));

const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-text': {
    backgroundColor: 'var(--component-background)',
    color: 'var(--application-color-foreground)',
  },
  '& .MuiPaginationItem-text:hover': {
    backgroundColor: 'var(--application-color-foreground)',
    color: 'var(--component-background)',
  },
  '& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text.MuiPaginationItem-rounded.Mui-selected':
    {
      backgroundColor: 'var(--application-color-foreground)',
      color: 'var(--component-background)',
    },
  '& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text.MuiPaginationItem-rounded.Mui-selected:hover':
    {
      backgroundColor: 'var(--application-color-foreground)',
      color: 'var(--component-background)',
    },
});

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <StyledPagination
      color="standard"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props) => (
        <PaginationItem {...props} variant={'text'} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default class StyledTable extends React.Component {
  buildRows() {
    if (this.props.autoGenerateIds) {
      return this.props.rows.map((row, index) => {
        return { ...row, id: index };
      });
    }
    return this.props.rows;
  }

  render() {
    let maxWidth = this.props.columns.reduce(
      (previousValue, currentValue) =>
        previousValue + (currentValue.width ? currentValue.width : 0),
      0
    );

    if (this.props.maxWidth) {
      maxWidth = this.props.maxWidth;
    }
    const rowsPerPage = this.props.rowsPerPage || 10;
    const hideFooter = this.props.rows.length <= rowsPerPage;
    const automaticPageSizing = !hideFooter;
    const automaticHeight = hideFooter;
    const rows = this.buildRows();

    return (
      <Box style={{ width: '100%', maxWidth: maxWidth + 'px' }}>
        {this.props.topRight && (
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ mb: '5px', mr: '5px' }}
          >
            {this.props.topRight}
          </Box>
        )}
        {this.props.header}
        <StyledDataGrid
          density={'compact'}
          disableExtendRowFullWidth={true}
          autoPageSize={automaticPageSizing}
          autoHeight={automaticHeight}
          rowsPerPageOptions={[rowsPerPage]}
          checkboxSelection={false}
          hideFooter={hideFooter}
          components={{ Pagination: CustomPagination }}
          disableColumnSelector={true}
          {...this.props}
          rows={rows}
          sx={{ '.MuiDataGrid-iconSeparator': { display: 'none' } }}
        />
        ;
      </Box>
    );
  }
}
