import styled from 'styled-components';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const RoundTabs = styled.div`
  width: 100%;
  border-radius: 100px;
  display: flex;
  border: 1px solid #c0c7cf;
  margin-top: 20px;
  /* padding: 0 20px; */
`;

export const RoundTab = styled.div<{
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  background-color: ${({ isActive }) => (isActive ? '#5F27CD' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #c0c7cf;
  border-right: 1px solid #c0c7cf;
  width: 100%;
  padding: 12px;
  border-start-start-radius: ${({ isFirst }) => (isFirst ? '50px' : '0')};
  border-end-start-radius: ${({ isFirst }) => (isFirst ? '50px' : '0')};
  border-end-end-radius: ${({ isLast }) => (isLast ? '50px' : '0')};
  border-start-end-radius: ${({ isLast }) => (isLast ? '50px' : '0')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#222428')};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;
