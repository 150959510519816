import React from 'react';
import { DimensionTimeframeValues, KPILeaderboardState } from '../../_types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  CustomSelect,
  CustomFilterContainer,
  CustomFilterTitle,
} from '../KPIFilter.styles';
import {
  getDimensionTimeframeTitle,
  getDimensionValues,
} from '../../_kpiLeaderboardHelpers';
import format from 'date-fns/format';

interface FilterDimensionValuesProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const FilterDimensionValues = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: FilterDimensionValuesProps) => {
  if (kpiLeaderboardState.dimensionTimeframe === DimensionTimeframeValues.Day) {
    return (
      <CustomFilterContainer>
        <CustomFilterTitle>Day</CustomFilterTitle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            PaperProps={{
              sx: {
                '& .MuiPickersDay-root': {
                  '&.Mui-selected': {
                    backgroundColor: '#5F27CD',
                  },
                },
              },
            }}
            InputProps={{
              sx: {
                borderRadius: '10px',
              },
            }}
            // NOTE: Need to offset local timezone to avoid setting the date to the previous day instead of the intended day. Also need this IIFE's type to be set as Date
            value={
              (() => {
                const tempDate = new Date(kpiLeaderboardState.dimensionValue);

                // NOTE: getTimezoneOffset gives the offset in minutes, converting to ms by multiplying by 60000 is needed
                const timezoneOffset = tempDate.getTimezoneOffset() * 60000;

                const adjustedDate = new Date(
                  tempDate.getTime() + timezoneOffset
                );

                return adjustedDate;
              })() as Date
            }
            onChange={(value) => {
              onChangeKpiLeaderboardState({
                ...kpiLeaderboardState,
                dimensionValue: format(value as Date, 'yyyy-MM-dd'),
                currentPage: 0,
              });
              resetTokens();
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  sx={{
                    '& input': { padding: '10px' },
                    '& fieldset': {
                      borderRadius: '10px !important',
                    },
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      </CustomFilterContainer>
    );
  }

  return (
    <CustomFilterContainer>
      <CustomFilterTitle>
        {getDimensionTimeframeTitle(kpiLeaderboardState.dimensionTimeframe)}
      </CustomFilterTitle>
      <CustomSelect
        value={kpiLeaderboardState.dimensionValue}
        onChange={(e) => {
          onChangeKpiLeaderboardState({
            ...kpiLeaderboardState,
            dimensionValue: e.target.value,
            currentPage: 0,
          });
          resetTokens();
        }}
      >
        {getDimensionValues(kpiLeaderboardState.dimensionTimeframe).map(
          (dimensionValue) => {
            return (
              <option key={dimensionValue.value} value={dimensionValue.value}>
                {dimensionValue.label}
              </option>
            );
          }
        )}
      </CustomSelect>
    </CustomFilterContainer>
  );
};

export default FilterDimensionValues;
