import ImageMetadata from './ImageMetadata';

export default class Reward {
  constructor(rewardData) {
    this.rewardData = rewardData.reward;
  }

  createImageMetadata() {
    return new ImageMetadata(this.getImageType(), this.getImageId());
  }

  getName() {
    return this.rewardData.name;
  }

  getImageId() {
    return this.rewardData.imageName;
  }

  getImageType() {
    return this.rewardData.imageType;
  }
}
