import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarView from './NavBar.view';
import { useSelector } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';

const NavBarContainer: React.FC = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const navigate = useNavigate();
  const location = useLocation();
  const avatar = useSelector((state: any) => state.avatar.value);
  const userProfileAvatar = avatar || '/images/default-avatar.png';
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(location.pathname);

  const handleProfileClick = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavigate = (path: string) => {
    if (path === '/signout') {
      signOut();
      return;
    }

    setActiveTab(path);
    setProfileDropdownOpen(false);
    setIsMobileMenuOpen(false);

    navigate(path);
  };

  const handleSignout = () => {
    signOut();
    navigate('/');
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <NavBarView
      activeTab={activeTab}
      userProfileAvatar={userProfileAvatar}
      isProfileDropdownOpen={isProfileDropdownOpen}
      handleProfileClick={handleProfileClick}
      handleNavigate={handleNavigate}
      // handleLeaderboardsMenuToggle={handleLeaderboardsMenuToggle}
      handleMobileMenuToggle={handleMobileMenuToggle}
      handleSignout={handleSignout}
      isMobileMenuOpen={isMobileMenuOpen}
    />
  );
};

export default NavBarContainer;
