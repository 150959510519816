import styled from 'styled-components';

export const ViewContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  padding: 125px 100px 20px 100px;

  @media (max-width: 768px) {
    padding: 125px 50px 20px 50px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 30px;
  font-family: Space Grotesk;
`;

export const NewRewardButton = styled.div`
  background-color: #5f27cd;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  gap: 6px;
  height: fit-content;
  width: fit-content;
  color: #fff;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`;

export const NoRewardsText = styled.div`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 30px;
  font-family: Space Grotesk;
  text-align: center;
  margin-top: 20px;
`;

export const NoImageText = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: Space Grotesk;
  text-align: center;
  margin-top: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 32px; 
  margin-top: 60px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; 
  }
`;

export const NewRewardViewContainer = styled.div`
  padding: 125px 100px 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NewRewardTitle = styled.div`
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  width: 500px;
  margin-bottom: 30px;
`;

export const BackButton = styled.div`
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 500px;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 125px;
  left: 100px;
`;

export const ImagePreviewCloseIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  position: absolute;
  border-radius: 100%;
  right: 0;
  top: 0;
`;

export const ViewInputsContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const InputTitleContainer = styled.div<{
  align?: string;
  flexDirection?: string;
}>`
  background-color: #d0d2d8;
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center' ? 'center' : 'space-between'};
  flex-direction: ${({ flexDirection }) =>
    flexDirection === 'column' ? 'column' : 'row'};
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 12px;
`;

export const InputTitleText = styled.div`
  color: #222428;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const InputTitleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const InputBodyContainer = styled.div`
  background-color: #f0f0f3;
  padding: 20px 12px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const DateContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
`;

export const AddModalContainer = styled.div`
  position: absolute;
  min-width: 360px;
  background-color: #fff;
  border: 1px solid #d0d2d8;
  padding: 10px;
  border-radius: 10px;
  right: 100px;
  top: 180px;
  z-index: 999;

  /* @media (min-width: 768px) {
    min-width: 300px;
  } */
`;

export const AddModalTitle = styled.div`
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const AddModalItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
`;

export const AddModalItemLeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AddModalItemRightSide = styled.div`
  display: flex;
  align-self: center;
`;

export const AddModalIcon = styled.div`
  padding: 6px;
  display: flex;
  border-radius: 10px;
`;

export const AddModalItemTitle = styled.div<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#CCCCCC' : 'black')};
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;
