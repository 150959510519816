// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// getMyInfo
//

import gql from 'graphql-tag';
import { API, Storage } from 'aws-amplify';

export async function getMyInfo() {
  console.log('!!getMyInfo()');
  try {
    //
    //  Get the user and config objects
    //
    const myQuery = gql`
      query MyQuery {
        getMyInfo(request: "{}")
      }
    `;

    const meData = await API.graphql({ query: myQuery });
    console.log('***meData: ', meData);
    const configObject = JSON.parse(meData.data.getMyInfo);
    console.log('!!!configObject: ', configObject);
    if (configObject === {}) {
      console.log('configObject not defined');
      return false;
    }
    global.me = configObject.me;
    console.log('!!appSettings: ', configObject.appSettings);
    global.appSettings = configObject.appSettings;
    global.refreshOnNotificationHandler = [];
    global.updateNotificationHandler = [];
    global.notifications = 0;
    console.log('me: ', global.me);
    if (global.me === undefined) {
      console.log('ME not defined');
      return false;
    }
    if (global.me.imageName !== '') {
      global.me.profilePicture = await Storage.get(global.me.imageName);
    } else {
      global.me.profilePicture = '/images/default-avatar.png';
    }
  } catch (err) {
    console.log("Can't getMyInfo: ", err);
    // alert('Error: ' + err);
    return true;
  }
  return true;
}
