import styled from '@emotion/styled';
// import { Link, LinkProps } from 'react-router-dom';

/* eslint-disable no-undef */
// export const NavbarContainer = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   max-height: 90px;
//   display: flex;
//   padding: 10px 100px;
//   /* padding-top: 30px; */
//   background-color: #fff;
//   box-shadow: 0px 2px 2px 0px rgba(137, 137, 137, 0.5);
//   z-index: 9999;

//   @media (max-width: 1024px) {
//     padding: 10px;
//     justify-content: space-between;
//   }
// `;

// export const Logo = styled.div`
//   font-size: 18px;
//   font-weight: 700;
//   font-family: Manrope;
//   letter-spacing: 6px;
//   padding-bottom: 12px;

//   @media (max-width: 1024px) {
//     padding: 10px;
//     justify-content: space-between;
//   }
// `;

// export const NavItemsContainer = styled.div<{ isOpen: boolean }>`
//   margin-left: 80px;
//   display: flex;
//   margin-right: auto;

//   @media (max-width: 1024px) {
//     position: absolute;
//     flex-direction: column;
//     top: 60px;
//     left: 0;
//     background-color: #fff;
//     width: 100%;
//     display: ${(props: { isOpen: boolean }) =>
//       props.isOpen ? 'block' : 'none'};
//     padding-bottom: 20px;
//   }
// `;

// export const NavItemContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// export const NavItemLabel = styled.div<{ isActive: boolean }>`
//   margin: 0 40px;
//   text-decoration: none;
//   color: #000; // Non-active color
//   padding-bottom: 10px;
//   cursor: pointer;
//   font-size: 14px;
//   position: relative;
//   letter-spacing: 2px;

//   font-weight: ${(props) => (props.isActive ? 700 : 500)};
//   color: ${(props) => (props.isActive ? '#5F27CD' : '#000')};

//   @media (max-width: 1024px) {
//     padding-left: 10px;
//     border-left: ${(props) =>
//       props.isActive ? '2px solid #5F27CD' : '2px solid #fff'};
//   }
// `;

export const NavItemIndicator = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#5F27CD' : 'transparent')};
  width: 8px;
  height: 8px;
  border-radius: 100%;
`;

export const ProfilePicture = styled.div`
  cursor: pointer;
`;

export const Dropdown = styled.div<{
  isOpen: boolean;
  itemsPosition: 'left' | 'right';
}>`
  position: absolute;
  top: 80px;
  right: 0px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};

  @media (max-width: 1024px) {
    top: 60px;
    right: ${(props: { itemsPosition: 'left' | 'right' }) =>
      props.itemsPosition === 'right' && 0};
    left: ${(props: { itemsPosition: 'left' | 'right' }) =>
      props.itemsPosition === 'left' && 0};
    z-index: 9999;
  }
`;

export const DropdownItem = styled.div`
  display: block;
  text-decoration: none;
  color: black;
  margin: 5px 0;
  margin-bottom: 16px;
`;

// export const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
//   display: none;

//   @media (max-width: 1024px) {
//     display: block;
//     cursor: pointer;
//   }
// `;

// export const MobileMenuIcon = styled.img`
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
// `;

export const SearchProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

// export const SearchIcon = styled.img`
//   width: 30px;
//   height: 30px;
// `;

export const ViewContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 90px;
  max-height: 90px;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  z-index: 9999;
  box-shadow: 0px 2px 2px 0px rgba(137, 137, 137, 0.5);

  @media (min-width: 768px) {
    padding: 0 100px;
  }
`;
