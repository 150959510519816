import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { EllipsisIcon, GamifyIcon } from '../../components/GamifyIcon';
import Moment from 'moment';
import AppointmentOptionsMenu from './AppointmentOptionsMenu';

export default function AppointmentCard({
  user,
  appointment,
  onTriggerAppointmentsRefetch,
  markAppointment,
  // onRescheduleAppointment,
  //   setShowDeal,
  //   setIsBusy,
  //   setAction,
  //   markAppointment,
}) {
  const navigate = useNavigate();
  // const [appointmentOptionsOpen, setAppointmentOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  //   console.log('AppointmentCard appointment: ', appointment);

  // const handleClickOffAppointmentOptionMenu = (e) => {
  //   e.stopPropagation();
  //   setAppointmentOptionsOpen(false);
  // };

  function formatScheduleDate(date) {
    const momentDate = new Moment(`${date}`);
    // console.log('momentDate: ', momentDate);
    return momentDate.format('dddd, MMMM Do');
  }

  function formatScheduleTime(minutesPastMidnight) {
    const hours = Math.trunc(minutesPastMidnight / 60);
    const minutes = minutesPastMidnight - hours * 60;
    const momentTime = new Moment({ hour: hours, minute: minutes });
    return momentTime.format('h:mm a');
  }

  return (
    <>
      {/* NOTE: This background was only needed for custom built options menu, CustomMenu and CustomMenuItem components do not need this */}
      {/* {appointmentOptionsOpen && (
        <Box
          onClick={handleClickOffAppointmentOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <Box
        onClick={(e) => {
          // Check if the click is not on the rewards-option-button
          if (!e.target.closest('.appointments-option-button')) {
            if (!appointment?.deal?.isDeleted) {
              navigate(`/pipeline/${appointment.dealID}`);
            }
          }
        }}
        sx={{ cursor: appointment?.deal?.isDeleted ? 'default' : 'pointer' }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          minWidth: 382,
          padding: 15,
          border: '1px solid #D0D2D8',
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        <Box className={'appointments-list-item-left-container'}>
          {/* {console.log('AppointmentCard appointment: ', appointment)} */}
          <Box style={{ marginRight: 12 }}>
            <GamifyIcon icon={'calendar'}></GamifyIcon>
          </Box>
          <Box className={'appointments-list-item-left-text-container'}>
            <Typography
              style={{ color: '#060606', fontSize: 15, fontWeight: 600 }}
            >
              {formatScheduleDate(appointment.appointmentDate)}
            </Typography>
            <Typography
              style={{ color: '#060606', fontSize: 15, fontWeight: 600 }}
            >
              {formatScheduleTime(appointment.appointmentStartTime)} -{' '}
              {formatScheduleTime(appointment.appointmentEndTime)}
            </Typography>
            <Typography
              style={{ color: '#060606', fontSize: 12, fontWeight: 500 }}
            >
              {`[${appointment?.deal?.name}]`}
            </Typography>
            <Typography
              style={{ color: '#060606', fontSize: 12, fontWeight: 500 }}
            >
              {`Assigned to: ${appointment?.user?.name}`}
            </Typography>
            <Typography
              style={{ color: '#060606', fontSize: 12, fontWeight: 500 }}
            >
              {`Setter: ${appointment?.setter?.name}`}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {/* TO DO: limit access to AppointmentOptionsMenu to admin/moderator/manager? */}
          <Button
            className={'appointments-option-button'}
            onClick={(e) => {
              e.stopPropagation();
              // setAppointmentOptionsOpen(!appointmentOptionsOpen);
              setAnchorEl(e.currentTarget);
            }}
            disableRipple={true}
          >
            <EllipsisIcon color={'#868686'}></EllipsisIcon>
          </Button>
          <AppointmentOptionsMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            appointment={appointment}
            user={user}
            onTriggerAppointmentsRefetch={onTriggerAppointmentsRefetch}
            markAppointment={markAppointment}
          ></AppointmentOptionsMenu>
          {/* {appointmentOptionsOpen && (
            <AppointmentOptionsMenu
              setAppointmentOptionsOpen={setAppointmentOptionsOpen}
              appointment={appointment}
              user={user}
              onTriggerAppointmentsRefetch={onTriggerAppointmentsRefetch}
              markAppointment={markAppointment}
            ></AppointmentOptionsMenu>
          )} */}
        </Box>
      </Box>
    </>
  );
}
