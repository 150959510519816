import React, { useState, useEffect } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  // IconButton,
  // Table as MuiTable,
  // TableBody,
  // TableContainer,
  // TableHead,
  Avatar,
  Typography,
  TextField,
  Backdrop,
  // Checkbox,
  InputAdornment,
  Input,
  CircularProgress,
  // CircularProgress,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Text } from '@aws-amplify/ui-react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as utils from '../../common/Utilities';
import fetchProfile from './fetchProfile';
import fetchProfileRewards from './fetchProfileRewards';
import fetchAllRewards from './fetchAllRewards';
import fetchPeople from '../teams/fetchPeople';
import fetchStats from './fetchStats';
import fetchAppointments from './fetchAppointments';
import featureFlag from '../../common/featureFlag';
import { BackIcon, GamifyIcon } from '../../components/GamifyIcon';
// import AddMemberLinkTeamModal from './AddMemberLinkTeamModal';
import './ProfileStatsPage.css';
// import { GamifyToast } from '../../common/CustomToasts';
// import TeamMemberCard from './TeamMemberCard';
import EditUserModal from '../users/EditUserModal';
import PermissionsModal from './PermissionsModal';
import RewardCard from './RewardCard';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import ToggleMenu from '../../common/ToggleMenu';
import AppointmentCard from './AppointmentCard';
import ProfileStatsPageSkeleton from './profileSkeletons/ProfileStatsPageSkeleton';
import ProfileStatsCardsSkeleton from './profileSkeletons/ProfileStatsCardsSkeleton';
import { GamifyToast } from '../../common/CustomToasts';
import DisplayMedia from '../../components/DisplayMedia';
import ProfileNoUserDisplay from './ProfileNoUserDisplay';
import UserFormModal from '../users/UserFormModal';

export default function ProfileStatsPage() {
  const { userId } = useParams();
  const [teamId, setTeamId] = useState(null);
  const location = useLocation();
  const [routeBackToTeamPage, setRouteBackToTeamPage] = useState(false);
  const [routeBackToLeaderboard, setRouteBackToLeaderbaord] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // // TO DO: implement searchParams for all filters, timeframe replaced with dimension
  // let timeframe = searchParams.get('timeframe');
  // // console.log('!!timeframe: ', timeframe);
  // if (timeframe === '' || timeframe === null) {
  //   timeframe = 'current';
  // }

  // console.log('ProfileStatsPage userId: ', userId);

  const [myProfile, setMyProfile] = useState(null);
  const [showUserFormModal, setShowUserFormModal] = useState(false);

  const [stats, setStats] = useState([]);
  const [dimension, setDimension] = useState('');
  const [selectDate, setSelectDate] = useState(false);
  let dimensionTypes;

  if (
    'scoreCard' in global.appSettings.features &&
    'dimensions' in global.appSettings.features.scoreCard
  ) {
    dimensionTypes = global.appSettings.features.scoreCard.dimensions;
  } else {
    dimensionTypes = [
      { label: 'Today', value: 'current_day' },
      { label: 'Current Stages', value: 'current' },
      { label: 'All Time', value: 'all' },
      { label: 'Yesterday', value: 'last_day' },
      { label: 'Current Week', value: 'current_week' },
      { label: 'Last Week', value: 'last_week' },
      { label: 'Current Month', value: 'current_month' },
      { label: 'Last Month', value: 'last_month' },
      { label: 'Current Year', value: 'current_year' },
      { label: 'Last Year', value: 'last_year' },
      { label: 'Select Day', value: 'select_day' },
      { label: 'Select Week', value: 'select_week' },
      { label: 'Select Month', value: 'select_month' },
      { label: 'Select Year', value: 'select_year' },
      { label: 'Personal Best', value: 'best_all' },
      { label: 'Best Day', value: 'best_day' },
      { label: 'Best Week', value: 'best_week' },
      { label: 'Best Month', value: 'best_month' },
      { label: 'Best Year', value: 'best_year' },
    ];
  }
  const [dimensionType, setDimensionType] = useState(dimensionTypes[0].value);

  const [searchStatus, setSearchStatus] = useState('all');
  const [orgPeople, setOrgPeople] = useState([]);
  const [searchAssigneeID, setSearchAssigneeID] = useState(userId);
  const [rewards, setRewards] = useState([]);
  const [rewardChecked, setRewardChecked] = useState([]);

  const [startBefore, setStartBefore] = useState(utils.getLocalDate());
  const [endAfter, setEndAfter] = useState(utils.getPreviousLocalDate());
  const [customRewardCount, setCustomRewardCount] = useState(0);
  const [allRewards, setAllRewards] = useState([]);

  let maxCustomRewards = 0;
  for (const entitlement of global.appSettings.entitlements) {
    if (entitlement.startsWith('reward:max_custom_rewards')) {
      maxCustomRewards = parseInt(entitlement.split('=')[1].trim());
    }
  }

  const [appointments, setAppointments] = useState([]);
  const [scheduleType, setScheduleType] = useState('mine');
  const canSeeSchedule =
    global.me.isAdmin ||
    global.me.isModerator ||
    global.me.functionalRoles?.includes('manager') ||
    userId === global.me.id;

  // const [subPage, setSubPage] = useState('stats');
  const [subPage, setSubPage] = useState(
    searchParams.get('subPage') || 'stats'
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [permissionsAnchorEl, setPermissionsAnchorEl] = useState(null);
  const [rolePositionObj, setRolePositionObj] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(0);
  const [triggerProfileDetailsRefetch, setTriggerProfileDetailsRefetch] =
    useState(0);

  const [areCoinsLoading, setAreCoinsLoading] = useState(false);
  const [triggerCoinRefetch, setTriggerCoinRefetch] = useState(0);
  const [showSendCoins, setShowSendCoins] = useState(false);

  const [areStatsLoading, setAreStatsLoading] = useState(false);
  const [triggerStatsRefetch, setTriggerStatsRefetch] = useState(0);

  const [isRewardsListLoading, setIsRewardsListLoading] = useState(false);
  const [triggerRewardsRefetch, setTriggerRewardsRefetch] = useState(0);

  const [isAppointmentsListLoading, setIsAppointmentsListLoading] =
    useState(false);
  const [triggerAppointmentsRefetch, setTriggerAppointmentsRefetch] =
    useState(0);

  // console.log('UserStatsPage myProfile: ', myProfile);
  // console.log('UserStatsPage allRewards: ', allRewards);

  console.log(
    'ProfileStatsPage unused for now state variables: ',
    rewardChecked,
    setStartBefore,
    setEndAfter,
    customRewardCount,
    maxCustomRewards
  );

  const onTriggerRefetch = () => {
    setTriggerRefetch((refetch) => refetch + 1);
  };

  const onTriggerCoinRefetch = () => {
    setTriggerCoinRefetch((refetch) => refetch + 1);
  };

  const onTriggerProfileDetailsRefetch = () => {
    setTriggerProfileDetailsRefetch((refetch) => refetch + 1);
  };

  const onTriggerStatsRefetch = () => {
    setTriggerStatsRefetch((refetch) => refetch + 1);
  };

  const onTriggerRewardsRefetch = () => {
    setTriggerRewardsRefetch((refetch) => refetch + 1);
  };

  const onTriggerAppointmentsRefetch = () => {
    setTriggerAppointmentsRefetch((refetch) => refetch + 1);
  };

  console.log(
    'onTriggerRefetches unused for now: ',
    onTriggerRefetch,
    onTriggerStatsRefetch,
    onTriggerAppointmentsRefetch
  );

  // Needed for fetchStats
  const getDimension = (type, inputNow) => {
    // console.log('fetchStats getDimension: ', type, inputNow);
    const now = new Date(utils.formatDate(inputNow, 'MM/DD/YY'));
    const curMonth = now.getUTCMonth() + 1;
    const curYear = now.getUTCFullYear();
    const curDayOfWeek = now.getUTCDay();
    // const curDay = now.getUTCDate();

    // console.log('type: ', type);
    // console.log('now: ', now);
    // console.log('curMonth: ', curMonth);
    // console.log('curYear: ', curYear);
    // console.log('curDayOfWeek: ', curDayOfWeek);
    // console.log('curDay: ', curDay);

    let monStr;
    if (curMonth < 10) {
      monStr = '0' + curMonth;
    } else {
      monStr = curMonth;
    }

    if (type === 'current') {
      return 'current:current';
    } else if (type === 'all') {
      return 'A:';
    } else if (type === 'current_day' || type === 'select_day') {
      // setDateRange(utils.formatDate(now, "MM/DD/YY"));
      const nowDateStr = utils.formatDate(now, 'YYYY-MM-DD');
      return 'D:' + nowDateStr;
    } else if (type === 'last_day') {
      const nowDate = now;
      nowDate.setDate(now.getDate() - 1);
      // setDateRange(utils.formatDate(nowDate, "MM/DD/YY"));
      const nowDateStr = utils.formatDate(now, 'YYYY-MM-DD');
      return 'D:' + nowDateStr;
    } else if (
      type === 'current_week' ||
      type === 'select_week' ||
      type === 'last_week'
    ) {
      //
      //  The current week always starts on Monday
      //
      let weekStartDay = 0;
      if (global.appSettings.weekStartDay !== undefined) {
        //
        //  weekStartDay is the PYTHON week where 0=Monday, 6=Sunday
        //
        weekStartDay = global.appSettings.weekStartDay;
        //
        //  Convert to the Javascript week where 0=Sunday, 6=Saturday
        //
        weekStartDay += 1;
        if (weekStartDay > 6) {
          weekStartDay = 0;
        }
      }
      // console.log('weekStartDay: ', weekStartDay);

      const offset = (((curDayOfWeek - weekStartDay) % 7) + 7) % 7;
      const first = now.getDate() - offset;

      // console.log('+!+offset: ', offset);
      // console.log('first: ', first);
      // if ( curDayOfWeek === 0 ) {
      //   var first = now.getDate() -6; // First day is the day of the month - the day of the week
      // } else {
      //   var first = now.getDate() - now.getDay()+1; // First day is the day of the month - the day of the week
      // }
      // const last = first + 6; // last day is the first day + 6

      //
      //  if we're dealing with last week, subtract 7 from the first day and last day
      //
      let offsetDays = 0;
      if (type === 'last_week') {
        offsetDays = 7;
      }
      const firstday = new Date(now.setDate(first - offsetDays)).toUTCString();
      // const lastday = new Date(now.setDate(last - offsetDays)).toUTCString();

      // setDateRange(utils.formatDate(firstday, "MM/DD/YY") + " - " + utils.formatDate(lastday, "MM/DD/YY"));
      // console.log('firstday: ', firstday, lastday);
      // console.log('current week: ', 'W:' + firstday);
      const weekStart = utils.formatDate(firstday, 'YYYY-MM-DD');
      // console.log('weekStart: ', weekStart);
      return 'W:' + weekStart;
    } else if (type === 'best_all') {
      return 'A:BEST';
    } else if (type === 'best_year') {
      return 'Y:BEST';
    } else if (type === 'best_month') {
      return 'M:BEST';
    } else if (type === 'best_week') {
      return 'W:BEST';
    } else if (type === 'best_day') {
      return 'D:BEST';
    } else if (
      type === 'current_month' ||
      type === 'select_month' ||
      type === 'last_month'
    ) {
      let ourMonth = curMonth;
      let ourYear = curYear;
      if (type === 'last_month') {
        if (curMonth === 1) {
          ourMonth = 12;
          ourYear--;
        } else {
          ourMonth--;
        }
      }
      // const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      // setDateRange(MONTHS[ourMonth - 1] + " " + ourYear);

      let ourMonStr;
      if (ourMonth < 10) {
        ourMonStr = '0' + ourMonth;
      } else {
        ourMonStr = ourMonth;
      }
      return 'M:' + ourYear + '-' + ourMonStr;
    } else if (type === 'current_year' || type === 'select_year') {
      // setDateRange(curYear.toString());
      return 'Y:' + curYear;
    } else if (type === 'last_year') {
      // setDateRange((curYear - 1).toString());return ('Y:' + curYear - 1);
    } else if (type === 'current_pay_period' || type === 'select_pay_period') {
      //
      //  TODO: Calculate the current pay period start date
      //
      const diff = now.getDate() - curDayOfWeek + (curDayOfWeek === 0 ? -6 : 1); // adjust when day is sunday
      // console.log('diff: ', diff);

      const monday = new Date(now.setDate(diff)).getDate();
      // console.log('monday: ', monday);

      let dayStr;
      if (monday < 10) {
        dayStr = '0' + monday;
      } else {
        dayStr = monday;
      }
      const startDateString = curYear + '-' + monStr + '-' + dayStr;
      // const startDate = new Date(startDateString);
      // console.log('startDateString: ', startDateString);
      // console.log('startDate: ', startDate);
      // const endDate = new Date(startDate.setDate(startDate.getDate() + 7));
      // console.log('endDate: ', endDate);

      // setDateRange(utils.formatDate(startDateString, "MM/DD/YY") + " - " + utils.formatDate(endDate, "MM/DD/YY"));
      return 'P:' + startDateString;
    } else {
      // setDateRange(curYear.toString());
      return 'Y:' + curYear;
    }
  };

  useEffect(() => {
    if (!userId) return;

    // use location state to determine whether user navigated from TeamPage for correct routing on going back
    if (location?.state?.teamPage) setRouteBackToTeamPage(true);
    // use location state to get the teamId if coming from a TeamPage for correct TeamPage routing on going baack
    if (location?.state?.teamId) setTeamId(location.state.teamId);
    // use location state to determine whether user navigated from the Leaderboard for correct routing on going back
    if (location?.state?.leaderboard) setRouteBackToLeaderbaord(true);
  }, []);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfile() {
      setIsLoading(true);

      const user = await fetchProfile(userId);

      // Handle invalid userId
      if (!user) {
        setIsLoading(false);
        return;
      }

      setMyProfile(user);
      setRolePositionObj({
        original: {
          position: user?.orgs?.items[0]?.memberType,
          role: user.functionalRole.toLowerCase(),
        },
        form: {
          position: user?.orgs?.items[0]?.memberType,
          role: user.functionalRole.toLowerCase(),
        },
      });

      const ourDimension = getDimension(
        dimensionType,
        utils.getLocalDateTime(new Date())
      );
      setDimension(ourDimension);
      const userStats = await fetchStats(userId, ourDimension);
      // const userStats = await fetchStats(userId, timeframe);
      setStats(userStats);

      const [userRewards, userRewardChecked] = await fetchProfileRewards(
        searchAssigneeID,
        searchStatus
      );
      setRewards(userRewards);
      setRewardChecked(userRewardChecked);

      const [tempAllRewards, tempCustomRewardCount] = await fetchAllRewards(
        startBefore,
        endAfter
      );
      setAllRewards(tempAllRewards[0]);
      setCustomRewardCount(tempCustomRewardCount);

      const people = await fetchPeople('');
      setOrgPeople(people);

      const userAppointments = await fetchAppointments(scheduleType, userId);
      setAppointments(userAppointments);

      setIsLoading(false);
    }
    prepareProfile();
  }, [userId, triggerRefetch]);

  // useEffect for changing searchParams in url
  useEffect(() => {
    searchParams.set('subPage', subPage);
    navigate(`?${searchParams.toString()}`);
  }, [subPage, searchParams]);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfileDetails() {
      setIsLoading(true);

      const user = await fetchProfile(userId);

      // Handle invalid userId
      if (!user) {
        setIsLoading(false);
        return;
      }

      setMyProfile(user);
      setRolePositionObj({
        original: {
          position: user?.orgs?.items[0]?.memberType,
          role: user.functionalRole.toLowerCase(),
        },
        form: {
          position: user?.orgs?.items[0]?.memberType,
          role: user.functionalRole.toLowerCase(),
        },
      });

      setIsLoading(false);
    }
    prepareProfileDetails();
  }, [triggerProfileDetailsRefetch]);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfileForCoins() {
      setAreCoinsLoading(true);

      const user = await fetchProfile(userId);

      // Handle invalid userId
      if (!user) {
        setIsLoading(false);
        return;
      }

      setMyProfile(user);

      setAreCoinsLoading(false);
    }
    prepareProfileForCoins();
  }, [triggerCoinRefetch]);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfileStats() {
      setAreStatsLoading(true);

      if (dimensionType.split('_')[0] !== 'select') {
        // Need to use getDimension to get updated dimension to use if not selecting a date.
        const ourDimension = getDimension(
          dimensionType,
          utils.getLocalDateTime(new Date())
        );
        setDimension(ourDimension);
        const userStats = await fetchStats(userId, ourDimension);
        setStats(userStats);
      } else {
        // Dimension set by the DatePicker when dimensionType is select_day, select_week, select_month, or select_year. Just need to fetch using updated dimension.
        const userStats = await fetchStats(userId, dimension);
        setStats(userStats);
      }

      setAreStatsLoading(false);
    }
    prepareProfileStats();
  }, [dimension, triggerStatsRefetch]);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfileRewards() {
      setIsRewardsListLoading(true);

      const [userRewards, userRewardChecked] = await fetchProfileRewards(
        searchAssigneeID,
        searchStatus
      );

      setRewards(userRewards);
      setRewardChecked(userRewardChecked);

      setIsRewardsListLoading(false);
    }
    prepareProfileRewards();
  }, [searchStatus, searchAssigneeID, triggerRewardsRefetch]);

  useEffect(() => {
    if (!userId) return;

    async function prepareProfileAppointments() {
      setIsAppointmentsListLoading(true);

      const userAppointments = await fetchAppointments(scheduleType, userId);
      setAppointments(userAppointments);

      setIsAppointmentsListLoading(false);
    }
    prepareProfileAppointments();
  }, [scheduleType, triggerAppointmentsRefetch]);

  // TO DO: Make 2 more useEffects for allRewards on startBefore or endAfter change, and Appointments on scheduleType change

  // console.log('ProfileStatsPage myProfile: ', myProfile);
  // console.log('ProfileStatsPage dimension: ', dimension);
  // console.log('ProfileStatsPage dimensionType: ', dimensionType);
  // console.log('ProfileStatsPage rewards: ', rewards);

  // useEffect(() => {
  //   console.log(myProfile);
  // }, [myProfile]);

  const handleBack = () => {
    if (routeBackToTeamPage) {
      navigate(`/teams/${teamId}`);
    } else if (routeBackToLeaderboard) {
      navigate('/kpileaderboard');
    } else {
      navigate('/users');
    }
  };

  const normalizedOrgPeople = orgPeople.reduce(
    (obj, item) => Object.assign(obj, { [item.value]: item }),
    {}
  );

  // console.log('ProfileStatsPage orgPeople: ', orgPeople);
  // console.log('ProfileStatsPage normalizedOrgPeople: ', normalizedOrgPeople);

  // NOTE: need to account for fetch for allRewards and orgPeople happening after isLoading is set to false when on the Rewards subpage. Most likely due to state behavior from multiple states being set for Rewards in prepareProfile. All rewards data is fetched after orgPeople is fetched and set.
  if (isLoading || (subPage === 'rewards' && orgPeople.length === 0))
    return (
      <ProfileStatsPageSkeleton
        areStatsLoading={areStatsLoading}
        routeBackToTeamPage={routeBackToTeamPage}
        teamId={teamId}
        routeBackToLeaderboard={routeBackToLeaderboard}
      ></ProfileStatsPageSkeleton>
    );

  // At a url with a userId for a user that doesn't exist
  if (!myProfile)
    return <ProfileNoUserDisplay isDeleted={false}></ProfileNoUserDisplay>;

  // At a url for a user that has been deleted
  if (myProfile.status === 'deleted')
    return <ProfileNoUserDisplay isDeleted={true}></ProfileNoUserDisplay>;

  return (
    <>
      <UserFormModal
        showUserFormModal={showUserFormModal}
        setShowUserFormModal={setShowUserFormModal}
        onTriggerUsersRefetch={onTriggerRefetch}
        userId={myProfile.id}
        user={myProfile}
        zIndex={100000}
      ></UserFormModal>
      <SendCoins
        user={myProfile}
        showSendCoins={showSendCoins}
        setShowSendCoins={setShowSendCoins}
        onTriggerCoinRefetch={onTriggerCoinRefetch}
      ></SendCoins>
      <Box
        style={{
          // height: '57vh',
          height: 540,
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          background:
            'radial-gradient(circle at 50% 80%, #8785EA 0%, #A0C5F9 50%, #BDD4EF 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'profile-stats-page-back-button'}
            onClick={(e) => handleBack(e)}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {routeBackToTeamPage
                ? 'Back to team'
                : routeBackToLeaderboard
                ? 'Back to leaderboard'
                : 'Back to users'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            width: '40vw',
            // width: 768,
            height: '100%',
            padding: '15px 15px 45px 15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {/* Users created on web get imageType 'image/<jpeg, png, etc...>', account for that */}
          {myProfile.avatarFile ? (
            myProfile.imageType === 'picture' ||
            myProfile.imageType.split('/')[0] === 'image' ? (
              <Box
                component={'img'}
                sx={{
                  width: 240,
                  height: 240,
                  minHeight: 240,
                  objectFit: 'cover',
                  borderRadius: '2000px',
                }}
                alt={myProfile.name}
                src={myProfile.avatarFile}
              />
            ) : (
              <CardMedia
                image={myProfile.imageName}
                component="img"
                sx={{
                  borderRadius: '2000px',
                  width: 240,
                  height: 240,
                  objectFit: 'contain',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#868686',
                fontSize: '96px',
                width: 240,
                height: 240,
                minHeight: 240,
                borderRadius: '2000px',
              }}
              variant="rounded"
            >
              {myProfile.initials}
            </Avatar>
          )}
          <Box style={{ display: 'flex' }}>
            <Text
              style={{
                fontSize: '36px',
                fontWeight: 700,
                marginTop: '16px',
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              {myProfile.name}
            </Text>
            {myProfile.status !== 'active' && (
              <Text
                style={{
                  // color: 'red',
                  color: '#DA2424',
                  fontSize: '36px',
                  fontWeight: 400,
                  marginTop: '16px',
                  marginLeft: 4,
                }}
              >
                (Deactivated)
              </Text>
            )}
          </Box>
          <Text
            style={{
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {myProfile.title}
          </Text>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12,
            }}
          >
            {global.me.isAdmin && featureFlag('sendCoins') && (
              <Box style={{ width: 24, height: 24, marginRight: 8 }}>
                <Typography style={{ color: 'transparent' }}>.</Typography>
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#5F27CD',
                padding: '6px 8px 6px 8px',
                borderRadius: '70px',
              }}
            >
              <GamifyIcon icon={'coins'}></GamifyIcon>
              {areCoinsLoading ? (
                <>
                  <CircularProgress
                    style={{
                      color: 'white',
                      width: 14,
                      height: 14,
                      marginLeft: 4,
                    }}
                  ></CircularProgress>
                  <Typography
                    style={{
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: 500,
                      marginLeft: 4,
                    }}
                  >
                    coins
                  </Typography>
                </>
              ) : (
                <Typography
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginLeft: 4,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {myProfile.points === 1
                    ? `${myProfile.points} coin`
                    : `${myProfile.points} coins`}
                </Typography>
              )}
            </Box>
            {global.me.isAdmin && featureFlag('sendCoins') && (
              <Button
                onClick={() => setShowSendCoins(true)}
                sx={{ cursor: 'pointer' }}
                style={{
                  width: 24,
                  minWidth: 24,
                  height: 24,
                  padding: 0,
                  borderRadius: '1000px',
                  marginLeft: 8,
                }}
                disableRipple={true}
              >
                <GamifyIcon icon={'add'}></GamifyIcon>
              </Button>
            )}
          </Box>
        </Box>
        {/* Box below keeps team image, name, and description centered */}
        <Box
          style={{
            display: 'flex',
            minWidth: routeBackToTeamPage
              ? 155.86
              : routeBackToLeaderboard
              ? 210.48
              : 159.45,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '-30px',
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '30vw',
            minWidth: 250,
            justifyContent: 'space-around',
          }}
        >
          {/* Only allow Admin, Moderator, Manager, and Self to Edit user */}
          {/* DO NOT ALLOW USER TO EDIT PERMISSIONS IF NOT ADMIN, MODERATOR, OR MANAGER */}
          {(global.me.isAdmin ||
            global.me.isModerator ||
            global.me.functionalRoles?.includes('manager') ||
            userId === global.me.id) &&
            Object.values({
              edit: {
                // icon: '/images/Edit_Pencil.svg',
                icon: 'edit',
                title: 'Edit',
                clickFunction: function () {
                  setShowUserFormModal(true);

                  // NOTE: No longer need to navigate to separate route, UserFormPage is now a modal
                  // navigate(`/users/edit/${myProfile.id}`, {
                  //   // Indicate that user is editing from ProfileStatsPage so they reroute back here when cancelling or updating
                  //   // NOTE: pass myProfile to UserFormModal as the user prop
                  //   state: { user: myProfile, statsProfile: true },
                  // });
                },
              },
              availability: {
                // icon: '/images/add-people-icon.svg',
                icon: 'calendar',
                title: 'Availability',
                clickFunction: function () {
                  console.log('Clicked Availability');
                  // setShowAddMemberModal(true);
                },
              },
              rolePosition: {
                // icon: '/images/link-icon.svg',
                icon: 'role',
                title: 'Permissions',
                clickFunction: function (e) {
                  // console.log('Clicked Permissions');
                  // setShowPermissionsModal(true);
                  setPermissionsAnchorEl(e.currentTarget);
                },
              },
            }).map((button, i) => {
              // console.log('button: ', button);
              if (
                userId === global.me.id &&
                !global.me.isAdmin &&
                !global.me.isModerator &&
                !global.me.functionalRoles?.includes('manager') &&
                button.title === 'Permissions'
              )
                return <></>;
              return (
                <Box
                  key={i}
                  onClick={button.clickFunction}
                  className="hov"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 91, // keep width uniform to stay centered
                  }}
                >
                  <Box
                    style={{
                      height: '56px',
                      width: '56px',
                      borderRadius: '50%',
                      boxShadow: '-1px 1px 4px 0 rgba(0, 0, 0, 0.1)',
                      // border: '1px solid red',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginBottom: '10px',
                    }}
                  >
                    <GamifyIcon
                      icon={button.icon}
                      color={'#5F27CD'}
                    ></GamifyIcon>
                  </Box>
                  <Text
                    style={{
                      color: '#5F27CD',
                      fontWeight: '600',
                      fontSize: 14,
                    }}
                  >
                    {button.title}
                  </Text>
                </Box>
              );
            })}
        </Box>
        <Box
          style={{
            width: '40vw',
            height: '100%',
            marginTop: '4ch',
            minWidth: 382,
          }}
        >
          <Box style={{ borderBottom: '1px solid #868686', display: 'flex' }}>
            <Box
              className="profile-stats-page-section-button hov"
              style={{
                borderBottom:
                  subPage === 'stats' ? '3px solid #5f27cd' : 'none',
                color: subPage === 'stats' ? '#5f27cd' : '#868686',
              }}
              onClick={() => setSubPage('stats')}
            >
              Stats
            </Box>

            <Box
              className="profile-stats-page-section-button hov"
              style={{
                borderBottom:
                  subPage === 'rewards' ? '3px solid #5f27cd' : 'none',
                color: subPage === 'rewards' ? '#5f27cd' : '#868686',
              }}
              onClick={() => setSubPage('rewards')}
            >
              Rewards
            </Box>

            {canSeeSchedule && (
              <Box
                className="profile-stats-page-section-button hov"
                onClick={() => setSubPage('appointments')}
                style={{
                  borderBottom:
                    subPage === 'appointments' ? '3px solid #5f27cd' : 'none',
                  color: subPage === 'appointments' ? '#5f27cd' : '#868686',
                }}
              >
                Appointments
              </Box>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: '40vw',
            marginTop: '2ch',
            minWidth: subPage === 'stats' ? 382 : 'fit-content',
            // NOTE: no minWidth on stats subpage because it results in the stats taking up the full width of the screen, not 40vw
          }}
        >
          {subPage === 'stats' && myProfile && (
            <UserStats
              stats={stats}
              setDimension={setDimension}
              selectDate={selectDate}
              setSelectDate={setSelectDate}
              dimensionType={dimensionType}
              setDimensionType={setDimensionType}
              dimensionTypes={dimensionTypes}
              getDimension={getDimension}
              areStatsLoading={areStatsLoading}
            ></UserStats>
          )}
          {subPage === 'rewards' && myProfile && (
            <UserRewards
              user={myProfile}
              rewards={rewards}
              searchStatus={searchStatus}
              setSearchStatus={setSearchStatus}
              orgPeople={orgPeople}
              normalizedOrgPeople={normalizedOrgPeople}
              searchAssigneeID={searchAssigneeID}
              setSearchAssigneeID={setSearchAssigneeID}
              allRewards={allRewards}
              isRewardsListLoading={isRewardsListLoading}
              onTriggerRewardsRefetch={onTriggerRewardsRefetch}
            ></UserRewards>
          )}
          {subPage === 'appointments' && myProfile && (
            <UserAppointments
              user={myProfile}
              appointments={appointments}
              setScheduleType={setScheduleType}
              isAppointmentsListLoading={isAppointmentsListLoading}
              setIsAppointmentsListLoading={setIsAppointmentsListLoading}
              onTriggerAppointmentsRefetch={onTriggerAppointmentsRefetch}
            ></UserAppointments>
          )}
        </Box>
      </Box>
      <EditUserModal
        user={myProfile}
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onTriggerTableRefetch={() => onTriggerRefetch()}
      />
      <PermissionsModal
        user={myProfile}
        rolePositionObj={rolePositionObj}
        setRolePositionObj={setRolePositionObj}
        // showPermissionsModal={showPermissionsModal}
        // setShowPermissionsModal={setShowPermissionsModal}
        permissionsAnchorEl={permissionsAnchorEl}
        setPermissionsAnchorEl={setPermissionsAnchorEl}
        onTriggerProfileDetailsRefetch={onTriggerProfileDetailsRefetch}
      ></PermissionsModal>
    </>
  );
}

function SendCoins(props) {
  const { user, showSendCoins, setShowSendCoins, onTriggerCoinRefetch } = props;
  const [text, setText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  async function sendCoins(userId, points) {
    try {
      await API.graphql({
        query: mutations.giveGift,
        variables: {
          giveToUserID: userId,
          points,
          fromAdmin: global.me.isAdmin,
        },
      });

      setIsSaving(false);
      setShowSendCoins(false);
      setText('');
      onTriggerCoinRefetch();

      GamifyToast.success(
        points === 1
          ? `${points} coin sent to ${user.name} successfully`
          : `${points} coins sent to ${user.name} successfully`
      );
    } catch (err) {
      GamifyToast.error('There was an error sending coins, please try again');
      console.error('Error sending coins: ', err);
      setIsSaving(false);
    }
  }

  const handleCancelSendClick = () => {
    setShowSendCoins(false);
  };

  const handleClearInput = () => {
    setText('');
  };

  const cancelIconAdornment =
    text.length > 0 ? (
      <Button
        className={'clear-input-button'}
        onClick={handleClearInput}
        disableRipple={true}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Button>
    ) : (
      <></>
    );

  return (
    <Backdrop
      sx={{ zIndex: 100001 }}
      open={showSendCoins}
      onClick={(e) => {
        if (
          !e.target.closest('.MuiInputBase-root') &&
          !e.target.closest('.send-coins-button')
        ) {
          handleCancelSendClick();
        }
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: 624,
          height: 286,
          padding: '40px 40px 32px 40px',
          borderRadius: 8,
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            style={{
              color: '#222428',
              fontSize: '28px',
              fontWeight: 700,
              lineHeight: '48px',
              letterSpacing: '-0.02em',
              marginBottom: '16px',
            }}
          >
            Send Coins
          </Typography>
        </Box>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: '#0B0B0C',
              marginBottom: 8,
            }}
          >
            Coins
          </Typography>
          <Input
            sx={{
              '&:before': {
                border: '0 !important',
              },
              '&:after': {
                border: '0 !important',
              },
            }}
            style={{
              backgroundColor: '#F0F0F3',
              width: '100%',
              padding: '12px 16px 12px 16px',
              border: 0,
              borderRadius: 8,
              marginBottom: 16,
            }}
            placeholder={'Coins'}
            inputProps={{ maxLength: 10 }}
            value={text}
            onChange={(e) => {
              if (isNaN(e.target.value)) return;
              //   console.log('ON CHANGE EVENT --->', e);
              //   console.log('ON CHANGE EVENT TARGET VALUE --->', e.target.value);
              setText(e.target.value);
            }}
            endAdornment={cancelIconAdornment}
          />
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            onClick={() => handleCancelSendClick()}
            sx={{ cursor: 'pointer' }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 262,
              padding: 12,
              border: '1px solid #cfcfcf',
              borderRadius: 8,
            }}
          >
            <Typography style={{ color: '#323232', fontWeight: 700 }}>
              Cancel
            </Typography>
          </Box>
          <Box
            className={'send-coins-button'}
            onClick={() => {
              // Don't repeat if saving in progress
              if (isSaving) return;
              // Don't submit when no input
              if (text === '') return;
              setIsSaving(true);
              sendCoins(user.id, Number(text));
            }}
            sx={{ cursor: text === '' ? 'default' : 'pointer' }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: text === '' ? '#868686' : '#5F27CD',
              width: 262,
              padding: 12,
              borderRadius: 8,
            }}
          >
            {isSaving ? (
              <CircularProgress
                style={{ color: 'white', width: 24, height: 24 }}
              ></CircularProgress>
            ) : (
              <Typography style={{ color: 'white', fontWeight: 700 }}>
                Send
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}

function UserStats({
  stats,
  setDimension,
  selectDate,
  setSelectDate,
  dimensionType,
  setDimensionType,
  dimensionTypes,
  getDimension,
  areStatsLoading,
}) {
  // console.log('UserStats stats: ', stats);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());

  const numColumns = 3;
  const gap = 16;
  const columnWidth = `calc((100% - ${numColumns * gap}px) / ${numColumns})`; // 10 is gap between items
  const containerWidth = `calc(100% + ${gap}px)`;
  const filterWidth = `calc(100% - (${gap}px / 2))`;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2ch',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box style={{ width: '33%', marginRight: 8 }}>
            <Typography>Date Range:</Typography>
            <StyledSelect
              style={{ width: filterWidth }}
              onChange={(e) => {
                if (
                  e.target.value === 'select_day' ||
                  e.target.value === 'select_week' ||
                  e.target.value === 'select_month' ||
                  e.target.value === 'select_year' ||
                  e.target.value === 'select_pay_period'
                ) {
                  setSelectDate(true);
                  setDimensionType(e.target.value);

                  // getDimension and setDimension will be done with the date selected rendered by selectDate being true, return here
                  return;
                } else {
                  setSelectDate(false);
                  setSelectedDateTime(new Date());
                  setDimensionType(e.target.value);
                }

                const ourDimension = getDimension(
                  e.target.value,
                  utils.getLocalDateTime(new Date())
                );
                setDimension(ourDimension);
              }}
              value={dimensionType}
              name="status"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {dimensionTypes.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box style={{ width: '33%' }}>
            {selectDate && (
              <>
                <Typography>Select Date:</Typography>
                <Box
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      value={selectedDateTime}
                      onChange={(newDateTime) => {
                        setSelectedDateTime(Moment(newDateTime).toDate());
                        const newDimension = getDimension(
                          dimensionType,
                          Moment(newDateTime).toDate()
                        );
                        setDimension(newDimension);
                      }}
                      // minDate={dateInputProps.minimumDate}
                      renderInput={(inputProps) => {
                        console.log('FormDatePicker inputProps: ', inputProps);
                        return (
                          <TextField
                            {...inputProps}
                            id={'form-date-picker'}
                            sx={{
                              width: filterWidth,
                              '& .MuiInputBase-root': {
                                border: '1px solid #D0D2D8',
                                borderRadius: '8px',
                                padding: '7.5px 16px 7.5px 12px',
                                width: '100%',
                              },
                              '& .MuiInputBase-input': {
                                width: '100%',
                              },
                            }}
                            variant="standard"
                            InputProps={{
                              ...inputProps.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        );
                      }}
                      PopperProps={{
                        style: { borderRadius: '8px' },
                      }}
                      PaperProps={{
                        style: {
                          border: '1px solid #D0D2D8',
                          borderRadius: '8px',
                        },
                      }}
                      // NOTE: slotProps below replaces renderInput in MUI v6
                      // slotProps={{ textField: { variant: 'outlined' } }}
                    ></DatePicker>
                  </LocalizationProvider>
                </Box>
                {/* // <Calendar
              //   onDayPress={(day) => {
              //     const newDimension = getDimension(
              //       dimensionType,
              //       new Date(day.timestamp)
              //     );
              //     setDimension(newDimension);
              //     getData(props.userID, props.dimensionType + ":" + newDimension);
              //     setSelectDate(false);
              //   }}
              // /> */}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {areStatsLoading ? (
        <ProfileStatsCardsSkeleton></ProfileStatsCardsSkeleton>
      ) : (
        <Box id="stats-subpage" style={{ width: containerWidth, gap }}>
          {stats.map((kpi, i) => {
            // console.log('stats kpi: ', kpi);
            return (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: columnWidth,
                  paddingTop: 20,
                  paddingBottom: 28,
                  border: '1px solid #D0D2D8',
                  borderRadius: 8,
                }}
                key={i}
              >
                <Typography
                  style={{
                    color: '#060606',
                    fontSize: '12px',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  {kpi.KPIRule.title}
                </Typography>
                <Typography
                  style={{
                    color: '#060606',
                    fontSize: '32px',
                    fontWeight: 700,
                  }}
                >
                  {Number.isInteger(kpi.val) ? kpi.val : kpi.val.toFixed(2)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
}

function UserRewards({
  user,
  rewards,
  searchStatus,
  setSearchStatus,
  orgPeople,
  normalizedOrgPeople,
  searchAssigneeID,
  setSearchAssigneeID,
  allRewards,
  isRewardsListLoading,
  onTriggerRewardsRefetch,
}) {
  // console.log('UserRewards rewards: ', rewards);
  const [showSendReward, setShowSendReward] = useState(false);

  const filterItemList = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'error',
      label: 'Error',
    },
    {
      value: 'requested',
      label: 'Requested',
    },
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'denied',
      label: 'Denied',
    },
    {
      value: 'delivered',
      label: 'Delivered',
    },
    {
      value: 'returned',
      label: 'Returned',
    },
    {
      value: 'shipped',
      label: 'Shipped',
    },
    {
      value: 'backordered',
      label: 'Backordered',
    },
  ];
  return (
    <>
      <SendReward
        user={user}
        allRewards={allRewards}
        showSendReward={showSendReward}
        setShowSendReward={setShowSendReward}
        onTriggerRewardsRefetch={onTriggerRewardsRefetch}
      ></SendReward>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2ch',
        }}
      >
        {/* <Typography
          style={{ color: '#0B0B0C', fontSize: '16px', fontWeight: 600 }}
        >
          All rewards
        </Typography> */}
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box style={{ width: '33%', marginRight: 16 }}>
            <Typography>Status:</Typography>
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => setSearchStatus(e.target.value)}
              value={searchStatus}
              name="status"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {filterItemList.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          {global.me.isAdmin && (
            <Box style={{ width: '33%' }}>
              <Typography>Person:</Typography>
              <StyledSelect
                style={{ width: '100%' }}
                // placeholder="HELLO!!"
                onChange={(e) => setSearchAssigneeID(e.target.value)}
                value={searchAssigneeID}
                name="person"
                MenuProps={{
                  style: {
                    zIndex: 100002,
                  },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
              >
                {orgPeople.map((opt, i) => (
                  <StyledMenuItem value={opt.value} key={i}>
                    {opt.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
        </Box>
        {global.me.isAdmin && featureFlag('sendRewards') ? (
          <Button
            onClick={() => setShowSendReward(true)}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
            style={{
              padding: 0,
              minWidth: 140,
            }}
            disableRipple={true}
          >
            <GamifyIcon icon={'send'}></GamifyIcon>
            <Typography
              style={{
                color: '#5F27CD',
                fontSize: '16px',
                fontWeight: 700,
                marginLeft: 7,
              }}
            >
              Send reward
            </Typography>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Box>
      {isRewardsListLoading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <CircularProgress style={{ color: 'black' }}></CircularProgress>
        </Box>
      )}
      {rewards.length > 0 ? (
        rewards.map((reward, i) => {
          return (
            // NOTE: reward.purchaseCount > 0 is now filtered for in fetchProfileRewards
            // <>
            //   {reward.purchaseCount > 0 && (
            <RewardCard
              key={i}
              user={normalizedOrgPeople[searchAssigneeID]}
              reward={reward}
            />
            //   )}
            // </>
          );
        })
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 150,
          }}
        >
          <Typography>
            No Available{' '}
            {searchStatus === 'all'
              ? ''
              : searchStatus[0].toUpperCase() + searchStatus.slice(1)}{' '}
            {global.appSettings.labels.reward.plural[0].toUpperCase() +
              global.appSettings.labels.reward.plural.slice(1)}{' '}
            for {normalizedOrgPeople[searchAssigneeID].label}
          </Typography>
        </Box>
      )}
    </>
  );
}

// TO DO: look into reward not showing up after sending and coins not reducing
function SendReward(props) {
  const {
    user,
    allRewards,
    showSendReward,
    setShowSendReward,
    onTriggerRewardsRefetch,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [rewardID, setRewardID] = useState(
    allRewards.rewards.length > 0 ? allRewards.rewards[0].id : ''
  );
  const [rewardName, setRewardName] = useState(
    allRewards.rewards.length > 0 ? allRewards.rewards[0].name : ''
  );

  const normalizedAllRewards = allRewards?.rewards.reduce(
    (obj, item) => Object.assign(obj, { [item.id]: item }),
    {}
  );

  // console.log('SendReward normalizedAllRewards: ', normalizedAllRewards);

  async function sendReward(userID, rewardID) {
    try {
      await API.graphql({
        query: mutations.giveGift,
        variables: {
          giveToUserID: userID,
          rewardID,
          fromAdmin: global.me.isAdmin,
        },
      });
      setIsSaving(false);
      setShowSendReward(false);
      onTriggerRewardsRefetch();

      GamifyToast.success(`${rewardName} sent to ${user.name} successfully`);
      setRewardID(
        allRewards.rewards.length > 0 ? allRewards.rewards[0].id : ''
      );
      setRewardName(
        allRewards.rewards.length > 0 ? allRewards.rewards[0].name : ''
      );
    } catch (err) {
      GamifyToast.error(
        `There was an error sending ${rewardName}, please try again`
      );
      console.error('Error sending reward: ', err);
      setIsSaving(false);
    }
  }

  const handleCancelSendClick = () => {
    setShowSendReward(false);
  };

  return (
    <Backdrop
      sx={{ zIndex: 100001 }}
      open={showSendReward}
      onClick={(e) => {
        if (
          !e.target.closest('.send-reward-button') &&
          !e.target.closest('.MuiSelect-select') &&
          !e.target.closest('.MuiMenuItem-root')
        ) {
          handleCancelSendClick();
        }
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: 624,
          maxHeight: '65vh',
          padding: '24px 40px 32px 24px',
          borderRadius: 8,
          overflowY: 'auto',
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            style={{
              color: '#222428',
              fontSize: '28px',
              fontWeight: 700,
              lineHeight: '48px',
              letterSpacing: '-0.02em',
              marginBottom: '16px',
            }}
          >
            Send a Reward
          </Typography>
        </Box>
        <Box>
          <DisplayMedia
            imageName={normalizedAllRewards[rewardID].imageName}
            imageType={normalizedAllRewards[rewardID].imageType}
            width={'100%'}
            height={300}
            objectFit={'cover'}
          ></DisplayMedia>
        </Box>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: '#0B0B0C',
              marginBottom: 8,
            }}
          >
            Reward
          </Typography>
          <StyledSelect
            style={{ width: '100%' }}
            // placeholder="HELLO!!"
            onChange={(e) => {
              setRewardID(e.target.value);
              setRewardName(normalizedAllRewards[e.target.value].name);
            }}
            value={rewardID}
            name="reward"
            MenuProps={{
              style: { zIndex: 100002 },
              MenuListProps: {
                sx: {
                  maxHeight: 300,
                  overflowY: 'auto',
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderRadius: '8px',
                  border: '1px solid #D0D2D8',
                },
              },
            }}
          >
            {[...allRewards.rewards].map((opt, i) => (
              <StyledMenuItem value={opt.id} key={i}>
                {opt.name}: {`${opt.cost} coins`}
                {/* {opt[0].toUpperCase() + opt.slice(1)} */}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            onClick={() => handleCancelSendClick()}
            sx={{ cursor: 'pointer' }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 262,
              padding: 12,
              border: '1px solid #cfcfcf',
              borderRadius: 8,
            }}
          >
            <Typography style={{ color: '#323232', fontWeight: 700 }}>
              Cancel
            </Typography>
          </Box>
          <Box
            className={'send-reward-button'}
            onClick={() => {
              // Don't repeat if saving in progress
              if (isSaving) return;
              // Don't submit when no input
              if (rewardID === '') return;
              setIsSaving(true);
              sendReward(user.id, rewardID);
            }}
            sx={{ cursor: rewardID === '' ? 'default' : 'pointer' }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: rewardID === '' ? '#868686' : '#5F27CD',
              width: 262,
              padding: 12,
              borderRadius: 8,
            }}
          >
            {isSaving ? (
              <CircularProgress
                style={{ color: 'white', width: 24, height: 24 }}
              ></CircularProgress>
            ) : (
              <Typography style={{ color: 'white', fontWeight: 700 }}>
                Send
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}

function UserAppointments({
  user,
  appointments,
  setScheduleType,
  isAppointmentsListLoading,
  onTriggerAppointmentsRefetch,
}) {
  // TO DO: make function onRescheduleAppointment after scheduling for web is planned and designed

  // markAppointment for cancelling and completing appointments
  async function markAppointment(appointmentID, action, notes, onSuccess) {
    // console.log('markAppointment: ', appointmentID, action, notes);

    try {
      const request = {
        action,
        appointmentID,
        notes,
        statusRole: 'setter',
        actionTmst: utils.newLocalToISODateFormat(utils.getLocalDateTime()),
      };
      console.log('request: ', request);

      const res = await API.graphql({
        query: mutations.bookCalendarSlot,
        variables: {
          request: JSON.stringify(request),
        },
      });
      console.log('schedule appt res: ', res);
      GamifyToast.success(`Appointment ${action}`);
      onSuccess(true);
    } catch (err) {
      console.error('markAppointment Error: ', JSON.stringify(err));
      GamifyToast.error(`Error while trying to mark appointment ${action}`);
      return false;
    }
  }

  return (
    <>
      {(global.me.isAdmin ||
        global.me.functionalRoles?.includes('closer') ||
        global.me.functionalRoles?.includes('manager')) && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2ch',
          }}
        >
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ToggleMenu
              initialActive="right"
              leftTitle="My Sets"
              rightTitle="My Appts"
              onLeftClick={() => {
                setScheduleType('setter');
              }}
              onRightClick={() => {
                setScheduleType('mine');
              }}
            ></ToggleMenu>
          </Box>
        </Box>
      )}
      {isAppointmentsListLoading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <CircularProgress style={{ color: 'black' }}></CircularProgress>
        </Box>
      )}
      {appointments.length > 0 ? (
        appointments.map((appointment, i) => {
          return (
            <AppointmentCard
              key={i}
              user={user}
              appointment={appointment}
              onTriggerAppointmentsRefetch={onTriggerAppointmentsRefetch}
              markAppointment={markAppointment}
              // onRescheduleAppointment={onRescheduleAppointment}
            ></AppointmentCard>
          );
        })
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 150,
          }}
        >
          <Typography>No Appointments Scheduled</Typography>
        </Box>
      )}
    </>
  );
}
