import React from 'react';
import {
  Dropdown,
  DropdownItem,
  NavItemIndicator,
  ProfilePicture,
  SearchProfileContainer,
  // NavbarContainer,
  // Logo,
  // ProfilePicture,
  // Dropdown,
  // DropdownItem,
  // SearchProfileContainer,
  // MobileMenuContainer,
  // MobileMenuIcon,
  // NavItemsContainer,
  // NavItemContainer,
  // NavItemLabel,
  // NavItemIndicator,
  ViewContainer,
} from './NavBar.styles';

interface Props {
  userProfileAvatar: string;
  activeTab: string;
  isProfileDropdownOpen: boolean;
  isMobileMenuOpen: boolean;
  handleProfileClick: () => void;
  handleMobileMenuToggle: () => void;
  handleSignout: () => void;
  handleNavigate: (path: string) => void;
}

const navItems = [
  { label: 'Feed', value: '/feed' },
  { label: 'Leaderboard', value: '/kpileaderboard' },
  { label: 'Games', value: '/games' },
  { label: 'Training', value: '/training' },
  { label: 'Users', value: '/users' },
  { label: 'Teams', value: '/teams' },
  { label: 'Rewards', value: '/rewards' },
  { label: 'Pipeline', value: '/pipeline' },
];

const NavBarView: React.FC<Props> = ({
  handleProfileClick,
  activeTab,
  isProfileDropdownOpen,
  // isMobileMenuOpen,
  userProfileAvatar,
  handleNavigate,
  // handleMobileMenuToggle,
  handleSignout,
}) => {
  return (
    <ViewContainer>
      <div style={{ display: 'flex', gap: 60, alignItems: 'center' }}>
        <img
          src="/images/gamify-logo-with-text.svg"
          alt="Logo"
          width={120}
          height={120}
        />
        <div style={{ display: 'flex', gap: 20 }}>
          {navItems.map((navItem) => {
            return (
              <div
                key={navItem.value}
                onClick={() => handleNavigate(navItem.value)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontWeight: activeTab.includes(navItem.value) ? 600 : 400,
                    cursor: 'pointer',
                    fontSize: 14,
                    color: activeTab.includes(navItem.value)
                      ? '#5F27CD'
                      : '#737373',
                  }}
                >
                  {navItem.label}
                </div>
                <NavItemIndicator
                  isActive={activeTab.includes(navItem.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <SearchProfileContainer>
        <img
          src="/images/search.svg"
          alt="Profile Icon"
          width={24}
          height={24}
          style={{ borderRadius: '50%' }}
        />
        <ProfilePicture onClick={handleProfileClick}>
          <img
            src={userProfileAvatar}
            alt="Profile Icon"
            width={40}
            height={40}
            style={{ borderRadius: '50%', objectFit: 'cover' }}
          />
          <Dropdown isOpen={isProfileDropdownOpen} itemsPosition="right">
            {
              // @ts-ignore
              global.me.orgID === 'gamify' && (
                <DropdownItem onClick={() => handleNavigate('/admin-config')}>
                  Gamify Admin Config
                </DropdownItem>
              )
            }
            {
              // @ts-ignore
              global.me.isAdmin && (
                <DropdownItem onClick={() => handleNavigate('/reports')}>
                  Reports
                </DropdownItem>
              )
            }
            <DropdownItem
              //  @ts-ignore
              onClick={() => handleNavigate('/users/stats/' + global.me.id)}
            >
              Me
            </DropdownItem>
            <DropdownItem onClick={handleSignout}>Sign out</DropdownItem>
          </Dropdown>
        </ProfilePicture>
      </SearchProfileContainer>
    </ViewContainer>
  );
};

export default NavBarView;
