import { useEffect, useState } from 'react';
import { useGetUserInformationQuery } from '../../../store/api/GraphQlQuery';
import GamesState from '../_types/GamesState';
import getGamesLeaderboards from '../games-helpers/getGamesLeaderboards';

const useGames = (initialState: GamesState) => {
  const {
    data: userData,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
  } = useGetUserInformationQuery({});
  const [gamesState, setGamesState] = useState<GamesState>(initialState);

  const getGamesData = async () => {
    if (isLoadingUserData || !userData) {
      return;
    }

    if (isErrorUserData) {
      throw new Error('Error fetching user data');
    }

    setGamesState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const games = await getGamesLeaderboards({
        gameDimensionType: gamesState.gameDimensionType,
        orgID: userData.me.orgID,
        showCompleted: gamesState.showCompleted,
      });

      games.sort((a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();

        // For ascending order
        return dateB - dateA;
      });
      setGamesState((prevState) => ({
        ...prevState,
        games,
        error: null,
        loading: false,
      }));
    } catch (error) {
      setGamesState((prevState) => ({
        ...prevState,
        error,
        loading: false,
      }));
      console.error(error);
    }
  };

  const onChangeGamesState = (gamesState: GamesState) => {
    setGamesState(gamesState);
  };

  useEffect(() => {
    getGamesData();
  }, [
    gamesState.gameDimensionType,
    gamesState.showCompleted,
    isLoadingUserData,
  ]);

  return { gamesState, onChangeGamesState };
};

export default useGames;
