export default class ImageCache {
  constructor(loadedImages) {
    this.imagesById =
      loadedImages === null
        ? {}
        : loadedImages
            .filter((loadedImages) => loadedImages !== null)
            .reduce((imagesMap, loadedImage) => {
              imagesMap[loadedImage.getImageId()] = loadedImage.getImage();
              return imagesMap;
            }, {});
  }

  addLoadedImage(loadedImage) {
    if (!loadedImage) {
      return;
    }
    this.imagesById[loadedImage.getImageId()] = loadedImage.getImage();
  }

  hasImage(objectWithImageId) {
    if (objectWithImageId === null) {
      return false;
    }
    return this.loadImage(objectWithImageId) !== undefined;
  }

  obtainImageUrl(objectWithImageId) {
    const image = this.loadImage(objectWithImageId);
    if (image === undefined) {
      return null;
    }
    return image.src;
  }

  loadImage(objectWithImageId) {
    console.log('***objectWithImageId.getImageId()', objectWithImageId);
    if (objectWithImageId === undefined) {
      return undefined;
    }
    return this.imagesById[objectWithImageId.getImageId()];
  }
}
