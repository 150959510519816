import { Table, flexRender } from '@tanstack/react-table';
import React from 'react';
import {
  StickyTableBodyCell,
  TableBodyCell,
  TableBodyRow,
} from './KPITable.styles';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';

const TableBody = ({ table }: { table: Table<any> }) => {
  const navigate = useNavigate();

  return (
    <tbody>
      {table.getRowModel().rows.map((row, parentIndex) => {
        return (
          <>
            <TableBodyRow key={row.id}>
              {row.getVisibleCells().map((cell, childIndex) => {
                return (
                  <>
                    {/* {console.log('TableBody cell: ', cell)} */}
                    {[0, 1].includes(childIndex) ? (
                      <StickyTableBodyCell
                        onClick={() => {
                          if (cell.column.id === 'name') {
                            // navigate(`/profile/${cell.row.original.id}`);
                            navigate(`/users/stats/${cell.row.original.id}`, {
                              // Indicate that user is navigating from Leaderboard so they reroute back here when cancelling or updating
                              state: { leaderboard: true },
                            });
                          }
                        }}
                        style={{
                          cursor:
                            cell.column.id === 'name' ? 'pointer' : 'default',
                        }}
                        isEven={parentIndex % 2 === 0}
                        left={childIndex === 0 ? 0 : 98}
                        key={cell.id}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent:
                              cell.column.id === 'name'
                                ? 'flex-start'
                                : 'center',
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Box>
                      </StickyTableBodyCell>
                    ) : cell.column.id === 'space' ? (
                      <></>
                    ) : (
                      <TableBodyCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableBodyCell>
                    )}
                  </>
                );
              })}
            </TableBodyRow>
          </>
        );
      })}
    </tbody>
  );
};

export default TableBody;
