import React from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

import EditUserForm from './EditUserForm';

// NOTE: This component has been replaced by UserFormPage

const EditUserModal = ({ open, onClose, ...rest }) => {
  return (
    <Modal
      sx={{ overflow: 'auto' }}
      open={open}
      disableEscapeKeyDown
      // BackdropProps={{
      //   sx: {
      //     bgcolor: 'rgba(255, 255, 255, 1)', // set background color to white with opacity
      //   },
      // }}
    >
      <Box>
        {/* <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 120, right: 20, color: 'black' }}
        >
          <Close />
        </IconButton> */}

        <Box
          sx={{
            position: 'absolute',
            top: '64%',
            left: '50%',
            transform: 'translate(-50%, -45%)',
            background: 'white',
            width: 624,
            p: 4,
            borderRadius: '16px',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'black',
            }}
          >
            <Close />
          </IconButton>

          <EditUserForm open={open} onClose={onClose} {...rest} />
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserModal;
