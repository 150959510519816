import { API } from 'aws-amplify';
import gql from 'graphql-tag';

export default async function fetchDealStages(
  categoryID,
  showAll,
  activeOption,
  allOption,
  minSequence,
  ignoreNoDealStages
) {
  let myQuery;
  let stagesNextToken = null;
  let filter;
  const assigneeList = [];

  // LOG.debug ("KPIs",KPIs);
  if (activeOption) {
    assigneeList[assigneeList.length] = {
      label: '- Active Only',
      id: 'active',
      value: 'active',
    };
  }
  if (allOption) {
    assigneeList[assigneeList.length] = {
      label: '- All',
      id: 'all',
      value: 'all',
    };
  }

  if (!showAll) {
    filter = ', userSelectable: {ne: false}';
  } else {
    filter = '';
  }

  //   console.log('categoryID: ', categoryID);

  if (categoryID !== undefined) {
    myQuery = gql`
        query MyQuery($categoryID: ID!, $nextToken: String) {
          listStageByCategorySequence(
            categoryID: $categoryID
            filter: { isDeleted: { eq: false } ${filter}}
            limit: 10000,
            nextToken: $nextToken
          ) {
            nextToken
            items {
              id
              sequence
              title
              flexAttributes
              description
              changeToDispositionID
            }
          }
        }
      `;
  } else {
    myQuery = gql`
        query MyQuery($orgID: ID!, $nextToken: String) {
          listStages(
            filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } ${filter} }
            limit: 10000,
            nextToken: $nextToken
          ) {
            nextToken
            items {
              id
              sequence
              title
              flexAttributes
              description
              changeToDispositionID
            }
          }
        }
      `;
  }
  do {
    const getStageRes = await API.graphql({
      query: myQuery,
      variables: {
        orgID: global.me.orgID,
        categoryID,
        nextToken: stagesNextToken,
      },
    });
    //   console.log('getStageRes', getStageRes);
    let stages;
    if (categoryID !== undefined) {
      stages = getStageRes.data.listStageByCategorySequence.items;

      if (getStageRes.data.listStageByCategorySequence.nextToken !== null) {
        // console.log(
        //   'token check stagesNextToken before assignment: ',
        //   getStageRes.data.listStageByCategorySequence.nextToken
        // );
        stagesNextToken =
          getStageRes.data.listStageByCategorySequence.nextToken.toString();
      } else {
        stagesNextToken = null;
      }
    } else {
      stages = getStageRes.data.listStages.items;

      if (getStageRes.data.listStages.nextToken !== null) {
        // console.log(
        //   'token check stagesNextToken before assignment: ',
        //   getStageRes.data.listStages.nextToken
        // );
        stagesNextToken = getStageRes.data.listStages.nextToken.toString();
      } else {
        stagesNextToken = null;
      }
    }

    if (ignoreNoDealStages) {
      minSequence = 1;
    } else {
      minSequence = -1000;
    }
    for (const stage of stages) {
      // console.log('stage: ', stage);
      if (stage.sequence >= minSequence) {
        //
        //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
        //
        // LOG.debug ("stage.id: ", stage.id + " == props.value: " + props.value + "?")
        // if ( stage.id == props.value ) {
        //   //LOG.debug ("YES: ", stage.title)
        //   setName ( stage.title );
        // }
        assigneeList[assigneeList.length] = {
          id: stage.id,
          name: stage.title,
          value: stage.id,
          label: stage.title,
          display: stage.title,
          config: stage.flexAttributes,
          categoryID,
          sequence: stage.sequence,
        };
      }
    }
  } while (stagesNextToken);

  const sortedAssigneeList = assigneeList.sort((a, b) => {
    if (a.sequence < b.sequence) return -1;
    if (a.sequence > b.sequence) return 1;
    return 0;
  });
  // LOG.debug ("sortedAssigneeList: ", sortedAssigneeList);
  // setItemList(sortedAssigneeList);
  return sortedAssigneeList;
}
