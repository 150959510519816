export default class LoadedImage {
  constructor(imageId, image) {
    this.imageId = imageId;
    this.image = image;
  }

  getImageId() {
    return this.imageId;
  }

  getImage() {
    return this.image;
  }
}
