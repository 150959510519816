import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import {
  Box,
  Backdrop,
  Button,
  TextField,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import { updateStage, createStage } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import './CRMConfig.css';
import './kpis.css';
// import { CookieSharp } from '@mui/icons-material';

export default function DealStageEditor(props) {
  const calendarAppointmentStatusOptions = [
    'scheduled',
    'rescheduled',
    'cancelled',
    'completed',
    'pending',
  ];
  const newDealStage = {
    id: null,
    name: '',
    orgID: props.orgID,
    title: '',
    description: '',
    action: '',
    actionPastTense: '',
    measure: '',
    measurePlural: '',
    valueUnit: '',
    valueUnitPlural: '',
    countUnit: '',
    countUnitPlural: '',
    iconName: '',
    iconType: '',
    iconColor: '',
    flexAttributes: [],
    calendarAppointmentStatus: [],
    flexFields: [],
    sequence: '-1',
    winProbability: 0,
    pinColor: '',
    icon: '',
    categoryID: props.category,
    imageName: '',
    imageType: '',
    createdBy: '',
    lastUpdatedBy: '',
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
    userSelectable: true,
    changeToDispositionID: '',
    dealMappingsName: '',
    dealMappingsDescription: '',
    dealMappingsNotes: '',
    dealMappingsValues: '',
    isMapDefault: false,
    isDisplay: false,
    isActive: false,
    isNoDealDefault: false,
    requireCloser: false,
    sendToCRM: false,
  };

  const { open, closer, stageProp, setter } = props;
  const [ogDealState, setOgDealState] = useState(null);
  const [dealStage, setDealStage] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [canSubmit, setCanSubmit] = useState(false);

  const [appStatusOptions, setAppStatusOptions] = useState(false);

  useEffect(() => {
    if (stageProp) {
      const temp = { ...stageProp };
      if (
        temp.calendarAppointmentStatus &&
        temp.calendarAppointmentStatus.length
      ) {
        temp.calendarAppointmentStatus =
          temp.calendarAppointmentStatus.split(', ');
      } else {
        temp.calendarAppointmentStatus = [];
      }
      setDealStage(temp);
      setOgDealState(temp);
    } else {
      setDealStage(newDealStage);
      setOgDealState(newDealStage);
    }
  }, [stageProp]);

  useEffect(() => {
    if (!dealStage) return;
    setCanSubmit(
      () =>
        JSON.stringify(dealStage) !== JSON.stringify(ogDealState) &&
        dealStage.title.length
    );
  }, [dealStage]);

  const closeEditor = () => {
    setter(null);
    setDealStage(null);
    closer(() => false);
    props.setter(null);
  };

  const handleChange = (e) => {
    const temp = {
      ...dealStage,
      [e.target.name]: e.target.value,
    };
    setDealStage(temp);
  };

  const addAppStatus = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = {
      ...dealStage,
      calendarAppointmentStatus: [...dealStage.calendarAppointmentStatus, val],
    };
    setDealStage(temp);
  };

  const removeAppStatus = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const newAppStatus = dealStage.calendarAppointmentStatus;
    const ind = dealStage.calendarAppointmentStatus.indexOf(val);
    newAppStatus.splice(ind, 1);
    const temp = {
      ...dealStage,
      calendarAppointmentStatus: newAppStatus,
    };
    setDealStage(temp);
  };

  const handleSubmit = async () => {
    const submitDealObj = {
      orgID: dealStage.orgID,
      title: dealStage.title,
      description: dealStage.description,
      action: dealStage.action,
      actionPastTense: dealStage.actionPastTense,
      measure: dealStage.measure,
      measurePlural: dealStage.measurePlural,
      valueUnit: dealStage.valueUnit,
      valueUnitPlural: dealStage.valueUnitPlural,
      countUnit: dealStage.countUnit,
      countUnitPlural: dealStage.countUnitPlural,
      userSelectable: dealStage.userSelectable,
      changeToDispositionID: dealStage.changeToDispositionID,

      iconName: dealStage.iconName,
      iconType: dealStage.iconType,
      iconColor: dealStage.iconColor,
      sequence: dealStage.sequence,
      winProbability: dealStage.winProbability,
      pinColor: dealStage.pinColor,
      icon: dealStage.icon,
      categoryID: dealStage.categoryID,
      imageName: dealStage.imageName,
      imageType: dealStage.imageType,
      isDeleted: false,
      isMapDefault: dealStage.isMapDefault,
      isDisplay: dealStage.isDisplay,
      isActive: dealStage.isActive,
      isNoDealDefault: dealStage.isNoDealDefault,
      requireCloser: dealStage.requireCloser,
      sendToCRM: dealStage.sendToCRM,
    };
    if (dealStage.calendarAppointmentStatus.length) {
      submitDealObj.calendarAppointmentStatus =
        dealStage.calendarAppointmentStatus.join(', ');
    }
    let message;

    try {
      if (!dealStage.id) {
        delete dealStage.id;
        await API.graphql(
          graphqlOperation(createStage, { input: submitDealObj })
        );
        message = `${submitDealObj.title} Stage Successfuly Created`;
      } else {
        submitDealObj.id = dealStage.id;
        await API.graphql(
          graphqlOperation(updateStage, { input: submitDealObj })
        );
        message = `${submitDealObj.title} Stage Successfuly Updated`;
      }
    } catch (err) {
      alert('There was an error while saving.  Please try again.');

      console.error('Error saving Mission: ', err);
      console.log('deal stage -- ', submitDealObj);
      return false;
    }
    props.refreshList();
    props.snackbar(message);
    closeEditor();
  };

  const deleteDealStage = async () => {
    const temp = { ...ogDealState, isDeleted: true };
    try {
      temp.id = ogDealState.id;
      await API.graphql(graphqlOperation(updateStage, { input: temp }));
      props.refreshList();
    } catch (err) {
      //   alert('There was an error while deleting.  Please try again.');
      console.error('Error saving Mission: ', err);
      return false;
    }
    props.snackbar(`${temp.title} Stage deleted`);
    closeEditor();
  };

  if (!dealStage) return null;
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Box className="stage-backdrop-container">
        <Box className="backdrop-close-button" onClick={closeEditor}>
          X
        </Box>
        <Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 0 15px 0',
            }}
          >
            <Text style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
              {stageProp ? stageProp.title : 'CREATE DEAL STAGE'}
            </Text>
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <TextField
              style={{ width: '41%' }}
              name="title"
              value={dealStage.title}
              label="Title"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '56%' }}
              name="description"
              value={dealStage.description}
              label="Description"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <TextField
              style={{ width: '16%', marginRight: '15px' }}
              name="sequence"
              value={dealStage.sequence}
              label="Sequence"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '2%' }} />
            <TextField
              style={{ width: '25%' }}
              name="action"
              value={dealStage.action}
              label="Action"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '25%' }}
              name="countUnit"
              value={dealStage.countUnit}
              label="Count Unit"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '25%' }}
              name="countUnitPlural"
              value={dealStage.countUnitPlural}
              label="Count Unit Plural"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <TextField
              style={{ width: '25%' }}
              name="valueUnit"
              value={dealStage.valueUnit}
              label="Value Unit"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '25%' }}
              name="valueUnitPlural"
              value={dealStage.valueUnitPlural}
              label="Value Unit Plural"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '25%' }}
              name="icomName"
              value={dealStage.icomName}
              label="Icon Name"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
            <Box style={{ width: '3%' }} />
            <TextField
              style={{ width: '25%' }}
              name="iconType"
              value={dealStage.iconType}
              label="Icon Type"
              id="outlined-read-only-input"
              onChange={handleChange}
            />
          </Box>
          <Box
            style={{
              minWidth: '100%',
              margin: '15px 0',
            }}
          >
            <Box>
              <Text>Calendar Appointment Status</Text>
              <Box
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'lightGray',
                  padding: '8px',
                  width: '100%',
                  borderRadius: '5px',
                }}
              >
                <Box style={{ display: 'flex' }}>
                  {dealStage.calendarAppointmentStatus?.length ? (
                    dealStage.calendarAppointmentStatus.map((el, i) => (
                      <Box
                        key={`${el}-${i}`}
                        name={el}
                        className="appointment-status-option hov"
                        onClick={removeAppStatus}
                      >
                        {el}
                      </Box>
                    ))
                  ) : (
                    <Text style={{ color: 'white' }}>
                      {"This stage isn't set based on appointment status"}
                    </Text>
                  )}
                </Box>
                <Box
                  id="purple-add-button"
                  onClick={() => setAppStatusOptions((p) => !p)}
                >
                  {appStatusOptions ? '-' : '+'}
                </Box>
                {appStatusOptions && (
                  <Box id="appointment-satus-absolute-options-box">
                    {calendarAppointmentStatusOptions
                      .filter(
                        (el) =>
                          dealStage.calendarAppointmentStatus.indexOf(el) === -1
                      )
                      .map((el, i) => (
                        <Box
                          key={i}
                          name={el}
                          className="appointment-status-option hov"
                          onClick={addAppStatus}
                        >
                          {el}
                        </Box>
                      ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '47%',
                justifyContent: 'space-between',
              }}
            >
              <Text>
                Default Deal Stage?
                <br />
                <span style={{ color: 'gray' }}>
                  (Make sure that only one is the default)
                </span>
              </Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    isMapDefault: !dealStage.isMapDefault,
                  }))
                }
                checked={dealStage.isMapDefault}
              />
            </Box>
            <Box style={{ width: '3%' }} />
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '50%',
                justifyContent: 'space-between',
              }}
            >
              <Text>
                Default No Deal Stage (Pins without data)?
                <br />
                <span style={{ color: 'gray' }}>
                  (Make sure that only one is the default)
                </span>
              </Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    isNoDealDefault: !dealStage.isNoDealDefault,
                  }))
                }
                checked={dealStage.isNoDealDefault}
              />
            </Box>
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '47%',
              }}
            >
              <Text>Show in My Deals Screen?</Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    isDisplay: !dealStage.isDisplay,
                  }))
                }
                checked={dealStage.isDisplay}
              />
            </Box>
            <Box style={{ width: '4%' }} />
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '50%',
              }}
            >
              <Text>Show as Active?</Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    isActive: !dealStage.isActive,
                  }))
                }
                checked={dealStage.isActive}
              />
            </Box>
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '47%',
              }}
            >
              <Text>Require Closer?</Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    requireCloser: !dealStage.requireCloser,
                  }))
                }
                checked={dealStage.requireCloser}
              />
            </Box>
            <Box style={{ width: '4%' }} />
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '50%',
              }}
            >
              <Text>Send To CRM?</Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    sendToCRM: !dealStage.sendToCRM,
                  }))
                }
                checked={dealStage.sendToCRM}
              />
            </Box>
          </Box>
          <Box style={{ display: 'flex', margin: '15px 0' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '47%',
              }}
            >
              <Text>User Selectable</Text>
              <Switch
                onChange={() =>
                  setDealStage(() => ({
                    ...dealStage,
                    userSelectable: !dealStage.userSelectable,
                  }))
                }
                checked={
                  [null, undefined].includes(dealStage.userSelectable)
                    ? true
                    : dealStage.userSelectable
                }
              />
            </Box>
            <Box style={{ width: '4%' }} />
            <Box
              style={{
                width: '50%',
              }}
            >
              <Text>Change to disposition</Text>
              <Select
                style={{ width: '100%' }}
                value={dealStage.changeToDispositionID || ''}
                onChange={(e) => {
                  setDealStage((p) => ({
                    ...p,
                    changeToDispositionID: e.target.value,
                  }));
                }}
              >
                <MenuItem
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                  }}
                  value={''}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = 'lightgray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'white')
                  }
                >
                  None
                </MenuItem>
                {props.stageDispositions?.map((el, i) => (
                  <MenuItem
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid lightgray',
                    }}
                    key={i}
                    value={el.id}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = 'lightgray')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'white')
                    }
                  >
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            disabled={!canSubmit}
            onClick={handleSubmit}
            variant="outlined"
            color="success"
          >
            SAVE
          </Button>
          {dealStage.id && (
            <Button
              style={{ marginLeft: '15px' }}
              variant="outlined"
              color="error"
              onClick={() => setConfirmDelete(true)}
            >
              DELETE STAGE
            </Button>
          )}
        </Box>
      </Box>
      <Backdrop
        open={confirmDelete}
        sx={{
          backgroundColor: 'rgba(0,0,0,0.8)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '10px',
          }}
        >
          <Text
            style={{ fontWeight: 'bold' }}
          >{`Are you sure you want to delete ${dealStage.title}?`}</Text>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px',
            }}
          >
            <Button variant="outlined" color="error" onClick={deleteDealStage}>
              Yes (Delete Rule)
            </Button>
            <Button variant="outlined" onClick={() => setConfirmDelete(false)}>
              No (Keep Rule)
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </Backdrop>
  );
}
