import React from 'react';
import { Box, Grid, Typography, Modal, Button } from '@mui/material';
import StyledTable from '../../components/StyledTable';
import TextImage from '../../components/TextImage';
import './Index.css';
import CenteredProgress from '../../components/CenteredProgress';
import DashboardRepository from '../../repository/DashboardRepository';
import ImageCache from '../../domain/ImageCache';
import CenteredComponent from '../../components/CenteredComponent';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../components/withRouter';
import { withUseParams } from '../../components/withUseParams';
import { withUseSearchParams } from '../../components/useSearchParams';

class Index extends React.Component {
  constructor(props) {
    super(props);
    // this.handleLeaderboardChange = this.onLeaderboardChange.bind(this);
    this.state = {
      leaderboardList: [],
      leaderboard: null,
      imageCache: null,
      isLoading: true,
      activeLeaderboardId: null,
      fullScreen: false,
      imageCaches: [],
    };
    this.tableWidthRef = React.createRef();
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.fullScreen) {
        this.fetchData();
      }
    }, 10000);
    this.fetchData();
  }

  fetchData() {
    const params = this.props.params;
    const kpiId = params.kpiId === undefined ? '' : params.kpiId;
    console.log('***kpiId: ', kpiId);
    const searchParams = this.props.searchParams;
    const reportID =
      searchParams.get('reportID') !== null
        ? searchParams.get('reportID')
        : 'kpi:person:Y:now';

    DashboardRepository.fetchDashboards(
      reportID,
      'ad5008c2-b77d-4d89-b566-ed8e81d496b0',
      null,
      true,
      'all',
      null,
      kpiId
    ).then((response) => {
      const dashboard = response;
      console.log('dashboard: ', dashboard);

      if (!dashboard || dashboard.length === 0) {
        this.setState({
          leaderboards: [],
          isLoading: false,
          reportID,
        });
      } else {
        console.log('!!!length > 0!!!!');
        for (const leaderboard of dashboard) {
          console.log('leaderboard: ', leaderboard);
          const promisedImages = leaderboard
            .collateImageMetadata()
            .map((metadata) => metadata.loadImage());
          Promise.all(promisedImages)
            .then((loadedImages) => new ImageCache(loadedImages))
            .then((imageCache) => {
              console.log('@@imageCache: ', imageCache);
              const _imageCaches = this.state.imageCaches;
              _imageCaches[_imageCaches.length] = imageCache;
              this.setState({
                imageCaches: _imageCaches,
              });
            });
        }
        this.setState({
          isLoading: false,
          leaderboards: dashboard,
          reportID,
        });
      }
    });
  }

  buildColumns(leaderboard, imageCache, nameWidth, clientWidth) {
    console.log('**buildColumns: leaderboard: ', leaderboard);
    console.log('**buildColumns: imageCache: ', imageCache);
    const maxWidth = clientWidth - 100 - nameWidth;
    const translation = this.props.t;
    // const actionType = leaderboard.getActionTypePluralized()
    //   ? leaderboard.getActionTypePluralized()
    //   : translation('tableColumnNames.value');
    return [
      {
        field: 'position',
        headerName: translation('tableColumnNames.position'),
        width: 100,
        headerAlign: 'center',
        align: 'center',
        cellClassName: 'row-position-overlay',
        renderCell: (params) => {
          return (
            <Box className={'row-profile-image-overlay'}>
              <TextImage
                className={'row-profile-image'}
                user={params.value.user}
                imageCache={imageCache}
              ></TextImage>
            </Box>
          );
        },
      },
      {
        field: 'name',
        headerName: translation('tableColumnNames.name'),
        width: nameWidth,
      },
      {
        field: 'value',
        headerName: 'Score',
        width: maxWidth,
        headerAlign: 'left',
        align: 'left',
        alignItems: 'left',
        renderCell: (params) => (
          <HorizontalBar
            className={'horizontal-bar'}
            value={params.value}
            maxWidth={maxWidth}
          ></HorizontalBar>
        ),
      },
    ];
  }

  buildLeaderboard(leaderboard, imageCache, isFullScreen) {
    console.log('buildLeaderboard: ', leaderboard, imageCache, isFullScreen);

    const maxWidth = window.innerWidth / 3;
    console.log('!!window.innerWidth: ', window.innerWidth);
    console.log('!!maxWidth: ', maxWidth);
    const nameWidth = 150;
    let clientWidth = 0;
    if (this.tableWidthRef.current !== null) {
      console.log('tableWidthRef: ', this.tableWidthRef.current.clientWidth);
      clientWidth = this.tableWidthRef.current.clientWidth;
    }

    const rows = this.buildLeaderboardRows(leaderboard, imageCache);
    return (
      <Grid container>
        <Grid container justifyContent="center">
          <Grid
            container
            sx={{ mt: 4 }}
            justifyContent="center"
            ref={this.tableWidthRef}
            style={{ marginTop: 0 }}
          >
            {clientWidth > 0 && (
              <>
                <StyledTable
                  className={'leaderboard-overlay'}
                  maxWidth={clientWidth}
                  density={'comfortable'}
                  autoGenerateIds={true}
                  columns={this.buildColumns(
                    leaderboard,
                    imageCache,
                    nameWidth,
                    clientWidth
                  )}
                  rows={rows}
                  rowsPerPage={rows.length}
                  disableColumnMenu={true}
                  header={this.buildTableHeader(leaderboard)}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  buildLeaderboardRows(leaderboard, imageCache) {
    console.log('buildLeaderboardRows: ', leaderboard);

    return leaderboard.getUsers().map((user) => {
      return {
        position: { user, imageCache },
        name: user.getFirstName(),
        value: user.getCurrentAchievement(),
        goal: user.calculatePercentComplete(),
      };
    });
  }

  buildTableHeader(leaderboard) {
    // const imageCache = this.state.imageCache;
    console.log('**leaderboard: ', leaderboard);
    // console.log('**imageCache: ', imageCache);
    // const users = leaderboard.getUsers();
    // const userPlaceFirst = <LeaderAvatar leader={users[0]} imageCache={imageCache}/>;
    // const userPlaceSecond = users.length > 1 ? <LeaderAvatar leader={users[1]} imageCache={imageCache}/> : <></>;
    // const translation = this.props.t;

    return (
      <>
        <Grid
          container
          className="kpi-table-header"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-evenly"
          alignItems="center"
          pt={2}
          sx={{ mt: 4 }}
          flexGrow="1"
          style={{ marginTop: '0%' }}
        >
          <Grid
            container
            flexDirection={{ md: 'column' }}
            alignItems={'center'}
          >
            <Typography variant={'h5'} paddingBottom={1}>
              {leaderboard.data.title}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  buildDashboard() {
    console.log('buildDashboards(): ', this.state.leaderboards);
    console.log('++imageCaches: ', this.state.imageCaches);
    console.log('++imageCaches[0]: ', this.state.imageCaches[0]);

    for (const key of this.state.imageCaches.keys()) {
      console.log('--key: ', key);
    }
    // if (false && this.state.fullScreen) {
    //   return (
    //     <>
    //       <Modal open={this.state.fullScreen}>
    //         <Box display={'inline-flex'} style={{
    //           backgroundColor: '#1d1d1d',
    //           borderWidth: 0,
    //           height: '100%',
    //           width: '100%',
    //           alignItems: 'center',
    //           justifyContent: 'center'
    //         }}>
    //           <Box width={'40%'} justifyContent="center">
    //             <Typography marginTop={5} textAlign="center" variant="h3"
    //                         style={{ marginTop: '20%', marginLeft: '1%' }}>Cloud Connections 2023</Typography>
    //             <Typography marginTop={5} textAlign="center" variant="h4">CPaaS Showcase Challenge</Typography>
    //             <Box display={'inline-flex'} alignContent={'center'} justifyContent={'space-around'} width={'100%'}
    //                  style={{ marginTop: '10%' }}>
    //               <img src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/202005-CPAASAA-logo.png"
    //                    height={80}/>
    //               <img src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/cca-logo.png"
    //                    height={80}/>
    //             </Box>
    //             <Box display={'inline-flex'} justifyContent={'center'} width={'100%'} style={{ marginTop: '10%' }}>
    //               <img src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/tradeshow_webapp.png"
    //                    height={230}/>
    //             </Box>
    //             <Box display="flex" justifyContent="center" alignItems="center" marginTop={10}>
    //               <Typography paddingRight={1} variant="h5">Powered By </Typography>
    //               <img src={'/images/Gamify Logo Reverse.png'} height={25}/>
    //             </Box>
    //             <Box display="flex" justifyContent="center" marginTop={5}>
    //               <Button
    //                 onClick={() => {
    //                   this.fetchData();
    //                   this.setState({ fullScreen: !this.state.fullScreen });
    //                 }}
    //                 className={'save-button'}>
    //                 Exit Full Screen
    //               </Button>
    //             </Box>
    //           </Box>
    //
    //           <Box marginTop={'-2%'}>
    //             <Grid container
    //                   marginTop={-2}
    //                   flexDirection={{ xs: 'row', sm: 'row' }}
    //                   justifyContent="space-around" alignItems="center" pt={2} sx={{ mt: 4 }} flexGrow="1" spacing={2}>
    //               {this.state.leaderboards.map((leaderboard, i) =>
    //                 <>
    //                   <Grid className={'leaderboard-item'} flex={1} item xs={4}>
    //                     {this.buildLeaderboard(leaderboard, this.state.imageCaches[i], true)}
    //                   </Grid>
    //                 </>
    //               )
    //               }
    //             </Grid>
    //           </Box>
    //         </Box>
    //       </Modal>
    //     </>
    //   );
    // }

    if (this.state.fullScreen) {
      return (
        <>
          <Modal open={this.state.fullScreen}>
            <Box
              display={'flex'}
              style={{
                backgroundColor: '#1d1d1d',
                borderWidth: 0,
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  style={{
                    marginBottom: -25,
                    paddingTop: 30,
                    paddingBottom: 5,
                    borderBottom: '1px solid white',
                  }}
                >
                  <img
                    src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/ITExpoIcon.png"
                    height={60}
                  />
                  <Typography
                    textAlign="center"
                    variant="h4"
                    marginTop={-1}
                    paddingLeft={20}
                    paddingRight={20}
                    width={'80%'}
                  >
                    CPaaS Showcase Challenge Sponsored by the CPaaSAA and ITEXPO
                  </Typography>
                  <img
                    src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/202005-CPAASAA-logo.png"
                    height={60}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Box width={'20%'}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <Typography marginTop={5} variant="h5" paddingBottom={6}>
                        Scan Code to Vote
                      </Typography>
                      <img
                        src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/tradeshow_webapp.png"
                        height={200}
                        width={200}
                      />
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        style={{ marginTop: '10%' }}
                        onClick={() => {
                          this.fetchData();
                          this.setState({ fullScreen: !this.state.fullScreen });
                        }}
                      >
                        <Typography paddingRight={1} variant="h5">
                          Powered By{' '}
                        </Typography>
                        <img
                          src={'/images/Gamify Logo Reverse.png'}
                          height={25}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: '80%',
                      paddingTop: '2.5%',
                      marginBottom: -5,
                    }}
                  >
                    <Grid
                      container
                      flexDirection={{ xs: 'row', sm: 'row' }}
                      justifyContent="space-around"
                      pt={2}
                      sx={{ mt: 4 }}
                      flexGrow="1"
                      spacing={2}
                      style={{ marginTop: 0, paddingTop: 0 }}
                    >
                      {this.state.leaderboards.map((leaderboard, i) => (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ paddingTop: 0, marginTop: -15 }}
                          >
                            {this.buildLeaderboard(
                              leaderboard,
                              this.state.imageCaches[i],
                              true
                            )}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      );
    }

    return (
      <>
        <Typography textAlign="center" variant="h3">
          CPaaS Showcase Challenge
        </Typography>
        {/* <Typography textAlign="center" variant="h5">Cloud Communications Alliance</Typography> */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src="https://lyferize-public-statics.s3.us-west-2.amazonaws.com/202005-CPAASAA-logo.png"
            height={80}
          />
        </Box>
        <Box marginTop={-5}>
          <Grid
            container
            flexDirection={{ xs: 'row', sm: 'row' }}
            justifyContent="space-around"
            pt={2}
            sx={{ mt: 4 }}
            flexGrow="1"
            spacing={2}
          >
            {this.state.leaderboards.map((leaderboard, i) => (
              <>
                <Grid item xs={4}>
                  {this.buildLeaderboard(
                    leaderboard,
                    this.state.imageCaches[i],
                    false
                  )}
                </Grid>
              </>
            ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={5}
          >
            <Typography paddingRight={1} variant="h5">
              Powered By{' '}
            </Typography>
            <img src={'/images/Gamify Logo Reverse.png'} height={25} />
          </Box>
          <Box display="flex" justifyContent="center" marginTop={5}>
            <Button
              onClick={() => {
                this.fetchData();
                this.setState({ fullScreen: !this.state.fullScreen });
              }}
              className={'save-button'}
            >
              Full Screen
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  render() {
    const translation = this.props.t;

    if (this.state.isLoading) {
      return <CenteredProgress />;
    }
    if (
      this.state.leaderboards === null &&
      this.state.leaderboards.length === 0
    ) {
      return (
        <CenteredComponent>
          <Typography variant="h5">{translation('noLeaderboards')}</Typography>
        </CenteredComponent>
      );
    }
    return this.buildDashboard();
  }
}

export default withTranslation('', {
  keyPrefix: 'routerComponents.home.index',
})(withRouter(withUseParams(withUseSearchParams(Index))));

function HorizontalBar({ value, maxWidth }) {
  const minWidth = 1; // minimum width of the bar
  // const maxWidth = isFullScreen?75:25; // maximum width of the bar
  const barWidth = Math.max(minWidth, value * (maxWidth / 5)); // Scale the value to a percentage, but ensure it's at least the minimum width
  const width = Math.min(barWidth, maxWidth); // Ensure the width is not greater than maximum width
  console.log('&&&maxWidth: ', maxWidth, width);

  const style = {
    width,
    backgroundColor: '#827ffc',
    height: '25px',
    position: 'absolute',
    marginTop: -12,
  };

  return (
    <div>
      <Box style={style}>
        {value > 0.1 ? (
          <div
            style={{
              color: 'white',
              position: 'relative',
              top: '50%',
              left: '60%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {value.toFixed(1)}
          </div>
        ) : (
          <div
            style={{
              color: 'white',
              position: 'relative',
              top: '50%',
              left: `${width + 10}px`,
              transform: 'translateY(-50%)',
            }}
          >
            {value.toFixed(1)}
          </div>
        )}
      </Box>
    </div>
  );
}
