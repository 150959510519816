import React, { useState } from 'react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { /* Backdrop, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import RolePositionModal from './RolePositionModal';
import { GamifyToast } from '../../common/CustomToasts';
import { GamifyIcon } from '../../components/GamifyIcon';

export default function PermissionsModal(props) {
  const {
    user,
    rolePositionObj,
    setRolePositionObj,
    // showPermissionsModal,
    // setShowPermissionsModal,
    permissionsAnchorEl,
    setPermissionsAnchorEl,
    onTriggerProfileDetailsRefetch,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [showRolePositionModal, setShowRolePositionModal] = useState(false);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);

  //   console.log('PermissionsModal rolePositionObj: ', rolePositionObj);
  //   console.log('PermissionsModal user: ', user);

  const modalsConfig = {
    deactivate: {
      //   icon: "disable",
      title: `Do you want to deactivate ${user?.name}?`,
      subtitle: 'You can activate and deactivate users\nanytime. ',
      onConfirm: () => {
        // LOG.debug("this is great");
        setIsSaving(true);
        changeUserStatus(user, 'disable', (res) => {
          setIsSaving(false);

          if (res) {
            setShowUserStatusModal(false);
            // setShowPermissionsModal(false);
            setPermissionsAnchorEl(null);
            setDynamicModalObj(null);

            onTriggerProfileDetailsRefetch();
            GamifyToast.success(`${user.name} Deactivated`);
          }
        });
      },
    },
    activate: {
      //   icon: "enable",
      title: `Do you want to activate ${user?.name}?`,
      subtitle: 'You can activate and deactivate users\nanytime. ',
      onConfirm: () => {
        // console.log('this is great');
        setIsSaving(true);
        changeUserStatus(user, 'enable', (res) => {
          // console.log("MY RES - ", res);
          setIsSaving(false);

          if (res) {
            setShowUserStatusModal(false);
            // setShowPermissionsModal(false);
            setPermissionsAnchorEl(null);
            setDynamicModalObj(null);

            onTriggerProfileDetailsRefetch();
            GamifyToast.success(`${user.name} Activated`);
          }
        });
      },
    },
  };

  //
  //  Do user admin functions
  //
  async function changeUserStatus(user, action, onComplete) {
    // console.log("changeUserStatus: ", user);
    try {
      await API.graphql({
        query: mutations.personAdminFunctions,
        variables: {
          userID: user.id,
          username: user.username,
          action,
        },
      });
      // console.log("userAdminFunctionsRes: ", userAdminFunctionsRes);
    } catch (err) {
      console.error(
        'changeUserStatus(): error updating UserStatus:',
        JSON.stringify(err)
      );
      GamifyToast.error('Error updating status, please try again');
      onComplete(false);
      return false;
    }
    onComplete(true);
    return true;
  }

  // const handleCloseClick = () => {
  //   setShowPermissionsModal(false);
  // };

  const handleMenuClose = () => {
    setPermissionsAnchorEl(null);
  };

  return (
    <>
      {/* Position Modal */}
      <RolePositionModal
        user={user}
        positionOnly={true}
        rolePositionObj={rolePositionObj}
        setRolePositionObj={setRolePositionObj}
        showRolePositionModal={showRolePositionModal}
        setShowRolePositionModal={setShowRolePositionModal}
        onTriggerRefetch={onTriggerProfileDetailsRefetch}
      ></RolePositionModal>
      {/* Activate or Deactivate Modal */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showUserStatusModal}
        setShowDynamicConfirmModal={setShowUserStatusModal}
        zIndex={100002}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        subtitleColor={dynamicModalObj?.subtitleColor}
        confirmButtonColor={dynamicModalObj?.confirmButtonColor}
        isSaving={isSaving}
        onConfirm={dynamicModalObj?.onConfirm}
      ></DynamicConfirmModal>
      {/* Permissions Modal */}
      {/* NOTE: The custom Backdrop component was replaced by the DynamicConfirmModal which was then replaced by CustomMenu and CustomMenuItem components per design documents */}
      <CustomMenu
        anchorEl={permissionsAnchorEl}
        open={Boolean(permissionsAnchorEl)}
        onClose={() => {
          handleMenuClose();
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowRolePositionModal(true);
            setPermissionsAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Change Position</Typography>
            <GamifyIcon icon={'role'} color={'black'}></GamifyIcon>
          </Box>
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (user.status === 'active') {
              setDynamicModalObj(modalsConfig.deactivate);
            } else {
              setDynamicModalObj(modalsConfig.activate);
            }
            setShowUserStatusModal(true);
            setPermissionsAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                color: user.status === 'active' ? '#DA2424' : 'black',
                fontWeight: 500,
              }}
            >
              {user.status === 'active' ? 'Deactivate' : 'Activate'}
            </Typography>
            <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon>
          </Box>
        </CustomMenuItem>
      </CustomMenu>

      {/* <DynamicConfirmModal
        showDynamicConfirmModal={showPermissionsModal}
        setShowDynamicConfirmModal={setShowPermissionsModal}
        zIndex={100001}
        title={'Permissions'}
        cancelButtonText={'Role'}
        onCancel={() => {
          setShowRolePositionModal(true);
          setShowPermissionsModal(false);
        }}
        confirmButtonText={user.status === 'active' ? 'Deactivate' : 'Activate'}
        confirmButtonColor={user.status === 'active' ? '#868686' : '#5F27CD'}
        onConfirm={() => {
          if (user.status === 'active') {
            setDynamicModalObj(modalsConfig.deactivate);
          } else {
            setDynamicModalObj(modalsConfig.activate);
          }
          setShowUserStatusModal(true);
          setShowPermissionsModal(false);
        }}
      ></DynamicConfirmModal> */}
      {/* <Backdrop
        sx={{ zIndex: 100001 }}
        open={showPermissionsModal}
        onClick={(e) => {
          if (
            // !e.target.closest('.role-position-button') &&
            !e.target.closest('.deactivate-button')
          ) {
            handleCloseClick();
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            width: 624,
            maxHeight: '65vh',
            padding: '24px 40px 32px 24px',
            borderRadius: 8,
            overflowY: 'auto',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              Permissions
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              className={'role-position-button'}
              onClick={() => {
                // console.log('Clicked Role/Position');
                setShowRolePositionModal(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 262,
                padding: 12,
                border: '1px solid #cfcfcf',
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: '#323232', fontWeight: 700 }}>
                Role
              </Typography>
            </Box>
            <Box
              className={'deactivate-button'}
              onClick={() => {
                // console.log('Clicked Deactivate');
                if (user.status === 'active') {
                  setDynamicModalObj(modalsConfig.deactivate);
                } else {
                  setDynamicModalObj(modalsConfig.activate);
                }
                setShowUserStatusModal(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  user.status === 'active' ? '#868686' : '#5F27CD',
                width: 262,
                padding: 12,
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: 'white', fontWeight: 700 }}>
                {user.status === 'active' ? 'Deactivate' : 'Activate'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Backdrop> */}
    </>
  );
}
