import styled from 'styled-components';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const TropyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TrophyLabel = styled.div`
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const TrophyBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
`;
export const TrophyItemContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
`;

export const TrophyItemBody = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#E2D8FF' : '#F0F0F3')};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
