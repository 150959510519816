import React from 'react';
import NewGameState from '../../_types/NewGameState';
import { ViewContainer } from './Bracket.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import CustomTextInput from '../../custom-inputs/CustomTextInput';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const BracketView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Bracket</InputTitleText>
        <InputTitleIcon src="/images/bracket.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomTextInput
          rightComponent={
            newGameState.totalRounds > 0 &&
            newGameState.bracket.totalCompetitors > 0 ? (
              <div>{`(${newGameState.totalRounds} round${
                newGameState.totalRounds > 1 ? 's' : ''
              })`}</div>
            ) : null
          }
          label="# Contestants"
          placeholder={'e.g 10'}
          value={
            newGameState.bracket.totalCompetitors
              ? String(newGameState.bracket.totalCompetitors)
              : ''
          }
          onChange={(newValue) => {
            if (isNaN(Number(newValue))) return;

            let totalRounds = getBaseLog(2, Number(newValue));

            if (totalRounds !== Number(newValue) && Number(newValue) !== 2) {
              totalRounds += 1;
            }

            console.log('totalRounds', totalRounds);

            onChangeNewGameState({
              ...newGameState,
              bracket: {
                ...newGameState.bracket,
                bracket:
                  totalRounds > 0
                    ? new Array(totalRounds)
                        .fill(null)
                        .map((_, index) => {
                          // Round 1
                          if (index === 0) {
                            return Array.from(
                              {
                                length:
                                  Number(newValue) % 2 === 0
                                    ? Number(newValue) / 2
                                    : (Number(newValue) + 1) / 2,
                              },
                              () => ({
                                id: 0,
                                position: {
                                  top: 130,
                                  left: 5,
                                },
                                competitors: [],
                                isSingle: true,
                                isDouble: true,
                                number: 1,
                              })
                            );
                          }

                          return [];
                        })
                        .filter((round) => round.length !== 0)
                    : [],
                totalCompetitors: Number(newValue),
              },
              totalRounds,
              currentRound: 1,
            });
          }}
        />
        {/* <CustomSelect
          label="Automatically create goals"
          value={newGameState.bracket.roundSpecificRules}
          onChange={(newValue: any) => {
            onChangeNewGameState({
              ...newGameState,
              bracket: {
                ...newGameState.bracket,
                roundSpecificRules: newValue,
              },
            });
          }}
          items={[
            { label: 'Automatic', value: true },
            { label: 'Custom', value: false },
          ]}
        /> */}
      </InputBodyContainer>
    </ViewContainer>
  );
};

function getBaseLog(x: number, y: number) {
  return Math.floor(Math.log(y) / Math.log(x));
}

export default BracketView;
