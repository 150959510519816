import React, { useState } from 'react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { GamifyToast } from '../../common/CustomToasts';

// NOTE: Role is an org/user-specifc attribute while Position is a team-specific attribute!

export default function RolePositionModal(props) {
  const {
    user,
    circleMemberId,
    // team,
    roleOnly = false,
    positionOnly = false,
    rolePositionObj,
    setRolePositionObj,
    showRolePositionModal,
    setShowRolePositionModal,
    onTriggerRefetch,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showDynamicConfirmModal, setShowDynamicConfirmModal] = useState(false);
  // console.log('RolePositionModal user: ', user);
  // console.log('RolePositionModal team: ', team);
  // console.log('RolePositionModal rolePositionObj: ', rolePositionObj);

  const positionOptions = [
    { label: 'Member', value: 'member' },
    { label: 'Moderator', value: 'moderator' },
    { label: 'Admin', value: 'admin' },
  ];

  const roleOptions = [
    { label: 'Setter', value: 'setter' },
    { label: 'Closer', value: 'closer' },
    { label: 'Manager', value: 'manager' },
    { label: 'Scheduler', value: 'scheduler' },
    { label: 'Other', value: 'other' },
  ];

  //  TEAM-SPECIFIC MODIFYING:
  //
  //  Modify user memberType and memberRole for a specific team
  //  NOTE: memberRole is 'Position' and memberType is set on the user, not circleMember so it is user-specific
  //
  async function modifyCircleMemberRole(
    id,
    // userID,
    // circleID,
    // memberType,
    memberRole,
    onComplete
  ) {
    // console.log(
    //   '!!modifyCircleMemberRoleAndPermission: ',
    //   id,
    //   userID,
    //   circleID,
    //   // memberType,
    //   memberRole
    // );
    try {
      const res = await API.graphql({
        query: mutations.updateCircleMember,
        variables: {
          input: {
            id,
            // memberType,
            memberRole,
          },
        },
      });
      console.log('!!res: ', res);
      onComplete('SUCCESS');
      return true;
    } catch (err) {
      console.error('modifyCircleMemberRoleAndPermission Error:', err);
      onComplete(err);
      return false;
    }
  }

  // NOTE: functionalRole is set on the circleMember not user so it is a team-specific
  // //  USER-SPECIFIC MODIFYING:
  // //
  // //  Modify user function roles
  // //
  // async function changeUserFunctionRole(personID, role, onComplete) {
  //   try {
  //     await API.graphql({
  //       query: mutations.modifyPerson,
  //       variables: {
  //         person: JSON.stringify({
  //           id: personID,
  //           functionalRoles: role,
  //         }),
  //       },
  //     });
  //   } catch (err) {
  //     onComplete(err);
  //   }
  //   onComplete('SUCCESS');
  // }

  //
  //  Modify a persons role
  //
  async function modifyPersonRole(orgMemberID, role, onComplete) {
    // console.log("modify PersonRole: ", orgerMemberID, role);

    try {
      const orgMember = {
        id: orgMemberID,
        memberType: role,
      };
      // console.log('RolePositionModal orgMember: ', orgMember);
      await API.graphql({
        query: mutations.updateOrgMember,
        variables: { input: orgMember },
      });
      onComplete(true);
      // onRefresh();
    } catch (err) {
      console.error(
        'modify PersonRole(): error updating PersonRole:',
        JSON.stringify(err)
      );
      onComplete(false);
    }
  }

  const handleCancelChangeClick = () => {
    setShowRolePositionModal(false);
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: 100002 }}
        open={showRolePositionModal}
        onClick={(e) => {
          if (
            !e.target.closest('.save-role-position-button') &&
            !e.target.closest('.MuiSelect-select') &&
            !e.target.closest('.MuiMenuItem-root')
          ) {
            handleCancelChangeClick();
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            width: 624,
            maxHeight: '65vh',
            padding: '24px 40px 32px 24px',
            borderRadius: 8,
            overflowY: 'auto',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              {roleOnly
                ? 'Change Role'
                : positionOnly
                ? 'Change Position'
                : 'Change Role/Position'}
            </Typography>
          </Box>
          {!roleOnly && (
            <Box
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: 16,
              }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#0B0B0C',
                  marginBottom: 8,
                }}
              >
                Position
              </Typography>
              <StyledSelect
                style={{ width: '100%' }}
                // placeholder="HELLO!!"
                onChange={(e) => {
                  setRolePositionObj((p) => ({
                    ...p,
                    form: { ...p.form, position: e.target.value },
                  }));
                }}
                value={rolePositionObj.form.position}
                name="position"
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
              >
                {positionOptions.map((opt, i) => (
                  <StyledMenuItem value={opt.value} key={i}>
                    {opt.label[0].toUpperCase() + opt.label.slice(1)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
          {!positionOnly && (
            <Box
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: 16,
              }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#0B0B0C',
                  marginBottom: 8,
                }}
              >
                Role
              </Typography>
              <StyledSelect
                style={{ width: '100%' }}
                // placeholder="HELLO!!"
                onChange={(e) => {
                  setRolePositionObj((p) => ({
                    ...p,
                    form: { ...p.form, role: e.target.value },
                  }));
                }}
                value={rolePositionObj.form.role}
                name="role"
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
              >
                {roleOptions.map((opt, i) => (
                  <StyledMenuItem value={opt.value} key={i}>
                    {opt.label}
                    {/* {opt[0].toUpperCase() + opt.slice(1)} */}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => handleCancelChangeClick()}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 262,
                padding: 12,
                border: '1px solid #cfcfcf',
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: '#323232', fontWeight: 700 }}>
                Cancel
              </Typography>
            </Box>
            <Box
              className={'save-role-position-button'}
              onClick={() => {
                // Don't repeat if saving in progress
                if (isSaving) return;

                const { role, position } = rolePositionObj.original;
                const { form } = rolePositionObj;

                // Don't submit when no change to role or position, just close the modal
                if (role === form.role && position === form.position) {
                  setShowRolePositionModal(false);
                  return;
                }

                const messages = [];

                if (position !== form.position) {
                  messages.push(
                    `position to ${
                      form.position === 'user' ? 'member' : form.position
                    }`
                  );
                }

                if (role !== form.role) {
                  messages.push(`role to ${form.role}`);
                }

                const message = `Change ${user.name}'s ${messages.join(
                  ' and '
                )}?`;

                let changeAdmin = false;
                let subtitle = 'You can change position and roles anytime';
                let subtitleColor;

                if (position !== 'admin' && form.position === 'admin') {
                  changeAdmin = true;
                  subtitle = `${user.name} will have admin access to all your accounts`;
                  // subtitleColor = '#FF5C77';
                  subtitleColor = '#E35050';
                } else if (position === 'admin' && form.position !== 'admin') {
                  changeAdmin = true;
                  subtitle = `${user.name} will lose all admin permissions!`;
                  // subtitleColor = '#FF5C77';
                  subtitleColor = '#E35050';
                }

                // console.log('onClick Save message: ', message);

                const modalObject = {
                  // icon: "enable",
                  title: message,
                  subtitle,
                  subtitleColor,
                  // confirmButtonColor: changeAdmin ? '#FF5C77' : '#5F27CD',
                  confirmButtonColor: changeAdmin ? '#E35050' : '#5F27CD',
                  // iconBackground: changeAdmin ? "#FFDCF3" : "#E2D8FF",
                  onConfirm: async () => {
                    setShowDynamicConfirmModal(false);
                    setDynamicModalObj(null);
                    //   setCustomTab(null);
                    setIsSaving(true);

                    let resMessage = '';
                    let errors = false;
                    const errorFields = [];

                    // console.log('MAKING CHANGES -------');
                    // NOTE: This was used for changing functionalRole on the user, not circleMember. modifyCircleMemberRole sets functionalRole/memberRole on circleMember so it's team-specific
                    // if (position !== form.position) {
                    //   await changeUserFunctionRole(
                    //     user.id,
                    //     form.position,
                    //     (msg) => {
                    //       //   console.log(msg);
                    //       if (msg === 'SUCCESS') {
                    //         resMessage += `${user.name}'s position changed to ${form.position}.\n`;
                    //       } else {
                    //         // console.log(msg);
                    //         errors = true;
                    //         errorFields.push('position');
                    //       }
                    //     }
                    //   );
                    // }
                    if (role !== form.role) {
                      await modifyCircleMemberRole(
                        // user.circleMember?.id,
                        circleMemberId,
                        // user.id,
                        // team.id,
                        // form.role,
                        form.role,
                        (msg) => {
                          //   console.log(msg);
                          if (msg === 'SUCCESS') {
                            resMessage += `${user.name}'s role changed to ${form.position}.\n`;
                          } else {
                            // console.log(msg);
                            errors = true;
                            errorFields.push('role');
                          }
                        }
                      );
                    }
                    if (position !== form.position) {
                      await modifyPersonRole(
                        user.orgMemberID,
                        form.position,
                        (success) => {
                          if (success) {
                            resMessage += `${user.name}'s position changed to ${form.position}.`;
                          } else {
                            errors = true;
                            errorFields.push('position');
                          }
                        }
                      );
                    }
                    // console.log(
                    //   'MY MESSAGE   -------------------- 5555 --------',
                    //   form.role
                    // );
                    // console.log(resMessage);

                    //   Toast.show({
                    //     text1: resMessage,
                    //     type: errors ? "error" : "success",
                    //     autoHide: true,
                    //     visibilityTime: 3000,
                    //   });

                    setTimeout(() => onTriggerRefetch(), 1500);
                    setIsSaving(false);
                    setShowRolePositionModal(false);

                    if (errors) {
                      if (errorFields.includes('position')) {
                        GamifyToast.error(
                          `There was an error updating ${user.name}'s position, please try again`
                        );
                      }

                      if (errorFields.includes('role')) {
                        GamifyToast.error(
                          `There was an error updating ${user.name}'s role, please try again`
                        );
                      }
                    } else {
                      // Update rolePositionObj.original on successful update
                      setRolePositionObj((p) => ({
                        ...p,
                        original: { ...p.form },
                      }));
                      GamifyToast.success(resMessage);
                    }
                  },
                };

                setDynamicModalObj(modalObject);
                setShowDynamicConfirmModal(true);
                //   setIsSaving(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#5F27CD',
                width: 262,
                padding: 12,
                borderRadius: 8,
              }}
            >
              {isSaving ? (
                <CircularProgress
                  style={{ color: 'white', width: 24, height: 24 }}
                ></CircularProgress>
              ) : (
                <Typography style={{ color: 'white', fontWeight: 700 }}>
                  Save
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Backdrop>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDynamicConfirmModal}
        setShowDynamicConfirmModal={setShowDynamicConfirmModal}
        zIndex={100002}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        confirmButtonColor={dynamicModalObj?.confirmButtonColor}
        onConfirm={dynamicModalObj?.onConfirm}
      ></DynamicConfirmModal>
    </>
  );
}
