import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  FormControl,
  Switch,
  FormGroup,
  FormControlLabel,
  Avatar,
  NativeSelect,
  CircularProgress,
} from '@mui/material';
import {
  StyledButton,
  SubmitButton,
  Title,
  Subtitle,
  StyledInputLabel,
  StyledInput,
  // StyledSelect,
  StyledSelectLabel,
  SubsectionTitle,
} from './StyledInputs.js';
import { StyledSelect } from '../../common/StyledComponents.js';
import moment from 'moment';
import DeleteUserModal from './DeleteUserModal.js';
import ConfirmationModal from './ConfirmationModal.js';
import { useDropzone } from 'react-dropzone';
import { Storage, API } from 'aws-amplify';
import getUserAvatar from '../../common/utils/getUserAvatar.js';
import { GamifyToast } from '../../common/CustomToasts.js';
import {
  personAdminFunctions,
  modifyPerson,
  updateOrgMember,
} from '../../../graphql/mutations.js';
import getSentenceInitials from '../../common/utils/getSentenceInitials.js';

// NOTE: This component has been replaced by UserFormPage

const validationSchema = Yup.object({
  avatar: Yup.string(),
  name: Yup.string().required('*Required'),
  location: Yup.string(),
  email: Yup.string().email('Invalid email').required('*Required'),
  phoneNumber: Yup.string().required('*Required'),
  title: Yup.string(),
});

const UserEditForm = ({ user, onTriggerTableRefetch, onClose }) => {
  let userFunctionalRoles;
  try {
    userFunctionalRoles = JSON.parse(user.functionalRoles)[0];
  } catch (err) {
    console.log('Got a JSON ERROR');
    userFunctionalRoles = '';
  }

  const formik = useFormik({
    initialValues: {
      id: user?.id || '',
      username: user?.username || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
      name: user?.name || '',
      birthdate: user?.birthdate || '',
      imageName: user?.imageName || '',
      imageType: user?.imageType || '',
      location: user?.location || '',
      initials: user?.initials || '',
      pronoun: user?.pronoun || '',
      phoneNumberVisibility: 'private',
      nameVisibility: 'public',
      emailVisibility: 'private',
      birthdateVisibility: 'circles',
      locationVisibility: 'circles',
      pictureVisibility: 'circles',
      nonCirclePoints: user?.nonCirclePoints || '',
      uploadPictureTitle: 'Upload Picture / Video',
      title: user?.title || '',
      avatar: user?.avatar || user?.avatarFile || '',
      avatarID: user?.avatarID || '',
      levelID: user?.levelID || '',
      role: user?.role || '',
      functionalRoles: userFunctionalRoles || '',
      memberType: user?.orgs?.items[0]?.memberType || '',
      enabled: user?.status === 'active',
      uploadingPicture: false,
      orgID: user?.orgs?.items[0]?.orgID || '',
    },
    validationSchema,
    onSubmit: (values) => {
      const params = {
        ...values,
        phoneNumber: '+1' + values?.phoneNumber,
      };

      onSubmit(params);
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);

  const formattedCreatedAt = user?.createdAt
    ? moment(user.createdAt)?.format('MMM DD, YYYY')
    : '';

  const onUploadImage = async (photo) => {
    const setFieldValue = formik?.setFieldValue;

    const quality = 0.2;

    const result = {
      name: photo.name,
      mediaTypes: photo.type,
      quality,
    };

    if (!photo) {
      setFieldValue('uploadingPicture', false);
    } else {
      setFieldValue('uploadingPicture', true);
      try {
        const blob = photo;
        const name = result.name.replace(/^.*[\\/]/, '');
        await Storage.put(name, blob, {
          contentType: result.mediaTypes,
        })
          .then((result) => console.log(result))
          .catch((err) => console.log(err));
        const VIDEO_EXTENSIONS = [
          'mov',
          'avi',
          'avif',
          'mp4',
          'mpgeg',
          'ogv',
          'ts',
          '3gp',
          '3g2',
        ];

        if (VIDEO_EXTENSIONS.includes(result.mediaTypes)) {
          setFieldValue('imageType', 'video');
        } else {
          setFieldValue('imageType', 'picture');
        }
        setFieldValue('imageName', result.name);
        const avatar = await getUserAvatar(
          result.name,
          result.mediaTypes,
          true
        );

        setFieldValue('avatar', avatar);
        setFieldValue('uploadingPicture', false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleFileSelection = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await onUploadImage(file);
    }
  };

  async function onChangeRole(role) {
    console.log(role);
    const orgMemberId = user?.orgs?.items[0]?.id;

    const orgMember = {
      id: orgMemberId,
      memberType: formik?.values.memberType,
    };

    await API.graphql({
      query: updateOrgMember,
      variables: { input: orgMember },
    });
  }

  async function onChangeStatus(user, enable) {
    const action = enable ? 'enable' : 'disable';

    await API.graphql({
      query: personAdminFunctions,
      variables: { userID: user.id, username: user.username, action },
    });
  }

  async function onSubmit(values) {
    formik.setSubmitting(true);
    const updatedPerson = {
      ...values,
      functionalRoles: JSON.stringify([values.functionalRoles]),
    };
    try {
      await API.graphql({
        query: modifyPerson,
        variables: {
          person: JSON.stringify(updatedPerson),
        },
      });

      await Promise.all([
        onChangeRole(values.role),
        onChangeStatus(user, values?.enabled),
      ]);

      onTriggerTableRefetch();
      GamifyToast.success('Updated successfully.');
      onClose();

      formik.setSubmitting(false);
    } catch (err) {
      GamifyToast.error('There was a problem updating this user.');
      formik.setSubmitting(false);
    }
  }

  const { open: onOpenDropzone } = useDropzone({
    accept: 'image/*,video/*',
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileSelection,
  });

  const onDeleteImage = () => {
    formik.setFieldValue('avatar', '');
    formik.setFieldValue('imageName', '');
    formik.setFieldValue('imageType', '');
  };

  const renderInitials = () => {
    if (formik?.values?.name) {
      return getSentenceInitials(formik?.values?.name);
    }
    return user?.initials;
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      <Title variant="h5" onClick={() => console.log(formik.values)}>
        {user.name}
      </Title>
      <Subtitle variant="subtitle1">Member since {formattedCreatedAt}</Subtitle>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Avatar src={formik.values.avatar} style={{ height: 80, width: 80 }}>
          {renderInitials()}
        </Avatar>

        <StyledButton onClick={onOpenDropzone} variant="contained">
          Upload New Picture
        </StyledButton>
        <StyledButton onClick={onDeleteImage} variant="contained">
          Delete
        </StyledButton>
      </Box>

      <StyledInputLabel>Name</StyledInputLabel>
      <StyledInput
        fullWidth
        id="name"
        name="name"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />

      <StyledInputLabel>Title (Optional)</StyledInputLabel>
      <StyledInput
        fullWidth
        id="title"
        name="title"
        variant="outlined"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
      />
      <FormControl fullWidth variant="outlined">
        <StyledSelectLabel>Role</StyledSelectLabel>
        <NativeSelect
          id="functionalRoles"
          name="functionalRoles"
          placeholder="Select"
          label=""
          value={formik.values.functionalRoles}
          onChange={formik.handleChange}
          input={<StyledSelect />}
          sx={{ marginTop: 4 }}
        >
          <option value="setter">Setter</option>
          <option value="closer">Closer</option>
          <option value="manager">Manager</option>
        </NativeSelect>
      </FormControl>

      <StyledInputLabel>Location (Optional)</StyledInputLabel>
      <StyledInput
        fullWidth
        id="location"
        name="location"
        variant="outlined"
        value={formik.values.location}
        onChange={formik.handleChange}
        error={formik.touched.location && Boolean(formik.errors.location)}
        helperText={formik.touched.location && formik.errors.location}
      />
      <StyledInputLabel>Email</StyledInputLabel>
      <StyledInput
        fullWidth
        id="email"
        name="email"
        variant="outlined"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <StyledInputLabel>Phone Number</StyledInputLabel>
      <StyledInput
        fullWidth
        type="tel"
        id="phoneNumber"
        name="phoneNumber"
        variant="outlined"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
      <SubsectionTitle>PERMISSIONS</SubsectionTitle>

      <FormControl fullWidth variant="outlined">
        <StyledSelectLabel>Access</StyledSelectLabel>
        <NativeSelect
          id="memberType"
          name="memberType"
          placeholder="Select"
          label=""
          value={formik.values.memberType}
          onChange={formik.handleChange}
          input={<StyledSelect />}
          sx={{ marginTop: 4 }}
        >
          <option value="member">Member</option>
          <option value="admin">Admin</option>
          <option value="moderator">Moderator</option>
        </NativeSelect>
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <StyledInputLabel sx={{ fontSize: '16px' }}>
            {formik.values.enabled ? 'Enabled' : 'Disabled'}
          </StyledInputLabel>
          <Subtitle sx={{ marginTop: 1 }}>
            Edit to enable and disable this member
          </Subtitle>
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.enabled}
                onChange={formik.handleChange}
                name="enabled"
                color="primary"
              />
            }
            label=""
          />
        </FormGroup>
      </Box>
      {/* <Box sx={{ marginTop: 3, marginBottom: 2 }}>
        <StyledInputLabel
          onClick={() => setIsDeleteModalOpen(true)}
          sx={{ fontSize: "16px", color: "#FC5555", cursor: "pointer" }}
        >
          Delete Account
        </StyledInputLabel>
      </Box> */}

      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SubmitButton
          disabled={formik?.isSubmitting}
          sx={{
            width: '166px',
            height: '52px',
          }}
          onClick={() => {
            setIsConfirmationModalOpen(true);
          }}
        >
          {formik?.isSubmitting ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Save'
          )}
        </SubmitButton>
      </Box>
      <DeleteUserModal
        user={[user]}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <ConfirmationModal
        title="Are you sure you want to save changes?"
        message="If you continue with the changes, this member will be disable. "
        user={user}
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        confirmationButtonText="Save changes"
        onConfirm={async () => {
          // Do the save action here
          formik.setSubmitting(true);
          await formik.handleSubmit();
          formik.setSubmitting(false);

          setIsConfirmationModalOpen(false);
        }}
      />
    </Box>
  );
};

export default UserEditForm;
