import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { GamifyToast } from '../../common/CustomToasts';

// NOTE: This component has been replaced by the DynamicConfirmModal

const customTextStyles = {
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  textAlign: 'left',
};

const CancelButton = styled(Button)(() => ({
  color: '#635ED1',
  backgroundColor: 'white',
  border: '2px solid #635ED1',
  '&:hover': {
    backgroundColor: '#635ED1',
    color: 'white',
  },
  width: '50%',
  marginRight: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',
  ...customTextStyles,
}));

const DeleteButton = styled(Button)(() => ({
  color: 'white',
  backgroundColor: '#FC5555',
  border: '2px solid #FC5555',
  '&:hover': {
    backgroundColor: '#ff3b3b',
    color: 'white',
  },
  width: '50%',
  paddingTop: '10px',
  paddingBottom: '10px',
  ...customTextStyles,
}));

const DeleteUserModal = ({ open, onClose, message, onConfirm, users = [] }) => {
  console.log(message);
  const [isLoading, setIsLoading] = React.useState(false);

  const titleStyle = {
    fontFamily: 'Manrope',
    fontSize: '28px',
    fontWeight: '600px',
    lineHeight: '34px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#222428',
    marginBottom: '0px',
  };

  const subtitleStyle = {
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    color: '#868686',
  };

  const modalContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    padding: '40px',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '16px',
    right: '16px',
    color: '#222428',
  };

  async function onDeleteUsers(users, onComplete) {
    setIsLoading(true);
    let successfulDeletes = 0;
    let failedDeletes = 0;

    const deletePromises = users.map(async (person) => {
      try {
        await API.graphql({
          query: mutations.personAdminFunctions,
          variables: {
            userID: person.id,
            username: person.username,
            action: 'delete',
          },
        });
        successfulDeletes++;
      } catch (err) {
        failedDeletes++;
      }
    });

    await Promise.all(deletePromises);

    if (successfulDeletes > 0) {
      GamifyToast.success(
        'User(s) updated successfully'`Successfully deleted ${
          successfulDeletes === 1 ? 'user' : `${successfulDeletes} users`
        }.`
      );
    }

    if (failedDeletes > 0) {
      GamifyToast.error(
        `Failed to delete ${
          failedDeletes === 1 ? 'user' : `${failedDeletes} users`
        }. Please try again.`
      );
    }

    setIsLoading(false);
    onComplete(true);
    return true;
  }

  const multipleUsers = users.length > 1;

  return (
    <Modal open={open} onClose={onClose}>
      <Box style={modalContainerStyle}>
        <IconButton style={closeButtonStyle} onClick={onClose}>
          <Close />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <h1 style={titleStyle}>Are you sure?</h1>
        </Box>
        <p style={subtitleStyle}>
          {multipleUsers
            ? `Are you sure you want to delete ${users.length} users? You can enable them again from the members section.`
            : `Are you sure you want to delete ${users[0]?.name}? You can enable them again from the members section.`}
        </p>
        <Box sx={{ display: 'flex', marginTop: 5 }}>
          <CancelButton variant="contained" onClick={onClose}>
            Cancel
          </CancelButton>
          <DeleteButton
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            onClick={() => {
              onDeleteUsers(users, () => {
                onConfirm();
                onClose();
              });
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Delete'
            )}
          </DeleteButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteUserModal;
