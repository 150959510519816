import React from 'react';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import { DateContainer, ViewContainer } from './About.styles';
import CustomTextInput from '../../custom-inputs/CustomTextInput';
import ItemSelectInput from '../../custom-inputs/ItemSelectInput';
import CustomDatePicker from '../../custom-inputs/CustomDatePicker';
import NewGameState from '../../_types/NewGameState';
import ContestType from '../../_types/ContestType';
import Scope from '../../_types/Scope';
import { format, formatISO, sub } from 'date-fns';
import ErrorMessagesState from '../../_types/ErrorMessagesState';

// Note: Only render DateContainer if contest type is NOT bracket
interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  errorMessages: ErrorMessagesState;
}

const AboutView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  errorMessages,
}) => {
  const disabledBeforeDateForEndDatePicker = newGameState.startDate
    ? formatISO(sub(new Date(newGameState.startDate), { days: 1 }))
    : '';

  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>About</InputTitleText>
        <InputTitleIcon src="/images/about.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomTextInput
          label="Name"
          placeholder="E.g. Knocking door challenge"
          value={newGameState.name}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, name: newValue });
          }}
          errorMessage={errorMessages.name}
        />
        <CustomTextInput
          label="Description (Optional)"
          placeholder="E.g. Knock 10 door per day to win this challenge"
          value={newGameState.description}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, description: newValue });
          }}
        />
        <ItemSelectInput
          label="Game Type"
          selectedItem={newGameState.contestType}
          onSelect={(newSelectedItem: {
            label: string;
            value: ContestType;
          }) => {
            const initialBracketValues = {
              roundValues: [],
              bracket: [],
              totalCompetitors: 0,
              roundSpecificRules: true,
              roundOver: 'after_time',
              nextRoundStarts: 'immediately',
              daysBeforeNextRound: 0,
              daysInRound: 0,
            };
            // If bracket, create initial bracket data
            if (newSelectedItem.value === 'bracket_manual') {
              onChangeNewGameState({
                ...newGameState,
                startDate: '',
                endDate: '',
                type: 'quest',
                contestType: 'bracket_manual',
                bracket: initialBracketValues,
              });

              return;
            }

            onChangeNewGameState({
              ...newGameState,
              contestType: newSelectedItem.value as ContestType,
              type:
                newSelectedItem.value === 'bracket_seed' ? 'quest' : 'mission',
              // If we change contest type, we need to remove bracket data if there is any
              bracket: initialBracketValues,
            });
          }}
          items={[
            { label: 'All Play All', value: 'regular' },
            { label: 'Percent to Goal', value: 'percent_to_goal' },
            { label: 'Seed/Matchup - Pre-Bracket', value: 'bracket_seed' },
            { label: 'Bracket', value: 'bracket_manual' },
          ]}
          numColumns={3}
        />
        <ItemSelectInput
          label="Participants"
          selectedItem={newGameState.scope}
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            onChangeNewGameState({
              ...newGameState,
              scope: newSelectedItem.value as Scope,
              isCircleOnly: (newSelectedItem.value as Scope) === 'circle',
              assigneeIDs: [],
              rewardDisposition: 'winner',
            });
          }}
          items={[
            { label: 'Team', value: 'circle' },
            { label: 'Person', value: 'person' },
          ]}
          numColumns={2}
        />
        <ItemSelectInput
          label="Game Viewers"
          selectedItem={newGameState.isPublic}
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            onChangeNewGameState({
              ...newGameState,
              isPublic: newSelectedItem.value,
            });
          }}
          items={[
            { label: 'Contestants', value: false },
            { label: 'Everyone', value: true },
          ]}
          numColumns={2}
        />

        {newGameState.contestType !== 'bracket_manual' && (
          <DateContainer>
            <CustomDatePicker
              label="Start date"
              onChange={(newValue: string) => {
                onChangeNewGameState({
                  ...newGameState,
                  startDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
                });
              }}
              value={
                newGameState.startDate
                  ? new Date(newGameState.startDate).toISOString()
                  : ''
              }
              errorMessage={errorMessages.startDate}
            />
            <CustomDatePicker
              label="End date"
              onChange={(newValue: string) => {
                onChangeNewGameState({
                  ...newGameState,
                  endDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
                });
              }}
              value={
                newGameState.endDate
                  ? new Date(newGameState.endDate).toISOString()
                  : ''
              }
              disableBeforeDate={disabledBeforeDateForEndDatePicker}
              errorMessage={errorMessages.endDate}
            />
          </DateContainer>
        )}
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default AboutView;
