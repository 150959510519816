import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { Box, Snackbar, Backdrop, Button, Tooltip, Alert } from '@mui/material';
import {
  updateCircleLevel,
  createCircleLevel,
} from '../../../graphql/mutations';
import { API } from 'aws-amplify';
import BackspaceIcon from '@mui/icons-material/Backspace';
import AddIcon from '@mui/icons-material/Add';
import gql from 'graphql-tag';
import './TeamsConfigurator.css';

export default function TeamsConfigurator(props) {
  const { orgID } = props;

  return (
    <Box display="flex" width="100%">
      <TeamLevels orgID={orgID} />
    </Box>
  );
}

function TeamLevels(props) {
  const { orgID } = props;

  const [circleLevels, setCircleLevels] = useState(null);
  const [newCircleLevel, setNewCircleLevel] = useState({
    name: '',
    description: '',
    levelNum: 0,
  });
  const [circleLevelObject, setCircleLevelObject] = useState(null);
  const [ogCircleLevel, setOgCircleLevel] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [validations, setValidations] = useState({ errors: false });
  const [submitted, setSubmitted] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(null);

  const fetchCircleLevels = async () => {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listCircleLevelByOrg(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            name
            description
            levelNum
          }
        }
      }
    `;

    try {
      const res = await API.graphql({
        query: myQuery,
        variables: { orgID },
      });
      const circleLevels = res.data.listCircleLevelByOrg.items;

      const sortedCircleLevels = circleLevels.sort((a, b) => {
        return a.levelNum - b.levelNum;
      });

      setCircleLevels(sortedCircleLevels);
    } catch (err) {
      console.error(
        'fetchMyCircleLevels(): error fetching my circle levels',
        err
      );
    }
  };

  const resetComp = () => {
    setChangesMade(false);
    setSubmitted(false);
    if (ogCircleLevel) setOgCircleLevel(null);
  };

  const validateObject = (obj) => {
    if (!obj) return null;
    const temp = { errors: false };
    if (!obj.name.length) {
      temp.errors = true;
      temp.name = 'Team Levels Must Have a name';
    }
    const duplicateLevel = circleLevels.find(
      (el) => el.levelNum === obj.levelNum && el.id !== obj.id
    );
    if (duplicateLevel) {
      temp.errors = true;
      temp.levelNum = `Level number is used by ${duplicateLevel.name}`;
    }
    return temp;
  };

  const handleSubmit = async () => {
    setSubmitted(true);

    if (validations.errors) return;
    if (!changesMade) {
      resetComp();
      setCircleLevelObject(null);
    }
    const submitObj = {
      name: circleLevelObject.name,
      description: circleLevelObject.description,
      levelNum: circleLevelObject.levelNum,
      orgID,
    };
    if (circleLevelObject.id) {
      submitObj.id = circleLevelObject.id;
      try {
        await API.graphql({
          query: updateCircleLevel,
          variables: { input: submitObj },
        });
      } catch (err) {
        console.error('ERROR -->');
        console.error(err);
      }
      setSnackbarObj({
        message: `${circleLevelObject.name} level updated`,
        severity: 'info',
      });
    } else {
      submitObj.isDeleted = false;
      try {
        await API.graphql({
          query: createCircleLevel,
          variables: { input: submitObj },
        });
      } catch (err) {
        console.error('ERROR -->');
        console.error(err);
      }
      setSnackbarObj({
        message: `${circleLevelObject.name} level created`,
        severity: 'success',
      });
    }
    resetComp();
    setCircleLevelObject(null);
    fetchCircleLevels();
  };

  const handleDelete = async () => {
    const deleteObject = {
      id: circleLevelObject.id,
      isDeleted: true,
    };
    try {
      await API.graphql({
        query: updateCircleLevel,
        variables: { input: deleteObject },
      });
    } catch (err) {
      console.error('ERROR -->');
      console.error(err);
    }
    resetComp();
    setSnackbarObj({
      message: `${circleLevelObject.name} level deleted`,
      severity: 'error',
    });
    setCircleLevelObject(null);
    fetchCircleLevels();
    setDeleteModal(false);
  };

  useEffect(() => {
    fetchCircleLevels();
  }, []);

  useEffect(() => {
    if (!circleLevels) return;
    setNewCircleLevel((p) => ({ ...p, levelNum: circleLevels.length }));
    // newCircleLevel.levelNum = circleLevels.length;
  }, [circleLevels]);

  useEffect(() => {
    setValidations(validateObject(circleLevelObject));
    if (ogCircleLevel) {
      setChangesMade(
        JSON.stringify(ogCircleLevel) !== JSON.stringify(circleLevelObject)
      );
    }
  }, [circleLevelObject]);

  return (
    <Box
      style={{
        border: '1px solid gray',
        borderRadius: '15px',
        overflow: 'hidden',
        flexGrow: '1',
        minWidth: '30vw',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'lightgray',
          padding: '10px 35px',
        }}
      >
        {circleLevelObject ? (
          <BackspaceIcon
            className="hov"
            style={{ fontSize: '2.3rem', color: '#5f27cd' }}
            onClick={() => {
              resetComp();
              setCircleLevelObject(null);
            }}
          />
        ) : (
          <Box style={{ width: '2.5rem', height: '2rem' }} />
        )}
        {circleLevelObject ? (
          circleLevelObject.id ? (
            <Text style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
              {`${circleLevelObject.name} Level`}
            </Text>
          ) : (
            <Text style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
              Create New Level
            </Text>
          )
        ) : (
          <Text style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
            Team Levels
          </Text>
        )}
        {circleLevelObject ? (
          <Box style={{ width: '2rem', height: '2rem' }} />
        ) : (
          <Tooltip fontWeight="bold" title="Create New Level">
            <Box
              className="add-level-button hov"
              onClick={() => {
                setCircleLevelObject(newCircleLevel);
              }}
            >
              <AddIcon style={{ color: 'white' }} />
            </Box>
          </Tooltip>
        )}
      </Box>
      {circleLevelObject ? (
        <Box style={{ padding: '15px', minHeight: '17.8rem' }}>
          <Box display="flex">
            <Box className="level-number-container">
              <Text
                style={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'gray',
                }}
              >
                Level Number
              </Text>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  className="number-display-box"
                  style={{
                    backgroundColor: validations?.levelNum ? 'gray' : '#5f27cd',
                  }}
                >
                  <Text style={{ color: 'white' }}>
                    {circleLevelObject.levelNum}
                  </Text>
                </Box>
                <Box style={{ display: 'flex', width: '60%', margin: '5px' }}>
                  <Box
                    className="order-change-button hov"
                    style={{
                      backgroundColor: !circleLevelObject.levelNum
                        ? 'gray'
                        : '#5f27cd',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}
                    onClick={() => {
                      if (!circleLevelObject.levelNum) return;
                      setCircleLevelObject((p) => ({
                        ...p,
                        levelNum: p.levelNum - 1,
                      }));
                    }}
                  >
                    -
                  </Box>
                  <Box
                    className="order-change-button hov"
                    style={{
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}
                    onClick={() => {
                      setCircleLevelObject((p) => ({
                        ...p,
                        levelNum: p.levelNum + 1,
                      }));
                    }}
                  >
                    +
                  </Box>
                </Box>
              </Box>
              {validations?.errors && validations?.levelNum ? (
                <Text style={{ fontSize: '1rem', color: 'red' }}>
                  {validations.levelNum}
                </Text>
              ) : (
                <Box style={{ height: '1.5rem', width: '1rem' }} />
              )}
            </Box>
            <Box className="circle-level-form">
              <Box>
                <Box display="flex" alignItems="center">
                  <Text
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      color: 'gray',
                      marginRight: '5px',
                    }}
                  >
                    Title
                  </Text>
                  {validations?.name && submitted && (
                    <Text
                      style={{
                        fontSize: '1rem',
                        color: 'red',
                      }}
                    >
                      {validations.name}
                    </Text>
                  )}
                </Box>
                <input
                  className="borderless-input"
                  placeholder="Enter Level Title..."
                  type="text"
                  value={circleLevelObject.name}
                  onChange={(e) =>
                    setCircleLevelObject((p) => ({
                      ...p,
                      name: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box>
                <Text
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    color: 'gray',
                  }}
                >
                  Description
                </Text>
                <textarea
                  className="description-teamlevel-input"
                  rows={4}
                  placeholder="Team level descreiption..."
                  value={circleLevelObject.description}
                  onChange={(e) => {
                    setCircleLevelObject((p) => ({
                      ...p,
                      description: e.target.value,
                    }));
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className="teamLevel-buttons-box">
            <Box
              id="save-teamLevel-button"
              className="hov teamLevel-form-button"
              onClick={handleSubmit}
            >
              {circleLevelObject?.id ? 'Update Level' : 'Save Level'}
            </Box>
            {circleLevelObject?.id && (
              <Box
                id="delete-teamLevel-button"
                className="hov teamLevel-form-button"
                onClick={() => setDeleteModal(true)}
              >
                Delete Level
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            padding: '15px',
          }}
        >
          {circleLevels &&
            circleLevels.map((circleLvl, i) => (
              <Box
                key={i}
                className="circle-level-list-item hov"
                onClick={() => {
                  setCircleLevelObject(circleLvl);
                  setOgCircleLevel(circleLvl);
                }}
              >
                <Box className="circle-level-number-box">
                  <Text
                    style={{
                      fontSize: '2.5rem',
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    {circleLvl.levelNum}
                  </Text>
                </Box>
                <Box>
                  <Text style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                    {circleLvl.name}
                  </Text>
                  <Text style={{ color: 'gray' }}>{circleLvl.description}</Text>
                </Box>
              </Box>
            ))}
        </Box>
      )}
      {deleteModal && circleLevelObject && (
        <Backdrop
          open={deleteModal}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Box id="delete-circle-level-modal">
            <Text
              fontWeight="bold"
              fontSize="1.2rem"
            >{`Are you sure you want to delete ${circleLevelObject.name} level?`}</Text>
            <Box id="delete-modal-buttons-box">
              <Button
                variant="contained"
                color="success"
                onClick={handleDelete}
              >
                Yes (Delete Level)
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setDeleteModal(false)}
              >
                No (Keep Level)
              </Button>
            </Box>
          </Box>
        </Backdrop>
      )}
      {snackbarObj && (
        <Snackbar
          autoHideDuration={6000}
          onClose={() => setSnackbarObj(null)}
          open={snackbarObj}
        >
          <Alert severity={snackbarObj.severity}>{snackbarObj.message}</Alert>
        </Snackbar>
      )}
    </Box>
  );
}
