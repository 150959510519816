import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export default class CircularProgressWithLabel extends React.Component {
  render() {
    const roundedValue = Math.round(this.props.value);
    const treatedValue = Math.min(100, roundedValue);

    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          {...this.props}
          value={treatedValue}
          color="application"
          size={50}
          thickness={1.0}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div">
            {`${roundedValue}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
}
