import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import * as utils from '../../common/Utilities';

import { Box } from '@mui/material';
import './Index.css';
import DisplayMedia from '../../components/DisplayMedia';
import { API, graphqlOperation } from 'aws-amplify';
import {
  blankIfValue,
  defaultDateFormat,
  graphqlDateFormat,
  LOG,
} from '../../common/Utilities';

import { createReward } from '../../../graphql/mutations';
import * as mutations from '../../../graphql/mutations';
import gql from 'graphql-tag';
import {
  BackButton,
  DateContainer,
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
  NewRewardTitle,
  NewRewardViewContainer,
  ViewInputsContainer,
} from './Rewards.styles';
import UploadMediaNewReward from './UploadMediaNewReward';
import ImageCloseButton from './ImageCloseButton';
import CustomTextInput from '../games/NewGame/custom-inputs/CustomTextInput';
import CustomSelect from '../games/NewGame/custom-inputs/CustomSelect';
import CustomDatePicker from '../games/NewGame/custom-inputs/CustomDatePicker';
import { format } from 'date-fns';
import { useAlert } from 'react-alert-with-buttons';
import { GamifyToast } from '../../common/CustomToasts';

export default function NewReward() {
  const alert = useAlert();

  const newReward = {
    id: '',
    name: '',
    description: '',
    rewardType: 'no_line',
    personLimit: '',
    limitCount: '',
    value: '',
    valueType: '',
    remainingCount: '',
    tags: '',
    validFrom: '',
    validTo: '',
    launchDate: '',
    isActive: 1,
    cost: '',
    costType: '',
    costName: 'points',
    ownerID: global.me.orgID,
    ownerType: global.me.isAdmin ? 'public' : 'circle',
    approverType: 'auto',
    location: '',
    uploadloadingPicture: false,
    uploadPictureTitle: 'Upload Picture / Video',
    imageName: '',
    imageType: '',
    orgID: global.me.orgID,
    useRewardCatalog: false,
    cloneReward: false,
    utid: '',
    minGiftcardAmount: 0,
    maxGiftcardAmount: 0,
    giftcardValue: '',
    giftcardValueFixed: false,
    giftcardDisclaimer: '',
    tangoAccountID: '',
  };
  const [initialValues, setInitialValues] = useState({});

  const createRewardValidationSchema = yup.object().shape({
    cost: yup
      .number()
      .required()
      .positive()
      .integer()
      .min(0, 'Value must be at least 0')
      .max(1000000, 'Value can be no larger than 1000000')
      .label('Reward Cost'),
    limitCount: yup
      .number()
      .required()
      .positive()
      .integer()
      .min(1, 'Value must be at least 1')
      .max(100000, 'Value can be no larger than 100000')
      .label('Limit'),
    personLimit: yup
      .number()
      .required()
      .positive()
      .integer()
      .min(1, 'Value must be at least 1')
      .max(1000, 'Value can be no larger than 1000')
      .label('Limit'),
    name: yup
      .string()
      .label('Name')
      .required()
      .min(5, 'The name is too short.  Please enter at least 5 characters'),
    tags: yup
      .string()
      .label('Tags')
      .min(5, 'The tag name is too short.  Please enter at least 5 characters')
      .max(32, 'The tag name is too long.'),
    // description: yup
    //   .string()
    //   .label('Description')
    //   .required()
    //   .min(
    //     10,
    //     'The description is too short.  Please enter at least 10 characters'
    //   )
    //   .max(
    //     1024,
    //     'The description is too long.  Please use less than 1024 characters'
    //   ),
    validFrom: yup
      .string()
      .required()
      .label('Start date')
      .matches(
        utils.dateRegExp,
        'Please enter a valid date in the form ' + global.appSettings.dateFormat
      ),
    validTo: yup
      .string()
      .required()
      .label('End date')
      .matches(
        utils.dateRegExp,
        'Please enter a valid date in the form form ' +
          global.appSettings.dateFormat
      )
      .test(
        'is-greater-or-equal',
        'End date must be later than or the same as the start date',
        function (value) {
          const { validFrom } = this.parent;
          // Check if validTo is greater than or equal to validFrom
          return new Date(value) >= new Date(validFrom);
        }
      ),
    launchDate: yup
      .string()
      .label('Launch Date')
      .matches(
        utils.dateRegExp,
        'Please enter a valid date in the form form ' +
          global.appSettings.dateFormat
      ),
  });

  /// ///////
  //
  // Get reward
  //
  /// ///////

  async function getRewardForScreen(rewardID) {
    LOG.debug('!!!getRewardForScreen: ', rewardID);

    if (rewardID === '' || rewardID === undefined) {
      return initialValues;
    }

    const myQuery = gql`
      query getRewardDetails($rewardID: ID!) {
        getReward(id: $rewardID) {
          id
          isDeleted
          approverType
          cost
          costType
          description
          imageName
          imageType
          isActive
          limitCount
          location
          name
          personLimit
          remainingCount
          rewardType
          validFrom
          validTo
          value
          valueType
          ownerType
          utid
          giftcardDisclaimer
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          tangoAccountID
        }
      }
    `;

    const rewardData = await API.graphql({
      query: myQuery,
      variables: { rewardID },
    });

    LOG.debug('!!!rewardData: ', rewardData);
    if (!rewardData.data.getReward) {
      setInitialValues(newReward);
      return newReward;
    }

    const reward = rewardData.data.getReward;
    const ourReward = {
      id: reward.id,
      name: reward.name,
      description: reward.description,
      rewardType: reward.rewardType,
      personLimit: reward.personLimit.toString(),
      limitCount: reward.limitCount.toString(),
      value: reward.value.toString(),
      valueType: reward.valueType,
      imageName: reward.imageName,
      imageType: reward.imageType,
      tags: reward.tags,
      validFrom: defaultDateFormat(
        blankIfValue(reward.validFrom, utils.MIN_DATE)
      ),
      validTo: defaultDateFormat(blankIfValue(reward.validTo, utils.MAX_DATE)),
      launchDate: defaultDateFormat(
        blankIfValue(reward.launchDate, utils.MAX_DATE)
      ),
      isActive: reward.isActive,
      cost: reward.cost.toString(),
      costType: reward.costType,
      ownerID: reward.ownerID,
      approverType: reward.approverType,
      location: reward.location,
      orgID: reward.orgID,
      remainingCount: reward.remainingCount,
      ownerType: reward.ownerType,
      createdBy: global.me.id,
      uploadPictureTitle: 'Upload Picture / Video',
      useRewardCatalog: false,
      cloneReward: false,
      utid: reward.utid,
      minGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.minGiftcardAmount)), 1),
        1
      ),
      maxGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.maxGiftcardAmount)), 1),
        1
      ),
      giftcardValue: reward.giftcardValue.toString(),
      giftcardValueFixed: false,
      tangoAccountID:
        reward.tangoAccountID !== 'NA' ? reward.tangoAccountID : '',
    };

    setInitialValues(ourReward);
  }

  /// ////////
  //
  // Update reward
  //
  /// ////////

  async function updateRewardRecord(reward) {
    LOG.debug('updateRewardRecord: ', reward);

    if (reward.ownerType === 'public') {
      reward.ownerID = global.me.orgID;
    }
    const ourReward = {
      id: reward.id,
      name: reward.name,
      description: reward.description,
      rewardType: reward.rewardType,
      personLimit: reward.personLimit.toString().trim(),
      limitCount: reward.limitCount.toString().trim(),
      value: reward.value.toString().trim(),
      valueType: reward.valueType,
      remainingCount: reward.remainingCount.toString().trim(),
      imageName: reward.imageName,
      imageType: reward.imageType,
      tags: reward.tags,
      validFrom: graphqlDateFormat(reward.validFrom),
      validTo: graphqlDateFormat(reward.validTo),
      launchDate: graphqlDateFormat(
        utils.defaultIfEmpty(reward.launchDate, reward.validFrom)
      ),
      isActive: reward.isActive,
      cost: reward.cost.toString().trim(),
      costType: reward.costType,
      ownerID: reward.ownerID,
      approverType: reward.approverType,
      location: reward.location,
      orgID: reward.orgID,
      ownerType: reward.ownerType,
      createdBy: global.me.id,
      isDeleted: false,
      giftcardValue: Number(utils.defaultIfEmpty(reward.giftcardValue, 0)),
      utid: reward.utid,
      giftcardDisclaimer: reward.giftcardDisclaimer,
      minGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.minGiftcardAmount)), 1),
        1
      ),
      maxGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.maxGiftcardAmount)), 1),
        1
      ),
      tangoAccountID:
        reward.tangoAccountID !== '' ? reward.tangoAccountID : 'NA',
    };

    try {
      // reward.isActive = 1;
      // reward.remainingCount = reward.limitCount;

      await API.graphql({
        query: mutations.updateReward,
        variables: { input: ourReward },
      });
      alert.open({
        message: global.appSettings.labels.reward.proper + ' Updated',
      });
      ourReward.validFrom = defaultDateFormat(
        blankIfValue(ourReward.validFrom, utils.MIN_DATE)
      );
      ourReward.validTo = defaultDateFormat(
        blankIfValue(ourReward.validTo, utils.MAX_DATE)
      );
      ourReward.launchDate = defaultDateFormat(
        blankIfValue(ourReward.launchDate, utils.MAX_DATE)
      );
      setInitialValues(ourReward);

      // setFormState(initialState);
      // setButtonState(initialButtonState);
    } catch (err) {
      alert.open({
        message:
          'There was an error updating your ' +
          global.appSettings.labels.reward.proper +
          '.  Error: ' +
          err,
      });
      LOG.error('updateReward(): error updating Reward:' + err);
      return false;
    }
    return true;
  }

  /// ////////
  //
  // Create reward
  //
  /// ////////

  async function createRewardRecord(reward) {
    LOG.debug('createRewardRecord: ', reward);

    if (reward.id !== '') {
      const ret = await updateRewardRecord(reward);
      return ret;
    }

    if (reward.ownerType === 'public') {
      reward.ownerID = global.me.orgID;
      reward.ownerName = 'Everyone';
    }

    const ourReward = {
      name: reward.name,
      description: reward.description,
      rewardType: reward.rewardType.toString().trim(),
      personLimit: reward.personLimit.toString().trim(),
      limitCount: reward.limitCount.toString().trim(),
      value: 0, //      value: reward.value,
      valueType: reward.valueType,
      remainingCount: reward.limitCount.toString().trim(),
      imageName: reward.imageName,
      imageType: reward.imageType,
      tags: reward.tags,
      validFrom: graphqlDateFormat(reward.validFrom),
      validTo: graphqlDateFormat(reward.validTo),
      launchDate: graphqlDateFormat(
        utils.defaultIfEmpty(reward.launchDate, reward.validFrom)
      ),
      isActive: 1, //      isActive: reward.isActive,
      cost: reward.cost.toString().trim(),
      costType: reward.costType,
      ownerID: reward.ownerID,
      approverType: reward.approverType,
      location: reward.location,
      ownerType: reward.ownerType,
      createdBy: global.me.id,
      orgID: global.me.orgID,
      isDeleted: false,
      giftcardValue: Number(utils.defaultIfEmpty(reward.giftcardValue, '0')),
      utid: reward.utid,
      giftcardDisclaimer: reward.giftcardDisclaimer,
      minGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.minGiftcardAmount)), 1),
        1
      ),
      maxGiftcardAmount: utils.defaultIfEmpty(
        Math.max(Math.round(parseFloat(reward.maxGiftcardAmount)), 1),
        1
      ),
      tangoAccountID:
        reward.tangoAccountID !== '' ? reward.tangoAccountID : 'NA',
    };

    try {
      await API.graphql(graphqlOperation(createReward, { input: ourReward }));
      setInitialValues(newReward);

      GamifyToast.success('Reward Created');
    } catch (err) {
      LOG.error('createReward(): error creating Reward:' + err);
      GamifyToast.error('There was an error creating your reward');
      return false;
    }
    return true;
  }

  async function doSubmit(values, actions) {
    LOG.debug('createReward doSubmit: ', values, actions);

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (values.utid !== '' && values.tangoAccountID === '') {
      alert.open({
        message: 'You must select an account to pay for a giftcard.',
      });
      actions.setSubmitting(false);
      return;
    }
    const ret = await createRewardRecord(values);
    if (ret) {
      console.log('New Record CREATED');
      window.location.href = '/rewards';
    }
    actions.setSubmitting(false);
  }

  // async function cloneReward (rewardID) {
  //   const rewardData = await API.graphql({ query: getReward, variables: { id: rewardID } });
  //   if (!rewardData.data.getReward) {
  //     setInitialValues(newReward);
  //     return (newReward);
  //   }
  //
  //   const reward = rewardData.data.getReward;
  //   const ourReward = {
  //     id: '',
  //     name: reward.name,
  //     description: reward.description,
  //     rewardType: reward.rewardType,
  //     personLimit: reward.personLimit.toString().trim(),
  //     limitCount: reward.limitCount.toString().trim(),
  //     value: reward.value.toString().trim(),
  //     valueType: reward.valueType,
  //     remainingCount: reward.limitCount.toString().trim(),
  //     imageName: reward.imageName,
  //     imageType: reward.imageType,
  //     tags: reward.tags,
  //     validFrom: '',
  //     validTo: '',
  //     launchDate: '',
  //     isActive: true,
  //     cost: reward.cost.toString().trim(),
  //     costType: reward.costType,
  //     ownerID: reward.ownerID,
  //     approverType: reward.approverType,
  //     location: reward.location,
  //     orgID: reward.orgID,
  //     ownerType: reward.ownerType,
  //     createdBy: global.me.id,
  //     uploadPictureTitle: 'Upload Picture / Video',
  //     useRewardCatalog: false,
  //     cloneReward: false,
  //     utid: reward.utid,
  //     minGiftcardAmount: utils.defaultIfEmpty(Math.max(Math.round(parseFloat(reward.minGiftcardAmount)), 1), 1),
  //     maxGiftcardAmount: utils.defaultIfEmpty(Math.max(Math.round(parseFloat(reward.maxGiftcardAmount)), 1), 1),
  //     giftcardValue: 0,
  //     giftcardValueFixed: false,
  //     giftcardDisclaimer: reward.giftcardDisclaimer,
  //     tangoAccountID: reward.tangoAccountID !== 'NA' ? reward.tangoAccountID : ''
  //   };
  //   setInitialValues(ourReward);
  // }

  // async function loadCatalogReward (rewardID, title, description, amount, minAmount, maxAmount, imageName, utid, giftcardValueFixed, giftcardDisclaimer) {
  //   LOG.debug('loadCatalogReward: ', rewardID, title, description, amount, minAmount, maxAmount, imageName, utid, giftcardValueFixed, giftcardDisclaimer);
  //
  //   let amountStr = amount.toString();
  //   let valueStr = (amount * 100).toString();
  //   if (amount === 0) {
  //     amountStr = '';
  //     valueStr = '';
  //   }
  //   let ourDescription = description.replace(/<p[^>]*>/g, '\n');
  //   ourDescription = ourDescription.replace(/(<([^>]+)>)/ig, '');
  //   ourDescription = ourDescription.replace(/&nbsp;/ig, ' ');
  //
  //   // LOG.debug ("!!giftcardDisclaimer: ", giftcardDisclaimer);
  //   let ourGiftcardDisclaimer = giftcardDisclaimer.replace(/<p[^>]*>/g, '\n');
  //   // LOG.debug ("!!ourGiftcardDisclaimer: ", ourGiftcardDisclaimer);
  //   ourGiftcardDisclaimer = ourGiftcardDisclaimer.replace(/(<([^>]+)>)/ig, '');
  //   // LOG.debug ("!!ourGiftcardDisclaimer: ", ourGiftcardDisclaimer);
  //   ourGiftcardDisclaimer = ourGiftcardDisclaimer.replace(/&nbsp;/ig, ' ');
  //   // LOG.debug ("!!ourGiftcardDisclaimer: ", ourGiftcardDisclaimer);
  //
  //   const ourReward = {
  //     id: rewardID,
  //     name: title,
  //     description: ourDescription,
  //     rewardType: 'no_line',
  //     personLimit: '',
  //     limitCount: '',
  //     value: amountStr,
  //     valueType: 'lyfCoins',
  //     remainingCount: '',
  //     tags: '',
  //     validFrom: '',
  //     validTo: '',
  //     launchDate: '',
  //     isActive: 1,
  //     cost: valueStr,
  //     costType: '',
  //     costName: 'points',
  //     ownerID: global.me.orgID,
  //     ownerType: global.me.isAdmin ? 'public' : 'circle',
  //     approverType: 'auto',
  //     location: '',
  //     uploadloadingPicture: false,
  //     uploadPictureTitle: 'Upload Picture / Video',
  //     imageName,
  //     imageType: 'giftcard_url',
  //     orgID: global.me.orgID,
  //     useRewardCatalog: false,
  //     cloneReward: false,
  //     minGiftcardAmount: utils.defaultIfEmpty(Math.max(Math.round(parseFloat(minAmount)), 1), 1),
  //     maxGiftcardAmount: utils.defaultIfEmpty(Math.max(Math.round(parseFloat(maxAmount)), 1), 1),
  //     giftcardValue: amountStr,
  //     utid,
  //     giftcardValueFixed,
  //     giftcardDisclaimer: ourGiftcardDisclaimer,
  //     tangoAccountID: ''
  //
  //   };
  //   LOG.debug('###ourReward: ', ourReward);
  //   setInitialValues(ourReward);
  // }

  /// //////////////////////////////////////////////////////////////////////////
  //
  // Main screen functionality
  //
  /// //////////////////////////////////////////////////////////////////////////

  // if (!useIsFocused()) {
  //   if (initialValues.id !== "") {
  //     setInitialValues(newReward);
  //     route.params = undefined;
  //   }
  // }

  let rewardID = '';
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('rewardID');
  console.log('GOT THE ID: ', id);
  if (id) {
    rewardID = id;
    if (rewardID !== initialValues.id) {
      getRewardForScreen(rewardID);
    }
  } else if (initialValues.id !== '') {
    rewardID = '';
    setInitialValues(newReward);
  }

  let ownerList = [
    { label: 'Anyone can purchase', value: 'public' },
    {
      label:
        'Only ' +
        global.appSettings.labels.circle.singular +
        ' members can purchase',
      value: 'circle',
    },
    {
      label: 'Attach to a ' + global.appSettings.labels.mission.singular,
      value: 'mission',
    },
  ];

  if (!global.me.isAdmin) {
    ownerList = [
      {
        label:
          'Only ' +
          global.appSettings.labels.circle.singular +
          ' members can purchase',
        value: 'circle',
      },
      {
        label: 'Attach to a ' + global.appSettings.labels.mission.singular,
        value: 'mission',
      },
    ];
  }

  // /* Attempting */

  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#000000',
  //     },
  //     success: {
  //       main: '#827ffc',
  //     },
  //   },
  //   typography: {
  //     allVariants: {
  //       fontFamily: "'Manrope', sans-serif",
  //       color: 'white',
  //     },
  //     button: {
  //       textTransform: 'none',
  //     },
  //   },
  //   DatePickerField: {
  //     borderColor: 'black',
  //   },
  // });

  // const DatePickerField = ({ ...props }) => {
  //   const { setFieldValue } = useFormikContext();
  //   const [field] = useField(props);
  //   return (
  //     <DatePicker
  //       {...field}
  //       {...props}
  //       selected={(field.value && new Date(field.value)) || null}
  //       onChange={(val) => {
  //         const formattedDate = moment(val).format('MM/DD/YYYY');
  //         setFieldValue(field.name, formattedDate);
  //       }}
  //     />
  //   );
  // };

  return (
    <React.Fragment>
      <img
        width="100%"
        style={{ position: 'absolute', zIndex: -1 }}
        src="/images/new-reward-background.svg"
      />
      <BackButton
        onClick={() => {
          alert.open({
            message:
              'Are you sure you wish to cancel the reward creation process?',
            buttons: [
              {
                label: 'Yes',
                onClick: async () => {
                  window.location.href = '/rewards';
                  alert.close();
                },
                style: {
                  borderRadius: 16,
                  backgroundColor: '#5F27CD',
                  marginRight: 20,
                  color: '#fff',
                  fontWeight: 700,
                },
              },
              {
                label: 'No',
                onClick: () => alert.close(),
                style: {
                  borderRadius: 16,
                  // backgroundColor: '#FF5C77',
                  backgroundColor: '#E35050',
                  color: '#fff',
                  fontWeight: 700,
                },
              },
            ],
          });
        }}
      >
        <img src="/images/back.svg" alt="back" width={10} height={10} />
        <div>Cancel</div>
      </BackButton>
      <NewRewardViewContainer>
        <NewRewardTitle>New Reward</NewRewardTitle>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={createRewardValidationSchema}
          onSubmit={(values, actions) => doSubmit(values, actions)}
        >
          {(formikProps) => {
            console.log(formikProps.errors);
            return (
              <Form>
                <Box style={{ width: 500, height: 300 }}>
                  {formikProps.values.imageName !== '' ? (
                    <Box
                      style={{
                        justifyContent: 'space-around',
                        width: 500,
                        border: '1px solid #E2D8FF',
                        height: 300,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <ImageCloseButton />
                      <DisplayMedia
                        imageName={formikProps.values.imageName}
                        imageType={formikProps.values.imageType}
                        objectFit="contain"
                      />
                    </Box>
                  ) : (
                    <UploadMediaNewReward formikProps={formikProps} />
                  )}
                </Box>
                <ViewInputsContainer>
                  <InputTitleContainer>
                    <InputTitleText>Permissions</InputTitleText>
                    <InputTitleIcon src="/images/permissions.svg" />
                  </InputTitleContainer>
                  <InputBodyContainer>
                    <CustomSelect
                      label="Who can purchase?"
                      value={formikProps.values.ownerType}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('ownerType', newValue);
                      }}
                      items={ownerList}
                    />
                    <CustomSelect
                      label="Who approves reward purchases?"
                      value={formikProps.values.approverType}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('approverType', newValue);
                      }}
                      items={[
                        {
                          label: 'No approval required',
                          value: 'auto',
                        },
                        {
                          label:
                            global.appSettings.labels.reward.proper + ' owner',
                          value: 'owner',
                        },
                        {
                          label:
                            global.appSettings.labels.circle.proper + ' owner',
                          value: 'circle_owner',
                        },
                        {
                          label:
                            global.appSettings.labels.circle.proper +
                            ' moderator',
                          value: 'moderator',
                        },
                      ]}
                    />
                  </InputBodyContainer>
                </ViewInputsContainer>
                <ViewInputsContainer>
                  <InputTitleContainer>
                    <InputTitleText>About</InputTitleText>
                    <InputTitleIcon src="/images/about.svg" />
                  </InputTitleContainer>
                  <InputBodyContainer>
                    <CustomTextInput
                      label="Name"
                      placeholder="E.g. Apple Watch"
                      value={formikProps.values.name}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('name', newValue);
                      }}
                      errorMessage={formikProps.errors.name}
                    />
                    <CustomTextInput
                      label="Description (optional)"
                      placeholder="E.g. Apple watch 2021 in 5 different colors."
                      value={formikProps.values.description}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('description', newValue);
                      }}
                      errorMessage={formikProps.errors.description}
                      type="TextArea"
                    />
                  </InputBodyContainer>
                </ViewInputsContainer>
                <ViewInputsContainer>
                  <InputTitleContainer>
                    <InputTitleText>Settings</InputTitleText>
                    <InputTitleIcon src="/images/settings.svg" />
                  </InputTitleContainer>
                  <InputBodyContainer>
                    <CustomTextInput
                      label="Cost"
                      placeholder="E.g. 500"
                      value={formikProps.values.cost}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('cost', newValue);
                      }}
                      errorMessage={formikProps.errors.cost}
                    />
                    <CustomTextInput
                      label="How many are Available?"
                      placeholder="E.g. 10"
                      value={formikProps.values.limitCount}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('limitCount', newValue);
                      }}
                      errorMessage={formikProps.errors.limitCount}
                    />
                    <CustomTextInput
                      label="How many can each person buy?"
                      placeholder="E.g. 2"
                      value={formikProps.values.personLimit}
                      onChange={(newValue) => {
                        formikProps.setFieldValue('personLimit', newValue);
                      }}
                      errorMessage={formikProps.errors.personLimit}
                    />
                    <DateContainer>
                      <CustomDatePicker
                        label="Start date"
                        onChange={(newValue) => {
                          formikProps.setFieldValue(
                            'validFrom',
                            format(new Date(newValue), 'MM/dd/yyyy')
                          );
                        }}
                        value={
                          formikProps.values.validFrom
                            ? new Date(
                                formikProps.values.validFrom
                              ).toISOString()
                            : ''
                        }
                        type="Date"
                        placeholder="Select"
                        errorMessage={formikProps.errors.validFrom}
                      />
                      <CustomDatePicker
                        label="End date"
                        onChange={(newValue) => {
                          formikProps.setFieldValue(
                            'validTo',
                            format(new Date(newValue), 'MM/dd/yyyy')
                          );
                        }}
                        value={
                          formikProps.values.validTo
                            ? new Date(formikProps.values.validTo).toISOString()
                            : ''
                        }
                        disableBeforeDate={formikProps.values.validFrom}
                        type="Date"
                        placeholder="Select"
                        errorMessage={formikProps.errors.validTo}
                      />
                    </DateContainer>
                  </InputBodyContainer>
                </ViewInputsContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 20,
                  }}
                >
                  <button
                    disabled={!formikProps.isValid}
                    type={'submit'}
                    style={{
                      backgroundColor: formikProps.isValid
                        ? '#5F27CD'
                        : '#737373',
                      padding: 12,
                      width: '50%',
                      border: 0,
                      borderRadius: 10,
                      color: 'white',
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </NewRewardViewContainer>
    </React.Fragment>
  );
}
