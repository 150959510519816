import React, { useState } from 'react';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import CustomSelect from '../../custom-inputs/CustomSelect';
import {
  TrophyBody,
  TrophyItemBody,
  TrophyItemContainer,
  TrophyLabel,
  TropyContainer,
  ViewContainer,
} from './AdvanceOptions.styles';
import NewGameState from '../../_types/NewGameState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const trophies = [
  {
    description: '1st Place',
    id: '',
    imageName: '/images/trophy-gold.svg',
    imageType: 'picture',
    place: 1,
    title: 'Gold',
  },
  {
    description: '2nd Place',
    id: '',
    imageName: '/images/trophy-silver.svg',
    imageType: 'picture',
    place: 2,
    title: 'Silver',
  },
  {
    description: '3rd Place',
    id: '',
    imageName: '/images/trophy-bronze.svg',
    imageType: 'picture',
    place: 3,
    title: 'Bronze',
  },
  {
    description: 'Completion',
    id: '',
    imageName: '/images/trophy-purple.svg',
    imageType: 'picture',
    place: 4,
    title: 'Completion',
  },
];

const AdvanceOptionsView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const [selectedTrophy, setSelectedTrophy] = useState<any>({
    description: '1st Place',
    id: '',
    imageName: '/images/trophy-gold.svg',
    imageType: 'picture',
    place: 1,
    title: 'Gold',
  });

  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Advanced Options</InputTitleText>
        <InputTitleIcon src="/images/trophy.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomSelect
          label="Automatically advance to the next campaign"
          value={newGameState.advanceType}
          onChange={(newValue: any) => {
            onChangeNewGameState({ ...newGameState, advanceType: newValue });
          }}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
        <CustomSelect
          label="Automatically create goals"
          value={newGameState.automaticallyCreateGoals}
          onChange={(newValue: any) => {
            onChangeNewGameState({
              ...newGameState,
              automaticallyCreateGoals: newValue,
            });
          }}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
        <CustomSelect
          label="Create recurring campaigns"
          value={newGameState.createRecurringCampaigns}
          onChange={(newValue: any) => {
            onChangeNewGameState({
              ...newGameState,
              createRecurringCampaigns: newValue,
            });
          }}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
        <TropyContainer>
          <TrophyLabel>Trophy</TrophyLabel>
          <TrophyBody>
            {trophies.map((trophy) => {
              return (
                <TrophyItemContainer
                  key={trophy.place}
                  onClick={() => {
                    setSelectedTrophy(trophy);
                    onChangeNewGameState({
                      ...newGameState,
                      trophies: [selectedTrophy],
                    });
                  }}
                >
                  <TrophyItemBody
                    isActive={
                      selectedTrophy
                        ? selectedTrophy.place === trophy.place
                        : false
                    }
                  >
                    <img src={trophy.imageName} width={28} height={28} />
                  </TrophyItemBody>
                </TrophyItemContainer>
              );
            })}
          </TrophyBody>
        </TropyContainer>
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default AdvanceOptionsView;
