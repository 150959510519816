import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Text, View } from '@aws-amplify/ui-react';
import { Box, Snackbar, Alert } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterListIcon from '@mui/icons-material/FilterList';
import { API } from 'aws-amplify';
import './Training.css';
import {
  LoadingVideoRows,
  SingleLessonCard,
  UploadTrainingVideo,
} from './TrainingComponents';
import { getLessons } from './TrainingHomePage';

export default function TrainingCategoryPage() {
  const navigate = useNavigate();
  const { category } = useParams();
  const orgID = global.me.orgID;
  const [lessons, setLessons] = useState(null);
  const [displayStyle, setDisplayStyle] = useState('grid');
  const [listOrder, setListOrder] = useState(true);
  const [uploadVid, setUploadVid] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(null);

  useEffect(() => {
    if (!category) return;
    if (global.trainingVideos) {
      setLessons(global.trainingVideos[category]);
    } else {
      getLessons(orgID, API, (res) => setLessons(res[category]));
    }
  }, [category]);

  useEffect(() => {
    if (!lessons) return;
    setLessons(() => [...lessons].reverse());
  }, [listOrder]);

  if (!lessons) return null;
  return (
    <View
      style={{
        paddingTop: '40px',
      }}
    >
      <Box id="training-home-container">
        <Box style={{ marginBottom: '8vh' }} id="training-home-topbar">
          <Box style={{ position: 'relative' }}>
            <Box id="page-title-box">
              <Text
                style={{
                  fontWeight: '700',
                  fontSize: '1.7rem',
                  marginRight: '0.7vw',
                }}
                className="hov"
                onClick={() => navigate('/training')}
              >
                Training
              </Text>
              <Text
                style={{
                  fontWeight: '700',
                  fontSize: '1.5rem',
                  marginRight: '1vw',
                }}
              >{`> ${category}`}</Text>

              {global.me.isAdmin ? (
                <Text
                  className="hov"
                  style={{
                    color: '#5F27CD',
                    fontWeight: '700',
                    transform: 'translateY(-3px)',
                  }}
                  onClick={() => setUploadVid(true)}
                >
                  + Add Video
                </Text>
              ) : null}
            </Box>
            <Text
              className="hov"
              style={{
                position: 'absolute',
                bottom: '-50px',
                fontWeight: 700,
                fontSize: '1.2rem',
              }}
              onClick={() => navigate('/training')}
            >
              {'< Back to Videos'}
            </Text>
          </Box>
          <Box id="type-selection-box">
            <Box className="training-type-option">
              <Text
                style={{
                  color: '#5F27CD',
                  fontSize: '1rem',
                  fontWeight: '700',
                }}
              >
                Videos
              </Text>
              <Box className="dot" style={{ backgroundColor: '#5F27CD' }} />
            </Box>
            <Box className="training-type-option">
              <Text
                style={{
                  color: '#868686',
                  fontSize: '1rem',
                  fontWeight: '700',
                }}
              >
                Documents
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box id="display-controls-box">
            <GridViewIcon
              className="hov"
              onClick={() => setDisplayStyle('grid')}
              style={{
                fontSize: '1.5rem',
                color: displayStyle === 'grid' ? '#5F27CD' : 'gray',
                marginRight: '5px',
              }}
            />
            <Box
              className="hov"
              onClick={() => {
                if (displayStyle === 'grid') {
                  setDisplayStyle('list');
                } else {
                  setListOrder((p) => !p);
                }
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {listOrder ? (
                <SouthIcon
                  style={{
                    color: displayStyle === 'list' ? '#5F27CD' : 'gray',
                  }}
                />
              ) : (
                <NorthIcon
                  style={{
                    color: displayStyle === 'list' ? '#5F27CD' : 'gray',
                  }}
                />
              )}
              <FilterListIcon
                style={{
                  color: displayStyle === 'list' ? '#5F27CD' : 'gray',
                  fontSize: '1rem',
                  //   display: 'relative',
                  marginLeft: '-3px',
                }}
              />
            </Box>
            {/* <Button onClick={() => setDisplayStyle('list')}>LIST</Button> */}
          </Box>
        </Box>

        {lessons ? (
          <Box
            className={
              displayStyle === 'grid'
                ? 'category-row-lessons-container'
                : 'category-list-view-container'
            }
          >
            {lessons.map((lesson, i) => (
              <SingleLessonCard
                lesson={lesson}
                key={i}
                display={displayStyle}
                refresh={() => {
                  getLessons(orgID, API, (res) => {
                    if (!res[category] || !res[category].length) {
                      navigate('/training');
                    } else {
                      setLessons(res[category]);
                    }
                  });
                }}
                snackbar={(message, severity) =>
                  setSnackbarObj({ message, severity })
                }
              />
            ))}
          </Box>
        ) : (
          <LoadingVideoRows />
        )}
      </Box>
      <UploadTrainingVideo
        open={uploadVid}
        category={category}
        close={() => setUploadVid(false)}
        refresh={() => {
          getLessons(orgID, API, (res) => {
            setLessons(res[category]);
          });
        }}
        snackbar={(message, severity) => setSnackbarObj({ message, severity })}
      />
      {snackbarObj && (
        <Snackbar
          autoHideDuration={6000}
          onClose={() => setSnackbarObj(null)}
          open={snackbarObj}
        >
          <Alert severity={snackbarObj.severity}>{snackbarObj.message}</Alert>
        </Snackbar>
      )}
    </View>
  );
}
