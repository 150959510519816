import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import Leaderboard from '../domain/Leaderboard';

export default class LeaderboardRepository {
  static async fetchLeaderboards(missionId, startBetween, endBetween) {
    const result = await API.graphql({
      query: queries.getLeaderboard,
      variables: {
        request: JSON.stringify({
          startBetween,
          endBetween,
          missionID: missionId,
          skipQuests: true,
        }),
      },
    });

    const data = JSON.parse(result.data.getLeaderboard);
    console.log('****data: ', data);
    return [data[0], new Leaderboard(data[1])];
  }
}
