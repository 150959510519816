import React from 'react';
import {
  CustomInputContainer,
  CustomInputLabel,
  ErrorMessage,
} from './CustomInputs.styles';
import { TextField, TextFieldProps } from '@mui/material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarMonth, KeyboardArrowDown } from '@mui/icons-material';
import { format } from 'date-fns';

interface Props {
  label: string;
  value: string;
  onChange: (newDate: string) => void;
  disableBeforeDate?: string;
  errorMessage?: string;
  type?: 'DateTime' | 'Date';
  placeholder?: 'Today' | 'Select';
}

const CustomDatePicker: React.FC<Props> = ({
  onChange,
  value,
  label,
  disableBeforeDate,
  errorMessage,
  type = 'DateTime',
  placeholder = 'Today',
}) => {
  const disableDates = (date: Date): boolean => {
    if (disableBeforeDate) {
      return date <= new Date(disableBeforeDate);
    }
    return false;
  };

  return (
    <CustomInputContainer>
      <CustomInputLabel>{label}</CustomInputLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {type === 'DateTime' ? (
          <DateTimePicker
            components={{
              OpenPickerIcon: value ? CalendarMonth : KeyboardArrowDown,
            }}
            shouldDisableDate={disableBeforeDate ? disableDates : undefined} // Use the function if prop is provided
            disablePast={!disableBeforeDate} // Default behavior of disabling past dates
            value={value ? new Date(value) : null}
            onChange={(e) => {
              if (!e) {
                return;
              }

              if (!new Date(e).valueOf()) {
                return;
              }

              onChange(e?.toISOString() || '');
            }}
            PopperProps={styles.PopperProps}
            PaperProps={styles.PaperProps}
            InputProps={styles.InputProps}
            renderInput={(params) => {
              return (
                <RenderInputTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                  }}
                />
              );
            }}
          />
        ) : (
          <DatePicker
            components={{
              OpenPickerIcon: value ? CalendarMonth : KeyboardArrowDown,
            }}
            shouldDisableDate={disableBeforeDate ? disableDates : undefined} // Use the function if prop is provided
            disablePast={!disableBeforeDate} // Default behavior of disabling past dates
            value={value ? new Date(value) : null}
            onChange={(e) => {
              if (!e) {
                return;
              }

              if (!new Date(e).valueOf()) {
                return;
              }

              onChange(e ? format(e, 'MM/dd/yyyy') : '');
            }}
            PopperProps={styles.PopperProps}
            PaperProps={styles.PaperProps}
            InputProps={styles.InputProps}
            renderInput={(params) => {
              return (
                <RenderInputTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                  }}
                />
              );
            }}
          />
        )}

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LocalizationProvider>
    </CustomInputContainer>
  );
};

const RenderInputTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#5F27CD',
          },
        },
        width: '100%',
        '& input': {
          padding: '10px',
          '&::placeholder': {
            opacity: 1,
            color: '#0B0B0C',
          },
        },
        '& fieldset': {
          borderRadius: '10px !important',
          border: 0,
          outline: 0,
        },
      }}
    />
  );
};

const styles = {
  PopperProps: {
    sx: {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
  },
  PaperProps: {
    sx: {
      '& .MuiClock-pin': {
        backgroundColor: '#5F27CD',
      },
      '& .MuiClockPointer-root': {
        backgroundColor: '#5F27CD',
      },
      '& .MuiButtonBase-root': {
        '&.Mui-active': {
          backgroundColor: '#5F27CD',
        },
      },
      '& .MuiCalendarPicker-root': {
        borderRadius: 2,
        marginTop: 1,
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: '#5F27CD',
        },
      },
    },
  },
  InputProps: {
    sx: {
      borderRadius: '10px',
    },
  },
};

export default CustomDatePicker;
