import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  padding: 125px 100px 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  width: 500px;
  margin-bottom: 30px;
`;

export const BackButton = styled.div`
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: 500px;
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SubmissionButton = styled.div<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#CCCCCC' : '#5F27CD')};
  width: 500px;
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

export const InputTitleContainer = styled.div<{
  align?: string;
  flexDirection?: string;
}>`
  background-color: #d0d2d8;
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center' ? 'center' : 'space-between'};
  flex-direction: ${({ flexDirection }) =>
    flexDirection === 'column' ? 'column' : 'row'};
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 12px;
`;

export const InputTitleText = styled.div`
  color: #222428;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const InputTitleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const InputBodyContainer = styled.div`
  background-color: #f0f0f3;
  padding: 20px 12px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
