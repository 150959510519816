import React, { useState } from 'react';
import { /* Backdrop, Button, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { GamifyToast } from '../../common/CustomToasts';
import './TeamOptionsMenu.css';

export default function TeamOptionsMenu(props) {
  const {
    // setTeamOptionsOpen,
    anchorEl,
    setAnchorEl,
    team,
    user,
    setShowRolePositionModal,
    onTriggerTeamRefetch,
    onRemoveTeamFromState,
    onRemoveMemberFromTeam,
    isOnUserForm,
  } = props;
  //   console.log('TeamOptionsMenu team, user: ', team, user);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleRemoveClick = () => {
    setShowConfirmRemove(true);
  };

  const handleConfirmRemoveClick = async (team, user) => {
    // console.log('handleConfirmRemoveClick teamId, user: ', teamId, user);
    setIsSaving(true);

    // onRemoveMember returns true if successfull, false if no
    const success = await onRemoveMemberFromTeam(user, team.circleID);

    setTimeout(() => {
      setIsSaving(false);
      setShowConfirmRemove(false);
      // setTeamOptionsOpen(false);
      setAnchorEl(null);
    }, 500);

    // End function if onRemoveMember fails
    if (!success) return;

    setTimeout(() => {
      GamifyToast.success(
        `${user.name} removed from ${team.circle.name} successfully`
      );
      if (onTriggerTeamRefetch) onTriggerTeamRefetch();
    }, 500);
  };

  // For removing teams while creating a user before the user has an id:
  const handleConfirmRemoveClickNoUserId = (team) => {
    // console.log('handleConfirmRemoveClickNoUserId team: ', team);
    onRemoveTeamFromState(team.id);
    GamifyToast.success(
      `${user.name} removed from ${team.name} successfully. Save changes below to ensure user is removed.`
    );
    // setTeamOptionsOpen(false);
    setAnchorEl(null);
  };

  // const handleCancelRemoveClick = () => {
  //   setShowConfirmRemove(false);
  // };

  return (
    <>
      {/* NOTE: DynamicConfirmModal has replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmRemove}
        setShowDynamicConfirmModal={setShowConfirmRemove}
        zIndex={100002}
        leftAlignText={true}
        title={`Are you sure you want to remove ${user.name} from ${
          isOnUserForm ? team.name : team.circle.name
        }?`}
        // subtitle={`You can add them back to the team later.`}
        isSaving={isSaving}
        // onCancel={() => {
        // }}
        confirmButtonText={'Remove'}
        confirmButtonColor={'#e35050'}
        onConfirm={() => {
          user.id
            ? handleConfirmRemoveClick(team, user)
            : handleConfirmRemoveClickNoUserId(team);
        }}
      ></DynamicConfirmModal>
      {/* <Backdrop
        sx={{ zIndex: 100001 }}
        open={showConfirmRemove}
        onClick={(e) => {
          e.stopPropagation();
          handleCancelRemoveClick();
        }}
      >
        <Box className={'remove-member-confirmation-container'}>
          <Box className={'remove-member-confirmation-top-container'}>
            <Typography className={'remove-member-confirmation-top-text'}>
              {`Are you sure you want to remove ${user.name} from ${
                isOnUserForm ? team.name : team.circle.name
              }?`}
            </Typography>
            <Typography className={'remove-member-confirmation-bottom-text'}>
              {`Member to remove: ${user.name}`}
            </Typography>
          </Box>
          <Box className={'remove-member-confirmation-bottom-container'}>
            <Button
              className={'cancel-remove-member-button'}
              onClick={(e) => {
                e.stopPropagation();
                handleCancelRemoveClick();
              }}
              disableRipple={true}
            >
              <Typography className={'cancel-remove-member-button-text'}>
                Cancel
              </Typography>
            </Button>
            <Button
              className={'confirm-remove-member-button'}
              onClick={(e) => {
                e.stopPropagation();
                user.id
                  ? handleConfirmRemoveClick(team, user)
                  : handleConfirmRemoveClickNoUserId(team);
              }}
              disableRipple={true}
            >
              <Typography className={'remove-member-button-text'}>
                Remove
              </Typography>
            </Button>
          </Box>
        </Box>
      </Backdrop> */}

      {/* NOTE: CustomMenu and CustomMenuItem components have replaced the custom options menu commented out below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {!isOnUserForm && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowRolePositionModal(true);
              setAnchorEl(null);
            }}
            disableRipple={true}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Change Position</Typography>
              {/* <GamifyIcon icon={'role'} color={'black'}></GamifyIcon> */}
            </Box>
          </CustomMenuItem>
        )}
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveClick();
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                color: '#e35050',
              }}
            >
              Remove from Team
            </Typography>
            {/* <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon> */}
          </Box>
        </CustomMenuItem>
      </CustomMenu>
      {/* <Box className={'option-menu-container'}>
        {!isOnUserForm && (
          <Box className={'option-menu-change-role-permissions-container'}>
            <Button
              disableRipple={true}
              className={'option-menu-top-button'}
              onClick={(e) => {
                e.stopPropagation();
                setShowRolePositionModal(true);
                setTeamOptionsOpen(false);
              }}
            >
              <Typography className={'option-menu-text'}>
                Change Position
              </Typography>
            </Button>
          </Box>
        )}
        <Box className={'option-menu-delete-container'}>
          <Button
            disableRipple={true}
            className={
              isOnUserForm
                ? 'option-menu-only-button'
                : 'option-menu-bottom-button'
            }
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveClick();
            }}
          >
            <Typography className={'option-menu-delete-text'}>
              Remove from team
            </Typography>
          </Button>
        </Box>
      </Box> */}
    </>
  );
}
