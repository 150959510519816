import { styled } from '@mui/material/styles';
import {
  TextField,
  Button,
  // Select,
  TableCell,
  TableRow,
  // MenuItem,
  // Menu,
} from '@mui/material';

export const TitleTableCell = styled(TableCell)`
  color: white;
  font-family: Manrope;
  font-size: 14px;
  height: 3rem;
  line-height: 17.07px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 10px 20px;
  background: #5f27cd;
  fontweight: 700;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    min-width: 30ch;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const UsersTableCell = styled(TableCell)`
  color: #323232;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 0px 20px;
  background-color: #f0f0f3;
  min-width: 25ch;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    max-width: 8ch;
    min-width: 8ch;
  }
`;

export const UsersTableRow = styled(TableRow)`
  // backgroundcolor: transparent;
  // padding: 16px 20px;
  // border-radius: 15px;
`;

export const SearchInput = styled(TextField)(({ theme }) => ({
  width: '312px',
  height: '48px',
  border: '1px solid #D0D2D8',
  borderRadius: 8,
  marginBottom: '11px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5),

  '& .MuiOutlinedInput-root': {
    // background: '#222428',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1.5),
    color: '#222428',
    fontFamily: 'Manrope, sans-serif',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: '#0B0B0C',
  },
}));

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 10px;
  // width: 189px;
  height: 30px;
  background: rgb(238, 238, 238, 0.5);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: black;
  //styleName: Title semibold 16px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: rgb(238, 238, 238, 0.5);
  }
`;
export const AddNewButton = styled(StyledButton)`
  color: white;
  background: #5f27cd;

  :hover {
    background-color: #5f17cd;
  }
`;

export const NavigationButton = styled(Button)`
  &:disabled {
    color: #898989;
  }
`;

// NOTE: components below moved to common folder

// export const CustomMenu = styled(Menu)(() => ({
//   '& .MuiPaper-root': {
//     padding: 0,
//     minWidth: 200,
//     backgroundColor: 'white',
//     border: '1px solid #D0D2D8',
//     borderRadius: '4px',
//   },
//   '& .MuiList-root': {
//     padding: 0,
//   },
// }));
// export const CustomMenuItem = styled(MenuItem)(() => ({
//   fontFamily: 'Manrope, sans-serif',
//   fontSize: '16px',
//   fontWeight: 400,
//   lineHeight: '24px',
//   letterSpacing: '-0.01em',
//   color: 'black',
//   '&:hover': {
//     backgroundColor: '#E6E4FF',
//   },
// }));

// export const StyledSelect = styled(Select)(({ theme }) => ({
//   '& .MuiOutlinedInput-input': {
//     borderRadius: 8,
//     border: '1px solid #D0D2D8',
//     padding: theme.spacing(1.5),
//   },
//   '& .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
//   '&:hover .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
//   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
// }));

// export const StyledMenuItem = styled(MenuItem)(() => ({
//   fontFamily: 'Source Sans Pro',
//   fontSize: '16px',
//   fontWeight: 400,
//   lineHeight: '24px',
//   letterSpacing: '-0.01em',
//   color: 'black',
//   backgroundColor: 'white',
//   '&:hover': {
//     backgroundColor: '#E6E4FF',
//   },
//   '&.Mui-selected': {
//     backgroundColor: '#F0F0F3',
//     '&.Mui-focusVisible': { background: '#F0F0F3' },
//   },
//   '&.Mui-selected:hover': {
//     backgroundColor: '#F0F0F3',
//   },
//   '&:first-of-type': {
//     borderTopLeftRadius: '5px',
//     borderTopRightRadius: '5px',
//   },
//   '&:last-of-type': {
//     borderBottomLeftRadius: '5px',
//     borderBottomRightRadius: '5px',
//   },
// }));
