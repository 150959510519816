import { styled } from '@mui/material/styles';
import {
  TableCell,
  TableContainer,
  TableRow,
  // Menu,
  // MenuItem,
} from '@mui/material';

export const UsersTableCell = styled(TableCell)`
  color: #323232;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  letter-spacing: -0.01em;
  text-align: left;
  // border-width: 10px;
  // border-bottom-color: #1a1a1a;
  padding: 0px 20px;
  background-color: #f0f0f3;
  &:first-of-type {
    /* Change from &:first-child */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    /* Change from &:last-child */
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const UsersTableRow = styled(TableRow)`
  // backgroundcolor: transparent;
  // padding: 16px 20px;
  // border-radius: 15px;
`;

export const TitleTableCell = styled(TableCell)`
  color: white;
  font-family: Manrope;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 10px 20px;
  background: #5f27cd;
  fontweight: 700;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

// export const CustomMenu = styled(Menu)(() => ({
//   '& .MuiPaper-root': {
//     padding: 0,
//     minWidth: 200,
//     backgroundColor: 'white',
//     border: '1px solid #D0D2D8',
//     borderRadius: '4px',
//   },
//   '& .MuiList-root': {
//     padding: 0,
//   },
// }));

// export const CustomMenuItem = styled(MenuItem)(() => ({
//   fontFamily: 'Manrope, sans-serif',
//   fontSize: '16px',
//   fontWeight: 400,
//   lineHeight: '24px',
//   letterSpacing: '-0.01em',
//   color: 'black',
//   '&:hover': {
//     backgroundColor: '#E6E4FF',
//   },
// }));

export const paginationStyle = {
  '& .Mui-selected, & .Mui-selected:hover': {
    borderColor: '#807BF7',
    backgroundColor: 'rgba(128, 123, 247, 0.4)',
    border: '1px solid #807BF7',
    color: 'white',
    borderRadius: '4px',
  },
  '& .Mui-selected .MuiPaginationItem-page, & .Mui-selected:hover .MuiPaginationItem-page':
    {
      color: 'white',
    },
  '& .Mui-selected:focus': {
    background: 'rgba(128, 123, 247, 0.4)',
    border: '1px solid #807BF7',
    borderRadius: '4px',
  },
  '& .MuiPaginationItem-page': {
    color: 'white',
  },
  '& .MuiPaginationItem-page:hover': {
    borderColor: '#807BF7',
    backgroundColor: '#807BF7',
    color: 'white',
    borderRadius: '4px',
  },
  '& .MuiPaginationItem-page:focus': {
    borderColor: '#807BF7',
  },
  '& .MuiPaginationItem-ellipsis': {
    color: 'white',
  },
  '& .MuiPaginationItem-page.Mui-disabled': {
    color: 'white',
    opacity: 0.5,
  },
  '& .MuiPaginationItem-icon': {
    color: 'white',
  },
};

export const ResultsText = styled('p')`
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.09em;
  text-align: center;
  color: white;
`;

export const StyledTableContainer = styled(TableContainer)`
  background-color: #1a1a1a;
`;
