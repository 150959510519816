import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import {
  Box,
  Select,
  MenuItem,
  Backdrop,
  Tooltip,
  Button,
  TextField,
  Switch,
} from '@mui/material';

import './CRMConfig.css';
import './kpis.css';

export default function CreateFlexForm(props) {
  const typeSelectOptions = [
    { label: 'String', value: 'String' },
    { label: 'Notes', value: 'Notes' },
    { label: 'Number', value: 'Number' },
    { label: 'Date', value: 'Date' },
    { label: 'Yes/No', value: 'Boolean' },
    { label: 'List', value: 'List' },
    { label: 'Money', value: 'Money' },
    { label: 'Power', value: 'Power' },
    { label: 'Order', value: 'Order Lines' },
    { label: 'Phone', value: 'Phone' },
    { label: 'Email', value: 'Email' },
    { label: 'Images', value: 'ImageList' },
    { label: 'Read Only', value: 'Read Only' },
  ];
  const doorFieldDataOptions = [
    { label: 'None', value: 'none' },
    { label: 'Name', value: 'name' },
    { label: 'First name', value: 'firstName' },
    { label: 'Last name', value: 'lastName' },
    { label: 'Address', value: 'address' },
    { label: 'Region (State)', value: 'region' },
    { label: 'City', value: 'city' },
    { label: 'Zip (postal Code)', value: 'postalCode' },
    { label: 'Email', value: 'email' },
    { label: 'Phone Number', value: 'phone' },
    { label: 'Cell Phone', value: 'cellPhone' },
  ];
  const newFieldTemp = {
    name: '',
    default: '',
    minLength: 0,
    maxLength: 4192,
    minValue: 0,
    maxValue: 100000000,
    required: false,
    requiredDispositions: [],
    label: '',
    corectValue: '',
    showTogether: [],
    type: 'String',
    heading: '',
    values: [],
    displayField: true,
    pickerDisplayField: false,
    pinMappingField: '',
    hideLabel: false,
  };
  const newListValTemp = { label: '', id: '' };

  const [displayPage, setDisplayPage] = useState('list');
  const [flexFields, setFlexFields] = useState(props.flexFields);
  const [create, setCreate] = useState(false);
  const [editField, setEditField] = useState(newFieldTemp);
  const [ogEditField, setOgEditField] = useState(newFieldTemp);
  const [addShowWithItem, setAddShowWithItem] = useState(false);
  const [selectRequiredDispositions, setSelecRequiredDispositions] =
    useState(false);

  useEffect(() => {
    setFlexFields(props.flexFields);
  }, [props.flexFields]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index.toString());
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData('text'));
    if (draggedIndex !== dropIndex) {
      const temp = [...editField.values];
      const [draggedItem] = temp.splice(draggedIndex, 1);
      temp.splice(dropIndex, 0, draggedItem);
      setEditField((prev) => ({ ...prev, values: temp }));
    }
  };

  const getIndexesAfterDrop = (draggedIndex, dropIndex, mainArray) => {
    const result = {};
    for (let i = 0; i < mainArray.length; i++) {
      if (i === draggedIndex) {
        result[i] = dropIndex;
      } else if (
        dropIndex < draggedIndex &&
        i >= dropIndex &&
        i < draggedIndex
      ) {
        result[i] = i + 1;
      } else if (
        dropIndex > draggedIndex &&
        i > draggedIndex &&
        i <= dropIndex
      ) {
        result[i] = i - 1;
      } else {
        result[i] = i;
      }
    }
    return result;
  };

  const handleFlexFieldDrop = (e, dropIndex, arr) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData('text'));
    if (draggedIndex === dropIndex) return;

    const newIndexes = getIndexesAfterDrop(draggedIndex, dropIndex, arr);

    const temp = [...flexFields];
    const [draggedItem] = temp.splice(draggedIndex, 1);
    temp.splice(dropIndex, 0, draggedItem);

    temp.forEach((ff) => {
      ff.showTogether = ff.showTogether.map((el) => newIndexes[el]);
    });
    setFlexFields(temp);
    props.setter(temp);
  };

  const toggleDisplay = (e) => {
    const val = e.currentTarget.getAttribute('name');
    if (displayPage === 'list') {
      if (val !== 'create-button') {
        setEditField(flexFields[val]);
        setOgEditField(flexFields[val]);
      } else {
        setEditField({ ...newFieldTemp });
        setOgEditField({ ...newFieldTemp });
        setCreate(true);
      }
      setDisplayPage('create');
    } else {
      setCreate(false);
      setEditField(newFieldTemp);
      setDisplayPage('list');
    }
  };

  const deleteListObj = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = editField.values;
    temp.splice(val, 1);
    setEditField((p) => ({ ...p, values: temp }));
  };

  const handleListValChange = (e) => {
    const [ind, key] = e.target.name.split('-');
    const temp = editField.values;
    temp[ind][key] = e.target.value;
    setEditField((p) => ({ ...p, values: temp }));
  };

  const removeShowWithItem = (e) => {
    const fieldIndex = flexFields.indexOf(ogEditField);
    const flexFieldsTemp = [...flexFields];
    flexFieldsTemp.forEach((el) => {
      el.showTogether = el.showTogether.filter((ind) => ind !== fieldIndex);
    });
    let showTogetherTemp = [...editField.showTogether];
    const ind = e.currentTarget.getAttribute('name');
    if (showTogetherTemp.length < 2) showTogetherTemp = [];
    else {
      showTogetherTemp.splice(ind, 1);
    }
    setEditField((p) => ({ ...p, showTogether: showTogetherTemp }));
    props.setter(flexFieldsTemp);
    setFlexFields(flexFieldsTemp);
  };

  const handleAddShowWithItem = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const valIndex = flexFields.findIndex((el) => el.name === val);

    if (valIndex === -1) return;

    const temp = [...editField.showTogether];

    temp.push(valIndex);
    if (temp.length === 1) {
      const fieldIndex = flexFields.indexOf(ogEditField);
      temp.push(fieldIndex);
    }

    setEditField((p) => ({ ...p, showTogether: temp }));
  };

  const deleteFlexField = () => {
    const deleteIndex = flexFields.indexOf(ogEditField);
    const newFlexFields = [...flexFields];
    newFlexFields.splice(deleteIndex, 1);
    newFlexFields.forEach((field) => {
      field.showTogether = field.showTogether.filter(
        (index) => index !== deleteIndex
      );
      if (field.showTogether.length < 2) {
        field.showTogether = [];
      } else {
        field.showTogether = field.showTogether.map((index) =>
          index > deleteIndex ? index - 1 : index
        );
      }
    });
    props.setter(newFlexFields);
    props.updateDisplayFields(newFlexFields);
    setFlexFields(newFlexFields);
    setCreate(false);
    setEditField(newFieldTemp);
    setDisplayPage('list');
  };

  const saveNewField = () => {
    const tempField = { ...editField };
    tempField.showTogether = tempField.showTogether.filter((el) => el !== -1);
    const flexFieldsTemp = [...flexFields, tempField];
    if (tempField.showTogether.length) {
      tempField.showTogether.forEach((ind) => {
        if (ind === -1) return;
        flexFieldsTemp[ind].showTogether.push(flexFieldsTemp.length - 1);
        if (flexFieldsTemp[ind].showTogether.length === 1)
          flexFieldsTemp[ind].showTogether.push(ind);
      });
      tempField.showTogether.push(flexFieldsTemp.length - 1);
    }

    setFlexFields(flexFieldsTemp);
    props.setter(flexFieldsTemp);
    props.updateDisplayFields(flexFieldsTemp);
    setCreate(false);
    setAddShowWithItem(false);
    setEditField(newFieldTemp);
    setDisplayPage('list');
  };

  const saveFieldChanges = () => {
    const fieldIndex = flexFields.indexOf(ogEditField);
    const tempField = { ...editField };
    const flexFieldsTemp = [...flexFields];
    flexFieldsTemp[fieldIndex] = tempField;
    if (
      JSON.stringify(ogEditField.showTogether) !==
      JSON.stringify(editField.showTogether)
    ) {
      tempField.showTogether.forEach((ind) => {
        if (
          flexFieldsTemp[ind].showTogether.length &&
          !flexFieldsTemp[ind].showTogether.includes(fieldIndex)
        )
          flexFieldsTemp[ind].showTogether.push(fieldIndex);
      });
    }
    setFlexFields(flexFieldsTemp);
    props.setter(flexFieldsTemp);
    props.updateDisplayFields(flexFieldsTemp);
    setCreate(false);
    setAddShowWithItem(false);
    setEditField(newFieldTemp);
    setDisplayPage('list');
  };

  const closeBackdrop = () => {
    setDisplayPage('list');
    setEditField(newFieldTemp);
    props.exit();
  };
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.open}
    >
      <Box id="flex-form-backdrop">
        <Box className="backdrop-close-button" onClick={closeBackdrop}>
          X
        </Box>
        <Box
          name="create-button"
          onClick={toggleDisplay}
          className="add-field-button"
        >
          {displayPage === 'list' ? '+' : '<'}
        </Box>
        <Box id="form-top-bar">
          <Text
            // onClick={() => console.log(props.stageDispositions)}
            style={{ fontWeight: 'bold', fontSize: '1.4rem', color: 'white' }}
          >
            {displayPage === 'list' ? 'Flex Fields Form' : 'Create Flex Field'}
          </Text>
        </Box>
        {displayPage === 'list' && (
          <Box id="flex-list-display">
            {flexFields.map((field, ind, list) => (
              <Box
                onClick={toggleDisplay}
                name={ind}
                key={ind}
                draggable={true}
                droppable={true}
                onDragStart={(e) => handleDragStart(e, ind)}
                onDragOver={(e) => handleDragOver(e, ind)}
                onDrop={(e) => handleFlexFieldDrop(e, ind, list)}
                className="flexfield-item-li"
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      width: '30%',
                    }}
                  >
                    {field.label}
                  </Text>
                  <Text style={{ width: '10vw' }}>{field.type}</Text>
                  <Box display="flex" style={{ width: '20vw' }}>
                    <Text style={{ color: 'gray', marginRight: 4 }}>
                      Heading:
                    </Text>
                    <Text>{field.heading}</Text>
                  </Box>
                  {field.showTogether.length > 0 && (
                    <Box style={{ display: 'flex' }}>
                      <Text style={{ color: 'gray', marginRight: '3px' }}>
                        Show with:
                      </Text>
                      {field.showTogether
                        .filter((el) => list[el].label !== field.label)
                        .map((num, i, arr) => (
                          <Text key={i} style={{ marginRight: '4px' }}>
                            {list[num].label}
                            {i < arr.length - 1 ? ', ' : ''}
                          </Text>
                        ))}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {displayPage === 'create' && (
          <Box id="flex-edit-display">
            <Box>
              <Text
                style={{
                  fontWeight: 'bold',
                  margin: '0 15px',
                  fontSize: '1.1rem',
                }}
              >
                Heading
              </Text>
            </Box>
            <TextField
              placeholder="Heading (Used to group like fields together)"
              style={{ width: '100%' }}
              value={editField.heading}
              onChange={(e) =>
                setEditField((p) => ({ ...p, heading: e.target.value }))
              }
            />
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Box style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Field Name
                </Text>
                <TextField
                  placeholder="Field Name (Must match source CRM)"
                  style={{ width: '100%' }}
                  value={editField.name}
                  onChange={(e) =>
                    setEditField((p) => ({ ...p, name: e.target.value }))
                  }
                />
              </Box>
              <Box style={{ width: '2%' }} />
              <Box style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Label
                </Text>
                <TextField
                  placeholder="Label (To be displayed in Gamify forms)"
                  style={{ width: '100%' }}
                  value={editField.label}
                  onChange={(e) =>
                    setEditField((p) => ({ ...p, label: e.target.value }))
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Box style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Type
                </Text>
                <Select
                  style={{ width: '100%' }}
                  value={editField.type}
                  onChange={(e) =>
                    setEditField((p) => ({ ...p, type: e.target.value }))
                  }
                >
                  {typeSelectOptions.map((el, i) => (
                    <MenuItem
                      key={i}
                      id={el.value}
                      value={el.value}
                      style={{
                        backgroundColor: 'white',
                        border: '1px lightgray solid',
                      }}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box style={{ width: '2%' }} />
              <Box style={{ width: '50%' }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Door Field Data
                </Text>
                <Select
                  style={{ width: '100%' }}
                  value={editField.pinMappingField}
                  onChange={(e) =>
                    setEditField((p) => ({
                      ...p,
                      pinMappingField: e.target.value,
                    }))
                  }
                >
                  {doorFieldDataOptions.map((el, i) => (
                    <MenuItem
                      key={i}
                      id={el.value}
                      value={el.value}
                      style={{
                        backgroundColor: 'white',
                        border: '1px lightgray solid',
                      }}
                      className="door-data-menu-item"
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            {['String', 'Notes'].includes(editField.type) && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: ' center',
                  marginTop: '10px',
                }}
              >
                <Box style={{ width: '50%', display: 'flex' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      margin: '0 15px',
                      fontSize: '1.1rem',
                    }}
                  >
                    what is the minimum length for this field?
                  </Text>
                  <TextField
                    placeholder="0"
                    value={editField.minLength}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setEditField((p) => ({
                          ...p,
                          minLength: e.target.value,
                        }));
                      }
                    }}
                  />
                </Box>
                <Box style={{ width: '2%' }} />
                <Box style={{ width: '50%', display: 'flex' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      margin: '0 15px',
                      fontSize: '1.1rem',
                    }}
                  >
                    What is the maximum length for this field?
                  </Text>
                  <TextField
                    placeholder={editField.type === 'String' ? '64' : '4000'}
                    value={editField.maxLength}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setEditField((p) => ({
                          ...p,
                          maxLength: e.target.value,
                        }));
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            {['Number', 'Money'].includes(editField.type) && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Box style={{ width: '50%', display: 'flex' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      margin: '0 15px',
                      fontSize: '1.1rem',
                    }}
                  >
                    What is the minimum value for this field?
                  </Text>
                  <TextField
                    value={editField.minValue}
                    placeholder="0"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setEditField((p) => ({
                          ...p,
                          minValue: e.target.value,
                        }));
                      }
                    }}
                  />
                </Box>
                <Box style={{ width: '2%' }} />
                <Box style={{ width: '50%', display: 'flex' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      margin: '0 15px',
                      fontSize: '1.1rem',
                    }}
                  >
                    What is the maximum value for this field?
                  </Text>
                  <TextField
                    placeholder="1000000000"
                    value={editField.maxValue}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setEditField((p) => ({
                          ...p,
                          maxValue: e.target.value,
                        }));
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            {editField.type === 'List' && (
              <Box
                style={{
                  //   display: 'flex',
                  //   alignItems: 'center',
                  width: '100%',
                  marginTop: '10px',
                  border: '1px solid lightgray',
                  borderRadius: '10px',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'lightgray',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                >
                  <Text
                    style={{
                      width: '50%',
                      fontWeight: 'bold',
                      padding: '10px',
                      fontSize: '1rem',
                      borderRight: '1px solid white',
                    }}
                  >
                    Label
                  </Text>
                  <Text
                    style={{
                      width: '45%',
                      fontWeight: 'bold',
                      padding: '10px',
                      fontSize: '1rem',
                    }}
                  >
                    ID
                  </Text>
                  <Box
                    className="hov"
                    style={{
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'black',
                      color: 'white',
                      borderRadius: '50%',
                    }}
                    onClick={() => {
                      const temp = editField.values;
                      temp.push(newListValTemp);
                      setEditField((p) => ({ ...p, values: temp }));
                    }}
                  >
                    +
                  </Box>
                </Box>
                {editField.values.length === 0 && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '5px',
                      fontWeight: 'bold',
                      color: 'gray',
                    }}
                  >
                    {'Click the "+" button to add a list item'}
                  </Box>
                )}
                {editField.values.map((obj, ind) => {
                  // console.log(obj, arr);
                  return (
                    <Box
                      draggable={true}
                      droppable={true}
                      onDragStart={(e) => handleDragStart(e, ind)}
                      onDragOver={(e) => handleDragOver(e, ind)}
                      onDrop={(e) => handleDrop(e, ind)}
                      key={ind}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <input
                        name={`${ind}-label`}
                        placeholder="Label"
                        type="text"
                        value={editField.values[ind].label}
                        style={{
                          width: '50%',
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderTop: 'none',
                          padding: '5px 10px',
                          fontSize: '1rem',
                        }}
                        onChange={handleListValChange}
                      />
                      <input
                        name={`${ind}-id`}
                        placeholder="ID"
                        type="text"
                        value={editField.values[ind].id}
                        style={{
                          width: '50%',
                          borderRight: 'none',
                          borderLeft: '1px solid lightgray',
                          borderTop: 'none',
                          padding: '5px 10px',
                          fontSize: '1rem',
                        }}
                        onChange={handleListValChange}
                      />
                      <Box
                        name={ind}
                        className="hov"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '25px',
                          height: '25px',
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          color: 'white',
                          backgroundColor: 'rgb(225, 147, 147)',
                          borderRadius: '15px',
                        }}
                        onClick={deleteListObj}
                      >
                        X
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: ' center',
                marginTop: '10px',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Hide Label on Gamify Form?
                </Text>
                <Switch
                  checked={editField.hideLabel}
                  onChange={() =>
                    setEditField((p) => ({ ...p, hideLabel: !p.hideLabel }))
                  }
                />
              </Box>
              <Box
                style={{
                  position: 'relative',
                  width: '50%',
                }}
              >
                <Box
                  style={{
                    // width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      margin: '0 15px',
                      fontSize: '1.1rem',
                    }}
                  >
                    Required?
                  </Text>
                  <Switch
                    checked={editField.required}
                    onChange={() =>
                      setEditField((p) => ({ ...p, required: !p.required }))
                    }
                  />
                  {/* WORK HERE */}
                </Box>

                {editField.required && (
                  <React.Fragment>
                    <Box display="flex">
                      <Text marginRight={4}>Select dispositions</Text>
                      <Text color="gray">
                        {'(selecting none will require for all)'}
                      </Text>
                    </Box>
                    <Box display="flex" position="relative">
                      <Box className="selected-dispositions-box">
                        {!editField.requiredDispositions ||
                        editField.requiredDispositions.length === 0 ? (
                          <Text>ALL</Text>
                        ) : (
                          <>
                            {editField.requiredDispositions.map((el, i) => {
                              const item = props.stageDispositions.find(
                                (x) => x.id === el
                              );
                              // console.log('item', item);
                              return (
                                <Box
                                  style={{
                                    backgroundColor: 'white',
                                    borderRadius: 4,
                                    padding: '2px 5px',
                                  }}
                                  key={i}
                                  onClick={() => {
                                    const temp = { ...editField };

                                    temp.requiredDispositions.splice(i, 1);
                                    setEditField(temp);
                                  }}
                                >
                                  <Text>{item?.title}</Text>
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </Box>
                      <Box
                        className="add-required-dispositions-button hov"
                        onClick={() => setSelecRequiredDispositions((p) => !p)}
                      >
                        {selectRequiredDispositions ? '-' : '+'}
                      </Box>
                    </Box>
                    {selectRequiredDispositions && (
                      <Box className="required-dispositions-selection-box">
                        {props.stageDispositions
                          .filter(
                            (el) =>
                              !editField.requiredDispositions?.includes(el.id)
                          )
                          .map((el) => (
                            <Box
                              style={{
                                borderRadius: 4,
                                backgroundColor: 'white',
                                whiteSpace: 'nowrap',
                                padding: '2px 5px',
                              }}
                              key={el.id}
                              onClick={() => {
                                // console.log(el.id);
                                const temp = { ...editField };
                                if (!temp.requiredDispositions) {
                                  temp.requiredDispositions = [];
                                }
                                temp.requiredDispositions.push(el.id);
                                setEditField(temp);
                              }}
                            >
                              {el.title}
                            </Box>
                          ))}
                      </Box>
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Display in Dropdown List?
                </Text>
                <Switch
                  checked={editField.pickerDisplayField}
                  onChange={() =>
                    setEditField((p) => ({
                      ...p,
                      pickerDisplayField: !p.pickerDisplayField,
                    }))
                  }
                />
              </Box>
              <Box
                style={{
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    margin: '0 15px',
                    fontSize: '1.1rem',
                  }}
                >
                  Display Field?
                </Text>
                <Switch
                  checked={editField.displayField}
                  onChange={() =>
                    setEditField((p) => ({
                      ...p,
                      displayField: !p.displayField,
                    }))
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  margin: '0 15px',
                  fontSize: '1.1rem',
                }}
              >
                Show With
              </Text>
              <Box id="show-with-container" style={{ flex: 1 }}>
                <Box style={{ display: 'flex' }}>
                  {editField.showTogether.map((ele, ind) => {
                    if (
                      !flexFields[ele] ||
                      flexFields[ele].name === editField.name
                    )
                      return null;
                    return (
                      <Box
                        key={ind}
                        name={ind}
                        className="show-with-el"
                        onClick={removeShowWithItem}
                      >
                        <Tooltip title="Click to Remove" placement="top">
                          <Text style={{ color: 'white' }}>
                            {flexFields[ele].name}
                          </Text>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  onClick={() => setAddShowWithItem((p) => !p)}
                  className="show-with-el"
                  style={{ color: 'white', fontWeight: 'bold' }}
                >
                  {`${addShowWithItem ? 'DONE' : 'ADD+'}`}
                </Box>
              </Box>
            </Box>
            {addShowWithItem && (
              <Box id="add-show-with-options">
                {flexFields.map((ele, ind) => {
                  if (
                    flexFields[ind].name === editField.name ||
                    editField.showTogether.includes(ind)
                  )
                    return null;
                  return (
                    <Box
                      key={ind}
                      name={ele.name}
                      className="show-with-el"
                      style={{ color: 'white' }}
                      onClick={handleAddShowWithItem}
                    >
                      {ele.name}
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box
              style={{
                marginTop: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                onClick={() => (create ? saveNewField() : saveFieldChanges())}
              >{`${create ? 'SAVE FLEX FIELD' : 'UPDATE FLEX FIELD'}`}</Button>
              {create ? (
                <></>
              ) : (
                <Button
                  onClick={deleteFlexField}
                  color="error"
                  variant="contained"
                >
                  DELETE FLEX FIELD
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Backdrop>
  );
}
